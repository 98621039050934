import { Box, Button, Dialog, Drawer, Grid, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
  buttonStyle: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    // padding: "12px 16px",
    boxShadow: "0px 4px 16px #5C86CB2E",
    height: "99px",
    cursor: "pointer",
    display: "flex"
  },
  lableStyle: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
  },
  submitBtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  sideBorder: {
    backgroundColor: (props) => props?.data?.map(item => item.borderColor),
    margin: " 3px 0px",
    borderRadius: "0px 10px 10px 0px",
    border: (props) => `3px solid ${props?.data?.map(item => item.borderColor)}`
  },
  img: {
    backgroundColor: "white",
    padding: "8px",
    borderRadius: "20px"

  }
}));
export const InformationAndServiceButton = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setopen] = React.useState(false);

  return (
    <>
      <Grid container spacing={1}>
        {props?.data?.map((item) => {
          return (
            <>
              <Grid
                item
                xs={4}
                spacing={1}
                onClick={() => {
                  if (item?.noavailable) {
                    setopen(true);
                  } else if (item?.link === "/vehicle") {
                    history.push(
                      {
                        pathname: item.link,
                        state: {
                          main: {
                            agreement: item.data
                          }
                        }
                      }
                    )
                  } else if (item?.link) {
                    history.push(item?.link + "?Id=" + item?.data);
                  } else {

                  }
                }}
              >
                <div className={classes.buttonStyle} style={{ background: item?.color ? item?.color : "" }} >
                  <Box
                    style={{
                      backgroundColor: item.borderColor,
                      margin: "3px 0px",
                      borderRadius: "0px 10px 10px 0px",
                      border: `3px solid ${item.borderColor}`
                    }}
                  />
                  <div style={{ flex: "auto", padding: "12px 16px" }}>
                    <center>
                      <Box height="4px" />
                      <img src={item.icon} alt="" className={item?.color ? classes.img : null} />
                      <Box height="8px" />
                      <Typography className={classes.lableStyle}>
                        {item.lable}
                      </Typography>
                      <Box height="6px" />
                    </center>
                  </div>
                </div>
              </Grid>
            </>
          );
        })}
      </Grid>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={() => setopen(false)}
          className={classes.dialog}
        >
          <Box p={1.5}>
            <center>
              <img src="/images/noavailabel.svg" alt="" />
            </center>
            <Button
              onClick={() => setopen(false)}
              variant="contained"
              fullWidth
              className={classes.submitBtn}
            >
              Close
            </Button>
          </Box>
        </Dialog>
      </Hidden>
      {/* Renewl Form Drawer*/}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={open}
          onClose={() => setopen(false)}
        >
          <Box p={1.5}>
            <center>
              <img src="/images/noavailabel.svg" alt="" />
            </center>
            <Button
              variant="contained"
              onClick={() => setopen(false)}
              fullWidth
              className={classes.submitBtn}
            >
              Close
            </Button>
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};
