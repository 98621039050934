import React, { useState } from "react";
import { FormGenerator } from "../../../components";
import { loadOptions, NetWorkCallMethods } from "../../../utils";
import {
  GET_MAINTENANCE_CATEGORY_NEW as GET_MAINTENANCE_CATEGORY,
  GET_MAINTENANCE_SUB_CATEGORY_NEW as GET_MAINTENANCE_SUB_CATEGORY,
} from "../../../graphql/queries";
import { NetworkCall } from "../../../networkcall";
import { config } from "../../../config";

export const Step1 = (props) => {
  const { state, updateState, t } = props;
  // state
  const [loading1, setLoading1] = useState(null);

  const loadOption = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "unit":
        result = await saveGeneralUnits(offset, 10, search);
        console.log(result);
        setLoading1(null);
        const unitList = result?.data?.data?.map((x) => {
          return {
            ...x,
            value: x?.id,
            label: x?.name,
          };
        });
        return {
          options: [...unitList],
          hasMore:
            array?.length + result?.data?.data?.length < result?.data?.count,
        };

      default:
        return { options: [] };
    }
  };

  // get units
  const saveGeneralUnits = async (offset, limit, search) => {
    const saveGeneralRequestPayload = {
      offset: offset,
      limit: limit,
      search: search ?? "",
    };
    const result = await NetworkCall(
      `${config.api_url}/owner/get_all_owner_units`,
      NetWorkCallMethods.post,
      saveGeneralRequestPayload,
      null,
      true,
      false
    );
    return result;
  };

  return (
    <div>
      <FormGenerator
        components={[
          {
            isActive: true,
            component: "select",
            label: t("Category"),
            value: state?.Category,
            placeholder: t("Category"),
            onChange: (value) => updateState("Category", value),
            error: state?.error?.Category,
            isRequired: true,
            size: {
              xs: 12,
            },
            isPaginate: true,
            loadOptions: (search, array, handleLoading) =>
              loadOptions(
                search,
                array,
                handleLoading,
                GET_MAINTENANCE_CATEGORY,
                "maintenance_category_master",
                { isActive: true },
                { label: "label", value: "value" },
                false,
                false
              ),
          },
          {
            isActive: true,
            component: "select",
            label: t("Sub-Category"),
            value: state?.subCategory,
            placeholder: t("Sub-Category"),
            onChange: (value) => updateState("subCategory", value),
            error: state?.error?.subCategory,
            isRequired: true,
            size: {
              xs: 12,
            },
            isPaginate: true,
            loadOptions: (search, array, handleLoading) =>
              loadOptions(
                search,
                array,
                handleLoading,
                GET_MAINTENANCE_SUB_CATEGORY,
                "maintenance_sub_category_master",
                { isActive: true },
                { label: "label", value: "value" },
                false,
                false,
                state?.Category?.value ?? ""
              ),
            key: JSON.stringify(state?.Category),
          },
          {
            isActive: true,
            component: "select",
            label: t("Unit"),
            value: state?.Unit,
            placeholder: t("Unit"),
            onChange: (value) => updateState("Unit", value),
            error: state?.error?.Unit,
            isRequired: true,
            size: {
              xs: 12,
            },
            isPaginate: true,
            loadOptions: (search, array) => loadOption(search, array, "unit"),
            loading: loading1 === "unit" ? true : false,
          },
          {
            isActive: true,
            component: "text",
            label: t("Title"),
            value: state?.Title,
            placeholder: t("Title"),
            onChange: (e) => updateState("Title", e?.target?.value),
            error: state?.error?.Title,
            isRequired: true,
            size: {
              xs: 12,
            },
            isPaginate: true,
          },
          {
            isActive: true,
            component: "text",
            label: t("Description"),
            value: state?.Description,
            placeholder: t("Description"),
            onChange: (e) => updateState("Description", e?.target?.value),
            error: state?.error?.Description,
            multiline: true,
            size: {
              xs: 12,
            },
            isPaginate: true,
          },
          {
            isActive: true,
            component: "date",
            label: t("Since"),
            value: state?.problemSince,
            placeholder: t("Since"),
            onChange: (value) => updateState("problemSince", value),
            error: state?.error?.problemSince,
            maxDate: new Date(),
            isRequired: true,
            size: {
              xs: 12,
            },
          },
          {
            isActive: true,
            component: "datePickerWithTime",
            label: t("Visit"),
            value: state?.visitorDate,
            placeholder: t("Visit"),
            onChange: (value) => updateState("visitorDate", value),
            error: state?.error?.visitorDate,
            minDate: new Date(),
            isRequired: true,
            size: {
              xs: 12,
            },
          },
        ]}
      />
    </div>
  );
};
