import makeStyles from "@mui/styles/makeStyles";
export const useStyle = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme?.palette?.primary?.contrastText,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 6px 10px ${theme?.palette?.boxShadow?.primary}`,
    padding: "8px",
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      color: theme?.palette?.background?.tertiary1,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  content: {
    height: (props) => props?.size?.height - 125,
    overflow: "auto",
    padding: "16px",
  },
  asset_card: {
    pointer: "cursor",
    objectFit: "cover",
    borderRadius: "4px",
    height: "106px",
    overflow: "auto",
    width: "100%"
  }
}));
