import makeStyles from "@mui/styles/makeStyles";
import { Regular } from "../../../utils";
export const ParkinguseStyles = makeStyles((theme) => ({
    root: {
        padding: "0px",
        backgroundColor: '#F2F5FA'
    },
    roots: {
        margin: "0px 12px",
        padding: "0px 4px",
    },
    image: {
        objectFit: "cover",
        width: "64px",
        borderRadius: theme.palette.borderRadius,
        height: "60px",
        border: "1px solid #5C86CB2E",
    },
    card: {
        backgroundColor: "white",
        marginTop: "12px",
        borderRadius: "16px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        cursor: "pointer",
    },
    name: {
        fontSize: "14px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",

        marginTop: "-8px",
    },
    name1: {
        fontSize: "12px",
        fontFamily: Regular,

        marginTop: "6px",
        color: "#4E5A6B"
    },
    total: {
        fontSize: "16px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: "#071741",
        marginTop: "12px",
    },
    arrow: {
        border: "1px solid #E4E8EE",
        backgroundColor: "#F2F4F7",
        fontSize: "10px",
    },
    filter: {
        borderRadius: "6px",
        border: "1px solid #E4E8EE",
        color: "black",
        backgroundColor: "white",
    },
    search: {
        "&.MuiOutlinedInput-root": {
            height: '20px !important',
            border: '1px solid #E4E8EE',
            '&:focus': {
                border: '1px solid #E4E8EE',
            }
        }
    },
    extraDetailsStyle: {
        fontSize: "12px",
        color: "#4E5A6B",
    },
    verticalDividerStyle: {
        backgroundColor: "#E4E8EE",
        width: "1px",
        height: "20px",
    },
    horizontalDividerStyle: {
        backgroundColor: "#E4E8EE",
        height: "1px",
    },
    bottomCard: {
        padding: "8px 12px 12px 12px",
    },
    title: {
        color: '#071741',
        fontSize: '14px',
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    },
    bottomTitle: {
        fontSize: "18px",
        fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "8px",
    },
    filterDrawer: {
        "& .MuiDrawer-paper": {
            width: "-webkit-fill-available",
        },
    },
    contentBottom: {
        padding: "12px",
    },
    unitImg: {
        height: "250px",
        width: "100%",
        borderRadius: "20px",
        objectFit: "cotain",
    },
    titleroot2: {
        borderBottom: "1px solid #E4E8EE",

        fontSize: "12px",
        color: theme.typography.color.secondary,
        flexFlow: 'wrap !important'
    },
    parkingNo: {
        fontSize: "14px",
        color: theme.typography.color.secondary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif"
    },
    phone: {
        borderRadius: "10px",
        border: "1px solid #E4E8EE",
    },
    bottomDetails: {
        fontSize: "14px",
        color: theme.typography.color.primary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
    viewHandbook: {
        color: theme.palette.primary.main,
        fontSize: "14px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        cursor: "pointer",
    },

    screenPadding: {
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    call: {
        fontSize: "16px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        textDecoration: "none",
        color: theme.typography.color.secondary,
    },
    callbox: {
        cursor: "pointer",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "16px",
            borderTopLeftRadius: "16px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    bottomsub: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '4px',
        fontSize: '14px'
    },
    drawermain: {
        padding: '0px 8px 0px 5px',
        backgroundColor: '#fff'
    },
    drawermain1: {
        padding: '0px 0px 0px 5px',
        backgroundColor: '#fff'
    },
    parkingImg: {
        height: "200px",
        width: "100%",
        objectFit: "cover",
        borderRadius: "12px"
    },
    rootdetails: {
        backgroundColor: "white"
    },
    dotCss: {
        background: "rgb(206, 211, 221)",
        width: "6px",
        height: "6px",
        display: "inline-block",
        borderRadius: "50%",
        margin: "2px 4px 2px 8px"
    },
    unitIdCss: {
        fontSize: 12
    },
    imageProfiles: {
        objectFit: "cover",
        width: "64px",
        borderRadius: theme.palette.borderRadius,
        height: "60px"
    },
    emailCss: {
        borderRightWidth: "1px",
        borderRightColor: "#e4e8ed",
        borderRightStyle: "solid"
    },
    bottomIcon: {
        fontSize: "12px",
        fontFamily: Regular,
        color: "#4E5A6B"
    },
    imageProfile: {
        objectFit: "cover",
        width: "60px",
        borderRadius: "50%",
        height: "60px",
        border: "1px solid #5C86CB2E",
    }
}));