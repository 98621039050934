import { Avatar, Box, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    overflow: "hidden",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  Cardcontent: {
    // [theme.breakpoints.up("sm")]: {
    //   padding: "8px 12px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px 7px",
    // },
    // borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#CED3DD",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "tenant_regular",
    marginTop: "5px"
  },
  avatar: {
    height: "120px",
    width: "110px",
    objectFit: "contain",
    [theme.breakpoints.down(321)]: {
      height: "170px",

    },
  },
  rejected: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#FF4B4B",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    alignItems: "center"
  },
}));

export const DomesticCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <div
        className={classes.card}
        onClick={() =>
          history.push(Routes.domesticDetails + "?passId=" + props?.data?.id)
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex" position={"relative"}>
            {props?.data?.helper_image ?
              <Avatar src={props?.data?.helper_image} alt={props?.data?.helper_image} variant="rounded" className={classes.avatar} /> :
              // <Avatar src="/images/workergate.svg" className={classes.avatar} variant="rounded" />
              <Avatar className={classes.avatar} variant="rounded" />
            }
            <div className={classes.bottomText}>
              <Typography fontSize={"10px"}>
                {props?.data?.reference_id}
              </Typography>
            </div>
            {/* */}
            <Box flexGrow={1} marginLeft="10px">
              <Box className={classes.details} justifyContent={"space-between"}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    <Hidden smUp>Domestic Help ...</Hidden>
                    <Hidden smDown>Domestic Help Request</Hidden>
                  </Typography>
                </Box>

                <Box marginRight="10px">
                  {props?.data?.request_status === "Pending" && (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      className={classes.progress}
                    >
                      Pending
                    </Typography>
                  )}
                  {props?.data?.request_status === "Approved" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.approved}
                    >
                      Approved
                    </Typography>
                  )}
                  {props?.data?.request_status === "Rejected" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.rejected}
                    >
                      Rejected
                    </Typography>
                  )}
                  {props?.data?.request_status === "Cancelled" && (
                    <Typography variant="subtitle2" className={classes.delete}>
                      Cancelled
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box className={classes.details}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.sub}
                    noWrap
                  >
                    {props?.data?.name}
                  </Typography>
                </Box>
                <Box className={classes.dot} />

                <Box>
                  <Typography variant="subtitle2" className={classes.sub}>
                    {props?.data?.unit_name}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="subtitle2" className={classes.sub} noWrap>
                {props?.data?.profession}
              </Typography>
              <Typography variant="subtitle2" className={classes.sub} noWrap>
                {props?.data?.raised_on ? "Requested at :" +
                  moment(props?.data?.raised_on)
                    .format("DD MMM YY hh:mm")
                  : ""}
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
