import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular } from "../../utils";
export const useStyle = makeStyles((theme) => ({
  root: {
    padding: "16px",
    backgroundColor: theme?.palette?.primary?.contrastText,
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    borderRadius: "4px",
    cursor: "pointer",
    position: "relative",
    boxShadow:'0px 0px 20px #0000001A'
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: `1px solid ${theme?.palette?.border?.primary}`,
      "& :hover": {
        border: `1px solid ${theme?.palette?.primary?.main}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "0px" : "11px 14px"),
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 12px",
  },
  filter: {
    backgroundColor: "white",
    padding: "14px",
    borderRadius: "4px",
    border: `1px solid ${theme?.palette?.border?.primary}`,
    marginLeft: "10px",
  },
  dot: {
    color: theme?.palette?.primary?.main,
  },
  unitname: {
    color: theme?.typography?.color?.primary,
    fontSize: "14px",
    fontFamily: Bold,
  },
  agreementNumber: {
    fontFamily: Regular,
    fontSize: "12px",
    color: theme?.palette?.primary?.main,
    padding: "2px 6px",
    borderRadius: "4px",
    backgroundColor: theme?.palette?.background?.badge,
    maxWidth: "118px",
    width: "100%",
    marginLeft: "10px",
  },
  propertyName: {
    fontFamily: Regular,
    fontSize: "12px",
    color: theme?.typography?.color?.third,
  },
  dot1: {
    backgroundColor: theme?.palette?.border?.primary,
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    margin: "0px 10px",
  },
  flagName: {
    color: "white",
    fontSize: "14px",
    fontFamily: Bold,
  },
  flag: {
    backgroundColor: (props)=>props?.color ?? theme?.palette?.piechart?.primary,
    padding: "2px 8px",
    position: "absolute",
    top: "11px",
    right: "-10px",
    borderRadius: "4px",
    boxShadow:'0px 3px 6px #00000029'
  },
  flagChild: {
    height: "10px",
    width: "11px",
    backgroundColor: (props)=>props?.color ?? theme?.palette?.piechart?.primary,
    bottom: "-8px",
    right: "-8px",
    position: "absolute",
    borderBottomRightRadius:'195px'
  },
}));
