import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Badge,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import {
  Filter,
  LoderSimmer,
  TopNavBars,
  UnitAndRequestCard,
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  NetWorkCallMethods,
  requsetStatus,
  enumSelect,
  enumCatagory,
  LocalStorageKeys,
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import { withNamespaces } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { GET_AGREEMENT_UNITS } from "../../graphql/queries";

const RequestHomeScreen = (props) => {
  const {t} = props
  const size = useWindowDimensions();
  const client = useApolloClient();
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [open, setOpen] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const [unitsfil, setUnitsfil] = React.useState([]);
  const [offSet, setOffSet] = React.useState(0);
  const [requestList, setRequestList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [requestsListCount, setRequestsListCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [statusType, setStatusType] = React.useState([]);
  const { state } = useLocation()
  const onClick = () => {
    history.push(Routes.requestDetails);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };

  //get requset
  const getAllRequsetData = (units, type, gen, offset, filter) => {
    const requestPayload = {
      tenantId: `${config.tenantid}`,
      request_units: units,
      request_type: type,
      request_status: gen,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/request`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((response) => {
        setRequestsListCount(response?.data?.totalCount ?? "");
        if (filter) {
          setRequestList(response?.data?.data ?? []);
        } else {
          setRequestList(requestList.concat(response?.data?.data));
        }


        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // alert.setSnack({
        //   ...alert,
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: "Some Thing Went Wrong.",
        // });
      });
  };
  //get all units by user id
  const getAllunitsByuserId = () => {
    let tempDecoded;
    tempDecoded = jwt_decode(authToken);
    const dashboardPayload = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
    };
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      dashboardPayload,
      null,
      true,
      false
    )
      .then((response) => {
        let agreement = response?.data?.data?.agreements?.result?.map((x) => x?.id)
        let data = response?.data?.data?.units?.result;
        const request_units = data?.map(({ id }) => id);


        getagreementUnitid(agreement, request_units)


      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  const getagreementUnitid = (v, x) => {
    client.query({
      query: GET_AGREEMENT_UNITS(),
      fetchPolicy: 'network-only',
      variables: {
        agreementId: v,
        unitId: x,
      }
    }).then((res) => {
      getEnum(res?.data?.agreement_units?.map((x) => x?.id))
      //get units for filter
      const allunits = res?.data?.agreement_units?.map((val, index) => {
        let _d;
        try {
          _d = {
            label: val?.unit?.name,
            value: String(val.id),
          };
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }

        return _d;
      });
      setUnitsfil(allunits.flat());
    }).catch((err) => {

    })
  }
  React.useEffect(() => {
    getAllunitsByuserId();
    // eslint-disable-next-line
  }, []);

  const filterData = [
    {
      id: "1",
      title: "Request Type",
      key: "request_type",
      filterType: "CHECKBOX",
      values: [
        {
          label: "General",
          value: requsetStatus.general,
        },
        {
          label: "Maintenance",
          value: requsetStatus.maintenance,
        },
      ],
    },

    {
      id: "2",
      title: "Units",
      key: "units",
      filterType: "CHECKBOX",
      values: unitsfil,
    },
    {
      id: "3",
      title: "Requset Status",
      key: "general",
      filterType: "CHECKBOX",
      values: statusType,
    },

  ];

  const applyFilter = (data) => {
    if (data?.request_type?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonerequesttype"),
      });
      return false;
    }
    if (data?.units?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    if (data?.general?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonegeneralstatus"),
      });
      return false;
    }

    getAllRequsetData(
      data?.units,
      data?.request_type,
      data?.general,
      0,
      true
    );
    setOffSet(0);
    setDrawer(false);
  };
  const fetchMoreData = () => {
    setOffSet(offSet + 10);
    getAllRequsetData(
      selectedFilter?.units,
      selectedFilter?.request_type,
      selectedFilter?.general,
      offSet + 10,
      false
    );
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.general_maintenance_type])
    setStatusType(stayType?.general_maintenance_type)
    getAllRequsetData(
      data,
      state ? state : [requsetStatus.general, requsetStatus.maintenance],
      stayType?.general_maintenance_type?.map(val => val?.value),
      0,
      false
    );
    setSelectedFilter({
      units: data,
      general: stayType?.general_maintenance_type?.map(val => val?.value),
      request_type: state ? state : [requsetStatus.general, requsetStatus.maintenance],
    });

  }
  return (
    <Container className={classes.root} maxWidth="sm">
      <TopNavBars is_logo />
      <Box
        display="flex"
        alignItems="center"
        marginBottom="10px"
        sx={{
          padding: "8px 16px 0px  16px",
        }}
      >
        {/* Total Request */}
        <Box flexGrow={1}>
          <Typography className={classes.total}>
            {t("Request")}&nbsp;({requestsListCount ?? '0'})
          </Typography>
        </Box>
        <Box>
          {/* Filter*/}

          <IconButton className={classes.filter} onClick={draweropen}>
            <Badge variant="dot" color="primary">
              <img src="/images/icons8-funnel.svg" alt="filter" />
            </Badge>
          </IconButton>
        </Box>
      </Box>
      <div>
        <Grid className={classes.screenScrollEffect}>
          <Grid container sx={{ backgroundColor: "#F2F4F7" }}>
            <Grid className={classes.screen} item xs={12}>
              {loading ? (
                <LoderSimmer count={10} />
              ) : (
                <>
                  {requestList?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={requestList?.length ?? null}
                      next={fetchMoreData}
                      hasMore={true}
                      height={size?.height - 190}
                    >
                      <Box height="12px" />
                      {requestList?.map((val) => (
                        <div style={{ padding: "0px 7px 0px 12px" }}>
                          <UnitAndRequestCard
                            data={val}
                            onClick={onClick}
                            type="request"
                          />
                          <Box height={'12px'} />
                        </div>
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <Typography className={classes.menuText} textAlign="center">
                      {t("Norecordsfound")}
                    </Typography>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12} style={{ position: "relative" }}>
              <Box sx={{ display: "flex", float: "right" }}>
                <Box>
                  <Fab
                    color="primary"
                    className={classes.fab}
                    aria-label="add"
                    onClick={handleToggle}
                  >
                    {open ? <CloseIcon /> : <AddIcon />}
                    <Backdrop
                      className={classes.backdrop}
                      open={open}
                      onClick={handleClose}
                    >
                      <div className={classes.menuItem}>
                        <MenuItem onClick={() => history.push(Routes.general)}>
                          <Typography className={classes.menuText}>
                            &nbsp; {t("General")}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => history.push(Routes.maintenanceParent)}
                        >
                          <Typography className={classes.menuText}>
                            &nbsp; {t("Maintenance")}
                          </Typography>
                        </MenuItem>
                      </div>
                    </Backdrop>
                  </Fab>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>


      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={drawerclose}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  agreement: selectedFilter?.agreement,
                  general: selectedFilter?.general,
                  units: selectedFilter?.units,
                  request_type: selectedFilter?.request_type,
                  maintanance: selectedFilter?.maintanance,
                }}
                unitsfil={unitsfil}
                setUnitsfil={setUnitsfil}
                onApply={applyFilter}
                setRequestList={setRequestList}
                onClose={drawerclose}
                defaultData={selectedFilter}
                request
                setSelectedFilter={setSelectedFilter}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.filterDrawer}
          open={drawer}
          onClose={drawerclose}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  agreement: selectedFilter?.agreement,
                  general: selectedFilter?.general,
                  units: selectedFilter?.units,
                  request_type: selectedFilter?.request_type,
                  maintanance: selectedFilter?.maintanance,
                }}
                unitsfil={unitsfil}
                setUnitsfil={setUnitsfil}
                onApply={applyFilter}
                onClose={drawerclose}
                defaultData={selectedFilter}
                request
                setSelectedFilter={setSelectedFilter}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("request")(RequestHomeScreen);
