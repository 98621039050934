import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  // Grid, 
  IconButton, Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { AgreementList } from "../agreementlist/index";
const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    padding: "12px",
    boxShadow: "0px 4px 16px #5C86CB2E",
    cursor: "pointer",
    minHeight: "94px"
    // boxShadow: "0px 4px 16px #53668523",
  },
  lableStyle: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
  },
  Tittle: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
    marginBottom: "12px",
  },
  Tittle1: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: "#5078E1",
    marginBottom: "12px",
    textDecoration: "none",
  },
  Tittle_agreement: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
  },
  backgroundColor: {
    backgroundColor: "#F2F5FA",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
  },
}));
const AgreementDrawer = (props) => {
  moment.defaultFormat = "YYYY-MM";
  const classes = useStyles();
  const history = useHistory();
  // const agreementContant = [
  //   {
  //     name: "Statement",
  //     img: "/images/statementnew.svg",
  //     link: "/statementofaccount"
  //   },
  //   {
  //     name: "Entry Log",
  //     img: "/images/enteriesnew.svg",
  //     link: "/entries"
  //   },
  //   {
  //     name: "Login Access",
  //     img: "/images/logsnew.svg",
  //     link: "/aggreement_unit_access"
  //   },

  // ];
  return (
    <div>
      <Box display="flex" alignItems="center" className={classes.titleroot} style={{ padding: '12px' }}>
        <Box flexGrow={1}><Typography className={classes.bottomTitle}>{props.t("agreement")}</Typography></Box>
        <Box><IconButton size="small" onClick={props?.onclose}><CloseIcon /></IconButton></Box>
      </Box>
      <div className={classes.backgroundColor} style={{ padding: '12px' }}>
        {/* <Typography className={classes.Tittle}>Manage</Typography>
        <Grid container spacing={2}>
          {agreementContant?.map((x) => {
            return (
              <>
                <Grid
                  item
                  xs={4}
                  onClick={() => {
                    history.push(x?.link);
                  }}
                >
                  <div className={classes.buttonStyle}>
                    <center>
                      <Box height="4px" />
                      <img src={x?.img} alt="" />
                      <Box height="4px" />
                      <Typography className={classes.lableStyle}>
                        {x?.name}
                      </Typography>
                      <Box height="6px" />
                    </center>
                  </div>
                </Grid>
              </>
            );
          })}
        </Grid> */}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "0px",
          }}
        >
          <Box>
            <Typography className={classes.Tittle_agreement}>
              Agreements ({props?.agreementList?.length})
            </Typography>
          </Box>
          <Box>
            <Link className={classes.Tittle1} onClick={() => history.push(Routes.experied)}>
              {props.t("view_expired_agreement")}
            </Link>
          </Box>
        </Box>
      </div>
      <AgreementList
        agreement={props?.agreement}
        updateagrement={props?.updateagrement}
        agreementList={props?.agreementList}
      />
    </div>
  );
};
export default withNamespaces("agreement")(AgreementDrawer);
