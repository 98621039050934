/* eslint-disable react/jsx-no-target-blank */
import { Grid } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const CarouselSliders = ({ data = [], autoScroll = false, onClick = false }) => {
  var settings = {
    dots: false,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    autoplay: autoScroll,
    speed: 1000,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };
  const theme = useTheme()

  return (
    <div style={{ cursor: "pointer" }} >
      <Grid container justifyContent="center">
        <Grid item xs={12} alignItems={"center"}>
          <Slider {...settings}>
            {data.map((val) => {
              return (
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  onClick={onClick ? onClick : false}
                >
                  <a href={val?.action_url} target="_blank">
                    <img
                      src={val.url}
                      alt="img"
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        borderRadius: theme.palette.borderRadius
                      }}
                    />
                  </a>
                </div>
              );
            })}
          </Slider>
        </Grid>
      </Grid>
    </div>
  );
};
