import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 0px 16px 0px",
    borderRadius: "16px",
    boxShadow: "0px 0px 4px #00000029",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
  },
  topCard: {
    padding: "12px",
  },
  bottomCard: {
    padding: "12px",
  },
  title: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#091B29",
    textAlign: "left",
  },
  subTitle1: {
    fontSize: "12px",
    color: "#4E5A6B",
    textAlign: "left",
  },
  subTitle2: {
    fontSize: "12px",
    color: "#4E5A6B",
    textAlign: "left",
    textTransform: "capitalize",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "5px",
    padding: "3px 10px 3px 10px",
  },
  tagTextStyle: {
    fontSize: "12px",
    color: "#091B29",
    fontWeight: "bold",
    textAlign: "left",
  },
  extraDetailsStyle: {
    fontSize: "12px",
    color: "#4E5A6B",
  },
  horizontalDividerStyle: {
    backgroundColor: "#E4E8EE",
    height: "1px",
  },
  verticalDividerStyle: {
    backgroundColor: "#E4E8EE",
    width: "1px",
    height: "20px",
  },
  iconButtonStyle: {
    backgroundColor: "#F2F4F7",
    borderColor: "#E4E8EE",
    height: "32px",
    width: "32px",
  },
  cardImage: {
    height: "60px",
    width: "100%",
    objectFit: "cover",
    borderRadius: theme.palette.borderRadius,
  },
  total: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  filter: {
    borderRadius: "10px",
    border: "1px solid #c1c1c1",
    color: "black",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    height: "78vh",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  unitImg: {
    height: "250px",
    width: "100%",
    borderRadius: "20px",
    objectFit: "cotain",
  },
  titleroot2: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "8px",
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  phone: {
    borderRadius: "10px",
    border: "1px solid #c1c1c1",
  },
  bottomDetails: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    cursor: "pointer",
  },
}));
export const RequestCard = (props) => {
  const classes = useStyles();

  return (
    <div>
      {/* Units data */}
      {props.data && (
        <Grid item xs={12} className={classes.root}>
          <div
            onClick={() => {
              props.onClick(props.data);
            }}
            style={{ cursor: "pointer" }}
          >
            <Grid container alignItems="center" className={classes.topCard}>
              <Grid sm={1.8} xs={2.5}>
                {props?.data?.assets.map((val) => {
                  return (
                    <>
                      {val?.priority === 1 ? (
                        <img
                          src={
                            props?.data?.assets.length > 0
                              ? val.url
                              : "https://www.google.com/url?sa=i&url=https%3A%2F%2Fstrykerslev.com%2Ftestimonial%2Fdan-h-san-diego%2F&psig=AOvVaw2v8dLxn6yc_5QmqcYyGSj5&ust=1642304105641000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCOix2djpsvUCFQAAAAAdAAAAABAJ"
                          }
                          className={classes.cardImage}
                          alt="altimage"
                        />
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </Grid>
              <Grid sm={0.2} xs={0} />

              <Grid sm={7} xs={7.5} style={{ marginLeft: "10px" }}>
                <Typography className={classes.title}>
                  {props.type === "unit"
                    ? props?.data?.unit_name
                    : props.data.unitName}
                </Typography>
                <Box height="6px" />
                <Grid container alignItems="center">
                  <Typography className={classes.subTitle1}>
                    {props.type === "unit"
                      ? props?.data?.block_name
                      : props.data.towerName}
                  </Typography>
                  <Box className={classes.periodStyle} />
                  <Typography className={classes.subTitle2}>
                    {props.type === "unit"
                      ? props?.data?.floor_name
                      : props.data.noOfFloors}
                  </Typography>
                </Grid>
                <Box height="6px" />
                {props.type === "request" && (
                  <Grid
                    item
                    className={classes.tagStyle}
                    style={{ maxWidth: "fit-content" }}
                  >
                    <Typography className={classes.tagTextStyle}>
                      {props.data.requestStatus}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                sm={2}
                xs={1}
                justifyContent="right"
                alignItems="center"
              >
                <IconButton
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.onClick(props.data);
                  }}
                  className={classes.iconButtonStyle}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box className={classes.horizontalDividerStyle} />
            <Grid
              container
              className={classes.bottomCard}
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid
                container
                sm={4}
                xs={4}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  sx={{ padding: "10px" }}
                  src={
                    props.type === "request"
                      ? "/images/icons8_calendar.svg"
                      : "/images/SQ_FT.svg"
                  }
                  alt="altimage"
                />
                <Box width="8px" />
                <Typography className={classes.extraDetailsStyle}>
                  {props.type === "unit" ? (
                    <>{props?.data?.total_area} </>
                  ) : (
                    props.data.sqFtArea
                  )}
                </Typography>
              </Grid>
              <Box className={classes.verticalDividerStyle} />
              <Grid
                container
                sm={4}
                xs={4}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={
                    props.type === "request"
                      ? "/images/Group_619.svg"
                      : "/images/Bedroom.svg"
                  }
                  alt="altimage"
                />
                <Box width="8px" />
                <Typography className={classes.extraDetailsStyle}>
                  {props.type === "unit"
                    ? props?.data?.unit_type
                    : props.data.noOfBedroom}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
    </div>
  );
};
