import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Grid, Box, Button, Divider } from '@mui/material';
import useWindowDimensions from "../../utils/useWindowDimensions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from "@mui/styles/makeStyles";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer, TitleBar } from "../../components";
import { GET_PROPERTY_AMENITIES_DETAILS, GET_UNIT_AMENITIES } from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { AlertProps, calculateDaysBetweenDates, getMonth, NetWorkCallMethods } from '../../utils';
import { config } from '../../config';
import { AlertContext } from "../../contexts";
import moment from 'moment';
import Paymentscreen from './paymentscreen';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px",
        backgroundColor: '#F2F5FA',
    },
    tagStyle: {
        backgroundColor: "#F1E6FE",
        borderRadius: "4px",
        padding: "4px 6px 4px 6px",
        fontSize: "8px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: "#5D427F",
        textTransform: "uppercase",
        marginLeft: "8px",
    },
    tagStyle1: {
        backgroundColor: "#F1E6FE",
        borderRadius: "4px",
        padding: "4px 6px 4px 6px",
        fontSize: "8px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: "#5D427F",
        textTransform: "uppercase",
        marginLeft: "8px",
    },
    title: {
        color: '#071741',
        fontSize: '14px',
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    },
    sub: {
        color: '#98A0AC',
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        fontSize: '12px',
    },
    amount: {
        color: '#091B29',
        fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
        fontSize: '20px',
        fontWeight: 600
    },
    date: {
        color: '#091B29',
        fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
        fontSize: '14px',
    },
    main: {
        backgroundColor: '#F5F7FE',
        borderRadius: '12px',
        padding: '15px'
    },
    accordion: {
        borderRadius: '12px !important',
    },
    paymentbtn: {
        borderRadius: '6px',
        fontSize: '14px',
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        width: '100%',
        padding: '9px',
        '&hover': {
            backgroundColor: '#5078E1'
        }
    },
    btnParent: {
        padding: '14px',
        margin: "0 auto",
        textAlign: 'center',
        background: '#F2F5FA',
        zIndex: 2
    },
    topDrap: {
        backgroundColor: '#5C86F6',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1px 15px 1px 15px',
        cursor: 'pointer'
    },
    toptitle: {
        color: '#fff',
        fontSize: '12px',
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    },
    rationame: {
        '&.MuiFormControlLabel-label': {
            color: '#091B29',
            fontSize: '15px',
            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        }
    },
    noData: {
        color: "#999",
        textAlign: "center",
        marginTop: "20vh",
        fontSize: "14px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        marginLeft: "12px"
    }
}));


export const AmenitiesType = () => {
    const classes = useStyles();
    const size = useWindowDimensions();
    const alert = React.useContext(AlertContext);
    const history = useHistory();
    const [details, setDetails] = React.useState([])
    const searchURL = useLocation().search;
    const amenityName = new URLSearchParams(searchURL).get("name");
    const AmenityId = new URLSearchParams(searchURL).get("Id");
    const type = new URLSearchParams(searchURL).get("type");
    const [paymentDrawer, setPaymentDrawer] = React.useState(false);
    const [alreadypayed, setAlreadypayed] = React.useState(false);
    const [totalBill, setTotalBill] = React.useState("");
    const [companyId, setCompanyId] = React.useState("");
    const [loading, setLoading] = React.useState(true);


    const closePayment = () => {
        setPaymentDrawer(!paymentDrawer)
    }

    const closeAllreadyPayment = () => {
        setAlreadypayed(!alreadypayed)
    }



    const DetailsAgainstAmenity = () => {
        const data = {
            query: GET_UNIT_AMENITIES,
            variables: {
                unit_amenities_id: AmenityId,
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {

                setDetails(response?.data?.data?.ameneties_usage)
                setTotalBill(response?.data?.data?.ameneties_usage?.[0]?.total_bill)
                setCompanyId(response?.data?.data?.ameneties_usage?.[0]?.invoice?.[0]?.company_id)
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some thing went wrong",
                });
            });
    }
    const DetailsAgainstPropertyAmenity = () => {
        const data = {
            query: GET_PROPERTY_AMENITIES_DETAILS,
            variables: {
                property_facilities_id: AmenityId,
            },
        };
        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {

                setDetails(response?.data?.data?.ameneties_usage)
                setTotalBill(response?.data?.data?.ameneties_usage?.[0]?.total_bill)
                setCompanyId(response?.data?.data?.ameneties_usage?.[0]?.invoice?.[0]?.company_id)
                setLoading(false)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Some thing went wrong",
                });
            });
    }




    React.useEffect(() => {
        if (type === "unit") {
            DetailsAgainstAmenity()
        }
        if (type === "property") {
            DetailsAgainstPropertyAmenity()
        }
        // eslint-disable-next-line
    }, [])
    console.log(totalBill)

    return (
        <Container className={classes.root} maxWidth="sm" style={{ height: size?.height }}>
            <TitleBar
                text={amenityName}

                isUtilities
                goBack={() => history.goBack()}
            />
            {loading ? <LoderSimmer count={20} /> :
                <>
                    {details.length > 0 ?
                        <>
                            <div style={{ height: size?.height - 145, overflow: 'auto', paddingBottom: '20px' }}>
                                {
                                    details?.map((x, index) => {
                                        return (
                                            <div style={{ padding: '16px 4px 0px 10px' }}>
                                                <AccordionComponent data={x} index={index} setTotalBill={setTotalBill} setCompanyId={setCompanyId} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <Box className={classes.btnParent}>
                                <Button className={classes.paymentbtn} variant='contained' onClick={closePayment}>{"Pay Now"}</Button>
                            </Box>
                        </> : <Typography className={classes.noData}>
                            {"Data not found"}
                        </Typography>

                    }
                </>
            }
            {/* payment */}
            {paymentDrawer &&
                <Paymentscreen closePayment={closePayment} closeAllreadyPayment={closeAllreadyPayment} alreadypayed={alreadypayed} paymentDrawer={paymentDrawer} companyId={companyId} />
            }



        </Container>
    );
}

const AccordionComponent = (props) => {
    const classes = useStyles();

    const dueDate = calculateDaysBetweenDates(new Date(props?.data?.invoice?.[0]?.payment_due_date), new Date(Date.now()))

    // const dueDate = new Date(props?.data?.[1][props?.data?.[1].length - 1].invoice[0].payment_due_date)
    return (
        <Accordion className={classes.accordion} TransitionProps={{ unmountOnExit: true }} defaultExpanded={props?.index === 0 ? true : false}
            onChange={() => {
                props?.setTotalBill(props?.data?.total_bill)
                props?.setCompanyId(props?.data?.invoice?.[0]?.company_id)
            }
            }
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#071741' }} />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography style={{ display: 'flex', alignItems: 'center' }} className={classes.title}>
                    {
                        getMonth(new Date(props?.data?.created_at).getMonth())
                    }
                    <span
                        className={dueDate > 0 ? classes.tagStyle1 : classes.tagStyle}
                    >
                        {
                            dueDate > 0 ? dueDate + "  days to due" : "over due"
                        }
                    </span>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.main}>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                    <img src='/images/utilitiesinvoices.svg' alt='invoice' />
                                </Box>
                                <Box style={{ marginLeft: '16px' }}>
                                    <Typography className={classes.sub}>Total Bill</Typography>
                                    <Typography className={classes.amount}>{props?.data?.total_bill}</Typography>
                                </Box>
                            </Box>

                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Box>
                                    <img src='/images/meter.svg' alt='invoice' />
                                </Box>
                                <Box style={{ marginLeft: '16px' }}>
                                    <Typography className={classes.sub}>Number Of Hours</Typography>
                                    <Typography className={classes.amount}>{props?.data?.total_hours}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {/* 2nd */}
                    <Grid item xs={6}>
                        <Box style={{ display: 'flex', alignItems: 'center' }} className={classes.main}>
                            <Box>
                                <img src='/images/utilitiescalander.svg' alt='invoice' />
                            </Box>
                            <Box style={{ marginLeft: '16px' }}>
                                <Typography className={classes.sub}>Booked From</Typography>
                                <Typography className={classes.date}>
                                    {moment(props?.data?.from).format("DD MMM YY")}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box style={{ display: 'flex', alignItems: 'center' }} className={classes.main}>
                            <Box>
                                <img src='/images/utilitiescalander.svg' alt='invoice' />
                            </Box>
                            <Box style={{ marginLeft: '16px' }}>
                                <Typography className={classes.sub}>Booked To</Typography>
                                <Typography className={classes.date}>
                                    {moment(props?.data?.to).format("DD MMM YY")}

                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {/* 3rd */}
                    <Grid item xs={12}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.main}>
                            <Box>
                                <Typography className={classes.sub}>Booked On Date</Typography>
                                <Typography className={classes.date}>
                                    {moment(props?.data?.from).format("DD MMM YY")}
                                </Typography>                            </Box>
                            <Box>
                                <img src='/images/bookedicon.svg' alt='bookedicon' />
                            </Box>
                        </Box>
                    </Grid>
                    {/* 4th */}
                    <Grid item xs={12}>
                        <Box style={{ display: 'flex', alignItems: 'center' }} className={classes.main}>
                            <Box>
                                <img src='/images/utilitiescalander.svg' alt='invoice' />
                            </Box>
                            <Box style={{ marginLeft: '16px' }}>
                                <Typography className={classes.sub}>Per Hour Price</Typography>
                                <Typography className={classes.date}>
                                    {`${props?.data?.invoice?.[0]?.currency_master?.symbol} ${props?.data?.rate_per_hour}`}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {props?.data?.paid_on &&
                        <>
                            <Grid item xs={12}>
                                <Box style={{ display: 'flex', alignItems: 'center' }} className={classes.main}>
                                    <Box>
                                        <img src='/images/utilitiescalander.svg' alt='invoice' />
                                    </Box>
                                    <Box style={{ marginLeft: '16px' }}>
                                        <Typography className={classes.sub}>Paid On</Typography>
                                        <Typography className={classes.date}>
                                            {moment(props?.data?.paid_on).format("DD MMM YY")}

                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} textAlign="center">
                                <Button variant='text' >
                                    View Invoice
                                </Button>
                            </Grid>

                        </>
                    }

                </Grid >
            </AccordionDetails>
        </Accordion>
    )
}




