import makeStyles from "@mui/styles/makeStyles";

// --------------------------step1---------------------------------------
export const useStylesStp1 = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "0px 16px",
  },
  btnRoot: {
    position: "fixed",
    bottom: 0,
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  contact: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  change: {
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  screen: {
    borderRadius: "16px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    marginTop: "5px",
    padding: "0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "box-shadow": "none",
      borderTopLeftRadius: "14px",
      borderTopRightRadius: "14px",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
}));

// --------------------------step2----------------------------------------
export const useStylesStep2 = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "16px",
  },
  btnRoot: {
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  screen: {
    borderRadius: "16px",

    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    marginTop: "5px",
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

// --------------------------step3----------------------------------------
export const useStylesStep3 = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "12px 16px",
  },
  btnRoot: {
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  contact: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  change: {
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  screen: {
    borderRadius: "16px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

// --------------------------step4-----------------------------------------
export const useStylesStep4 = makeStyles((theme) => ({
      root: {
        padding: 0,
      },

      screenPadding: {
        padding: "0px 16px 0px 16px",
        overflow: "overlay",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      },
      btnRoot: {
        backgroundColor: "#F5F7FA",
        padding: "16px",
        border: "1px solid #F9FAFE",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
      },
      btn: {
        borderRadius: theme.palette.borderRadius,
        padding: "8px",
      },
      btnPrevious: {
        borderRadius: theme.palette.borderRadius,
        padding: "8px",
        backgroundColor: "white",
        color: theme.palette.primary.main,
      },
      stepperBody: {
        backgroundColor: theme.palette.primary.main,
        backgroundImage: "url(/images/stepperBackground.svg)",
        padding: "8px 12px",
      },
      stepper: {
        width: "26px",
        height: "26px",
        borderRadius: "100%",
        display: "grid",
        placeItems: "center",
        backgroundColor: "white",
        color: theme.palette.primary.main,
      },
      circle: {
        backgroundColor: theme.palette.primary.main,
        border: "2px solid white",
        width: "36px",
        height: "36px",
        borderRadius: "100%",
        display: "grid",
        placeItems: "center",
      },
      steps: {
        fontSize: "12px",
        color: theme.typography.color.primary,
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
      },
      details: {
        fontSize: "16px",
        color: "white",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
      },
      contact: {
        fontSize: "16px",
        color: theme.typography.color.primary,
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
      },
      change: {
        fontSize: "16px",
        color: theme.palette.primary.main,
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
      },
      requsettop: {
        backgroundColor: "#5078E11E",
        padding: "4px",
        borderRadius: "5px",
        fontSize: "12px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
      },
      requsettitle: {
        marginTop: "10px",
        fontSize: "16px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
      },
      requsetsub: {
        fontSize: "12px",
        color: theme.typography.color.Tertiary,
        marginTop: "8px",
      },
      requsetsubs: {
        fontSize: "14px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
      },
      requsetsubs2: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        marginTop: "4px",
      },
      requsetsubs3: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        marginTop: "4px",
        alignSelf: "end",
      },
      screen: {
        borderRadius: "16px",
        border: "1px solid #F5F7FA",
        background: theme.palette.background.tertiary1,
      },
    }));