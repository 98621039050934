import { NetWorkCallMethods } from ".";
import { config } from "../config";
import { NetworkCall } from "../networkcall";

export const loadOptions = async (
  search = "",
  array = [],
  handleLoading = null,
  queryFn,
  extractLabel,
  functionParams = {},
  mappingVariable = {},
  queryVariables = {},
  manualResponse,
  company_id = "",
  appraisal = false
) => {

  let result,
    query,
    offset = 0;

  if (search && !Boolean(array?.length)) {
    offset = 0;
  } else {
    offset = array?.length;
  }
  query = queryFn(offset, 10, search, { ...functionParams }, company_id).loc
    .source.body;
  result = await networkCallBack(query, queryVariables, handleLoading);

  //Select options should be label,value pairs like ({label:'',value:''})
  if (mappingVariable?.label || mappingVariable?.value) {
    if (appraisal) {
      result[extractLabel] = result?.[extractLabel]?.map((_) => ({
        label: _?.[mappingVariable?.label ?? "label"],
        value: _?.[mappingVariable?.value ?? "value"],
        unit_count: appraisal
          ? _?.[mappingVariable?.unit_count ?? "unit_count"]
          : undefined,
        address: appraisal
          ? _?.[mappingVariable?.address ?? "address"]
          : undefined,
      }));
    } else {
      result[extractLabel] = result?.[extractLabel]?.map((_) => ({
        label: _?.[mappingVariable?.label ?? "label"],
        value: _?.[mappingVariable?.value ?? "value"],
      }));
    }
  }

  //manually construct array
  if (manualResponse) {
    result[extractLabel] = manualResponse(result[extractLabel]);
  }

  return {
    options: [...result?.[extractLabel]],
    hasMore:
      array?.length + result?.[extractLabel]?.length < result?.count[0]?.count,
  };
};

const networkCallBack = async (query, variable = {}, handleLoading) => {
  let payload = {
    query,
    variable,
  };

  const options = await NetworkCall(
    `${config.graphql_url}`,
    NetWorkCallMethods.post,
    payload,
    null,
    true,
    false
  )
    .then((response) => {
      let main = response.data.data;
      handleLoading && handleLoading();
      return main;
    })
    .catch((error) => {
      handleLoading && handleLoading();
      return null;
    });

  return options;
};
