import { Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#7EA1FF",
    backgroundImage: `url(/images/welcome.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    [theme.breakpoints.down(322)]: {
      backgroundPosition: "bottom"

    },


  },
  welcomeText: {
    fontSize: "26px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: "white",
  },
  img: {
    width: "auto",
    height: "50px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      color: "#5078E1",
      backgroundColor: "#FFFFFF",
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#5078E1",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
}));

export const WelcomePage = () => {
  const alert = React.useContext(AlertContext);
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get("token");
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {

    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);
  const getStarted = () => {

    const payload = {
      userId: decoded.user_id,
    };
    NetworkCall(
      `${config.auth_api_url}/auth/verifyUser/?token=${welcomePageAuthToken}`,
      NetWorkCallMethods.post,
      payload,
      null,
      false,
      false
    )
      .then((response) => {
        if (response?.data?.is_allowed) {
          history.push(
            Routes.setupAccount + "?welcomeAuthToken=" + welcomePageAuthToken
          );
        } else {
          history.push(Routes.login);
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };

  return (
    <Container maxWidth="sm" sx={{ padding: 0 }}>
      <div className={classes.root} style={{ height: size?.height }}>
        <Grid
          item
          xs={12}
          style={{ height: size.height - 94, padding: "24px" }}
        >
          {/* <Box height={24} />
          <Grid item xs={12}>
            <img
              src="/images/welcome.png"
              alt="PA Logo-01"
              className={classes.img}
            />
          </Grid>
          <Box height={24} /> */}
          <Grid item xs={12}>
            <Typography className={classes.welcomeText}>Welcome </Typography>
            <Typography className={classes.welcomeText}>
              {decoded && decoded.username ? decoded.username : ""},
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
          <Button
            fullWidth
            variant="contained"
            className={classes.btn}
            onClick={getStarted}
          >
            <Typography className={classes.buttonTextStyle}>
              Get Started
            </Typography>
          </Button>
        </Grid>
      </div>
    </Container>
  );
};
