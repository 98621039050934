import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const useStyle = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme?.palette?.primary?.contrastText,
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: `1px solid ${theme?.palette?.border?.primary}`,
      "& :hover": {
        border: `1px solid ${theme?.palette?.primary?.main}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "0px" : "11px 14px"),
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 12px",
  },
  filter: {
    backgroundColor: "white",
    padding: "14px",
    borderRadius: "4px",
    border: `1px solid ${theme?.palette?.border?.primary}`,
    marginLeft: "10px",
    marginRight:"10px"
  },
  dot: {
    color: theme?.palette?.primary?.main,
  },
  scrollBar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  menuText: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    marginTop:'20%',
    fontFamily:Bold
  },
}));
