import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",
        textTransform: "capitalize",
        cursor: "pointer",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 3px 30px #5C86CB2E"
    },
    roots: {
        padding: "12px",
        position: 'relative'
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    name: {
        fontSize: "14px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        marginTop: "8px",
        color: theme.typography.color.primary

    },
    call: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
    },
    mail: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
    },
    tagStyle: {
        backgroundColor: "#F1E6FE",
        borderRadius: "4px",
        padding: "4px 6px 4px 6px",
        fontSize: "8px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: "#5D427F",
        // textTransform: "uppercase",
        marginLeft: "8px",
        position: 'absolute',
        right: '10px'
    },
    tagStyle1: {
        backgroundColor: "#FF4B4B29",
        borderRadius: "4px",
        padding: "4px 6px 4px 6px",
        fontSize: "8px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: "#FF4B4B",
        textTransform: "uppercase",
        marginLeft: "8px",
        position: 'absolute',
        right: '10px',
        marginBottom: "16px"
    },
    tagStyle2: {
        fontSize: "14px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        marginTop: "8px",
        marginLeft: "8px",
        position: 'absolute',
        right: '10px',
        color: theme.typography.color.primary
    },

    avatar: {
        backgroundColor: "#F0F4FF",
        width: "48px",
        height: "48px",
        borderRadius: '10px'
    },
}));

export const AmenitiesCard = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const name = props?.data?.name ? props?.data?.name : props?.data?.amenities_type_masterByID?.amenities_name
    const isBookingTrue = () => {
        history.push({
            pathname: Routes.amenitiesBookingDetails,
            state: {
                main: {
                    id: props?.data?.id,
                    name: name,
                    type: props?.type,
                    rate: `${props?.data?.currencyByID?.symbol ?? ""} ${props?.data?.rate ?? ""}`,
                    period: props?.data?.period,
                    description: props?.data?.description,
                    companyId: props?.data?.company?.[0]?.id ?? props?.companyId,
                    propertyId: props?.propertyId,
                    agreementId: props?.agreementId
                },
            },
        })

    }
    const isBookingFalse = () => {
        history.push(
            Routes.amenitiestype +
            "?Id=" +
            props?.data?.id +
            "&name=" +
            name +
            "&type=" +
            props?.type
        )
    }
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.roots}
                    onClick={() => props?.data?.amenities_type_masterByID?.is_booking ? props?.data?.is_chargeable === false ? false :
                        isBookingTrue() : props?.data?.is_chargeable === false ? false :
                        isBookingFalse()
                    }
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Box display="flex">
                                <Box>
                                    <Avatar className={classes.avatar}>
                                        <img
                                            src="/images/Amenitiesnew.svg"
                                            alt={"phone"}
                                            className={classes.img}
                                        />
                                    </Avatar>
                                </Box>
                                <Box flexGrow={1} marginTop="6px" marginLeft="8px">
                                    <Box display="flex" alignItems="center" marginBottom="2px">
                                        <Typography className={classes.name} noWrap>
                                            {props?.data?.amenities_type_masterByID?.amenities_name && props?.data?.amenities_type_masterByID?.amenities_name}
                                            {props?.data?.name && props?.data?.name}
                                        </Typography>
                                        {/* {!props?.data?.amenities_type_masterByID?.is_transactional &&
                                            <Typography className={classes.tagStyle}>
                                                {"Free"}
                                            </Typography>
                                        } */}
                                        {props?.data?.is_chargeable === false &&
                                            <Typography className={classes.tagStyle}>
                                                {"Free"}
                                            </Typography>
                                        }
                                        {props?.data?.rate &&
                                            <Typography className={classes.tagStyle2}>
                                                {props?.data?.currencyByID?.symbol ?? ""} {props?.data?.rate}
                                            </Typography>
                                        }
                                    </Box>

                                    {/* <Box display="flex" style={{ flexFlow: 'wrap' }}>
                                        <Box>
                                            <Typography className={classes.call} noWrap>
                                                {`${props?.data?.readings} ${props?.data?.readings > 1 ? "Metres" : "Metre"}`}
                                            </Typography>
                                        </Box>
                                    </Box> */}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
