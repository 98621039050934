import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// import styled from '@mui/material/styles/styled';
// import useTheme from '@mui/material/styles/useTheme';
import useTheme from '@mui/styles/useTheme';
import React from 'react';
import { CustomTypography } from '.';

// const CustomImage = styled('img')(({ theme }) => ({
//     width: 73,
//     height: 73,
//     borderRadius: 12
// }))

export const AnnouncementCard = (props) => {

    const {
        logo = "",
        category = "",
        title = "",
        postedOn = "",
        onClickSlider = null
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={[{
                // height: "115px",
                '&:focus-visible': {
                    outline: "none"
                }
            }]}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}

                sx={(theme) => ({
                    // backgroundColor: "#FFFFFF",
                    boxShadow: "0px 3px 10px #5C86CB2E",
                    cursor: "pointer",
                    height: "275px",
                    backgroundImage: `url(${logo})`,
                    backgroundPosition: "100% 100%",
                    backgroundSize: "cover",
                    [theme.breakpoints.down("sm")]: {
                        width: "250px",
                        marginRight: "10px",
                        borderRadius: theme.palette.borderRadius,

                    },
                    [theme.breakpoints.up("sm")]: {
                        width: "250px",
                        marginRight: "10px",
                        borderRadius: theme.palette.borderRadius,

                    },
                    borderRadius: "4px",
                })}
                flexWrap={"wrap"}
                onClick={onClickSlider}
            >
                {/* <CustomImage
                    src={logo}
                    alt={"annoucemnet_img"}
                /> */}
                <div style={{
                    flex: 1,
                    marginTop: "auto",
                    padding: "16px",
                    backgroundColor: "rgba(28, 27, 27,0.400)",
                    borderRadius: theme.palette.borderRadius,

                }
                }>
                    {
                        category &&
                        <CustomTypography
                            fontSize={10}
                            color={theme.palette.background.tertiary1}
                            fontFamily={"'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif"}
                            sx={{
                                padding: '2px 4px',
                                backgroundColor: "#5078E1",
                                borderRadius: '4px',
                                width: "fit-content"
                            }}
                        >
                            {category}
                        </CustomTypography>
                    }
                    {
                        title &&
                        <CustomTypography
                            color={theme.palette.background.tertiary1}
                            fontFamily={"'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif"}
                            sx={{
                                marginBlock: 1 / 2,

                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {title}
                        </CustomTypography>
                    }
                    {
                        postedOn &&
                        <CustomTypography
                            fontSize={10}
                            color={theme.palette.border.primary}
                        >
                            {postedOn}
                        </CustomTypography>
                    }
                </div>
            </Stack>
        </Box >
    )
}