import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "100%",
    padding: "0 !important",
    "& .MuiPaper-root": {
      width: "100% !important",
    },
    "& .MuiDialogContent-root": {
      padding: "0 !important",
      borderRadius: theme.palette.borderRadius,
    },
    "& .MuiDialog-paper": {
      borderRadius: theme.palette.borderRadius,
      padding: "0 !important",
    },
  },
  header: {
    border: "1px solid #E4E8EE",
    fontSize: "16px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
export const AlertDialog = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={props?.open}
        onClose={props?.onClose}
        aria-labelledby="alert-dialog-title"
        className={classes.dialog}
        aria-describedby="alert-dialog-description"
      >
        {props?.isTitle && (
          <DialogTitle className={classes.header}>
            <span>{props?.header}</span>{" "}
            <img
              src="/images/close.svg"
              alt="close"
              onClick={props?.onClose}
              style={{ cursor: "pointer" }}
            />
          </DialogTitle>
        )}
        <DialogContent style={{ padding: "0 !important" }}>
          {props?.component}
        </DialogContent>
      </Dialog>
    </div>
  );
};
