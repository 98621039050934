import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    padding: "12px",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    boxShadow: "0px 2px 6px #00000014",
    backgroundColor: (props) =>
      props?.color ? props?.color : theme.palette.background.paper,
  },
  title: {
    fontSize: "16px",
    textTransform: "capitalize",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  arrow: {
    fontSize: "20px",
  },
  id: {
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    fontSize: "14px",
    textAlign: "left"
  },
  agreement: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    marginTop: "12px",
    cursor: "pointer",
  },
}));

export const TitleBar = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <Box>
              <IconButton size="small" onClick={props.goBack}>
                <ArrowBackIosIcon
                  fontSize="small"
                  className={classes.arrow}
                  style={{ color: props?.color ? "white" : "#091B29" }}
                />
              </IconButton>
            </Box>
            <Box flexGrow={1}>
              <Typography
                className={classes.title}
                style={{ color: props?.color ? "white" : "black" }}
              >
                {props?.text}
              </Typography>
            </Box>
            <Box>
              {/* <IconButton size="small">
                <ArrowBackIosIcon
                  fontSize="small"
                  style={{ color: props.color ? props.color : "white" }}
                />
              </IconButton> */}
            </Box>
            <Box>
              {/* {props.clearFilter && (
                <Typography className={classes.clear} onClick={props.onClear}>
                  Clear Filter
                </Typography>
              )} */}
            </Box>
          </Box>
          {props?.entries && (
            <Box display="flex" alignItems="center" className={classes.agreement} onClick={props.handleClick}>
              <Box flexGrow={1}>
                <Typography className={classes.id}>
                  {
                    props?.data && <>{props?.data?.property?.label},{props?.data?.unit?.label}</>
                  }

                </Typography>
              </Box>
              <Box>
                <IconButton
                  size="small" onClick={props.handleClick} style={{ color: "#091B29", }}><KeyboardArrowDownIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </Grid>
        {
          props?.isUtilities &&
          <Grid item xs={12} marginTop="8px">
            {
              props?.component
            }
          </Grid>
        }
      </Grid>

    </div>
  );
};
