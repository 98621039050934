import makeStyles from "@mui/styles/makeStyles";
import { Bold, Regular, SemiBold } from "../../utils";
export const useStyle = makeStyles((theme) => ({
  unitname: {
    color: theme?.typography?.color?.primary,
    fontSize: "14px",
    fontFamily: Bold,
  },
  agreementNumber: {
    fontFamily: Bold,
    fontSize: "12px",
    color: theme?.palette?.primary?.main,
    padding: "2px 6px",
    borderRadius: "4px",
    backgroundColor: theme?.palette?.background?.badge,
    maxWidth: "fit-content",
    width: "100%",
    marginLeft: "10px",
  },
  propertyName: {
    fontFamily: Regular,
    fontSize: "12px",
    color: theme?.typography?.color?.third,
  },
  dot1: {
    backgroundColor: theme?.palette?.border?.primary,
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    margin: "0px 10px",
  },
  card: {
    padding: "16px",
    borderRadius: "4px",
    backgroundColor: "white",
    position: "absolute",
    top: "230px",
    left: 0,
    right: 0,
    width: "90%",
    margin: "0 auto",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  image: {
    height: "304px",
    width: "100%",
    position: "relative",
    marginBottom: "40px",
  },
  root: {
    backgroundColor: theme?.palette?.background?.third,
    padding: "0px",
    textAlign: "center",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    color: "#FFFFFF",
    width: "100%",
    marginTop: "14px",
    backgroundColor: theme?.palette?.primary?.main,
    fontSize: "12px",
    fontFamily: SemiBold,
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  agreementTitle: {
    color: theme?.palette?.secondary?.main,
    fontSize: "14px",
    fontFamily: SemiBold,
  },
  agreementNo: {
    backgroundColor: theme?.palette?.info?.light,
    color: theme?.palette?.info?.primary,
    borderRadius: "4px",
    padding: "2px 6px",
    fontSize: "10px",
    fontFamily: Bold,
    marginLeft: "10px",
  },
  lease: {
    backgroundColor: theme?.palette?.background?.unit_catagory,
    color: "#8A74A6",
    borderRadius: "4px",
    padding: "2px 6px",
    fontSize: "10px",
    fontFamily: Bold,
  },
  agreementBox: {
    padding: "10px 0px",
    borderTop: `1px dashed #00000029`,
    marginTop: "10px",
    textAlign: "left",
  },
  dateTitle: {
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.tertiary,
    marginBottom: "2px",
  },
  date: {
    fontSize: "14px",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.primary,
    marginLeft: "10px",
  },
  avatarGroup: {
    backgroundColor: theme?.palette?.info?.light,
    padding: "10px",
    borderTopRightRadius: "50px",
    borderBottomRightRadius: "50px",
    // maxWidth: "132px",
    marginTop: "16px",
    justifyContent: "left",
    cursor: "pointer",
    width: "fit-content",
  },
  avatarGroups: {
    justifyContent: "left",
    "& .MuiAvatar-root": {
      height: "34px",
      width: "34px",
      fontSize: "12px",
    },
  },
  contactName: {
    fontFamily: Regular,
    fontSize: "14px",
    color: theme?.typography?.color?.secondary,
  },
  contactName1: {
    fontFamily: Regular,
    fontSize: "12px",
    color: theme?.typography?.color?.secondary,
  },
  avatar: {
    backgroundColor: theme?.palette?.background?.avatar,
  },
  flexBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  assetsBox: {
    minHeight: "123px",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    padding: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  arrowIcon: {
    fontSize: "12px",
  },
  marginTop: {
    marginTop: "260px",
  },
  parentBox: {
    padding: "10px",
    backgroundColor: "white",
  },
  catagoryTitle: {
    fontSize: "16px",
    fontFamily: Bold,
  },
}));
