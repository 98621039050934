import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import { useStyle } from "../style";
export const ProfileCard = ({ data = {} }) => {
  const classes = useStyle();
  return (
    <Box display="flex" alignItems="center">
      <Avatar src={data?.url} />
      <Box
        ml={1}
        p={1}
        borderBottom={"1px solid #E4E8EE"}
        className={classes.flexBox}
      >
        <Box>
          <Box display="flex" alignItems="center">
            <Typography className={classes.contactName}>
              {data?.name}
            </Typography>
            <Box className={classes.dot1} />
            <Typography className={classes.contactName}>
              {data?.contactNumber}
            </Typography>
          </Box>
          <Typography className={classes.contactName1}>
            {data?.email}
          </Typography>
        </Box>
        <Avatar className={classes.avatar}>
          <img src="/images/icons8-call.svg" alt="phone" />
        </Avatar>
      </Box>
    </Box>
  );
};
