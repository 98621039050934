import { Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "4px solid #F2F4F7",
    padding: "10px 0px",
  },
  checkBox: {
    color: theme.palette.primary.main,
    borderRadius: "5px",
  },
  title: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,
  },
  roundShape: {
    width: "6px",
    height: "6px",
    backgroundColor: theme.palette.border.primary,
    borderRadius: "100%",
    display: "inline-block",
    marginBottom: "2px",
  },
  money: {
    fontSize: "16px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.secondary,
  },
  viewInvoice: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const InvoicePayNowList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={8}>
          {props?.dashboard ? (
            ""
          ) : (
            <Typography className={classes.title}>
              {props.InvoicePay.description}
            </Typography>
          )}

          <span className={classes.subTitle}>
            {props.InvoicePay.invoice_no}&nbsp;{" "}
            <>
              <span className={classes.roundShape}></span>&nbsp;&nbsp;
              <span
                className={classes.viewInvoice}
                onClick={(val) => {
                  props.viewinvoice(props?.InvoicePay.id);
                }}
              >
                {props?.t("view_invoice")}
              </span>
            </>
          </span>
        </Grid>

        <Grid item xs={4}>
          <div style={{ float: "right" }}>
            <Typography className={classes.money} noWrap>
              {props.InvoicePay.currency_master.symbol +
                Intl.NumberFormat("en-IN", {
                  minimumFractionDigits: 0,
                }).format(Math.round(props.InvoicePay.invoice_due_amount))}
              &nbsp;&nbsp;
              {props.delete ? (
                ""
              ) : (
                <IconButton onClick={() => props.onDelete(props.InvoicePay)}>
                  <img src="/images/delete.svg" alt="delete" />
                </IconButton>
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default withNamespaces("invoice")(InvoicePayNowList);
