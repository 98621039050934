import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
// --------------------------step2----------------------------------------
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "16px",
  },
  btnRoot: {
    backgroundColor: theme?.palette?.background?.secondary,
    padding: "16px",
    border: `1px solid ${theme?.palette?.border?.tertiary}`,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 6px 10px ${theme?.palette?.boxShadow?.primary}`,
    padding: "8px",
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      color: theme?.palette?.background?.tertiary1,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: Bold,
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    boxShadow: `0px 6px 10px ${theme?.palette?.boxShadow?.primary}`,
    "&:hover": {
      backgroundColor: "white",
      color: theme.palette.primary.main,
    },
  },
  screen: {
    borderRadius: "16px",

    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    marginTop: "5px",
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  contact: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: Bold,
  },
  change: {
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontFamily: Bold,
    cursor: "pointer",
  },
  requsettop: {
    backgroundColor: theme?.palette?.background?.tertiary2,
    padding: "4px",
    borderRadius: "5px",
    fontSize: "12px",
    fontFamily: Bold,
  },
  requsettitle: {
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: Bold,
  },
  requsetsub: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "8px",
  },
  requsetsubs: {
    fontSize: "14px",
    fontFamily: Bold,
  },
  requsetsubs2: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
  },
  requsetsubs3: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    alignSelf: "end",
  },
}));
