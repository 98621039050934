import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const CardStyles = makeStyles((theme) => ({
    mainBlock: {
        borderRadius: theme.palette.borderRadius,
        padding: "10px",
        margin: "8px",
        backgroundRepeat: "no-repeat",
        backgroundSize:"cover"
    },
    iconBox:{
        backgroundColor:"#FFFFFF",
        borderRadius:theme.palette.borderRadius,
        padding:"7px",
        display:"flex",
        justifyContent:"center"
    },
    title:{
        fontSize:"16px",
        color:"#FFFFFF",
        fontFamily: props => FontFamilySwitch(props?.language).bold,
        marginTop:"5px",
        marginLeft:"15px",
        marginRight:"15px"
    },
    arrowIcon:{
        backgroundColor:"#FFFFFF",
        borderRadius:"50%",
        width:"20px",
        height:"20px",
        display:"flex",
        justifyContent:"center",
        marginTop:"8px"
    }
}))