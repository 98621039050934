import jwt_decode from "jwt-decode";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "authToken",
  version: "version",
  deviceToken: "device_token",
  accessType: "accessType",
  userProfileId: "userProfileId",
  client: "client",
  agreement: "agreement"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export const calculateTime = (date1, date2) => {
  var oneDay = 24 * 60 * 60 * 1000;
  var date1InMillis = date1.getTime();
  var date2InMillis = date2.getTime();
  var days = Math.round((date2InMillis - date1InMillis) / oneDay);
  return days;
};

export const TabContant = {
  Tabs: [
    {
      mounth: "Jan",
      value: 0,
    },
    {
      mounth: "Feb",
      value: 1,
    },
    {
      mounth: "Mar",
      value: 2,
    },
    {
      mounth: "Apr",
      value: 3,
    },
    {
      mounth: "May",
      value: 4,
    },
    {
      mounth: "Jun",
      value: 5,
    },
    {
      mounth: "July",
      value: 6,
    },
    {
      mounth: "Aug",
      value: 7,
    },
    {
      mounth: "Sep",
      value: 8,
    },
    {
      mounth: "Oct",
      value: 9,
    },
    {
      mounth: "Nov",
      value: 10,
    },
    {
      mounth: "Dec",
      value: 11,
    },
  ],

  TabYearly: [
    {
      label: "2022",
      value: 2022,
    },
    {
      label: "2021",
      value: 2021,
    },
  ],
};

export let roles = {
  tenant: "Tenant",
  owner: "Owner"
}

export const getClient = (token) => jwt_decode(token)?.selected_client

export let requsetStatus = {
  open: "Open",
  assigned: "Assigned",
  closed: "Closed",
  maintenance: "Maintenance",
  general: "General"
}

export let parkingEnum = {
  parking_unit: "Parking Unit",
}

export let quotationActionCard = [
  {
    id: 1,
    img: "/images/icons8-paid-bill.svg",
    title: "Accept quote",
    color: "#35A27D",
    value: "Interested"
  },
  // {
  //   id: 2,
  //   img: "/images/icons8-leave-house (1).svg",
  //   title: "Request for visit",
  //   color: "#27AFCF",
  //   value: "Interested"
  // },
  {
    id: 4,
    title: "Not Interested",
    value: "Not Interested"
  }
];

export let quotationEnum = {
  interested: "Interested",
  notinterested: "Not Interested"

}

export let agreementEnum = {
  termination: "Termination",
  pending: "Pending",
  renwal: "Renewal"

}
//fonts function
export const Regular =
  "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif";
export const Bold =
  "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif";
export const SemiBold =
  "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif";
export const ExtraBold =
  "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif";

export const TempBanner = [
  {
    "id": "89be92ef-8666-445f-b6a2-efc6e43eef5a",
    "company_id": 43,
    "is_active": true,
    "created_by": "1b247a92-f6a7-4734-84d8-2edfe45888d4",
    "updated_by": "1b247a92-f6a7-4734-84d8-2edfe45888d4",
    "created_at": "2022-07-26T06:42:25.382Z",
    "updated_at": "2022-07-26T06:42:25.382Z",
    "type": "Dashboard",
    "property_id": null,
    "client": "ed345407-3e8a-4baa-b451-11f671f1eb64",
    "company_name": "Toyota Builders",
    "first_name": "Ray New 2322",
    "last_name": "dcode",
    "assets": [
      {
        "reference_id": "89be92ef-8666-445f-b6a2-efc6e43eef5a",
        "id": "64144f96-7d94-496d-b451-2e3cc666942a",
        "priority": 1,
        "type": "General",
        "url": "https://protomate.objectstore.e2enetworks.net/Banner%203new.png",
        "action_url": "",
        "file_meta": {
          "name": "sample_banner1.png",
          "size": "3kb",
          "type": "png"
        }
      },
      {
        "reference_id": "89be92ef-8666-445f-b6a2-efc6e43eef5a",
        "id": "2f728cdd-fea1-4e58-ab07-2bfdfb374d15",
        "priority": 1,
        "type": "General",
        "url": "https://protomate.objectstore.e2enetworks.net/Banner%203new.png",
        "action_url": "",
        "file_meta": {
          "name": "sample_banner2.png",
          "size": "3kb",
          "type": "png"
        }
      },
      {
        "reference_id": "89be92ef-8666-445f-b6a2-efc6e43eef5a",
        "id": "e89c09ef-1e9c-41ce-a4b3-8caeec7567af",
        "priority": 1,
        "type": "General",
        "url": "https://protomate.objectstore.e2enetworks.net/Banner%203new.png",
        "action_url": "",
        "file_meta": {
          "name": "sample_banner3.png",
          "size": "30kb",
          "type": "png"
        }
      }
    ]
  },
  {
    "id": "a5842254-28e2-4622-9ad0-78dc34eaff6c",
    "company_id": 1,
    "is_active": false,
    "created_by": "1b247a92-f6a7-4734-84d8-2edfe45888d4",
    "updated_by": "1b247a92-f6a7-4734-84d8-2edfe45888d4",
    "created_at": "2022-07-05T12:45:10.597Z",
    "updated_at": "2022-07-05T12:45:10.597Z",
    "type": "Dashboard",
    "property_id": null,
    "client": "ed345407-3e8a-4baa-b451-11f671f1eb64",
    "company_name": "Uzumaki Naruto test",
    "first_name": "Ray New 2322",
    "last_name": "dcode",
    "assets": [
      {
        "reference_id": "a5842254-28e2-4622-9ad0-78dc34eaff6c",
        "id": "cf895922-75d4-4b5f-b731-c8020a7255d9",
        "priority": 1,
        "type": "General",
        "url": "https://protomate.objectstore.e2enetworks.net/rayDcode/1657025092523-Banner%20-%205.jpg.jpeg",
        "action_url": "",
        "file_meta": {
          "name": "Banner - 5.jpg",
          "size": "144.5KB",
          "type": "jpeg"
        }
      },
      {
        "reference_id": "a5842254-28e2-4622-9ad0-78dc34eaff6c",
        "id": "a4ff229d-6024-42d9-bbb9-7126628ee24f",
        "priority": 2,
        "type": "General",
        "url": "https://protomate.objectstore.e2enetworks.net/rayDcode/1657025103390-Banner%20-%206.jpg.jpeg",
        "action_url": "",
        "file_meta": {
          "name": "Banner - 6.jpg",
          "size": "87.7KB",
          "type": "jpeg"
        }
      },
      {
        "reference_id": "a5842254-28e2-4622-9ad0-78dc34eaff6c",
        "id": "17e82ee0-4620-4f53-8ebd-2c2ed942f8a3",
        "priority": 3,
        "type": "General",
        "url": "https://protomate.objectstore.e2enetworks.net/rayDcode/1657025107738-Banner%20-%207.jpg.jpeg",
        "action_url": "",
        "file_meta": {
          "name": "Banner - 7.jpg",
          "size": "129.2KB",
          "type": "jpeg"
        }
      }
    ]
  }
]
export const RTLLanguages = ["ar"]

export const LanguageOptions = [
  { value: "en", label: "English" },
  { value: "zh", label: "Chinese (Simplified)" },
  { value: "hi", label: "Hindi" },
  { value: "es", label: "Spanish" },
  { value: "fr", label: "French" },
  { value: "ar", label: "Arabic" },
  { value: "ru", label: "Russian" },
  { value: "pt", label: "Portuguese" },
  { value: "it", label: "Italy" },
  { value: "fil", label: "Filipino" },
  { value: "id", label: "Indonesian" },
  { value: "ta", label: "Tamil" },
  { value: "te", label: "Telugu" },
  { value: "kn", label: "Kannada" },
  { value: "ml", label: "Malayalam" },
  { value: "de", label: "German" },
];