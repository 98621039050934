import { Avatar, Box, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import DateIcon from "../../assets/dateIcon";
import { Routes } from "../../router/routes";
import { Regular } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",
    overflow: "hidden",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  Cardcontent: {
    // [theme.breakpoints.up("sm")]: {
    //   padding: "8px 12px",
    // },

    // [theme.breakpoints.down("sm")]: {
    //   padding: "8px 7px",
    // },
    // borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: Regular,
  },

  box: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px",
    [theme.breakpoints.down(321)]: {
      height: "140px",

    },
  },
  img: {
    // objectFit: "cover",
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  bold: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.secondary,

  },
  semiBold: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,

  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    alignItems: "center"
  },

}));

export const ParkingCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <div
        className={classes.card}
        onClick={() =>
          history.push(Routes.parkingDetails + "?passId=" + props.data.id)
        }
      >
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex" sx={{ flexWrap: "nowrap" }}>
            {props?.data?.vehicle_image ?
              <Avatar src={props?.data?.vehicle_image} alt={props?.data?.vehicle_image} variant="rounded" className={classes.avatar} /> :

              <Avatar src={
                props?.parking
                  ? "/images/parkingslot.svg"
                  : "/images/workergate.svg"
              }
                className={classes.img} variant="rounded" />
            }

            <Box flexGrow={1} marginLeft="10px">
              <Box className={classes.details} justifyContent={"space-between"}>
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    <Hidden smUp>Parking Slot Requi...</Hidden>
                    <Hidden smDown>Parking Slot Requirement</Hidden>
                  </Typography>

                </Box>
                <Box marginRight="10px">
                  {props?.data?.status === "Pending" && (
                    <Typography
                      variant="subtitle2"
                      noWrap
                      className={classes.progress}
                    >
                      In Progress
                    </Typography>
                  )}
                  {props?.data?.status === "Approved" && (
                    <Typography
                      variant="subtitle2"
                      className={classes.approved}
                    >
                      Approved
                    </Typography>
                  )}
                  {props?.data?.status === "Rejected" && (
                    <Typography variant="subtitle2" className={classes.delete}>
                      Declined
                    </Typography>
                  )}
                  {props?.data?.status === "Cancelled" && (
                    <Typography variant="subtitle2" className={classes.delete}>
                      Cancelled
                    </Typography>
                  )}
                </Box>

              </Box>
              <Box
                style={{ flexFlow: "wrap !important" }}
                className={classes.box}
                alignItems="center"
                display="flex"
                marginTop="4px"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.property_name}
                    </Typography>
                  </Box>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box className={classes.dot} />
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.sub}
                      noWrap
                    >
                      {props?.data?.units?.[0]?.unit_no ??
                        props?.data?.unit?.[0]?.unit_no}
                    </Typography>
                  </Box>
                </div>
              </Box>
              <Box
                style={{ flexFlow: "wrap !important" }}
                className={classes.box}
                alignItems="center"
                display="flex"
                marginTop="4px"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.semiBold}
                      noWrap
                    >
                      {props?.data?.vechicle_number ?? ""}
                    </Typography>
                  </Box>
                </div>


                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                  {props?.data?.request_no && <Box className={classes.dot} />}

                  <Box>
                    <Typography variant="subtitle2" className={classes.sub}>
                      {props?.data?.request_no}
                    </Typography>
                  </Box>
                </div> */}
              </Box>
              <Box
                style={{ flexFlow: "wrap !important" }}
                className={classes.box}
                alignItems="center"
                display="flex"
                marginTop="4px"
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <DateIcon /> &nbsp;
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.bold}
                      noWrap
                    >
                      {moment(props?.data?.request_from)
                        .tz(moment.tz.guess())
                        .format("DD MMM YY")}
                      &nbsp;-&nbsp;
                      {moment(props?.data?.request_to)
                        .tz(moment.tz.guess())
                        .format("DD MMM YY")}
                    </Typography>
                  </Box>
                </div>
              </Box>
            </Box>

          </Box>
        </div>
      </div>
    </>
  );
};
