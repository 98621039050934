import React from "react";
import  RequestDetails  from './requestDetails';

class RequestDetailsParent extends React.Component {
    render() {
        return <RequestDetails />;
    }
}

export default RequestDetailsParent;
