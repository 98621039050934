import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import DateTime from "../date";
const useStyles = makeStyles((theme) => ({
  bottomTitle: {
    fontSize: "16px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "6px 8px",
  },
  root: {
    padding: "4px 8px",
  },
  btn: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
  },
}));
const InitialState = {
  type: "",
  amount: "",
  date: null,
  bankname: "",
  transation: "",
  company: "",
  notes: "",
  error: {
    type: "",
    amount: "",
    date: "",
    bankname: "",
    transation: "",
    company: "",
  },
};
const PayForm = (props) => {
  const classes = useStyles();
  const [data, setdata] = React.useState({ ...InitialState });
  const [disable, setDisable] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const history = useHistory();

  const paymentMode = [
    {
      type: props?.t("cash"),
      value: "CASH",
      grid: 3,
    },
    {
      type: props?.t("cheque"),
      value: "CHEQUE",
      grid: 4,
    },
    {
      type: props?.t("bank_transfer"),
      value: "TRANSFER",
      grid: 5,
    },
  ];
  const isIamValide = () => {
    let isValid = true;
    let error = data.error;
    //Checking Pass Type
    if (data.type.length === 0) {
      isValid = false;
      error.type = "Type is Required";
    }
    //Checking unit
    if (data.amount.length === 0) {
      isValid = false;
      error.amount = "Amount is Required";
    }
    //Checking Stay Type
    if (data.date.length === 0) {
      isValid = false;
      error.date = "Date is Required";
    }
    //Checking Start Date
    if (data.bankname.length === 0) {
      isValid = false;
      error.bankname = "Bank Name is Required";
    }
    //Checking End Date
    if (data.transation.length === 0) {
      isValid = false;
      error.transation = "Transation ID is Required";
    }

    setdata({ ...data, error });
    return isValid;
  };
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const submit = () => {
    setDisable(true);
    if (isIamValide()) {
      const params = {
        tenantId: `${config.tenantid}`,
        payment_date: data?.date,
        payment_amount: data?.amount,
        transaction_reference_no: data?.transation,
        payment_mode: data?.type,
        currency_master_id: props?.currency_id,
        user_profile_id: localStorage.getItem("userProfileId"),
        invoices: props?.invoices,
      };
      NetworkCall(
        `${config.api_url}/payment-sync/invoice`,
        NetWorkCallMethods.post,
        params,
        null,
        true,
        false
      )
        .then((response) => {
          props?.onClose();

          history.push({
            pathname: Routes.success,
            state: true,
          });
          setDisable(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing went wrong",
          });
          setDisable(false);
        });
    }
  };
  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center" className={classes.titleroot}>
        <Box flexGrow={1}>
          <Typography className={classes.bottomTitle}>
            {props?.t("already_paid")}
          </Typography>
        </Box>
        <Box>
          <IconButton size="small" onClick={() => props?.onClose(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box p={1}>
        <Box height="6px" />
        <FormControl component="fieldset">
          <RadioGroup>
            <Grid container>
              {paymentMode?.map((val) => {
                return (
                  <Grid item xs={val?.grid ?? 4}>
                    <FormControlLabel
                      value={val?.value}
                      control={<Radio checked={val?.value === data?.type} />}
                      label={<Typography noWrap>{val?.type}</Typography>}
                      onChange={() => updateState("type", val?.value)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        </FormControl>
        <br />
        {data?.error?.type?.length > 0 && (
          <Typography variant={"caption"} color={"error"}>
            {data?.error?.type}
          </Typography>
        )}
        <TextBox
          label={props?.t("amount_paid")}
          placeholder={`${props?.t("enter")} ${props?.t("amount_paid")}`}
          type="number"
          value={data.amount}
          onKeyPress={(e) => {
            if (e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(value) => updateState("amount", value.target.value)}
          isError={data?.error?.amount?.length > 0}
          errorMessage={data?.error?.amount}
        />
        <Box height="6px" />
        <DateTime
          title={props?.t("date")}
          value={data.date ?? null}
          onChange={(value) => updateState("date", value)}
          isError={data?.error?.date?.length > 0}
          errorMessage={data?.error?.date}
        />
        <Box height="8px" />
        <TextBox
          label={props?.t("bank_name_location")}
          placeholder={`${props?.t("enter")} ${props?.t("bank_name_location")}`}
          value={data.bankname}
          onChange={(value) => updateState("bankname", value.target.value)}
          isError={data?.error?.bankname?.length > 0}
          errorMessage={data?.error?.bankname}
        />
        <Box height="6px" />
        <TextBox
          label={props?.t("transaction_id")}
          placeholder={`${props?.t("enter")} ${props?.t("transaction_id")}`}
          value={data.transation}
          onChange={(value) => updateState("transation", value.target.value)}
          isError={data?.error?.transation?.length > 0}
          errorMessage={data?.error?.transation}
        />
        <Box height="6px" />
        <TextBox
          label={props?.t("notes")}
          placeholder={`${props?.t("enter")} ${props?.t("notes")}`}
          value={data.notes}
          onChange={(value) => updateState("notes", value.target.value)}
        />
        <Box height="12px" />
        <Button
          variant="contained"
          fullWidth
          className={classes.btn}
          onClick={submit}
          disabled={disable}
        >
          {props?.t("reconcile")}
        </Button>
      </Box>
    </div>
  );
};
export default withNamespaces("invoice")(PayForm);
