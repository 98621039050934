import React, { useState, useContext, useEffect } from "react";
import { Container, Box, Typography, Button, Grid } from "@mui/material";
import { useStyle } from "./style";
import { TopNavBars } from "../../components";
import { VacantUnitsImage } from "../../assets/vacantUnit";
import { withNamespaces } from "react-i18next";
import { CurrentAgreementCard, Card } from "./components";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import moment from "moment";
import { Routes } from "../../router/routes";

const YourUnitsVacant = ({ t }) => {
  // classes
  const classes = useStyle();
  // context
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  // history
  const history = useHistory();
  const { state } = useLocation();
  // state
  const [details, setDetails] = useState([]);

  const onUpload = () => {
    history.push(Routes.assetsUpload);
  };
  const viewAssts = () => {
    history.push({
      pathname: Routes.assetsUpload,
      state: {
        id: state?.id,
        type: true,
      },
    });
  };
  const saveGeneralUnits = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const payload = {
      unit_id: state?.id ?? "",
    };
    NetworkCall(
      `${config.api_url}/owner/get_owner_unit`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((res) => {
        setDetails(res?.data?.data);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
      })
      .catch(() => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "Loading",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };

  useEffect(() => {
    saveGeneralUnits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const Details = [
    {
      image: '/images/yourunits/aminities.svg',
      title: "Amenites",
    },
    {
      image: '/images/yourunits/utilities.svg',
      title: "Utilities",
    },
    {
      image: '/images/yourunits/inventory.svg',
      title: "Inventory",
    },
  ];
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* top navbar */}
      <TopNavBars title={state?.name} isBack />
      <Box className={classes.image}>
        <img
          src={details?.agreement?.logo ?? ""}
          alt="images"
          width={"100%"}
          height={"304px"}
        />
        <Cards
          t={t}
          isShow={state?.status === "Occupied" ? true : false}
          onView={viewAssts}
          details={details}
          data={{
            name: details?.agreement?.unit_name,
            no: details?.agreement?.unit_no,
            property_name: details?.agreement?.property_name,
            location: `${details?.agreement?.area} , ${details?.agreement?.city}`,
          }}
        />
      </Box>
      {state?.status === "Occupied" ? (
        <Box p={3} className={classes.marginTop}>
          <Grid container spacing={2}>
            {Details.map((x) => {
              return (
                <Grid item xs={12}>
                  <Card
                    onClick={onUpload}
                    data={{
                      title: x?.title,
                      image: x?.image,
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Box p={3} className={classes.marginTop1}>
          <VacantUnitsImage />
        </Box>
      )}
    </Container>
  );
};
export default withNamespaces("yourUnits")(YourUnitsVacant);

// card UI
const Cards = ({
  data = {},
  t,
  details = {},
  onView = () => false,
  isShow = false,
}) => {
  // classes
  const classes = useStyle();
  return (
    <div className={classes.card}>
      <Box display="flex" alignItems="center">
        {/* name */}
        {console.log(data?.name)}
        <Typography className={classes.unitname} noWrap>
          {data?.name ?? "-"}
        </Typography>
        {/* agreement number */}
        <Typography className={classes.agreementNumber} noWrap>
          {data?.no ?? "-"}
        </Typography>
      </Box>
      {/* property name */}
      <Box display="flex" alignItems="center">
        <Typography className={classes.propertyName} noWrap>
          {data?.property_name ?? "-"}
        </Typography>
        <Box className={classes.dot1} />
        <Typography className={classes.propertyName} noWrap>
          {data?.location ?? "-"}
        </Typography>
      </Box>

      <Button className={classes.btn} onClick={onView}>
        {t("view_assets")}
      </Button>

      {isShow && (
        <CurrentAgreementCard
          details={details}
          date={[
            {
              title: "Agreement start date",
              date: moment(details?.agreement?.lease_start_date).format(
                "yyyy-MM-DD"
              ),
            },
            {
              title: "Agreement end date",
              date: moment(details?.agreement?.lease_end_date).format(
                "yyyy-MM-DD"
              ),
            },
          ]}
        />
      )}
    </div>
  );
};
