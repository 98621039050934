import {
    Box,
    CardContent,
    Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { withNamespaces } from "react-i18next";
import { calculateDaysBetweenDates } from "../../utils";

const useStyles = makeStyles((theme) => ({
    card: {},

    requestID: {
        borderRadius: "11px",
        backgroundColor: "#EDE4FE",
        backgroundPositionX: "100%",
        color: "black",

        marginBottom: "10px",
        width: "200px",
        "&.MuiCardContent-root": {
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingBottom: "12px",
            paddingTop: "12px",
        },
    },
    CardContent: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#E4EDFF",

        width: "200px",
        marginBottom: "10px",

        "&.MuiCardContent-root": {
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingBottom: "12px",
            paddingTop: "12px",
        },
    },
    general: {
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#DBF0F1",
        backgroundPositionX: "100%",

        marginBottom: "10px",

        width: "200px",
        "&.MuiCardContent-root": {
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingBottom: "12px",
            paddingTop: "12px",
        },
    },
    title: {
        fontSize: "18px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        //color: theme.palette.primary.contrastText,
        alignItems: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    body: {
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        display: "flex",
        alignItems: "center",
        marginTop: "6px",
        color: "#4E5A6B",
        fontSize: "12px",
    },
    subBody: {
        fontSize: "10px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        backgroundColor: "white",
        borderRadius: theme.palette.borderRadius,
        color: "black",
        padding: " 1px 7px",
    },
    titles: {
        fontSize: "16px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: theme.typography.color.primary,
    },
    totalAmount: {
        fontSize: "14px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
        color: theme.typography.color.primary,
    },

    subTitle: {
        fontSize: "12px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
        color: theme.typography.color.secondary,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "16px",
            padding: "0px",
        },
    },
    maintanceimg: {
        padding: "10px 10px 5px 10px",
        backgroundColor: "#7F48CE",
        borderRadius: "7px",
    },
    invoiceimg: {
        padding: "10px 10px 5px 10px",
        backgroundColor: "#5078E1",
        borderRadius: "7px",
    },
    generalimg: {
        padding: "10px 10px 5px 10px",
        backgroundColor: "#119DA4",
        borderRadius: "7px",
    },
    badge: {
        fontSize: "8px",
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingBottom: "4px",
        paddingTop: "4px",
        backgroundColor: "white",
        borderRadius: "8px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    },
    type: {
        color: "#4E5A6B",
        fontSize: "12px",
    },
    periodStyle: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
    },
    btn: {
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "none",
    },
    btn1: {
        padding: "10px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "white",
        border: "1px solid #5078E1",
        color: "#5078E1",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "white",
        },
    },
    cardDatas: {
        flexFlow: 'wrap !important',
        alignItems: 'center',
        display: "flex"
    }
}));

const AmenitiesInvoiceCard = ({
    cardData = [],
    type = "",
    onPay = () => false,
    names = "",
    t,
}) => {
    const dueDate = calculateDaysBetweenDates(new Date(cardData?.payment_due_date), new Date(Date.now()))

    // const [invoicepdf, setInvoicepdf] = React.useState([]);
    // const [showpdf, setShowpdf] = React.useState(false);
    const classes = useStyles(cardData);
    // const [paymentoption, setPaymentoption] = React.useState(false);
    // const [formopen, setformopen] = React.useState(false);
    // const [openinvoice, setOpeninvoice] = React.useState(false);
    // const alert = React.useContext(AlertContext);
    // const size = useWindowDimensions();
    // const invoicedata = {
    //     id: cardData?.id,
    //     currency_master: {
    //         symbol: cardData.currency_symbol,
    //     },
    //     description: "",
    //     invoice_due_amount: cardData.OutsandingAmount,
    //     invoice_no: cardData.invoice_no,
    //     company: cardData?.company_id,
    //     currency_id: cardData?.currency_id,
    // };
    // const viewinvoice = (data) => {
    //     const params = {
    //         query: GETAGREEMENTPDF,
    //         variables: {
    //             id: data,
    //         },
    //     };
    //     NetworkCall(
    //         `${config.graphql_url}`,
    //         "post",
    //         params,
    //         null,
    //         true,
    //         false
    //     )
    //         .then((response) => {
    //             setInvoicepdf(response.data.data);
    //             setShowpdf(true);
    //         })
    //         .catch((error) => {
    //             alert.setSnack({
    //                 ...alert,
    //                 open: true,
    //                 severity: AlertProps.severity.error,
    //                 msg: "Some thing went wrong",
    //             });
    //         });
    // };
    return (
        <>
            {type === "Rental" && (
                <CardContent
                    className={classes.CardContent}
                // onClick={() => {
                //     setOpeninvoice(true);
                // }}
                >
                    <Box display="flex">
                        <Box>
                            <Box className={classes.invoiceimg}>
                                <img src="/images/invoiceCard.svg" alt="" />
                            </Box>
                        </Box>
                        <Box flexGrow={1}></Box>
                        <Box>
                            <Typography className={classes.badge}>
                                {
                                    dueDate > 0 ? dueDate + "  days to due" : "over due"
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box height={"8px"} />
                    <Typography
                        style={{
                            fontSize: "12px",
                            color: "#4E5A6B",
                            fontWeight: "bold",
                            wordWrap: "break-word",
                        }}
                    >
                        {t("invoice")}
                    </Typography>
                    <Box height={"4px"} />
                    <Typography style={{ fontWeight: "bolder" }}>
                        {cardData?.description}
                    </Typography>
                    <Box className={classes.cardDatas}>
                        <Box>
                            <Typography className={classes.type}>
                                {cardData.invoice_type}&nbsp;
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Box className={classes.periodStyle} />
                            <Box>
                                <Typography noWrap className={classes.type}>
                                    &nbsp;{cardData.invoice_no}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            )}
            {type === "Maintenance" && (
                <CardContent className={classes.requestID}>
                    <Box display="flex">
                        <Box>
                            <Box className={classes.maintanceimg}>
                                <img src="/images/generalCard.svg" alt="" />
                            </Box>
                        </Box>
                        <Box flexGrow={1}></Box>
                        <Box>
                            <Typography className={classes.badge}>
                                {
                                    dueDate > 0 ? dueDate + "  days to due" : "over due"
                                }
                            </Typography>
                        </Box>
                    </Box>

                    <Box height={"8px"} />

                    <Typography
                        style={{
                            fontSize: "12px",
                            color: "#4E5A6B",
                            fontWeight: "bold",
                            wordWrap: "break-word",
                        }}
                    >
                        {t("maintenance")}
                    </Typography>

                    <Box height={"4px"} />
                    <Typography style={{ fontWeight: "bolder" }} noWrap>
                        {cardData.description}
                    </Typography>
                    <Box className={classes.cardDatas} >
                        <Box>
                            <Typography className={classes.type} noWrap width={"60px"}>
                                {cardData.invoice_type}&nbsp;
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Box className={classes.periodStyle} />
                            <Box>
                                <Typography noWrap className={classes.type}>
                                    &nbsp;{cardData.invoice_no}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            )}
            {type === "General" && (
                <CardContent className={classes.general}>
                    <Box display="flex">
                        <Box>
                            <Box className={classes.generalimg}>
                                <img src="/images/maintanaceCard.svg" alt="" />
                            </Box>
                        </Box>
                        <Box flexGrow={1}></Box>
                        <Box>
                            <Typography className={classes.badge}>
                                {
                                    dueDate > 0 ? dueDate + "  days to due" : "over due"
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box height={"8px"} />

                    <Typography
                        style={{
                            fontSize: "12px",
                            color: "#4E5A6B",
                            fontWeight: "bold",
                            wordWrap: "break-word",
                        }}
                    >
                        {" "}
                        {t("general")}
                    </Typography>

                    <Box height={"4px"} />
                    <Typography style={{ fontWeight: "bolder" }}>
                        {cardData.description}
                    </Typography>
                    <Box className={classes.cardDatas}>
                        <Box>
                            <Typography className={classes.type} noWrap>
                                {cardData.invoice_type}&nbsp;
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Box className={classes.periodStyle} />
                            <Box>
                                <Typography noWrap className={classes.type}>
                                    &nbsp;{cardData.invoice_no}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            )}
            {type === "Settlement Invoice" && (
                <CardContent className={classes.general}>
                    <Box display="flex">
                        <Box>
                            <Box className={classes.generalimg}>
                                <img src="/images/maintanaceCard.svg" alt="" />
                            </Box>
                        </Box>
                        <Box flexGrow={1}></Box>
                        <Box>
                            <Typography className={classes.badge}>
                                {
                                    dueDate > 0 ? dueDate + "  days to due" : "over due"
                                }
                            </Typography>
                        </Box>
                    </Box>
                    <Box height={"8px"} />

                    <Typography
                        style={{
                            fontSize: "12px",
                            color: "#4E5A6B",
                            fontWeight: "bold",
                            wordWrap: "break-word",
                        }}
                    >
                        {" "}
                        {t("general")}
                    </Typography>

                    <Box height={"4px"} />
                    <Typography style={{ fontWeight: "bolder" }}>
                        {cardData.description}
                    </Typography>
                    <Box className={classes.cardDatas}>
                        <Box>
                            <Typography className={classes.type} noWrap>
                                {cardData.invoice_type}&nbsp;
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <Box className={classes.periodStyle} />
                            <Box>
                                <Typography noWrap className={classes.type}>
                                    &nbsp;{cardData.invoice_no}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
            )}
            {/* <Hidden smDown>
                <Dialog
                    maxWidth="sm"
                    className={classes.dialog}
                    fullWidth
                    open={openinvoice}
                    onClose={() => {
                        setOpeninvoice(!openinvoice);
                    }}
                >
                    <Box
                        display="flex"
                        p={1}
                        style={{ borderBottom: "1px solid #c1c1c1" }}
                        alignItems="center"
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.titles}>
                                {type === "invoice" && t("invoice")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    setOpeninvoice(!openinvoice);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {type === "invoice" && (
                        <Grid xs={12} style={{ padding: "0px 12px 12px 12px" }}>
                            <InvoicePayNowList
                                InvoicePay={invoicedata}
                                dashboard={true}
                                delete={true}
                                type="dashboard"
                                viewinvoice={viewinvoice}
                            />
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    spacing={1}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.titles}
                                            style={{ marginTop: "8px" }}
                                        >
                                            {t("bill_summary")}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{ padding: "4px 8px 8px 8px" }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography className={classes.subTitle}>
                                                {t("invoice_amount")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ float: "right" }}>
                                                <Typography className={classes.subTitle}>
                                                    {cardData.currency_symbol +
                                                        Intl.NumberFormat("en-IN", {
                                                            minimumFractionDigits: 0,
                                                        }).format(Math.round(cardData.OutsandingAmount))}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                            display: "flex",
                                            borderBottom: "2px dashed #CED3DD",
                                            borderTop: "2px dashed #CED3DD",
                                            padding: "4px 8px 4px 8px",
                                        }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography className={classes.totalAmount}>
                                                {t("total_amount_to_pay")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ float: "right" }}>
                                                <Typography className={classes.totalAmount}>
                                                    {cardData.currency_symbol +
                                                        Intl.NumberFormat("en-IN", {
                                                            minimumFractionDigits: 0,
                                                        }).format(Math.round(cardData.OutsandingAmount))}
                                                </Typography>
                                            </div>
                                            <br />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                className={classes.btn1}
                                                onClick={() => setformopen(true)}
                                                fullWidth
                                            >
                                                {t("already_paid")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                className={classes.btn}
                                                fullWidth
                                                onClick={() => setPaymentoption(true)}
                                            >
                                                {t("pay_now")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    className={classes.drawer}
                    maxWidth="sm"
                    fullWidth
                    anchor="bottom"
                    open={openinvoice}
                    onClose={() => {
                        setOpeninvoice(!openinvoice);
                    }}
                >
                    <Box
                        display="flex"
                        p={1}
                        style={{ borderBottom: "1px solid #c1c1c1" }}
                        alignItems="center"
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.titles}>
                                {type === "invoice" && "Invoices"}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                onClick={() => {
                                    setOpeninvoice(!openinvoice);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {type === "invoice" && (
                        <Grid xs={12} style={{ padding: "0px 12px 12px 12px" }}>
                            <InvoicePayNowList
                                InvoicePay={invoicedata}
                                dashboard={true}
                                delete={true}
                                type="dashboard"
                                viewinvoice={viewinvoice}
                            />
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    spacing={1}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            className={classes.titles}
                                            style={{ marginTop: "8px" }}
                                        >
                                            {t("bill_summary")}
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{ padding: "4px 8px 8px 8px" }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography className={classes.subTitle}>
                                                {t("invoice_amount")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ float: "right" }}>
                                                <Typography className={classes.subTitle}>
                                                    {cardData.currency_symbol +
                                                        Intl.NumberFormat("en-IN", {
                                                            minimumFractionDigits: 0,
                                                        }).format(Math.round(cardData.OutsandingAmount))}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                            display: "flex",
                                            borderBottom: "2px dashed #CED3DD",
                                            borderTop: "2px dashed #CED3DD",
                                            padding: "4px 8px 4px 8px",
                                        }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography className={classes.totalAmount}>
                                                {t("total_amount_to_pay")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <div style={{ float: "right" }}>
                                                <Typography className={classes.totalAmount}>
                                                    {cardData.currency_symbol +
                                                        Intl.NumberFormat("en-IN", {
                                                            minimumFractionDigits: 0,
                                                        }).format(Math.round(cardData.OutsandingAmount))}
                                                </Typography>
                                            </div>
                                            <br />
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={1}>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                className={classes.btn1}
                                                onClick={() => setformopen(true)}
                                                fullWidth
                                            >
                                                {t("already_paid")}
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                variant="contained"
                                                className={classes.btn}
                                                fullWidth
                                                onClick={() => setPaymentoption(true)}
                                            >
                                                {t("pay_now")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Drawer>
            </Hidden>
            {/* invoice */}
            {/* <Hidden smDown>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={showpdf}
                    className={classes.dialog}
                    onClose={() => setShowpdf(false)}
                >
                    <Box
                        display="flex"
                        p={1}
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                                {names?.invoice ?? "Invoice"}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton size="small" onClick={() => setShowpdf(false)}>
                                <CloseIcon  />
                            </IconButton>
                        </Box>
                    </Box>
                    <div style={{ height: size?.height - 115 }}>
                        <DocumentViewer url={invoicepdf?.assets?.[0]?.url} />
                    </div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    maxWidth="sm"
                    anchor="bottom"
                    fullWidth
                    open={showpdf}
                    className={classes.drawer}
                    onClose={() => setShowpdf(false)}
                >
                    <Box
                        display="flex"
                        p={1}
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                                {names?.invoice ?? "Invoice"}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton size="small" onClick={() => setShowpdf(false)}>
                                <CloseIcon  />
                            </IconButton>
                        </Box>
                    </Box>
                    <div style={{ height: size?.height - 115 }}>
                        <DocumentViewer url={invoicepdf?.assets?.[0]?.url} />
                    </div>
                </Drawer>
            </Hidden>
            {
                paymentoption || formopen ?
                    <InvoicePay
                        paymentoption={paymentoption}
                        onClose={() => setPaymentoption(false)}
                        formopen={formopen}
                        onClose1={() => setformopen(false)}
                        Outstanding_amount={cardData.OutsandingAmount}
                        amount={cardData.OutsandingAmount}
                        invoices={[cardData?.id]}
                        currency_symbol={cardData.currency_symbol}
                        company={cardData?.company_id}
                        currency_id={cardData?.currency_id}
                    />
                    : ""
            }  */}

        </>
    );
};

export default withNamespaces("invoice")(AmenitiesInvoiceCard);
