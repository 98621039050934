import DateAdapter from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
  },
  datepicker: {
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      height: 50,
      borderRadius: "8px",
      border: "1.5px solid  #CED3DD",
      boxShadow: "none",
    },
  },

}));

const DateTime = (props) => {
  const classes = useStyles(props);
  const getTitle = (props) => {
    return (
      <div style={{ display: "flex" }}>
        {
          <Typography
            variant="body1"
            color="textsecondary"
            className={classes.text}
            align="left"
            gutterBottom
          >
            {props?.title}
          </Typography>
        }
        {props?.isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>
    );
  };

  return (
    <>
      {getTitle(props)}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Stack spacing={3}>
          <DatePicker
            disablePast={props.disablePast}
            className={classes.datepicker}
            disableFuture={props.disableFuture}
            // label=""
            views={['day']}
            value={props?.value ?? null}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" className={classes.textbox} />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </>
  );
};
export default DateTime;
