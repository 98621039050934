import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Typography } from "@mui/material";
import React from "react";
import { CardStyles } from "./styles";

const Cards = ({ data,authContext=""}) => {

    const classes = CardStyles({ language: authContext?.auth?.auth?.language })
    return (
        <Box className={classes.mainBlock} style={{backgroundImage:`url(${data?.bgImage})`}}>
            <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"}>
                    <div className={classes.iconBox}>
                        <img src={data?.icon} alt="" />
                    </div>
                    <Typography className={classes.title}>{data?.title}</Typography>
                </Box>
                <Box className={classes.arrowIcon}>
                <ArrowForwardIosIcon style={{ fontSize: "10px",marginTop:"5px" }} />
                </Box>
            </Box>

        </Box>
    )
}
export default Cards