import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { TextBox } from "../../../components";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { AGREEMENTREQUSET } from "../../../graphql/mutations";
import { NetworkCall } from "../../../networkcall/index";
import { agreementEnum, AlertProps, NetWorkCallMethods } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
  },
}));
const initialStaterequset = {
  date: null,
  remarks: "",
  error: {
    date: "",
    remarks: "",
  },
};
const RenewalForm = (props) => {
  const classes = useStyles();
  const [agreementrequset, setagreementrequset] = React.useState({ ...initialStaterequset });
  const [disable, setDisable] = React.useState(false);
  const alert = React.useContext(AlertContext);
  //Update Requset
  const upadatestaterequest = (key, value) => {
    let error = agreementrequset.error;
    error[key] = "";
    setagreementrequset({ ...agreementrequset, [key]: value, error });
  };
  const requsetsubmit = (purpose) => {
    setDisable(true);
    const variable = {
      query: AGREEMENTREQUSET,
      variables: {
        insert: [
          {
            agreement_id: props?.agreement_id,
            remarks: agreementrequset.remarks,
            created_by: localStorage.getItem("userProfileId"),
            is_active: true,
            request_purpose: agreementEnum.renwal,
            status: agreementEnum?.pending
          },
        ],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      variable,
      null,
      true,
      false
    )
      .then((response) => {
        setDisable(false);
        props?.reload()
        props?.onClose()
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Requset submitted successfully",
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went Wrong",
        });
        setDisable(false);
      });
  };

  return (
    <div>
      <Box display="flex" alignItems="center" className={classes.titleroot} style={{ padding: '12px' }}>
        <Box flexGrow={1}><Typography className={classes.bottomTitle}>Agreement Renewal</Typography></Box>
        <Box><IconButton size="small" onClick={props?.onClose}><CloseIcon /></IconButton></Box>
      </Box>
      <Box p={1}>
        <TextBox
          label={`${props.t("remarks")} ${props.t("optinal")}`}
          multiline={true}
          placeholder=" "
          onChange={(value) =>
            upadatestaterequest("remarks", value.target.value)
          }
          value={agreementrequset.remarks}
        />
        <Button
          variant="contained"
          fullWidth={true}
          sx={{
            padding: "12px",
            borderRadius: "10px",
            marginTop: "12px",
          }}
          onClick={requsetsubmit}
          disabled={disable}
        >
          {props.t("submit")}
        </Button>
      </Box>
    </div>
  )
}
export default withNamespaces("agreement")(RenewalForm);