import { Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { BottomNavbar } from "../components";
import { Routes } from "../router/routes";
import useWindowDimensions from "../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
  },

  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const withBottombar = (Component) => (props) => {
  const classes = useStyles({ props });
  const history = useHistory();
  const size = useWindowDimensions();

  const onTab1Clicked = () => {
    history.push(Routes.dashboard);
  };
  const onTab2Clicked = () => {
    history.push(Routes.yourunit);
  };
  const onTab3Clicked = () => {
    history.push(Routes.service);
  };
  const onTab4Clicked = () => {
    history.push(Routes.requestScreen);
  };
  const onTab5Clicked = () => {
    history.push(Routes.profileView);
  };

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        <Grid
          className={classes.screen}
          style={{ height: size?.height - 10}}
          item
          xs={12}
        >
          <Grid
            className={classes.screenPadding}
            style={{ height: size?.height - 62, overflow: "auto" }}
          >
            <Component {...props}>{props.children}</Component>
          </Grid>
          <Grid>
            <BottomNavbar
              onTab1Clicked={onTab1Clicked}
              onTab2Clicked={onTab2Clicked}
              onTab3Clicked={onTab3Clicked}
              onTab4Clicked={onTab4Clicked}
              onTab5Clicked={onTab5Clicked}
            />
          </Grid>
        </Grid>
      </Container>

      {/* Content */}
    </div>
  );
};

export default withBottombar;
