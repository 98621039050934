import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ParkinguseStyles } from "./style";
export const ParkingCard = ({ val = {}, handleDetails = () => false, clickAction = true }) => {
    const classes = ParkinguseStyles()
    return (
        <>
            < Grid container onClick={() => handleDetails(val?.id)} className={classes.card} >
                <Grid item xs={12}>
                    <>
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{ padding: "16px 16px 9px 16px" }}

                        >
                            <Box>
                                {val?.logo &&
                                    <img
                                        src={val?.logo ?? "/images/imagesproperty.svg"}
                                        alt=""
                                        className={classes.image}
                                    />
                                }
                                {(val?.profile_url || val?.profile_url === "") &&
                                    <img
                                        src={val?.profile_url?.length > 0 ? val?.profile_url : "/images/embtyProfile.svg"}
                                        alt="profile_url"
                                        className={classes.imageProfile}
                                    />
                                }
                            </Box>
                            <Box flexGrow={1} marginLeft="12px">
                                <Typography className={classes.name}>
                                    {val?.name}
                                    {val?.unitId &&
                                        <Typography className={classes.unitIdCss} component={"span"} variant={"body2"} ><Typography className={classes.dotCss} component={"div"} style={{ background: "#CED3DD", width: 6, height: 6 }} ></Typography>{val?.unitId}</Typography>
                                    }
                                </Typography>
                                <Typography className={classes.name1}>
                                    {val?.property?.name}
                                </Typography>
                                {val?.description &&
                                    <Typography className={classes.name1}>
                                        {val?.description}
                                    </Typography>
                                }
                            </Box>
                            {clickAction &&
                                <Box>
                                    <IconButton className={classes.arrow} size="small">
                                        <ArrowForwardIosIcon
                                            style={{ fontSize: "18px" }}
                                        />
                                    </IconButton>
                                </Box>}
                        </Box>
                    </></Grid>
                <Grid item xs={12}>
                    <Box className={classes.horizontalDividerStyle} />
                </Grid>
                <Grid item xs={12}>
                    <Grid
                        container
                        className={classes.bottomCard}
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        {val?.parking_master?.[0]?.parking_no &&
                            <Grid
                                container
                                sm={12}
                                xs={12}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <img
                                    sx={{ padding: "10px" }}
                                    src="/images/parkingagrrement.svg"
                                    alt="altimage"
                                />
                                <Box width="8px" />
                                <Typography className={classes.name1}>
                                    {val?.parking_master?.[0]?.parking_no}
                                </Typography>
                            </Grid>
                        }
                        {/* email */}
                        {(val?.email || val?.contact) &&
                            <Grid
                                container
                                sm={12}
                                xs={12}
                            >
                                <Grid className={classes.emailCss} container sm={6} xs={6} description={"row"} justifyContent="center" alignItems="center">
                                    <a href={`mailto:${val?.email}`} style={{ display: "flex", textDecorationLine: 'none' }}>
                                        <img
                                            sx={{ padding: "10px" }}
                                            src="/images/mailIcon.svg"
                                            alt="altimage"
                                        />
                                        <Box width="8px" />
                                        <Typography className={classes.bottomIcon}>
                                            {"Email"}
                                        </Typography>
                                    </a>
                                </Grid>


                                {/* contact */}
                                <Grid container sm={6} xs={6} description={"row"} justifyContent="center" alignItems="center">
                                    <a href={`tel:${val?.contact}`} style={{ display: "flex", textDecorationLine: "none", color: "#071741" }}>
                                        <img
                                            sx={{ padding: "10px" }}
                                            src="/images/callIcon.svg"
                                            alt="altimage"
                                        />
                                        <Box width="8px" />
                                        <Typography className={classes.bottomIcon}>
                                            {"Contact"}
                                        </Typography>
                                    </a>
                                </Grid>

                            </Grid>}


                    </Grid>
                </Grid>

            </Grid >
        </>
    )
}