import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { CommunityCard, Filter, LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { ADD_LIKE, UPDATELIKE } from "../../graphql/mutations";
import { GET_PROPERTY_BY_UNITID_VIEW } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },

  title: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "50px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "50px",
      left: "63%",
    },
  },
}));

export const CommunityDiscussion = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  const [drawer, setDrawer] = React.useState(false);
  const [communitiydata, setCommunityData] = React.useState([]);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const { state } = useLocation();
  const [offSet, setOffSet] = React.useState(0);
  const [property, setproperty] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [selectedFilter, setSelectedFilter] = React.useState([]);
  const [proppertyFilter, setPropertyFilter] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);

  const getCommunities = (propertyid, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      property_id: propertyid,
      offset: offset,
      limit: 20,
    };
    NetworkCall(
      `${config.api_url}/community/fetch`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setCommunityData(response.data.data.threads);
        } else {
          setCommunityData([...communitiydata, ...response.data.data.threads]);
        }
        setDrawer(false);
        setCount(response.data.data.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  //get property
  const getpropertyByunit = (val) => {
    const data = {
      query: GET_PROPERTY_BY_UNITID_VIEW,
      variables: {
        unit_id: val,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let resposepropertyId = response?.data.data?.unit?.map(
          (val) => val?.property.id
        );
        let uniqArray = [...new Set(resposepropertyId)];
        setproperty(uniqArray);
        getCommunities(uniqArray, false, 0);
        //for filter
        const propertyFilter = response?.data.data?.unit?.map((val, index) => {
          let _d;
          try {
            _d = {
              value: val?.property?.id,
              label: val?.property?.name,
              key: "property",
              all: "true" + index,
            };
          } catch (err) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some thing Went wrong.",
            });
          }
          return _d;
        });
        const key = "value";
        const u_arr = [
          ...new Map(propertyFilter.map((item) => [item[key], item])).values(),
        ];

        setPropertyFilter(u_arr);
        setSelectedFilter(u_arr.map((val) => val.value));
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  //get units
  React.useState(() => {
    if (authToken !== null && authToken !== "") {
      const temp_decoded = jwt_decode(authToken);
      const userId = temp_decoded.id;
      const data = {
        tenantId: `${config.tenantid}`,
        userId: userId,
      };
      NetworkCall(
        `${config.api_url}/dashboard`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          getpropertyByunit(
            response?.data?.data?.units?.result.map((val) => val.unit_name)
          );
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    } else {
    }
  }, []);

  const filterData = [
    {
      id: "1",
      title: "Property",
      key: "property",
      showSearch: true,
      filterType: "CHECKBOX",
      values: proppertyFilter,
    },
  ];
  const applyFilter = (result) => {
    if (result?.property?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast select one property",
      });
      return false;
    }
    setOffSet(0);
    setSelectedFilter(result.property);
    getCommunities(result.property, true, 0);
  };
  const addLike = (val) => {
    let check = val?.liked_by?.some(
      (val) => val === localStorage.getItem("userProfileId")
    );
    const adddata = {
      query: ADD_LIKE,
      variables: {
        like: [
          {
            community_discussion: val.id,
            created_by: localStorage.getItem("userProfileId"),
            updated_by: localStorage.getItem("userProfileId"),
            is_active: true,
          },
        ],
      },
    };
    const deletedata = {
      query: UPDATELIKE,

      variables: {
        id: val.like_id,
        updatedData: {
          is_active: false,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      check ? deletedata : adddata,
      null,
      true,
      false
    )
      .then((response) => {
        const data = {
          tenantId: `${config.tenantid}`,
          property_id: property,
          offSet: offSet,
          limit: 7,
        };
        NetworkCall(
          `${config.api_url}/community/fetch`,
          NetWorkCallMethods.post,
          data,
          null,
          true,
          false
        )
          .then((response) => {
            if (response.data.data.threads.length > 0) {
              setCommunityData(response.data.data.threads);
              setCount(response.data.data.totalCount);
            }
          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some thing Went wrong.",
            });
          });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  const fetchMoreData = () => {
    setOffSet(offSet + 20);
    getCommunities(selectedFilter, false, offSet + 20);
  };
  const goBack = () => {
    history.push(state ? Routes.dashboard : Routes.service);
  };
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        <TitleBar text="Community Discussion" goBack={goBack} />
        <Box display="flex" alignItems="center" p={1}>
          <Box flexGrow={1}>
            <Typography className={classes.title}>
              Discussion({count})
            </Typography>
          </Box>
          <Box marginLeft="10px">
            <IconButton
              className={classes.filter}
              onClick={() => setDrawer(true)}
            >
              <Badge variant="dot" color="primary">
                <img src="/images/icons8-funnel.svg" alt="filter" />
              </Badge>
            </IconButton>
          </Box>
        </Box>
        <div
          style={{
            padding: "12px 6px 8px 12px",
            overflow: "hidden",
          }}
        >
          {loading ? (
            <LoderSimmer count={10} />
          ) : (
            <>
              {communitiydata.length > 0 ? (
                <InfiniteScroll
                  dataLength={communitiydata.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 130}
                >
                  {communitiydata.map((val) => {
                    return (
                      <Box item key={val.id} marginTop="6px">
                        <CommunityCard data={val} handleClick={addLike} />
                      </Box>
                    );
                  })}
                </InfiniteScroll>
              ) : (
                <Typography className={classes.title} textAlign="center">
                  No Record is Found
                </Typography>
              )}
            </>
          )}

          <Fab
            color="primary"
            className={classes.fab}
            onClick={() => history.push(Routes.createDiscussion)}
          >
            <AddIcon />
          </Fab>
        </div>
      </Container>
      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={() => setDrawer(false)}
          fullWidth
          className={classes.dialog}
          maxWidth="sm"
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              property: selectedFilter,
            }}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
            onApply={applyFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={drawer}
          onClose={() => setDrawer(false)}
          className={classes.dialog}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              property: selectedFilter,
            }}
            onApply={applyFilter}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
          />
        </Drawer>
      </Hidden>
    </>
  );
};
