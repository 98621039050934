import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    textTransform: "capitalize",
    cursor: "pointer",
  },
  roots: {
    padding: "8px",
    margin: "0px 8px",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  name: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  call: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  mail: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  tagStyle: {
    backgroundColor: "#F1E6FE",
    borderRadius: "4px",
    padding: "4px 6px 4px 6px",
    fontSize: "8px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#5D427F",
    textTransform: "uppercase",
    marginLeft: "8px",
  },

  avatar: {
    backgroundColor: "#F0F4FF",
    width: "48px",
    height: "48px",
  },
}));

export const NeigbourList = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.roots}>
          <Grid container>
            <Grid item xs={11}>
              <Box display="flex">
                <Box>
                  <Avatar className={classes.avatar}>
                    <img
                      src="/images/neigboursperson.svg"
                      alt={"phone"}
                      className={classes.img}
                    />
                  </Avatar>
                </Box>
                <Box flexGrow={1} marginTop="6px" marginLeft="8px">
                  <Box display="flex" alignItems="center" marginBottom="2px">
                    <Typography className={classes.name} noWrap>
                      {props?.data?.first_name} &nbsp;{props?.data?.last_name}
                    </Typography>
                    <Typography className={classes.tagStyle}>
                      {props?.data?.is_owner ? "Owner" : "Resident"}
                    </Typography>
                  </Box>

                  <Box display="flex" style={{ flexFlow: 'wrap' }}>
                    <Box>
                      <Typography className={classes.call} noWrap>
                        {props?.data?.block_name}
                      </Typography>
                    </Box>

                    <Box className={classes.periodStyle} />
                    <Box>
                      <Typography className={classes.mail} noWrap>
                        {props?.data?.floor_name}
                      </Typography>
                    </Box>
                    <Box className={classes.periodStyle} />
                    <Box>
                      <Typography className={classes.mail} noWrap>
                        {props?.data?.unit_name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider variant="middle" sx={{ backgroundColor: "#CED3DD" }} />
        </Grid>
      </Grid>
    </div>
  );
};
