import React from "react";
import { SelectBox } from "../../components/dropDown";
import { Container, Box, Button, Grid, Typography } from "@mui/material";
import { AlertDialog, DocumentViewer, TopNavBars } from "../../components";
import { useStyle } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ASSEST_MASTER_TABLE_1, UNIT_ASSETS } from "../../graphql/queries";
import { useApolloClient } from "@apollo/client";
import { AlertContext, BackdropContext } from "../../contexts";
import { useLocation } from "react-router-dom";
import { AlertProps, assestType, singleImageUpload } from "../../utils";
import { INSERT_UNIT_ASSETS } from "../../graphql/mutations";

export const YourUnitsAssetsUpload = (props) => {
  const size = useWindowDimensions();
  const classes = useStyle({ ...props, size });
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const { state } = useLocation();
  const [loading1, setLoading1] = React.useState(null);
  const [list, setList] = React.useState([]);
  const [viewFile, setViewFile] = React.useState()
  const inputFile = React.useRef(null)
  const [type, setType] = React.useState({
    value: 2,
    label: "General"
  });
  const client = useApolloClient();

  const loadOption = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "type":
        result = await client.query({
          query: ASSEST_MASTER_TABLE_1(offset, 10, search),
          variables: {
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.assets_master],
          hasMore:
            array?.length + result?.data?.assets_master?.length <
            result?.data?.count[0]?.count,
        };
      default:
        return { options: [] };
    }
  };
  const onUploadButton = () => {
    inputFile.current.click();
  };
  const getAssests = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    client
      .query({
        query: UNIT_ASSETS,
        fetchPolicy: "network-only",
        variables: {
          id: state?.id,
          type: type?.value,
        },
      })
      .then((res) => {
        setList(res?.data?.unit_assets);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
        alert.setSnack({
          ...alert, open: true, msg: "Some Thing Went Wrong",
          severity: AlertProps.severity.error,
        })
      });
  };
  React.useEffect(() => {
    if (type?.label?.length > 0) getAssests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.id, type]);

  const viewComponents = (type) => {
    if (type === assestType.Documents) {
      return <DocumentViewer url={viewFile?.url} />
    } else {
      return <img
        style={{
          objectFit: "contain",
          height: "600px",
          width: "100%",
        }}
        alt={"assets"}
        src={viewFile?.url} />
    }
  }

  const openFileDialog = (data) => {
    setViewFile({
      ...viewFile,
      viewerDialog: true,
      url: data?.url,
    })
  }

  const closeFileDialog = () => {
    setViewFile({
      ...viewFile,
      viewerDialog: false,
    })
  }

  const uploadAsset = async (image) => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Uploading Asset..." });
    try {
      const tempURL = await singleImageUpload(image)
      const payload = [{
        unit_id: state?.id,
        url: tempURL,
        priority: 1,
        is_active: true,
        created_at: new Date(),
        updated_at: new Date(),
        asset_type: type?.value
      }]
      client.mutate({
        mutation: INSERT_UNIT_ASSETS,
        variables: { payload }
      }).then((res) => {
        getAssests()
        alert.setSnack({
          ...alert, open: true,
          severity: AlertProps.severity.success,
          msg: `${type.label} Asset Uploaded Successfully.`,
        });
      }).catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      })
    }
    catch (err) {
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
      alert.setSnack({
        ...alert, open: true, msg: "Some Thing Went Wrong",
        severity: AlertProps.severity.error,
      })
    }
  }

  return (
    <Container className={classes.root} maxWidth="sm">
      <TopNavBars title={"Assets"} isBack />
      <Box className={classes.content}>
        <SelectBox
          label=""
          placeholder="Select Category"
          value={type}
          onChange={(value) => setType(value)}
          loadOptions={(search, array) => loadOption(search, array, "type")}
          loading={loading1 === "type"}
          debounceTimeout={800}
          isPaginate
        />
        <Box mt={2}>
          {list?.length > 0 ?
            <Grid container spacing={2}>
              {list?.map((x) => {
                return (
                  <Grid item xs={4} onClick={() => openFileDialog(x)}>
                    {
                      x?.asset_type === assestType.Documents ?
                        <div style={{ height: "106px", overflow: "auto" }}>
                          <DocumentViewer url={x?.url} />
                        </div>
                        :
                        <img
                          className={classes.asset_card}
                          src={x?.url}
                          alt="assets"
                          width={"100%"}
                          height={"106px"} />
                    }
                  </Grid>
                );
              })}
            </Grid> :
            <Grid container spacing={2} justifyContent={"center"}>
              <Typography style={{ marginTop: "40vh" }}>{`No ${type.label} found`}</Typography>
            </Grid>}
        </Box>
      </Box>
      <Box p={2}>
        <Button className={classes.btn} onClick={onUploadButton}>Upload Assets</Button>
        <input type='file' style={{ display: 'none' }}
          accept={type?.value === assestType.Documents ? "application/pdf" : "image/*"}
          ref={inputFile} onClick={(e) => e.target.value = null}
          onChange={(e) => uploadAsset(e?.target?.files?.[0])} />
      </Box>
      <AlertDialog
        isNormal
        isTitle
        header={`${type.label} Viewer`}
        onClose={closeFileDialog}
        open={viewFile?.viewerDialog}
        component={viewComponents(type?.value)}
      />
    </Container>
  );
};
