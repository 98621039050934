import { Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginBottom: theme.spacing(2),
  },
  root: {
    width: "100%",
    height: "100%",
    "& input": {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0,
      left: 0,
      cursor: "pointer",
      zIndex: 10000,
    },
    "& .MuiButton-root": {
      top: "80px",
    },
    "& .MuiTypography-colorPrimary": {
      marginTop: theme.spacing(11),
    },
  },
  drap: {
    position: "relative",
    height: "163px",
    borderRadius: 6,
    textAlign: "center",
    background: "#F5F7FA",
    border: "2px dashed #E4E8EE",
  },
  drap2: {
    position: "relative",
    height: "163px",
    borderRadius: 6,
    textAlign: "center",
    background: "#F5F7FA",
    border: "2px dashed #E4E8EE",
  },
  text: {
    color: "#404E61",
    fontSize: "12px",
    padding: 10,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  text2: {
    color: "#404E61",
    fontSize: "12px",
    padding: 10,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  upload: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "30px",
  },
}));

export const Upload = (props) => {
  const classes = useStyles(props);
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Grid item xs={12}>
        {props.state.length > 0 ? (
          <div className={classes.drap}>
            <div className={classes.root}>
              <div className={classes.upload}>
                {props?.single && (
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) => props.onChange(e)}
                    disabled={props.disabled}
                  />
                )}

                {!props?.single && (
                  <input
                    accept="image/*"
                    type="file"
                    multiple
                    onChange={(e) => props.onChange(e)}
                    disabled={props.disabled}
                  />
                )}
                <IconButton
                  style={{
                    marginTop: "-1px",
                    textTransform: "capitalize",
                    color: "grey",
                  }}
                >
                  <img src="/images/uploadImg.svg" alt="" />
                </IconButton>
                <Typography className={classes.text}>
                  Upload Images Here
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.drap2}>
            <div className={classes.root}>
              <div className={classes.upload}>
                {props?.single && (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => props.onChange(e)}
                    disabled={props.disabled}
                  />
                )}

                {!props?.single && (
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => props.onChange(e)}
                    disabled={props.disabled}
                  />
                )}
                <IconButton>
                  <img src="/images/uploadImg.svg" alt="upload_image" />
                </IconButton>
                <Typography className={classes.text2}>
                  Upload Images Here
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};
