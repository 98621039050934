import React, { useState, useContext } from "react";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useStyles } from "./style";
import { TopNavBars, ImageUpload } from "../../components";
import { Step1, Step2, Step4 } from "./steps";
import { initialState } from "./common";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { useHistory } from "react-router-dom";
import { withNamespaces } from "react-i18next";

const MaintanceRequest = ({ t }) => {
  // ---------------------------class-------------------------------------------
  const classes = useStyles();
  // -------------------------------state---------------------------------------
  const [step, setStep] = useState(1);
  const [state, setState] = useState({ ...initialState });
  const [disable, setDisable] = useState(false);
  const [images, setImages] = useState([]);
  // --------------------------------use Diemension-------------------------------
  const size = useWindowDimensions();
  const alert = useContext(AlertContext);

  const history = useHistory();
  // ---------------------------------functions----------------------------------
  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    if (key === "Category") {
      setState({ ...state, subCategory: "", [key]: value, error });
    } else if (key === "Agreement") {
      setState({ ...state, Unit: "", [key]: value, error });
    } else {
      setState({ ...state, [key]: value, error });
    }
  };
  // next function
  const next = () => {
    if (step === 1) {
      if (validateService()) {
        setStep(2);
      }
    }
    if (step === 2) {
      if (validateContact()) {
        setStep(3);
      }
    }
    if (step === 3) {
      setStep(4);
    }
  };
  const previous = () => {
    setStep(step - 1);
  };
  const submit = () => {
    setDisable(true);
    const userProfileId = localStorage.getItem("userProfileId");
    const payload = {
      type: 2,
      subject: state?.Title,
      description: state?.Description,
      category: state?.Category?.value,
      sub_category: state?.subCategory?.value,
      problem_since: state?.problemSince,
      preferred_time: state?.visitorDate,
      contact_name: state?.name,
      contact_mobile: state?.primaryMobileNumber?.mobile,
      mobile_country_code: state?.primaryMobileNumber?.mobile_no,
      contact_alternative_mobile:
        state?.alternative?.mobile?.length > 0
          ? state?.alternative?.mobile
          : undefined,
      alternative_mobile_country_code:
        state?.alternative?.mobile_no?.length > 0
          ? state?.alternative?.mobile_no
          : undefined,
      image_urls: images,
      units: [state?.Unit?.value],
      userProfileId,
      propertyId: state?.Unit?.property_id,
      companyId: state?.Unit?.company_id,
      is_active:true
    };
    NetworkCall(
      `${config.api_url}/request/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Request submitted successfully.",
        });
        setDisable(false);
        goBack();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
        setDisable(false);
      });
  };
  const goBack = () => {
    history.push(Routes.requestScreen);
  };
  // validation
  const validateService = () => {
    let isValid = true;
    let error = state.error;
    if (state?.Category?.length === 0) {
      isValid = false;
      error.Category = "Category is Required";
    }
    if (state?.Unit?.length === 0) {
      isValid = false;
      error.Unit = "Unit is Required";
    }
    if (state?.visitorDate?.length === 0) {
      isValid = false;
      error.visitorDate = "Prefered date is Required";
    }
    if (state?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = "subCategory is Required";
    }
    if (state?.Title?.length === 0) {
      isValid = false;
      error.Title = "Problem Title is Required";
    }
    if (state?.problemSince?.length === 0) {
      isValid = false;
      error.problemSince = "Problem Since is Required";
    }

    // if (state?.Agreement?.length === 0) {
    //   isValid = false;
    //   error.Agreement = "Agreement is Required";
    // }
    if (
      state?.Category?.length === 0 ||
      state?.subCategory?.length === 0 ||
      state?.Unit?.length === 0 ||
      state?.Title?.length === 0 ||
      state?.visitorDate?.length === 0 ||
      state?.Property?.length === 0 ||
      state?.problemSince?.length === 0
      // state?.Agreement?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }

    setState({ ...state, error });

    return isValid;
  };
  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    if (state?.contactName?.length === 0) {
      isValid = false;
      error.contactName = "Contact Name is Required";
    }
    if (state?.PrimaryContact?.mobile?.length === 0) {
      isValid = false;
      error.PrimaryContact = "Mobile is Required";
    }
    if (state?.alternativeContact?.mobile?.length === 0) {
      isValid = false;
      error.alternativeContact = "AlterNative Mobile is Required";
    }

    if (
      state?.contactName?.length === 0 ||
      state?.PrimaryContact?.length === 0 ||
      state?.alternativeContact?.length === 0
    ) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }

    setState({ ...state, error });

    return isValid;
  };
  //   -----------------------------steps---------------------------------------
  const components = {
    1: <Step1 updateState={updateState} state={state} t={t}/>,
    2: <Step2 setState={setState} state={state} updateState={updateState} t={t}/>,
    3: (
      <ImageUpload
        setState={setImages}
        state={images}
        companyId={state?.Unit?.company_id}
        t={t}
      />
    ),
    4: <Step4 request={state} uploadedImages={images} t={t}/>,
  };
  // title
  const titleType = {
    1: t("Service"),
    2: t("Contact"),
    3: t("Images"),
    4: t("Summary"),
  };
  // propgressvalue
  const progressValue = {
    1: 25,
    2: 50,
    3: 75,
    4: 100,
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      <Grid item xs={12} sx={{ position: "sticky", top: 0 }}>
        <TopNavBars title={t("MaintenanceTitle")} isBack />
      </Grid>
      {/* stepper */}
      <div style={{ zIndex: 999, width: "100%" }}>
        <Grid
          container
          className={classes.stepperBody}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={2}>
            <div className={classes.circle}>
              <div className={classes.stepper}>{step}</div>
            </div>
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.steps}>
              {t("Step")} {step} / 4
            </Typography>
            <Typography className={classes.details}>
              {titleType[step]}
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <LinearProgress variant="determinate" value={progressValue[step]} color="warning"/>
        </Box>
      </div>

      <div
        style={{
          height: size?.height - 200,
          overflow: "auto",
          padding: "16px",
        }}
      >
        {components[step]}
      </div>

      {/* button  */}
      <Container maxWidth="sm" className={classes.btnRoot}>
        <Grid
          container
          spacing={2}
          className={classes.saveNUpdateButtonContainerStyle}
        >
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              disabled={step === 1 ? true : false}
              className={classes.btnPrevious}
              onClick={previous}
            >
              {t("Previous")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={step === 4 ? submit : next}
              disable={disable}
            >
              {step === 4 ? t("Submit") : t("Next")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
export default withNamespaces("request")(MaintanceRequest);
