import SendIcon from "@mui/icons-material/Send";
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { ADD_COMMENT, ADD_LIKE, UPDATELIKE } from "../../graphql/mutations";
import { GET_THREAD } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, Regular } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },
  commentSection: {
    padding: "12px 14px",
  },
  Cardcontent: {
    backgroundColor: "white",
    padding: "12px 14px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  title: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    marginTop: "8px",
  },
  sendRoot: {
    backgroundColor: "white",
    borderTop: "2px solid #E4E8EE",
  },
  send: {
    backgroundColor: "#5078E1",
    borderRadius: "4px",
    color: "white",
  },
  name: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  unit: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: Regular,
  },
  time: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: Regular,
  },
  dis: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    marginTop: "4px",
  },
  titlecontainer: {
    paddingBottom: "12px",
  },
  like: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  property: {
    fontSize: "10px",
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    padding: "2px",
    borderRadius: "4px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    display: "inline-block"
  },
  new: {
    fontSize: "10px",
    color: "#FFFFFF",
    backgroundColor: "#FF4B4B",
    padding: "2px",
    borderRadius: "4px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  comment: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    marginTop: "4px",
  },
  replay: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: Regular,
    marginLeft: "4px",
    marginTop: "4px",
  },
  replaySection: {
    padding: "8px 12px",
  },
  communityBox: {
    flexFlow: 'wrap !important'
  }
}));
//referenceId
export const DiscussionDetails = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [allow, setAllow] = React.useState(true);
  const [comment, setComment] = React.useState("");
  const size = useWindowDimensions();
  const search = useLocation().search;
  const [comments, setComments] = React.useState([]);
  const discussionId = new URLSearchParams(search).get("discussionId");
  const [details, setDetails] = React.useState([]);
  const [offSet, setOffSet] = React.useState(0);
  const alert = React.useContext(AlertContext);
  const goBack = () => {
    history.goBack();
  };

  const getDiscussionDetails = () => {
    const data = {
      query: GET_THREAD,
      variables: {
        threadId: discussionId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetails(response.data.data.community_discussion);
        setAllow(true);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  const getComments = (offset, filter) => {
    const data = {
      tenantId: `${config.tenantid}`,
      discussion_id: discussionId,
      offset: offset,
      limit: 20,
    };
    NetworkCall(
      `${config.api_url}/community/comments`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setComments(response.data.data);
        } else {
          setComments(comments.concat(response.data.data));
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  React.useEffect(() => {
    getDiscussionDetails();
    getComments(0);
    // eslint-disable-next-line
  }, []);

  const Liked = () => {
    const adddata = {
      query: ADD_LIKE,
      variables: {
        like: [
          {
            community_discussion: discussionId,
            created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
            updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
            is_active: true,
          },
        ],
      },
    };

    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      adddata,
      null,
      true,
      false
    )
      .then((response) => {
        getDiscussionDetails();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  const DisLiked = (val1) => {
    const deletedata = {
      query: UPDATELIKE,
      variables: {
        id: val1,
        updatedData: {
          is_active: false,
        },
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      deletedata,
      null,
      true,
      false
    )
      .then((response) => {
        getDiscussionDetails();
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  const addLike = async (val1, val2, val3) => {
    setAllow(false);
    if (allow) {
      if (val3) {
        await DisLiked(val1, val2, val3);
      } else {
        await Liked(val1, val2, val3);
      }
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (comment.length > 0) {
      const data = {
        query: ADD_COMMENT,
        variables: {
          demo: [
            {
              discussion: discussionId,
              created_by: localStorage.getItem(LocalStorageKeys.userProfileId),
              updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
              comment: comment,
              is_active: true,
              client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
            },
          ],
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setComment("");
          getComments(0, true);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    }
  };
  const fetchMoreData = () => {
    setOffSet(offSet + 20);
    getComments(offSet + 20);
  };
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        <TitleBar text="Community Discussion" goBack={goBack} />

        <div style={{ height: size.height - 120, overflow: "auto" }}>
          <div className={classes.Cardcontent}>
            <Box alignItems="center" display="flex">
              <Box>
                <Avatar src={details?.[0]?.created_by?.get_assets_url_id} />
              </Box>
              <Box flexGrow={1} marginLeft="10px">
                <Box className={classes.communityBox} alignItems="center" display="flex">
                  <Box>
                    <Typography variant="subtitle2" className={classes.name}>
                      {details[0]?.created_by?.first_name}&nbsp;
                      {details[0]?.created_by?.last_name}
                    </Typography>
                  </Box>
                  <Box className={classes.dot} />
                  <Box flexGrow={1} noWrap>
                    <Typography
                      variant="subtitle2"
                      className={classes.unit}
                      noWrap
                    >
                      {details[0]?.unit?.name}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.time}
                    noWrap
                  >
                    Posted at&nbsp;
                    {moment(new Date(details[0]?.created_at)).fromNow()}
                  </Typography>
                </Box>

                {details[0]?.property?.name && (
                  <Typography className={classes.property} noWrap>
                    &nbsp;#{details[0]?.property?.name}&nbsp;
                  </Typography>
                )}
              </Box>
            </Box>
            <div className={classes.titlecontainer}>
              <Typography variant="subtitle2" className={classes.title}>
                {details[0]?.title}
              </Typography>
              <Typography variant="subtitle2" className={classes.dis}>
                {details[0]?.description}
              </Typography>
            </div>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  marginTop="10px"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    addLike(
                      details[0]?.community_discussion_reactions?.[0]?.id,
                      details[0]?.community_discussion_reactions?.[0]
                        ?.created_by,
                      details[0]?.community_discussion_reactions?.[0]
                    )
                  }
                >
                  <Box>
                    {details[0]?.community_discussion_reactions.map(
                      (val) => val.created_by
                    )[0] === localStorage.getItem("userProfileId") ? (
                      <img src="images/icons8-facebook-like (1).svg" alt="" />
                    ) : (
                      <img src="/images/icons8-facebook-like.svg" alt="" />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" className={classes.like}>
                      {details[0]?.community_discussion_reactions.map(
                        (val) => val.created_by
                      )[0] === localStorage.getItem("userProfileId") ? (
                        <span style={{ color: "#5078E1" }}>
                          &nbsp;
                          {
                            details[0]?.community_discussion_reactions.length
                          }{" "}
                          Likes
                        </span>
                      ) : (
                        <>
                          &nbsp;
                          {
                            details[0]?.community_discussion_reactions.length
                          }{" "}
                          Likes
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>

          <div className={classes.commentSection}>
            <Typography variant="subtitle2" className={classes.comment}>
              {comments.length} Comments
            </Typography>

            <div>
              {comments?.length > 0 && (
                <>
                  <InfiniteScroll
                    dataLength={comments.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size.height - 330}
                  >
                    {comments.map((val) => {
                      return (
                        <div className={classes.replaySection}>
                          <Box alignItems="center" display="flex">
                            <Box>
                              <Avatar
                                src={
                                  val?.image_url ?? null
                                }
                              />
                            </Box>
                            <Box flexGrow={1} marginLeft="10px">
                              <Box alignItems="center" display="flex">
                                <Box>
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.name}
                                  >
                                    {val?.first_name}&nbsp;
                                    {val?.last_name}
                                  </Typography>
                                </Box>
                                {/* <Box className={classes.dot} />
                               <Box flexGrow={1}>
                                <Typography
                                  variant="subtitle2"
                                  className={classes.unit}
                                >
                                  {val?.comment}
                                </Typography>
                              </Box> */}
                              </Box>

                              <Typography
                                variant="subtitle2"
                                className={classes.time}
                              >
                                Posted at&nbsp;
                                {moment(new Date(val.created_at)).fromNow()}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            variant="subtitle2"
                            className={classes.replay}
                          >
                            {val?.comment}
                          </Typography>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                </>
              )}
            </div>
          </div>
        </div>
        <div>
          <Box
            alignItems="center"
            display="flex"
            p={1}
            className={classes.sendRoot}
          >
            <Box flexGrow={1} marginLeft="10px" marginTop="4px">
              <form onSubmit={handleSubmit}>
                <TextField
                  size="small"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                  placeholder="Add comment"
                  fullWidth
                />
              </form>
            </Box>
            <Box marginLeft="4px">
              <IconButton
                disabled={comment.length > 0 ? false : true}
                onClick={handleSubmit}
                className={classes.send}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
        </div>
      </Container>
    </>
  );
};
