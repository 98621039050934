/* eslint-disable array-callback-return */
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { LoderSimmer, RadioButton, TitleBar } from "../../components";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.main,
        padding: "0px",
        overflow: "hidden",
    },
    menuText: {
        fontSize: "16px",
        color: theme.typography.color.secondary,
        marginTop: '30%'
    },
    search: {
        [`& fieldset`]: {
            borderRadius: "10px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "6px 0px 10px 10px",
            fontSize: "14px",
        },
    },
    filter: {
        borderRadius: "6px",
        border: "1px solid #E4E8EE",
        color: "black",
        backgroundColor: "white",
    },
    total: {
        fontSize: "16px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    },
    typo: {
        fontSize: "14px",
        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",

    },
    boxRoot: {
        display: "flex", alignItems: "center",
        padding: "8px",
        backgroundColor: "#F5F7FA"
    }
}));

const PaymentScreen = () => {
    const size = useWindowDimensions();
    const classes = useStyles()
    const history = useHistory()
    const { state } = useLocation()
    const [gateWays, setGateWays] = React.useState({
        value: "",
        options: []
    })
    const alert = React.useContext(AlertContext)
    const [ccavRequestForm, setccavRequestForm] = React.useState(null);
    const [loadingGateway, setLoadingGateway] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const formEl = React.useRef();


    const handleChange = (data) => {
        setGateWays({
            ...gateWays,
            value: data
        })
    }
    const getGateways = () => {
        setLoading(true)
        const data = {};
        NetworkCall(
            `${config.api_url}/payment/access/gateways`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                let option = response?.data?.data.map((item) => {
                    return {
                        value: item.name,
                        label: item.name,
                        asset: item.asset
                    }
                })
                setGateWays({
                    options: option ?? [],
                    value: option?.[0] ?? null
                })
                setLoading(false)

            })
            .catch((err) => {
                setLoading(false)
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something Went wrong",
                });
            });
    };

    React.useEffect(() => {
        getGateways()
        //eslint-disable-next-line
    }, [])


    const getPaymentHiddenForm = (encRequest, accessCode, ccaUrl) => {
        return (
            <form
                id="nonseamless"
                method="post"
                name="redirect"
                action={ccaUrl}
                ref={formEl}
            >
                <input
                    type="hidden"
                    id="encRequest"
                    name="encRequest"
                    value={encRequest}
                />
                <input
                    type="hidden"
                    name="access_code"
                    id="access_code"
                    value={accessCode}
                />
            </form>
        );
    };
    const onTriggerHiddenForm = () => {
        return formEl.current && formEl.current.submit();
    };

    const payNow = (method) => {
        if (method?.label === "ccavenue") {
            const payload = {
                /** Redirect URL is mandatory */
                redirect_path: "paymentstatus/success",
                cancel_path: "paymentstatus/failure",
            };
            setLoadingGateway(true);
            NetworkCall(
                `${config.api_url}/payment`,
                NetWorkCallMethods.post,
                payload,
                { "x-build-code": config.app_x_build },
                true,
                false
            ).then((response) => {
                if (response?.data?.data) {
                    const { accessCode, encRequest, paymentGatewayUrl } =
                        response?.data?.data;
                    const hiddenForm = getPaymentHiddenForm(
                        encRequest,
                        accessCode,
                        paymentGatewayUrl
                    );
                    setccavRequestForm(hiddenForm);
                    setTimeout(() => {
                        onTriggerHiddenForm();
                        setLoadingGateway(false);
                    }, 200);
                } else {
                    setLoadingGateway(false);
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Something Went wrong",
                    });
                }
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Something Went wrong",
                });
            });
        }
        if (method.label === "stripe") {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.warning,
                msg: "Currently stripe service is not available...",
            });
        }
    };

    return (
        <Container className={classes.root} maxWidth="sm">
            <TitleBar
                text={"Make Payment"}
                goBack={() => history.goBack()}
            />
            <Grid container height={size?.height - 70} position={"relative"}>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center" p={1}>
                        <Box flexGrow={1}>
                            <Typography className={classes.total}>
                                {"Payment Options"}
                            </Typography>
                        </Box>
                    </Box>
                    <div style={{ marginRight: "-6px", paddingBottom: '15%', padding: "8px" }}>
                        {loading ? (
                            <LoderSimmer count={10} />
                        ) : (
                            <>
                                {gateWays?.value &&
                                    <RadioButton
                                        option={gateWays?.options}
                                        handleChange={handleChange}
                                        value={gateWays?.value}
                                    />
                                }
                            </>

                        )}
                    </div>
                </Grid>
                <Grid item xs={12} position={"absolute"} bottom={0} width={"inherit"}>
                    <Box className={classes.boxRoot}>
                        <Box>
                            <Typography className={classes.typo}>{state?.amount}</Typography>
                        </Box>
                        <Box flexGrow={1} />
                        <Box>
                            <Button
                                variant="contained"
                                disabled={loadingGateway}
                                onClick={() => payNow(gateWays?.value)}
                            >
                                {loadingGateway ? "Loading..." : "PayNow"}
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                {ccavRequestForm}
            </Grid>

        </Container>
    );
};
export default (PaymentScreen);