import { Box, Button, Grid, Typography } from "@mui/material";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  CarouselSliders,
  HomepagePayCard,
  StatCard,
  TopNavBars
} from "../../components";
import Cards from "../../components/card";
import { QuickLinkCard } from "../../components/quickLinkCard";
import { DashboardSimmer } from "../../components/simmer/dashboardSimmer";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { BackendRoutes, Routes } from "../../router/routes";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
  TempBanner
} from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Announcement } from "./announcement";
import { HomepageGraph } from "./graphDashboard";
import {
  Image,
  ProfileImageInnerDiv,
  ProfileImageOuterDiv,
  UserID,
  useStyles
} from "./style";
const DashboardMain = (props) => {
  // Constants
  const {t,i18n}=props
  const size = useWindowDimensions();
  const authContext=React.useContext(AuthContext)
  const classes = useStyles({ language: authContext?.auth?.auth?.language });
  const hour = new Date().getHours();
  const auth = localStorage.getItem(LocalStorageKeys.authToken);

  // useContext
  const alert = React.useContext(AlertContext);

  // Hooks
  const history = useHistory();

  // useStates
  const [decodedAuth, setDecodedAuth] = React.useState(null);
  const [data, setData] = React.useState({});
  const [load, setLoad] = React.useState(true);
  const [banners, setBanners] = React.useState([]);
  const [prevData, setPrevData] = React.useState({});

  React.useEffect(() => {
    if (auth) {
      setDecodedAuth(jwt_decode(auth));
      getData();
      getBanners();
      getPrevData();
    }
    // eslint-disable-next-line
  }, [auth]);

  const getData = () => {
    NetworkCall(
      `${config.api_url}${BackendRoutes.ownerDashboard}`,
      NetWorkCallMethods.get,
      null,
      null,
      true,
      false
    )
      .then((response) => {
        let tempData = response?.data;
        setData({
          ...data,
          stats: {
            totalUnits: tempData?.total_units,
            vacantUnits: tempData?.total_vacant_units,
            occupiedUnits: tempData?.total_occupied_units,
            generalRequests: tempData?.total_general_request,
            maintenanceRequests: tempData?.total_maintenance_request,
            unpaidInvoices: tempData?.total_un_paid_invoice,
            totalDueAmount: tempData?.total_due_amount,
            totalOutstandingDueAmount: tempData?.total_outstanding_due_amount,
          },
        });
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal Error. Something Went Wrong.",
        });
      });
  };

  const getBanners = () => {
    // let data = {
    //   tenantId: `${config.tenantid}`,
    //   company_id: logo?.map((val) => val?.company_id),
    //   offset: 0,
    //   type: "Dashboard",
    //   limit: 1000,
    // }
    // NetworkCall(
    //   `${config.api_url}${BackendRoutes.companBannerList}`,
    //   NetWorkCallMethods.post,
    //   data, null, true, false
    // ).then((response) => {
    //   let bannerss = response?.data?.data?.map((item) => item?.assets)?.flat()
    //   setBanners(bannerss)
    // }).catch(() => {
    //   alert.setSnack({
    //     ...alert, open: true, severity: AlertProps.severity.error,
    //     msg: "Internal Error. Something Went Wrong."
    //   })
    // })

    let bannerss = TempBanner?.map((item) => item?.assets)?.flat();
    setBanners(bannerss);
  };

  const getPrevData = () => {
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      {},
      null,
      true,
      false
    )
      .then((response) => {
        let tempData = response?.data.data;
        localStorage.setItem(
          "userProfileId",
          response?.data?.data?.userProfileId
        );
        localStorage.setItem("contactID", response?.data?.data?.contactId);
        setPrevData({
          agreements: tempData?.agreements,
          invoices: tempData?.invoices,
          units: tempData?.units,
          general: tempData?.general,
          maintenance: tempData?.maintenance,
          profileImg: tempData?.profileImg,
          security_request: tempData?.security_request,
          unread: tempData?.is_unread,
          notification: tempData?.notifications,
          responseData: response?.data.data,
        });
        setLoad(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Internal Error. Something Went Wrong.",
        });
      });
  };

  const Stats = (_) => [
    {
      title: [t("total"), t("units")],
      count: _?.totalUnits ?? "00",
      icon: "images/totalUnits.svg",
      route: Routes.yourunit,
      color: "#50CBC7",
    },
    {
      title: [t("vacant"),t("units")],
      count: _?.vacantUnits ?? "0",
      icon: "images/vacantUnits.svg",
      color: "#059669",
    },
    {
      title: [t("occupied"), t("units")],
      count: _?.occupiedUnits ?? "0",
      icon: "images/occupiedUnits.svg",
      color: "#EA580C",
    },
    {
      title: [t("general"), t("requests")],
      count: _?.generalRequests ?? "0",
      icon: "images/generalRequests.svg",
      color: "#0891B2",
    },
    {
      title: [t("maintenance"), t("units")],
      count: _?.maintenanceRequests ?? "0",
      icon: "images/maintenanceRequests.svg",
      color: "#f17360",
    },
    {
      title: [t("unpaid"), t("invoices")],
      count: _?.unpaidInvoices ?? "0",
      icon: "images/unpaidInvoice.svg",
      color: "#36AAE2",
    },
    {
      title: [t("landlord"), t("contract")],
      count: _?.activeContracts ?? "0",
      icon: "images/landlordcontracts.svg",
      color: "#C026D3",
    },
    {
      title: [t("landlord"), t("invoices")],
      count: _?.activeContracts ?? "0",
      icon: "images/landlordinvoices.svg",
      color: "#C026D3",
    },
  ];
  const QuickLinks = () => [
    {
      title:[t("my"),t("contacts")],
      icon: "images/mycontacts.svg",
      color:"#2881E7"
    },
    {
      title:[t("my"),t("residents")],
      icon: "images/myneighbours.svg",
      color:"#707B85"
    },
    {
      title:[t("my"),t("bills")],
      icon: "images/myutilityBills.svg",
      color:"#16528C"
    },
  ];
  const List = () => [
    {
      title: t("marketPlace"),
      icon: "images/marketIcon.svg",
      bgImage: "images/marketBgImage.svg"
    },
    {
      title: t("listing"),
      icon: "images/listingIcon.svg",
      bgImage: "images/listingBgImage.svg"
    },
  ]
  return (
    <>
      {load ? (
        <DashboardSimmer />
      ) : (
        <div className={classes.root}>
          <TopNavBars
            note={true}
            i18n={i18n}
            t={t}
            count="4"
            handleClicknote={() => history.push(Routes.notification)}
            new={prevData?.unread}
            is_logo
          />
          <div
            style={{
              height: size?.height - 134,
              overflow: "overlay",
            }}
          >
            <Box p={"2"}>
              {/* Profile*/}
              <Box display="flex" alignItems="center" padding={"0px 16px"}>
                <Box flexGrow={1}>
                  <Typography className={classes.username}>
                    {t("hi")+", " +
                      (decodedAuth && decodedAuth.username
                        ? decodedAuth.username
                        : "")}
                  </Typography>
                  <Typography className={classes.msg}>
                    {t("good") + " "+
                      (hour < 12
                        ? t("morning")
                        : hour >= 12 && hour < 18
                          ? t("afternoon")
                          : t("evening")) +
                      `, ${t("welcomeBack")}!`}
                  </Typography>
                </Box>
                <ProfileImageOuterDiv>
                  <ProfileImageInnerDiv>
                    <Image
                      variant="circular"
                      src={prevData?.profileImg ?? ""}
                      alt={"profile_img"}
                      onClick={() => history.push(Routes.profileView)}
                    />
                    <UserID>{"Owner"}</UserID>
                  </ProfileImageInnerDiv>
                </ProfileImageOuterDiv>
              </Box>
              <Box height="12px" />
              {/* Dashboard Card */}
              <Box display="flex" alignItems="center" padding={"0px 16px"}>
                <Grid container spacing={"10px"}>
                  {Stats(data?.stats)?.map((item) => {
                    return (
                      <Grid item xs={4}>
                        <StatCard data={item} authContext={authContext}/>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box height="12px" />
              {/* Carousel Slider Ad */}

              <CarouselSliders autoScroll={true} data={banners} />

              <Box height="12px" />
              <Box alignItems="center" padding={"0px 16px"}>
                <div style={{ paddingBottom: "10px" }}>
                  <Grid container spacing={1}>
                    {QuickLinks()?.map((item) => {
                      return (
                        <Grid item xs={4}>
                          <QuickLinkCard data={item} authContext={authContext}/>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Box>
              <div className={classes.invoice3}>
                <HomepagePayCard
                  Outstanding_amount={data?.stats?.totalOutstandingDueAmount}
                  amount={data?.stats?.totalDueAmount}
                  currency_symbol={""}
                  onClick={() => { }}
                  authContext={authContext}
                />
                {/* <Box height="14px" /> */}
              </div>
              <div className={classes.graph}>
                <Typography className={classes.username}>
                  &nbsp;{t("revenueGraph")}
                </Typography>
                <Box height="12px" />
                <HomepageGraph data={prevData?.responseData} t={t} authContext={authContext}/>
              </div>
              <Box height="10px" />
            </Box>
            <Box height="10px" />
            {/* Announcement carousel */}
            <div style={{ marginBottom: "16px" }}>
              <Announcement data={prevData?.notification} loading={load} t={t} authContext={authContext}/>
            </div>
            {/* Cards */}
            <div>
              {List()?.map((item) => {
                return (
                  <Cards data={item} authContext={authContext}/>
                )
              })}

            </div>
            <Grid container className={classes.referCard}>
              <Grid item xs={6}>
                <Typography className={classes.name2}>
                  {t("refer&Win")}
                </Typography>
                <Typography className={classes.subName}>
                  {t("referFamily&FriendsToCashback")}
                </Typography>
                <Button
                  variant="contained"
                  className={classes.btn}
                >
                  {t("referFriends")}
                </Button>
              </Grid>
              <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                <img src="/images/refer.svg" alt="refer friend" />
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};
export default withNamespaces("dashboard")(DashboardMain)
