import { Box, Typography, IconButton, Badge, Stack } from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useStyles } from "./style";
import { useHistory } from "react-router-dom";
import FileIcon from "../../assets/fileIcon";
import TranslateIcon from "../../assets/translateIcon";
import { DialogDrawer } from "../dialogDrawer";
import { TranslatorModal } from "../translatorModal";
import { LanguageOptions } from "../../utils";
import { AuthContext } from "../../contexts";
export const TopNavBars = ({
  title = "",
  isBack = false,
  is_logo = false,
  ...props
}) => {
  const clientLogo = localStorage.getItem("clientLogo");
  const logo = JSON.parse(localStorage.getItem("accessType"))
    ? JSON.parse(localStorage.getItem("accessType"))
      .filter((val) => val.name === "Owner")
      .find((item) => item?.company_logo !== null)
    : null;
    const auth=React.useContext(AuthContext)
  const classes = useStyles(props);
 
  const history = useHistory();
  const [openLanguage, setOpenLanguage] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState(LanguageOptions?.[0])
  const updateLanguage = (data) => {
    setSelectedLanguage(data)
    saveLanguage(data)
  }
  const openLanguageSwitcher = () => {
    let getselectedlag = props?.i18n.language
    setSelectedLanguage(LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    setOpenLanguage(true)
  }
  const saveLanguage = (language) => {
    props?.i18n.changeLanguage(language?.value);
    setOpenLanguage(false)
    auth.setAuth({
      ...auth,
      auth: {
        language: language?.value
      }
    })
  };
  return (
    <Box className={classes.appBar}>
      {/* back icon */}
      {isBack && (
        <IconButton
          className={classes.iconPosition}
          onClick={() => history.goBack(-1)}
        >
          <ArrowBackIosNewIcon className={classes.backArrow} />
        </IconButton>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Box>
          {(logo?.company_logo || clientLogo) && is_logo && (
            <img
              src={logo?.company_logo ?? clientLogo}
              alt="logo"
              className={classes.navbarimg}
            />
          )}
        </Box>
        <Box>
          {/* title */}
          {title?.length > 0 && (
            <Typography className={classes.title}>{title}</Typography>
          )}
        </Box>
        <Box>
          {props?.note ? (
            <Stack
              spacing={1}
              alignItems={"center"}
              direction="row"
            >
              <IconButton
                size="small"
              >
                <FileIcon />
              </IconButton>
              <IconButton size="small" onClick={props.handleClicknote}>
                {props?.new ? (
                  <Badge color="error" variant="dot">
                    <img src="/images/notificationICON.svg" alt="note" />
                  </Badge>
                ) : (
                  <img src="/images/notificationICON.svg" alt="note" />
                )}
              </IconButton>
              <IconButton
                size="small"
                onClick={openLanguageSwitcher}
              >
                <TranslateIcon />
              </IconButton>
            </Stack>
          ) : (
            <Box width={"30px"} />
          )}
        </Box>
      </Box>
      <DialogDrawer
        height={"400px"}
        open={openLanguage}
        header={"Choose Language"}
        padding={"0px 16px 16px 16px"}
        component={
          <TranslatorModal
            languageOptions={LanguageOptions}
            selectedLanguage={selectedLanguage}
            updateLanguage={updateLanguage}
          />
        }
        onClose={() => setOpenLanguage(false)} />

    </Box>
  );
};
