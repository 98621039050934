import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { SlotCardStyles } from "./style";
import moment from "moment";

export const Slots = (props) => {
  const classes = SlotCardStyles();

  return (
    <Box className={classes.main} onClick={() => props?.onClick(props?.data)}>
      <Box className={classes.gridBox} >
        <Typography className={classes.slotTime}>
          {props?.data?.check_in_date ? moment(props?.data?.check_in_date).format("Do MMM YY") : moment(props?.data?.check_in_time, "HH:mm").format("hh:mm A")} - {props?.data?.check_out_date ? moment(props?.data?.check_out_date).format("Do MMM YY") : moment(props?.data?.check_out_time, "HH:mm").format("hh:mm A")}
        </Typography>
      </Box>
      <Box className={classes.gridBox}>
        <Typography className={classes.slotPerson}>
          {props?.data?.check_in_date ? "24 hours" : "1 hour"} <span className={classes.dot}></span> {"4 Persons / Slot"}
        </Typography>
      </Box>
    </Box>
  );
};
