import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AddAddress } from "../screens/addAddress/addAddress";
import { AggreeUnitDetails } from "../screens/aggreementUnitAccess/aggreeUnitDetails";
import { CreateAggreementUnit } from "../screens/aggreementUnitAccess/createAggreementUnit";
import { AmenitiesType } from "../screens/amenitiesDetails/amenitiesType";
import { AnnouncementDetails } from "../screens/announcement/announcementDetails";
import { CreateDiscussion } from "../screens/communityDiscussion/createDiscussion";
import { DiscussionDetails } from "../screens/communityDiscussion/discussionDetails";
import { ContactDetails } from "../screens/contacts/contactDetails";
import { CreateDelivery } from "../screens/deliveryInfo/createDelivery";
import { DeliveryDetails } from "../screens/deliveryInfo/deliveryDetails";
import { DomesticDetails } from "../screens/domesticHelp/demesticDetails";
import { CreateDomestic } from "../screens/domesticHelp/domesticCreate";
import { OldQuotationDetails } from "../screens/oldQuotationDetails/oldQuotationDetails";
import { CreateParking } from "../screens/parkingpass/createParking";
import { ParkingDetails } from "../screens/parkingpass/passDetails";
import PaymentHistory from "../screens/paymentHistory/paymentHistory";
import { ProfileEdit } from "../screens/profileEdit/profileEdit";
import { Quotation as QuotationDetails } from "../screens/quotationNew/quotation";
import { QuotationPaymentScreen as QuotationPayment } from "../screens/quotationPaymentNew";
import { UtilitiesType } from "../screens/utilitesDetails/utilitiestype";
import { AddVehicle } from "../screens/vehicle/addVehicle";
import { ViewVehicle } from "../screens/vehicle/vehicleView";
import { CompanySelect } from "../screens/welcomePage/companyselectpage";
import { LoginDetails } from "../screens/welcomePage/login";
import { OtpPage } from "../screens/welcomePage/otpPage";
import { ResetPassword } from "../screens/welcomePage/resetPassword";
import { SetupAccountDetails } from "../screens/welcomePage/setupAccountDetails";
import { GatePassDetails } from "../screens/workerGatepass/passDetails";
import {
  AddPass,
  AggreeUnitAccess,
  AgreementParking,
  Amenities,
  AmenitiesDetails,
  AmenitiesBooking,
  Announcement,
  CommunityDiscussion,
  Contacts,
  Dashboard,
  DeliveryPass,
  DomesticHelpList,
  EmergencyNumber,
  EmergencyNumberDetails,
  Entries,
  ExperiedAgreemnt,
  InvoiceScreen,
  ManagementDetails,
  Managment,
  MyNeighbours,
  NeighboursDetails,
  NotFound,
  Notification,
  Owners,
  Ownerslist,
  ParkingPass,
  ProfileView,
  RequestDetails,
  RequestHomeScreen,
  Service,
  Statementofacoount,
  Success,
  SwiftInOutDetails,
  YourUnits,
  Utilities,
  UtilitiesDetails,
  Vehicle,
  WelcomePage,
  WorkerGatePass,
  YourUnitsVacant,
  MaintanceRequest,
  GeneralRequest,
  YourUnitsAssetsUpload,
  PaymentScreen
} from "./../screens";
import PrivateRouter from "./privateRouter";
import { Routes } from "./routes";
const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <PrivateRouter exact path={Routes.aggreementUnitAccess}>
          <AggreeUnitAccess />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.aggreementUnitAccessDetails}>
          <AggreeUnitDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createNew}>
          <CreateAggreementUnit />
        </PrivateRouter>
        {/*Neighbours Details*/}
        <PrivateRouter exact path={Routes.myNeighboursDetails}>
          <NeighboursDetails />
        </PrivateRouter>
        {/* My Neighbours*/}
        <PrivateRouter exact path={Routes.myNeighbours}>
          <MyNeighbours />
        </PrivateRouter>
        {/*Managment*/}
        <PrivateRouter exact path={Routes.managment}>
          <Managment />
        </PrivateRouter>
        {/*Managment*/}
        <PrivateRouter exact path={Routes.utilities}>
          <Utilities />
        </PrivateRouter>
        {/*UtilitiesType*/}
        <PrivateRouter exact path={Routes.utilitiestype}>
          <UtilitiesType />
        </PrivateRouter>
        {/*Management Details*/}
        <PrivateRouter exact path={Routes.managementdetails}>
          <ManagementDetails />
        </PrivateRouter>
        {/* Announcement Detials*/}
        <PrivateRouter exact path={Routes.utilitiesdetails}>
          <UtilitiesDetails />
        </PrivateRouter>
        {/* Announcement Detials*/}
        <PrivateRouter exact path={Routes.announcementDetails}>
          <AnnouncementDetails />
        </PrivateRouter>
        {/* Announcement*/}
        <PrivateRouter exact path={Routes.announcement}>
          <Announcement />
        </PrivateRouter>
        {/* emergency details*/}
        <PrivateRouter exact path={Routes.emergencyNumberDetails}>
          <EmergencyNumberDetails />
        </PrivateRouter>
        {/* aggreement Unit details*/}

        {/* emergency number*/}
        <PrivateRouter exact path={Routes.emergencyNumber}>
          <EmergencyNumber />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createPass}>
          <AddPass />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.passDetails}>
          <GatePassDetails />
        </PrivateRouter>
        {/* Quotation Details Route */}
        <Route exact path={Routes.quotationDetails}>
          <QuotationDetails />
        </Route>

        {/* Old Quotation Details Route */}
        <Route exact path={Routes.oldQuotationDetails}>
          <OldQuotationDetails />
        </Route>

        {/* Quotation Payment Route */}
        <Route exact path={Routes.quotationPayment}>
          <QuotationPayment />
        </Route>

        {/* Welcome page Route */}
        <Route exact path={Routes.welcomePage}>
          <WelcomePage />
        </Route>

        {/* SetupAccount Details Route */}
        <Route exact path={Routes.setupAccount}>
          <SetupAccountDetails />
        </Route>

        {/* Login Route */}
        <Route exact path={Routes.login}>
          <LoginDetails />
        </Route>

        {/* Dashboard */}
        <PrivateRouter exact path={Routes.dashboard}>
          <Dashboard />
        </PrivateRouter>

        {/* Requestscreen Route */}
        <PrivateRouter exact path={Routes.requestScreen}>
          <RequestHomeScreen />
        </PrivateRouter>

        {/* Profile View Route */}
        <PrivateRouter exact path={Routes.profileView}>
          <ProfileView />
        </PrivateRouter>

        {/* units Route */}
        <PrivateRouter exact path={Routes.units}>
          <YourUnits />
        </PrivateRouter>

        {/* Service Route */}
        <PrivateRouter exact path={Routes.service}>
          <Service />
        </PrivateRouter>

        {/* InviceScreen Route */}
        <PrivateRouter exact path={Routes.invoiceScreen}>
          <InvoiceScreen />
        </PrivateRouter>

        {/* Experied */}
        <PrivateRouter exact path={Routes.experied}>
          <ExperiedAgreemnt />
        </PrivateRouter>

        {/* notification */}
        <PrivateRouter exact path={Routes.notification}>
          <Notification />
        </PrivateRouter>

        {/* RequsetDetails */}
        <PrivateRouter exact path={Routes.requestDetails}>
          <RequestDetails />
        </PrivateRouter>

        {/* Statement of account */}
        <PrivateRouter exact path={Routes.statementofaccount}>
          <Statementofacoount />
        </PrivateRouter>

        {/* General Route */}
        {/* <PrivateRouter exact path={Routes.general}>
          <General />
        </PrivateRouter> */}

        {/* MaintenanceScreen Step 1 Route */}
        {/* <PrivateRouter exact path={Routes.maintenanceParent}>
          <MaintenanceParent />
        </PrivateRouter> */}

        {/* Profile Edit Route */}
        <PrivateRouter exact path={Routes.profileEdit}>
          <ProfileEdit />
        </PrivateRouter>

        {/* Add Address Route */}
        <PrivateRouter exact path={Routes.addAddress}>
          <AddAddress />
        </PrivateRouter>

        {/* Payment History Route */}
        <PrivateRouter exact path={Routes.paymentHistory}>
          <PaymentHistory />
        </PrivateRouter>
        {/* amenities Route */}
        <PrivateRouter exact path={Routes.amenities}>
          <Amenities />
        </PrivateRouter>
        {/* amenities details Route */}
        <PrivateRouter exact path={Routes.amenitiesdetails}>
          <AmenitiesDetails />
        </PrivateRouter>
        {/* amenities details Route */}
        <PrivateRouter exact path={Routes.amenitiestype}>
          <AmenitiesType />
        </PrivateRouter>

        {/* Payment History Route */}
        <PrivateRouter exact path={Routes.paymentHistory}>
          <PaymentHistory />
        </PrivateRouter>
        {/* Sucess Route */}
        <Route exact path={Routes.success}>
          <Success />
        </Route>
        {/* failure Route */}
        <Route exact path={Routes.failure}>
          <Success failure />
        </Route>
        {/* Sucess Route */}
        <PrivateRouter exact path={Routes.communityDiscussion}>
          <CommunityDiscussion />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.createDiscussion}>
          <CreateDiscussion />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.discussions}>
          <DiscussionDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.contacts}>
          <Contacts />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.contactsDetails}>
          <ContactDetails />
        </PrivateRouter>
        <PrivateRouter exact path={Routes.workerGatePass}>
          <WorkerGatePass />
        </PrivateRouter>
        {/* parking pass */}
        <PrivateRouter exact path={Routes.parkingPass}>
          <ParkingPass />
        </PrivateRouter>
        {/* parking pass Deatils*/}
        <PrivateRouter exact path={Routes.parkingDetails}>
          <ParkingDetails />
        </PrivateRouter>
        {/* parking pass create*/}
        <PrivateRouter exact path={Routes.createParking}>
          <CreateParking />
        </PrivateRouter>
        {/* Delivery*/}
        <PrivateRouter exact path={Routes.delivery}>
          <DeliveryPass />
        </PrivateRouter>
        {/* Delivery Details*/}
        <PrivateRouter exact path={Routes.deliveryDetails}>
          <DeliveryDetails />
        </PrivateRouter>
        {/* create Details*/}
        <PrivateRouter exact path={Routes.createDelivery}>
          <CreateDelivery />
        </PrivateRouter>
        {/* Domestic Help*/}
        <PrivateRouter exact path={Routes.domesticHelp}>
          <DomesticHelpList />
        </PrivateRouter>
        {/* Domestic Details*/}
        <PrivateRouter exact path={Routes.domesticDetails}>
          <DomesticDetails />
        </PrivateRouter>
        {/* Domestic Create*/}
        <PrivateRouter exact path={Routes.domesticCreate}>
          <CreateDomestic />
        </PrivateRouter>
        {/* Entries*/}
        <PrivateRouter exact path={Routes.entries}>
          <Entries />
        </PrivateRouter>
        {/* Entries Details*/}
        <PrivateRouter exact path={Routes.swiftinout}>
          <SwiftInOutDetails />
        </PrivateRouter>
        {/* Vehicle List*/}
        <PrivateRouter exact path={Routes.vehicle}>
          <Vehicle />
        </PrivateRouter>
        {/* Add Vehicle List*/}
        <Route exact path={Routes.addVehicle}>
          <AddVehicle />
        </Route>
        {/* View Vehicle List*/}
        <PrivateRouter exact path={Routes.viewVehicle}>
          <ViewVehicle />
        </PrivateRouter>
        {/* Entries Details*/}
        <PrivateRouter exact path={Routes.agreementparking}>
          <AgreementParking />
        </PrivateRouter>

        {/* Properties list */}
        <PrivateRouter exact path={Routes.propertiesList}>
          <Owners />
        </PrivateRouter>
        {/* Owners list based on properties */}
        <PrivateRouter exact path={Routes.ownerslists}>
          <Ownerslist />
        </PrivateRouter>
        {/* amenity image card page Route */}
        <PrivateRouter exact path={Routes.amenitiesBookingDetails}>
          <AmenitiesBooking />
        </PrivateRouter>

        {/* -----------------------------------------routes----------------------------------- */}
        {/* Login */}
        <Route exact path="/">
          <LoginDetails />
        </Route>

        {/* otp  */}
        <Route exact path={Routes.otpPage}>
          <OtpPage />
        </Route>

        {/* reset  */}
        <Route exact path={Routes.resetpassword}>
          <ResetPassword />
        </Route>

        {/* company select page Route */}
        <Route exact path={Routes.companyselect}>
          <CompanySelect />
        </Route>

        {/* your units */}
        <PrivateRouter exact path={Routes.yourunit}>
          <YourUnits />
        </PrivateRouter>

        {/* your vacant units */}
        <PrivateRouter exact path={Routes.yourvacantunit}>
          <YourUnitsVacant />
        </PrivateRouter>

        {/* maintance Request */}
        <PrivateRouter exact path={Routes.maintenance}>
          <MaintanceRequest />
        </PrivateRouter>

        {/* General Route */}
        <PrivateRouter exact path={Routes.general}>
          <GeneralRequest />
        </PrivateRouter>

        {/* Your UNits Route */}
        <PrivateRouter exact path={Routes.assetsUpload}>
          <YourUnitsAssetsUpload />
        </PrivateRouter>
        {/* Your UNits Route */}
        <PrivateRouter exact path={Routes.payment}>
          <PaymentScreen />
        </PrivateRouter>
        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
