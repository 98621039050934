import React, { useEffect, useContext, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { useStyles } from "../style";
import {
  ContactDetails,
  FormGenerator,
  DialogDrawer,
} from "../../../components";
import { GETPROFILEDETAILS as query_3 } from "../../../graphql/queries";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { NetworkCall } from "../../../networkcall";
import {
  AlertProps,
  LocalStorageKeys,
  NetWorkCallMethods,
} from "../../../utils";
import { contactDetails } from "../common";
import useWindowDimensions from "../../../utils/useWindowDimensions";

export const Step2 = (props) => {
  const { setState, state, updateState ,t} = props;
  // ---------------------------class-------------------------------------------
  const classes = useStyles();
  // ---------------------------context-------------------------------------------
  const alert = useContext(AlertContext);
  // -------------------------------state---------------------------------------
  const [showDrawer, setShowDrawer] = useState(false);
  // --------------------------------use Diemension-------------------------------
  const size = useWindowDimensions();

  // ---------------------------------functions----------------------------------
  //     contct Details
  const getDetails = () => {
    const payload = {
      query: query_3,
      variables: {
        userID: localStorage.getItem(LocalStorageKeys.userProfileId),
        roleID: localStorage.getItem(LocalStorageKeys.roleID),
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let tempContact = response?.data?.data?.user_profiles[0];
        setState({
          ...state,
          name: `${tempContact?.first_name ?? ""} ${
            tempContact?.last_name ?? ""
          }`,
          primaryMobileNumber: {
            mobile: tempContact?.mobile_no,
            mobile_no: tempContact?.mobile_no_country_code ?? "",
          },
          alternative: {
            mobile: tempContact?.mobile_no,
            mobile_no: tempContact?.alternative_mobile_no_country_code ?? "",
          },
        });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  //   drawer function
  const onDrawerOpen = () => {
    setShowDrawer(!showDrawer);
  };
  //   useEffect
  useEffect(() => {
    getDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        display={"flex"}
      >
        <Grid item xs={6}>
          <Typography className={classes.contact}>{t("ContactText")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <div style={{ float: "right" }}>
            <Typography className={classes.change} onClick={onDrawerOpen}>
              {t("Change")}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ContactDetails data={contactDetails(state)} />
      </Grid>
        <DialogDrawer
          isTitle
          className={classes.dialog}
          open={showDrawer}
          fullWidth={true}
          maxWidth="xs"
          header={t("contactDetails")}
          onClose={() => setShowDrawer(false)}
          component={
            <Box>
              <div
                style={{
                  height: size?.height - 390,
                  overflow: "auto",
                }}
              >
                <FormGenerator
                  components={[
                    {
                      isActive: true,
                      component: "text",
                      label: t("name"),
                      value: state?.name,
                      placeholder:  t("name"),
                      onChange: (value) =>
                        updateState("name", value?.target?.value),
                      error: state?.error?.name,
                      isRequired: true,
                      size: {
                        xs: 12,
                      },
                    },
                    {
                      isActive: true,
                      component: "mobile",
                      label: t("Primary"),
                      value: state?.primaryMobileNumber,
                      placeholder:  t("Primary"),
                      onChange: (value) =>
                        updateState("primaryMobileNumber", value),
                      errorMessage: state?.error?.primaryMobileNumber,
                      isRequired: true,
                      size: {
                        xs: 12,
                      },
                      isPaginate: true,
                    },
                    {
                      isActive: true,
                      component: "mobile",
                      label: t("Alternate"),
                      value: state?.alternative,
                      placeholder: t("Alternate"),
                      onChange: (value) => updateState("alternative", value),
                      errorMessage: state?.error?.alternative,
                      isRequired: true,
                      size: {
                        xs: 12,
                      },
                      isPaginate: true,
                    },
                  ]}
                />
              </div>
              <Box height={'16px'}/>
              <Button
                className={classes.btn}
                onClick={() => setShowDrawer(false)}
              >
                {t("Submit")}
              </Button>
            </Box>
          }
        />
    </div>
  );
};
