
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Password, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, FirebaseContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, Message, ValidateEmail } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ForgetPassword } from "./forgetPassword";
import { silentOTP } from "./silentOTP";
import { loginuseStyles } from "./style";

export const LoginDetails = () => {
  const classes = loginuseStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [disable, setDisable] = React.useState(false)
  const [decoded, setDecoded] = React.useState({
    decode: null,
    isDecode: false
  });
  const InitialState = {
    email: decoded?.isDecode > 0
      ? decoded?.decode?.email_id
      : "",
    password: "",
    error: {
      email: "",
      password: "",
    },
  };
  const [login, setLogin] = React.useState({ ...InitialState });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const search = useLocation().search;
  const firebaseContext = React.useContext(FirebaseContext);
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );

  const [showDrawer, setShowDrawer] = React.useState(false);



  React.useEffect(() => {

    if (localStorage.getItem("device_token")) {
    } else {
      firebaseContext.requestPermission();
    }
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded({
        decode: jwt_decode(welcomePageAuthToken),
        isDecode: true
      })
      const email_id = jwt_decode(welcomePageAuthToken)
      setLogin({ ...login, email: email_id?.email_id })
    } else if (authToken !== null && authToken !== "") {
      history.push(Routes.dashboard);
    } else {
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [history]);

  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = login.error;
    if (login?.email?.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage("Email");
    }
    if (login?.password?.length === 0) {
      isValid = false;
      error.password = Message.English.requiredMessage("Password");
    }
    setLogin({ ...login, error });
    return isValid;
  };
  // eslint-disable-next-line
  const onSendLoginBtnClicked = (e) => {
    e.preventDefault();
    let data = {
      password: login.password,
    };
    if (isIamValideToLogin()) {
      // Check phone number

      //eslint-disable-next-line
      var isphone = /^\d+$/.test(login.email)

      // Check email id

      //eslint-disable-next-line
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        login.email
      );


      if (isphone) {
        data["mobile_no"] = login.email;
      } else if (email) {
        data["email_id"] = login.email;
      } else {
        data["ucid"] = login.email;
      }

      NetworkCall(
        `${config.auth_api_url}/auth/login`,
        NetWorkCallMethods.post,
        data,
        null,
        false,
        false
      )
        .then((response) => {
          if (response?.status === 200) {
            const email_ID = jwt_decode(response?.data?.token)
            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
              history.push(
                Routes.otpPage + "?welcomeAuthToken=" + welcomePageAuthToken
              );

            } else {
              history.push(
                Routes.otpPage +
                "?data=" +
                email_ID?.email_id
              );
            }

            localStorage.setItem(LocalStorageKeys.authToken, response.data.token)
          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Credientials",
          });
        });
    } else {
      return false;
    }
  };

  const onDrawerClose = () => {
    setShowDrawer(false);
  };

  return (
    <div>
      <Container maxWidth="sm" style={{ padding: 0 }}>
        <form
          onSubmit={(e) => {
            setDisable(true)
            silentOTP(e, login, alert, history, isIamValideToLogin, setDisable, welcomePageAuthToken)
          }}
          autoComplete="on">
          <Grid container>
            <Grid
              item
              className={classes.content}
              style={{ height: size.height > 620 ? size.height - 94 : 527 }}
            >
              <Grid item xs={12} className={classes.backgroundImage} />
              <Grid style={{ padding: "0px 16px 0px 16px" }}>
                <Box />

                <Box height={"16px"} />
                <Grid item xs={12}>
                  <Typography className={classes.text}>Log in</Typography>
                </Grid>
                <Box height={"16px"} />
                <Grid item xs={12}>
                  <TextBox
                    type="text"
                    value={login?.email ?? ""}
                    onChange={(e) => updateState("email", e.target.value)}
                    label={Message.English.login.email.label}
                    placeholder={Message.English.login.email.placeholder}
                    isError={login.error.email.length > 0}
                    errorMessage={login.error.email}
                    isReadonly={decoded && decoded.email_id ? true : false}
                    isRequired
                  />
                </Grid>
                <Box height={"16px"} />
                <Grid item xs={12}>
                  <Password
                    value={login?.password}
                    onChange={(e) => updateState("password", e.target.value)}
                    label={Message.English.login.password.label}
                    placeholder={Message.English.login.password.placeholder}
                    isError={login.error.password.length > 0}
                    errorMessage={login.error.password}
                    isRequired
                  />
                  <Box height={"20px"} />
                  <Grid
                    item
                    xs={12}
                    justifyContent={"end"}
                    alignItems={"center"}
                  >
                    <Typography
                      onClick={() => setShowDrawer(true)}
                      className={classes.forgetPasswordTextStyle}
                    >
                      Forget password ?
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    direction="row"
                    justifyContent={"space-between"}
                    alignContent={"center"}
                    mt={1}
                  >
                    <img
                      src="/images/PA Logo.svg"
                      alt="logo"
                      className={classes.img}
                    />
                    <Typography className={classes.version}>{"Ver " + localStorage.getItem(LocalStorageKeys.version)}</Typography>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
              <Button
                fullWidth
                disabled={disable}
                variant="contained"
                className={classes.btn}
                type="submit"
              >
                <Typography className={classes.buttonTextStyle}>
                  Log In
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* Drawer */}
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            open={showDrawer}
            anchor="bottom"
            onClose={() => setShowDrawer(false)}
          >
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  Forget Password
                </Typography>
              </Box>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => setShowDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div className={classes.contentBottom}>
              <ForgetPassword onDrawerClose={onDrawerClose} />
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            className={classes.dialogStyle}
          >
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  Forget Password
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setShowDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div className={classes.contentBottom}>
              <ForgetPassword onDrawerClose={onDrawerClose} />
            </div>

          </Dialog>
        </Hidden>
      </Container>
    </div>
  );
};
