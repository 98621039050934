export const initialState = {
      Category: "",
      subCategory: "",
      Agreement: "",
      Unit: "",
      Title: "",
      Description: "",
      problemSince: "",
      visitorDate: "",
      name: "",
      primaryMobileNumber: "",
      alternative: "",
      error: {
        Category: "",
        subCategory: "",
        Agreement: "",
        Unit: "",
        Title: "",
        Description: "",
        problemSince: "",
        visitorDate: "",
        name: "",
        primaryMobileNumber: "",
        alternative: "",
      },
    };