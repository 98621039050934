import { Box, Typography } from "@mui/material";
// import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { Regular } from "../../utils";
const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E8EE",
    padding: '12px',
    cursor: "pointer"
  },
  Tittle: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
  },
  contant: {
    fontSize: "12px",
    fontFamily: Regular,
    color: "#4E5A6B",
  },
  expires: {
    fontSize: "10px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#4E5A6B",
    backgroundColor: "#F2F4F7",
    padding: "5px",
    marginLeft: "10px",
  },
  Appartment: {
    fontSize: "10px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#4E5A6B",
  },
  root: {
    marginTop: "12px",
  },
}));
export const AgreementList = (props) => {
  moment.defaultFormat = "YYYY-MM";
  const classes = useStyles();
  // const history = useHistory();
  return (
    <div className={classes.root}>
      {props?.agreementList?.map((val) => {
        return (
          <>
            <Box
              className={classes.list}
              onClick={() => props?.updateagrement(val)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* agreement image */}
                <Box>
                  <img src="/images/agreementnew.svg" alt="agreement_img" />
                </Box>
                {/* Agreement number */}
                <Box style={{ marginLeft: "10px" }}>
                  <div>
                    <Typography className={classes.Tittle}>
                      {val.agreement_no}
                      {/* {val?.expiry_on && (
                        <span className={classes.expires}>
                          {props.expiry}&nbsp;
                          {moment(val?.expiry_on)
                            .tz(moment.tz.guess())
                            .format("DD MMM YY")}
                        </span>
                      )} */}
                    </Typography>
                  </div>
                </Box>
              </Box>
              <Box>
                {val.id ===
                  props?.agreement?.id ? (
                  <img src="/images/ticknew.svg" alt="tick_img" />
                ) : (
                  <img src="/images/arrownew.svg" alt="agreement_img" />
                )}
              </Box>
            </Box>
          </>
        );
      })}
    </div>
  );
};
