import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";

export const silentOTP = (e, login, alert, history, isIamValideToLogin, setDisable, welcomePageAuthToken) => {
    e.preventDefault();
    let data = {
        password: login.password,
    };
    if (isIamValideToLogin()) {
        //eslint-disable-next-line
        var isphone = /^\d+$/.test(login.email)
        //eslint-disable-next-line
        var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
            login.email
        );
        if (isphone) {
            data["mobile_no"] = login.email;
        } else if (email) {
            data["email_id"] = login.email;
        } else {
            data["ucid"] = login.email;
        }
        NetworkCall(
            `${config.auth_api_url}/auth/login`,
            NetWorkCallMethods.post, data, null, false, false
        ).then((response) => {
            if (response?.status === 200) {
                localStorage.setItem(LocalStorageKeys.authToken, response.data.token)
                if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
                    history.push(
                        Routes.otpPage + "?welcomeAuthToken=" + welcomePageAuthToken
                    );
                } else {
                    const payload = {
                        otp: "0000",
                    };
                    NetworkCall(
                        `${config.auth_api_url}/auth/verifyOTP/?token=${response?.data?.token}`,
                        NetWorkCallMethods.post, payload, null, false, false
                    ).then((response) => {
                        if (response.status === 200) {
                            localStorage.setItem(LocalStorageKeys.authToken, response.data.token);
                            history.push({
                                pathname: Routes.companyselect,
                                state: response.data.user.clients
                            });
                        } else {
                            setDisable(false);
                            localStorage.clear();
                            history.push(Routes.login);
                        }
                    }).catch((err) => {
                        alert.setSnack({
                            ...alert, open: true, msg: "Invalid OTP",
                            severity: AlertProps.severity.error,
                        });
                        setDisable(false);
                    });
                }
            }
        }).catch((err) => {
            alert.setSnack({
                ...alert, open: true, msg: "Invalid Credientials",
                severity: AlertProps.severity.error,
            });
            setDisable(false);
        });
    } else {
        setDisable(false);
        return false;
    }
};