/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  MenuItem,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import {
  Filter,
  LoderSimmer,
  TitleBar,
  UnitAccessCard
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "0px",
  },

  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "60px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "50px",
      left: "65%",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  menuContainer: {},
  menuText: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  menuItem: {
    position: "absolute",
    background: theme.palette.common.white,
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(5),
    },
    [theme.breakpoints.up("sm")]: {
      left: "58%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
}));
const InitialDate = {
  startDate: "",
  endDate: "",
};
export const AggreementUnitAccess = () => {
  const size = useWindowDimensions();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };
  //const [search, setSearch] = React.useState("");
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [filter, setFilter] = React.useState(false);
  const [gatelist, setGateList] = React.useState([]);
  const [accountList, setAccountList] = React.useState([]);
  const [unitList, setUnitList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const filterData = [
    {
      id: "1",
      title: "Type",
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: [
        {
          label: "Worker Gate Pass",
          value: "WGR",
        },
        {
          label: "Visitor Gate Pass",
          value: "VGR",
        },
      ],
    },
    {
      id: "2",
      title: "Status",
      key: "status",

      filterType: "CHECKBOX",
      values: [
        {
          label: "In Progress",
          value: "Pending",
        },
        {
          label: "Approved",
          value: "Approved",
        },
        {
          label: "Cancelled",
          value: "Cancelled",
        },
        {
          label: "Rejected",
          value: "Rejected",
        },
      ],
    },
    {
      id: "3",
      title: "Unit",
      key: "unit",
      filterType: "CHECKBOX",
      values: unitList,
    },
    {
      id: "4",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];
  const getPassList = (unit, type, status, date, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: type,
      status: status,
      offset: offset,
      limit: 20,
      unit_id: unit,
      start_date:
        dateFilter.endDate.toString().length > 0
          ? dateFilter?.startDate
          : undefined,
      end_date:
        dateFilter.endDate.toString().length > 0
          ? dateFilter?.endDate
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setGateList(response.data.data);
        } else {
          setGateList(gatelist.concat(response.data.data));
        }
        setFilter(false);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  const getUnitids = () => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded.id,
    };
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        const unitkistTemp = response.data?.data?.units?.result?.map(
          (val, index) => {
            let _d;
            try {
              _d = {
                value: val?.id,
                label: val?.unit_name,
                key: "unit",
                all: "true" + index,
              };
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some thing Went wrong.",
              });
            }
            return _d;
          }
        );
        setSelectedFilter({
          type: ["WGR", "VGR"],
          status: ["Pending", "Approved", "Cancelled", "Rejected"],
          unit: unitkistTemp.map((val) => val.value),
        });
        getPassList(
          response.data?.data?.units?.result.map((val) => val?.id),
          ["WGR", "VGR"],
          ["Pending", "Approved", "Cancelled", "Rejected"]
        );

        setUnitList(unitkistTemp);
        // aggreementUnitAccessApi(unitkistTemp.map((val) => val.value))
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  const aggreementUnitAccessApi = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      // account_id: accountId,
      // unit_id: unitId,
      account_id: state.map((val) => val.value),
      unit_id: unitList.map((val) => val.value),
      type: ["Unit Access", "Agreement Access"],
      offset: 0,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/agreement-access/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setAccountList(response?.data?.data);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  React.useEffect(() => {
    getUnitids();
    // if (unitList.length > 0 && state.length > 0) {
    //   aggreementUnitAccessApi()
    // }
    // eslint-disable-next-line
  }, []);

  const applyFilter = (data) => {
    setOffset(0);
    setSelectedFilter(data);
    getPassList(data.unit, data.type, data.status, dateFilter, filter, 0);
  };
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(
      selectedFilter.unit,
      selectedFilter.type,
      selectedFilter.status,
      "",
      false,
      offset + 20
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  //acounts
  const [state, setState] = React.useState([]);
  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const tempDecoded = jwt_decode(authToken);
      const userId = tempDecoded.id;
      const payload = {
        tenantId: `${config.tenantid}`,
        userId: userId,
      };
      NetworkCall(
        `${config.api_url}/user-accounts`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          const fetch = response?.data?.data.map((val, index) => {
            let _d;
            try {
              _d = {
                value: val.account_no,
                label: val.account_no,
                key: "account_no",
              };
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some thing Went wrong.",
              });
            }
            return _d;
          });
          setState(fetch);
          // aggreementUnitAccessApi(fetch.map((val) => val.value))
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (unitList.length > 0 && state.length > 0) {
      aggreementUnitAccessApi();
    }
    // eslint-disable-next-line
  }, [unitList, state]);

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text="Multi-Login Access"

        goBack={() => history.push(Routes.profileView)}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                  {/* <TextField
                    size="small"
                    variant="outlined"
                    
                    placeholder="Search Services Here"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      height: "30px",
                    }}
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={classes.search}
                  /> */}
                  <Typography className={classes.total}>
                    Access ({accountList?.length})
                  </Typography>
                </Box>
                {/* <Box marginLeft="10px">
                  <IconButton
                    className={classes.filter}
                    onClick={() => setFilter(true)}
                  >
                    <Badge variant="dot" color="primary">
                      <img src="/images/icons8-funnel.svg" alt="filter" />
                    </Badge>
                  </IconButton>
                </Box> */}
              </Box>

              {loading ? (
                <LoderSimmer count={10} />
              ) : (
                <InfiniteScroll
                  dataLength={gatelist.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 100}
                >
                  <Box padding={"16px"}>
                    {accountList?.map((item) => {
                      return <Box marginBottom={"10px"}> <UnitAccessCard data={item} />
                      </Box>
                    })}
                  </Box>
                </InfiniteScroll>
              )}

              <Grid item xs={12} style={{ position: "relative" }}>
                <Box sx={{ display: "flex", float: "right" }}>
                  <Box>
                    <Fab
                      color="primary"
                      className={classes.fab}
                      aria-label="add"
                      onClick={handleToggle}
                    >
                      {open ? <CloseIcon /> : <AddIcon />}
                      <Backdrop
                        className={classes.backdrop}
                        open={open}
                        onClick={handleClose}
                      >
                        <div className={classes.menuItem}>
                          <MenuItem
                            onClick={() =>
                              history.push(
                                Routes.createNew + "?title=" + "account"
                              )
                            }
                          >
                            <Typography className={classes.menuText}>
                              Account Level Access
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              history.push(
                                Routes.createNew + "?title=" + "unit"
                              )
                            }
                          >
                            <Typography className={classes.menuText}>
                              Unit Level Access
                            </Typography>
                          </MenuItem>
                        </div>
                      </Backdrop>
                    </Fab>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter.type,
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter.type,
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};
