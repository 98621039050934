import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { saveAs } from "file-saver";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";
import { useHistory } from "react-router-dom";
import ArrowRight from "../../assets/arrowRight";
import {
  AgreementCard,
  AgreementDrawer,
  CardList,
  DocumentViewer,
  InformationAndServiceButton as RelatedInfo,
  OptionMenu,
  TabsComponent,
  TopNavBars
} from "../../components";
import { AgreementSimmer } from "../../components/simmer/agreementSimmer";
import { config } from "../../config";
import { BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Invoicescreen from "../invoiceScreen";
import PaymentHistory from "../paymentHistory";
import RenewalForm from "./component/renewalForm";
import RequsetCard from "./component/requsetCard";
import TerminationForm from "./component/terminationForm";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  tabs: {
    padding: "6px 10px",
    boxShadow: "0px 1px 6px #00000021",
    // backgroundColor: "white",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
  },
  title: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    margin: "12px 0px",
  },
  agreementimg: {
    height: "50vh",
    msOverflowY: "scroll",
  },
  actionroot: {
    backgroundColor: "white",
    padding: "8px 12px 12px 12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  manageCard: {
    backgroundColor: "#fff",
    padding: 16,
    paddingTop: 10
  },
  mangerCard: {
    backgroundColor: "#A7BFFF",
    borderRadius: theme.palette.borderRadius,
    padding: "14px 16px"
  },
  ownerList: {
    backgroundColor: "#091B29",
    borderRadius: "15px",
    color: "#fff",
    fontSize: "12px",
    width: "fit-content",
    padding: "2px 10px"
  },
  ownerCardDeitals: {
    justifyContent: "space-between",
    alignitems: "flex-start",
    display: "flex",
    flexDirection: "column"
  },
  cardTitle: {
    color: "#091B29",
    fontsize: "18px",
    fontWeight: 600,
  },
  cardDes: {
    color: "#091B29",
    fontSize: "10px"
  },

}));
const AgreementNew = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [agreement, setAgreement] = React.useState([]);
  const [agreementList, setAgreementList] = React.useState([]);
  const [openList, setopenList] = React.useState(false);
  const [termination, settermination] = React.useState(false);
  const [renewl, setrenewl] = React.useState(false);
  const [pdf, setPdf] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const size = useWindowDimensions();
  const [agreementData, setAgreementData] = React.useState({
    agreementRequest: [],
    assets: [],
    general: [],
    invoices: [],
    maintenance: [],
    property: [],
    agreement: {},
    lastes_requset: ""
  });
  const getAgreementData = (id, unit) => {
    const data = {
      tenantId: `${config.tenantid}`,
      agreementId: id,
      units: unit,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/request-invoices`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      setAgreementData({
        agreement: response.data.data?.agreement,
        agreementRequest: response.data.data?.agreementRequest,
        assets: response.data.data?.assets,
        general: response.data.data?.general,
        invoices: response.data.data?.invoices,
        maintenance: response.data.data?.maintenance,
        property: response.data.data?.property,
        lastes_requset: response.data.data?.latestAgreementRequest,
      });
      setopenList(false);
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      setLoading(false);
    });
  };
  React.useEffect(() => {
    const temp_decoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: temp_decoded.id,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher `,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      let localAgreement = JSON.parse(localStorage.getItem(LocalStorageKeys.agreement))
      let tempAgreement = localAgreement?.id ? localAgreement : response.data.data[0]
      setAgreement(tempAgreement);
      setAgreementList(response.data.data);
      getAgreementData(tempAgreement?.id, tempAgreement?.agreement_unit?.map((val) => val?.id));
    });
    // eslint-disable-next-line
  }, []);

  const ManageData = [
    {
      icon: "/images/statementIcon.svg",
      lable: 'Statement',
      link: "/statementofaccount",
      data: agreement?.id,
      color: "#FEEAEA"
    },
    {
      icon: "/images/entryLogIcon.svg",
      lable: 'Entry Log',
      link: "/entries",
      data: agreement?.id,
      color: "#EDE4FE"
    },
    {
      icon: "/images/LoginsIcon.svg",
      lable: 'Logins',
      link: "/aggreement_unit_access",
      data: agreement?.id,
      color: "#DBF0F1"
    }
  ];
  const RelatedInfoData = [
    {
      icon: "/images/unitAgree.svg",
      lable: `${props.t("units")}`,
      link: "/units",
      data: agreement?.id,
      borderColor: "#199BE2"


    },
    {
      icon: "/images/requsetAgree.svg",
      lable: `${props.t("requests")}`,
      link: "/requestscreen",
      data: agreement?.id,
      borderColor: "#FFD869"

    },
    {
      icon: "/images/aminitiesAgree.svg",
      lable: `${props.t("amenities")}`,
      link: "/amenities",
      data: agreement?.id,
      borderColor: "#C966E9"

    },
    {
      icon: "/images/parkingAgree.svg",
      lable: `${props.t("parking")}`,
      // link: "/parking_pass",
      link: "/agreementparking",
      data: agreement?.id,
      borderColor: "#50E6FF"

    },
    {
      icon: "/images/vechicleAgree.svg",
      lable: `${props.t("vehicle")}`,
      link: "/vehicle",
      data: agreement,
      borderColor: "#EE6B01"

    },
    {
      icon: "/images/utlitiesAgree.svg",
      lable: `${props.t("utlities")}`,
      data: agreement?.id,
      link: "/utilities",
      borderColor: "#5C5C5D"

    },
    {
      icon: "/images/roomsAgree.svg",
      lable: `${props.t("violations")}`,
      data: agreement?.id,
      noavailable: true,
      borderColor: "#C966E9"

    },
    {
      icon: "/images/ownersAgree.svg",
      lable: `${props.t("move_in")}`,
      data: agreement?.id,
      noavailable: true,
      borderColor: "#5C5C5D"

    },
  ];
  const updateagrement = (data) => {
    setopenList(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Switching Agreement",
    });
    localStorage.setItem(LocalStorageKeys.agreement, JSON.stringify(data))
    setAgreement(data);
    getAgreementData(data?.id, data?.agreement_unit?.map(val => val?.id));
  };
  const renderAgreementPdf = () => {
    return (
      <div>
        <Box
          display="flex"
          alignItems="center"
          className={classes.titleroot}
          style={{ padding: "12px" }}
        >
          <Box flexGrow={1}>
            <Typography className={classes.bottomTitle}>
              {agreement?.agreement_no}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setPdf(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box p={1}>
          <div className={classes.agreementimg}>
            <DocumentViewer url={agreementData?.assets[0]?.url} />
          </div>
          <Button
            variant="contained"
            fullWidth={true}
            sx={{ padding: "12px", borderRadius: "10px", marginTop: "12px" }}
            onClick={() => saveAs(agreementData?.assets[0].url, agreement?.id)}
          >
            {props.t("download")}
          </Button>
        </Box>
      </div>
    );
  };
  const reload = () => {
    getAgreementData(agreement?.id, agreement?.units);
  };

  const properiesList = () => {
    history.push(
      {
        pathname: Routes.propertiesList,
        state: {
          agreement: agreement?.id
        }
      }
    )
  }


  return (
    <>
      {" "}
      {loading ? (
        <AgreementSimmer />
      ) : (
        <div className={classes.root}>
          <TopNavBars
            home={true}
            expire={props.t("expires_on")}
            handleClick={() => setopenList(true)}
            agreementList={agreementList}
            agreement={agreement}
          />
          {agreement?.is_primary && (
            <Grid container alignItems="center" className={classes.tabs}>
              <Grid item xs={11}>
                <TabsComponent
                  tab1Label={props.t("home")}
                  tab2Label={props.t("invoice")}
                  tab3Label={props.t("payment")}
                  selectedTab={selectedTab}
                  onTab1Clicked={() => setSelectedTab(0)}
                  onTab2Clicked={() => setSelectedTab(1)}
                  onTab3Clicked={() => setSelectedTab(2)}
                />
              </Grid>
              <Grid item xs={1}>
                <OptionMenu
                  onOpen={() => settermination(true)}
                  onOpenagree={() => setPdf(true)}
                  downlodeName={props.t("download")}
                  terminationName={props.t("agreement_termination")}
                  termination={agreementData?.agreementRequest[0]?.request_purpose === "Termination" ? false : true}
                />
              </Grid>
            </Grid>
          )}
          <Box
            style={{
              height: size?.height - (agreement?.is_primary ? 200 : 140),
              overflow: "overLay",
              // backgroundColor: "#fff"

            }}
          >

            {selectedTab === 0 && (
              <Box className={classes.agreementRoot}>
                <Box padding={"4px 16px 16px 16px"} style={{ background: "#F2F5FA" }} >
                  {/* Properties ( */}
                  {agreementData?.property?.length > 0 && (
                    <Typography className={classes.title}>
                      {props.t("banner_label")} (
                      {agreementData?.property?.length < 10 ? (
                        <>0{agreementData?.property?.length}</>
                      ) : (
                        <>{agreementData?.property?.length}</>
                      )}
                      )
                    </Typography>
                  )}
                  <ScrollContainer className="scroll-container">
                    <Box display="flex">
                      {agreementData?.property?.map((datas) => {
                        return (
                          <Box>
                            <AgreementCard
                              data={datas}
                              unitName={props.t("units")}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </ScrollContainer>
                  <div style={{ paddingBottom: "10px" }}>
                    <RequsetCard
                      agreementData={agreementData?.agreementRequest?.[0]}
                      agreementDatas={agreementData?.agreement}
                      status={agreementData?.lastes_requset}
                      openRenwel={() => setrenewl(true)}
                    />
                  </div>
                  {agreement?.is_primary && (
                    <Box className={classes.actionroot}>
                      <Typography className={classes.title}>
                        {props.t("actions")}
                      </Typography>
                      <ScrollContainer className="scroll-container">
                        <Box display="flex">
                          <CardList
                            main={agreementData}
                            primary={agreement?.is_primary}
                          />
                        </Box>
                      </ScrollContainer>
                    </Box>
                  )}
                  <Typography className={classes.title}>
                    {props.t("Related_Info")}
                  </Typography>
                  <div style={{ paddingBottom: "10px" }}>
                    <RelatedInfo data={RelatedInfoData} />
                  </div>
                </Box>
                {/* Manage */}
                <div className={classes.manageCard} >

                  <div>
                    <Grid container className={classes.mangerCard} >
                      <Grid md={6} xs={6} className={classes.ownerCardDeitals} >
                        <Typography variant={"h6"} className={classes.cardTitle}  >Owners</Typography>
                        <Typography variant={"subtitle1"} className={classes.cardDes}  >To get contact and email id information of the unit owners</Typography>
                        <Button
                          onClick={() => properiesList()}
                          variant="contained"
                          fullWidth
                          className={classes.ownerList}
                        >
                          View more
                          <ArrowRight color={"#ffffff"} width={5} style={{ marginLeft: "8px" }} />
                        </Button>
                      </Grid>
                      <Grid md={6} xs={6} >
                        <center>
                          <img src="/images/ownerAdBanner.svg" alt="ownerAdBanner" />
                        </center>
                      </Grid>
                    </Grid>
                  </div>
                  <Typography className={classes.title}>
                    {"Manage"}
                  </Typography>
                  <div style={{ paddingBottom: "10px" }}>
                    <RelatedInfo data={ManageData} />
                  </div>
                </div>
              </Box>
            )}
            {selectedTab === 1 && (
              <Invoicescreen
                titlebar={true}
                id={agreement?.id}
                agreemntlist={agreementList}
                agreement={true}
                agreement_no={agreement?.agreement_no}
              />
            )}
            {selectedTab === 2 && <PaymentHistory id={agreement?.id} />}
          </Box>
          {/* AgreementList Drawer */}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={openList}
              onClose={() => setopenList(false)}
            >
              <AgreementDrawer
                agreementList={agreementList}
                agreement={agreement}
                updateagrement={updateagrement}
                onclose={() => setopenList(false)}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={openList}
              onClose={() => setopenList(false)}
              className={classes.dialog}
            >
              <AgreementDrawer
                agreementList={agreementList}
                agreement={agreement}
                updateagrement={updateagrement}
                onclose={() => setopenList(false)}
              />
            </Dialog>
          </Hidden>
          {/* Agreement pdf drawer */}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={pdf}
              onClose={() => setPdf(false)}
            >
              {renderAgreementPdf()}
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={pdf}
              onClose={() => setPdf(false)}
              className={classes.dialog}
            >
              {renderAgreementPdf()}
            </Dialog>
          </Hidden>
          {/* Termination Form Drawer*/}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={termination}
              onClose={() => settermination(false)}
            >
              <TerminationForm
                agreement_id={agreement?.id}
                onClose={() => settermination(false)}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={termination}
              onClose={() => settermination(false)}
              className={classes.dialog}
            >
              <TerminationForm
                agreement_id={agreement?.id}
                onClose={() => settermination(false)}
              />
            </Dialog>
          </Hidden>
          {/* Renewl Form Drawer*/}
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={renewl}
              onClose={() => setrenewl(false)}
            >

              <RenewalForm
                settermination={() => setrenewl(false)}
                agreement_id={agreement?.id}
                onClose={() => setrenewl(false)}
                reload={reload}
              />
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={renewl}
              onClose={() => setrenewl(false)}
              className={classes.dialog}
            >

              <RenewalForm
                settermination={() => settermination(false)}
                agreement_id={agreement?.id}
                onClose={() => setrenewl(false)}
                reload={reload}
              />
            </Dialog>
          </Hidden>
        </div>
      )}
    </>
  );
};
export default withNamespaces("agreement")(AgreementNew);
