import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const useStyles = makeStyles((theme) => ({
  arrowfeed: {
    marginLeft: "8px",
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  arrowiconfeed: {
    fontSize: "16px",
    color: "white",
  },
  feedcontainer: {
    // backgroundImage: (props) =>
    //   props.data.type === "feedback"
    //     ? "url('/images/Dashboard swimming pool 2-10@2x.png')"
    //     : "url('/images/Dashboard swimming pool-09.svg')",

    borderRadius: "10px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100%",
  },
  feed: {
    fontSize: "12px",
    color: (props) => (props.data.type === "feedback" ? "#0443A0" : "black"),
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  feedsub: {
    fontSize: "14px",
    fontWeight: "bold",
    fontStyle: "italic",
    marginTop: "10px",
    color: (props) => (props.data.type === "feedback" ? "black" : "#8D5C21"),
  },
  surveyImage: {
    objectFit: "cover",
    height: "120px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
  }
}));
export const SurveyCard = (props) => {
  const classes = useStyles(props);
  return (
    <Box display="flex" className={classes.feedcontainer}>
      <Box flexGrow={1}>
        <img
          src={props.data.img}
          alt=""
          className={classes.surveyImage}
        />
      </Box>
    </Box>
  );
};
