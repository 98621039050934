import React from "react";
import { useStyle } from "./style";
import { Box, Typography } from "@mui/material";

export const YourUnitCard = ({
  data = {},
  onClick = () => false,
  color = "",
}) => {
  const classes = useStyle({ color });
  return (
    <div className={classes.root} onClick={()=>onClick(data)}>
      {/* image */}
      <img src={data?.logo} alt="ld" width={"100%"} height={"162px"} />
      <div>
        <Box display="flex" alignItems="center">
          {/* name */}
          <Typography className={classes.unitname} noWrap>
            {data?.name ?? "-"}
          </Typography>
          {/* agreement number */}
          <Typography className={classes.agreementNumber} noWrap>
            {data?.no ?? "-"}
          </Typography>
        </Box>
        {/* property name */}
        <Box display="flex" alignItems="center">
          <Typography className={classes.propertyName} noWrap>
            {data?.property_name ?? "-"}
          </Typography>
          <Box className={classes.dot1} />
          <Typography className={classes.propertyName} noWrap>
            {data?.location ?? "-"}
          </Typography>
        </Box>
      </div>
      {/* flag */}
      <Box className={classes.flag}>
        <Box position={"relative"}>
          <Typography className={classes.flagName} noWrap>{data?.status}</Typography>
          <Box className={classes.flagChild} />
        </Box>
      </Box>
    </div>
  );
};
