import React from "react";
import { Statementofaccounts } from './statementofaccount';

class StatementofaccountParent extends React.Component {
    render() {
        return <Statementofaccounts />;
    }
}

export default StatementofaccountParent;
