/* eslint-disable array-callback-return */
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  TextField,
  Typography
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { CarouselSliders, TopNavBars } from "../../components";
import { QuickLinkCard } from "../../components/quickLinkCard";
import { TempBanner } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AuthContext } from "../../contexts";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.dashboard.primary,
    // padding: "0px",
  },
  tabroot: {
    display: "flex",
    alignItems: "center",
    padding: "10px 12px",
    backgroundColor: theme.palette.background.secondary,
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "0px",
    minHeight: "auto",
    // borderRadius: "20px",
    "& .MuiButtonBase-root-MuiTab-root": {
      // borderRadius: "20px",
    },
    minWidth: "auto",

    marginRight: "8px",
    // backgroundColor: "white",
  },
  tabItemSelect: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: theme.palette.borderRadius,
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },

  screenScrollEffect: {
    // overflow: "overlay",
    // scrollbarWidth: "none",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
  },
  info: {
    fontSize: "18px",
    fontFamily: props => FontFamilySwitch(props?.language).extraBold,
    textAlign: "center",
  },
  title: {
    padding: "8px 14px",
    // background: "#5078E1",
    color: "#071741",
    display: "flex",
    alignItems: "center",
  },
  titleselect: {
    padding: "8px 14px",
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  titles: {
    fontSize: "16px",
    fontFamily: props => FontFamilySwitch(props?.language).extraBold,
    margin: "8px 8px 8px 8px",
  },
}));

const Service = ({ t, i18n }) => {
  const size = useWindowDimensions();
  const [banners, setBanners] = React.useState([]);
  const authContext = React.useContext(AuthContext)
  const classes = useStyles({ language: authContext?.auth?.auth?.language });
  const [value, setValue] = React.useState("1");
  const [search, setSearch] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getBanners = () => {
    // let data = {
    //   type: value === "1" ? "Community" : "Services",
    //   tenantId: `${config.tenantid}`,
    //   company_id: logo?.map((val) => val?.company_id),
    //   offset: 0,
    //   limit: 1000,
    // };
    // NetworkCall(
    //   `${config.api_url}/company-banner/list`,
    //   NetWorkCallMethods.post,
    //   data,
    //   null,
    //   true,
    //   false
    // ).then((response) => {
    //   let bannerss = response.data.data.map((item) => item?.assets).flat();
    //   setBanners(bannerss);
    // });

    let bannerss = TempBanner?.map((item) => item?.assets)?.flat()
    setBanners(bannerss)
  };

  const Requests = () => [
    {
      title: [t("your"), t("township")],
      icon: "images/yourtownship.svg",
      color: "#5059BE"
    },
    {
      title: [t("announce"), t("ments")],
      icon: "images/announcementsIcon.svg",
      color: "#0078D4"
    },
    {
      title: [t("contact"), t("me")],
      icon: "images/contactme.svg",
      color: "#26ACE5",

    },
    {
      title: [t("public"), t("amenities")],
      icon: "images/publicamenities.svg",
      color: "#7819A4",


    },
    {
      title: [t("placesOf"), t("interest")],
      icon: "images/placesOfInterest.svg",
      color: "#C83807",


    },
    {
      title: [t("enroll"), t("residents")],
      icon: "images/enrollresident.svg",
      color: "#37C6D0",


    },
    {
      title: [t("committee"), ""],
      icon: "images/committeeIcon.svg",
      color: "#25ABE8",


    },
  ]

  const HomeCare = () => [
    {
      title: [t("home"), t("cleaning")],
      icon: "images/homeCleaning_2.svg",
    },
    {
      title: [t("home"), t("sanitization")],
      icon: "images/homeSanitization.svg",
    },
    {
      title: [t("bathroom"), t("cleaning")],
      icon: "images/bathroomCleaning.svg",
    },
    {
      title: [t("home"), t("repair")],
      icon: "images/homerepair.svg",
    },
    {
      title: [t("home"), t("painting")],
      icon: "images/homePainting.svg",
    },
    {
      title: [t("home"), t("cleaning")],
      icon: "images/homeCleaning_3.svg",
    },
  ]

  const Essentials = () => [
    {
      title: [t("car"), t("rental")],
      icon: "images/carRental.svg",
    },
    {
      title: [t("postal"), t("assistance")],
      icon: "images/post.svg",
    },
    {
      title: [t("medical"), t("supplies")],
      icon: "images/medical.svg",
    },
    {
      title: [t("pest"), t("control")],
      icon: "images/pestControl.svg",
    },
    {
      title: [t("packers&"), t("movers")],
      icon: "images/packers&movers.svg",
    },
    {
      title: [t("legal"), t("assistance")],
      icon: "images/legalAssistance.svg",
    },
    {
      title: [t("car"), t("wash")],
      icon: "images/carWash.svg",
    },
    {
      title: [t("more"), ""],
      icon: "images/more.svg",
    },
  ]

  const HomeProducts = () => [
    {
      title: [t("sweets&"), t("savouries")],
      icon: "images/sweets&savouries.svg",
    },
    {
      title: [t("organic"), t("products")],
      icon: "images/organicProducts.svg",
    },
    {
      title: [t("food"), t("supplies")],
      icon: "images/foodSupplies.svg",
    },
  ]

  React.useEffect(() => {
    getBanners();
    // eslint-disable-next-line
  }, [value]);
  return (
    <div className={classes.root}>
      <TopNavBars note={true} i18n={i18n}
        is_logo
        t={t} />
      {/* Tabs section */}
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="none"
        textColor="secondary"
        className={classes.tabroot}
        variant="scrollable"
        visibleScrollbar={false}
        scrollButtons={false}>
        <Tab className={value === "1" ? classes.tabItemSelect : classes.tabItem}
          label={
            <span className={value === "1" ? classes.titleselect : classes.title}>
              {value === "1" && <img src="/images/township.svg" alt="township" />}
              &nbsp;Township
            </span>}
          value="1" />
        <Tab className={value === "2" ? classes.tabItemSelect : classes.tabItem}
          label={
            <span className={value === "2" ? classes.titleselect : classes.title}>
              {value === "2" && <img src="/images/marketplace.svg" alt="marketplace" />}
              &nbsp;Marketplace
            </span>}
          value="2" />
        <Tab className={value === "3" ? classes.tabItemSelect : classes.tabItem}
          label={
            <span className={value === "3" ? classes.titleselect : classes.title}>
              {value === "3" && <img src="/images/selectedclean.svg" alt="listing" />}
              &nbsp;Listing
            </span>}
          value="3" />
      </Tabs>
      <Grid container>
        <Grid item xs={12} sx={{ padding: "12px" }}>
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              placeholder={t("searchServicesHere")}
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className={classes.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <img src="/images/icons8-search (2).svg" alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }} />
          </Grid>
          <Grid container spacing={1} style={{ overflow: "overlay", height: size.height - 239 }}>
            {value === "1" && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {/* <Grid item xs={12}>
                    <Typography className={classes.titles}>Request</Typography>
                  </Grid> */}
                  {Requests()?.map((item) => {
                    return <Grid item xs={4} mt={2}>
                      <QuickLinkCard data={item} marketplace={true} />
                    </Grid>
                  })}
                </Grid>
              </Grid>)}
            {value === "2" && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.titles}>
                      {t("homeCare")}
                    </Typography>
                  </Grid>
                  {HomeCare()?.map((item) => {
                    return <Grid item xs={4}>
                      <QuickLinkCard data={item} marketplace={true} />
                    </Grid>
                  })}
                </Grid>
                <Grid item xs={12} sx={{ margin: "12px 0px 0px 0px" }}>
                  <CarouselSliders autoScroll={true} data={banners} />
                </Grid>
                <Box height="4px" />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.titles}>
                      {t("essentials")}
                    </Typography>
                  </Grid>
                  {Essentials()?.map((item) => {
                    return <Grid item xs={4}>
                      <QuickLinkCard data={item} marketplace={true} />
                    </Grid>
                  })}
                </Grid>
                <Box height="4px" />
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box height="12px" />
                    <Typography className={classes.titles}>
                      {t("homeProducts")}
                    </Typography>
                  </Grid>
                  {HomeProducts()?.map((item) => {
                    return <Grid item xs={4}>
                      <QuickLinkCard data={item} marketplace={true} />
                    </Grid>
                  })}
                </Grid>
                <Box height="12px" />
              </Grid>)}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default withNamespaces("services")(Service)