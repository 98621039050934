import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import OtpInput from "react-otp-input";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps } from "../../utils";
import { NetWorkCallMethods } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  otp: {
    boxShadow: "none",
    "& input": {
      boxShadow: "none",
      height: "48px",
      width: "50px !important",
      background: theme.palette.common.white,
      borderRadius: 8,
      fontSize: "18px",
      fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
      border: `1.5px solid ${theme.palette.border.secondary}`,
      "&:focus": {
        border: `1.5px solid ${theme.palette.primary.main}`,
        outline: "none",
      },
    },
  },
  resendOtp: {
    fontSize: "14px",
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  resendOtps: {
    fontSize: "14px",
    color: theme.palette.primary.main,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
}));

export const EnterOtp = (props) => {
  const classes = useStyles(props);
  const [counter, setCounter] = React.useState(59);
  const alert = React.useContext(AlertContext);
  const [resendBtn, setResendBtn] = React.useState(false);

  const resendOtp = () => {
    const data = {
      email_id: props?.emailId,
    };
    NetworkCall(
      `${config.auth_api_url}/auth/resendOTP`,
      NetWorkCallMethods.post,
      data,
      null,
      false,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "OTP resend to respective email address",
          });
          setCounter(59);
          setResendBtn(false);
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing went wrong",
        });
      });
  };
  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => {
      clearInterval(timer);
      if (counter === 1) {
        setResendBtn(true);
      }
    };
  }, [counter]);
  return (
    <Container maxWidth={"sm"}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs>
          <OtpInput
            isInputNum
            shouldAutoFocus={true}
            className={classes.otp}
            value={props.value}
            onChange={props.handleChange}
            numInputs={4}
            separator={<span>&nbsp;&nbsp;&nbsp;</span>}
          />
        </Grid>
        <br />
        <Grid item xs>
          {resendBtn ? (
            <Typography className={classes.resendOtps} onClick={resendOtp}>
              Resend OTP
            </Typography>
          ) : (
            <Typography className={classes.resendOtp}>
              Resend in &nbsp;
              <span>00:{counter}</span>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
