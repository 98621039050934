import * as React from "react"

export const VacantUnitsImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={210.209}
    height={218.576}
    {...props}
  >
    <defs>
      <filter
        id="c"
        x={144.416}
        y={97.75}
        width={56.481}
        height={92.214}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="d"
        x={164.625}
        y={119.739}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-2" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="e"
        x={154.379}
        y={119.739}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-3" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-3" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="f"
        x={140.648}
        y={68.633}
        width={69.561}
        height={52.976}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-4" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-4" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="g"
        x={9.313}
        y={97.492}
        width={56.481}
        height={92.214}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-5" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-5" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="h"
        x={19.047}
        y={119.481}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-6" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-6" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="i"
        x={29.293}
        y={119.481}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-7" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-7" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="j"
        x={0}
        y={68.376}
        width={69.561}
        height={52.976}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-8" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-8" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="k"
        x={42.475}
        y={93}
        width={124.925}
        height={99.982}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-9" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-9" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="l"
        x={85.077}
        y={128.684}
        width={38.176}
        height={57.359}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-10" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-10" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="m"
        x={84.255}
        y={118.485}
        width={39.821}
        height={23.729}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-11" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-11" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="n"
        x={82.966}
        y={169.109}
        width={42.768}
        height={24.502}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-12" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-12" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="o"
        x={114.539}
        y={118.007}
        width={45.619}
        height={63.894}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-13" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-13" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="p"
        x={42.817}
        y={5.793}
        width={125.834}
        height={105.207}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-14" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-14" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="q"
        x={119.393}
        y={10.411}
        width={26.844}
        height={32.4}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-15" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-15" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="r"
        x={116.857}
        y={8.322}
        width={32.295}
        height={22.422}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-16" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-16" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="s"
        x={36.826}
        y={0}
        width={137.096}
        height={71.066}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-17" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-17" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="t"
        x={84.526}
        y={52.061}
        width={39.989}
        height={51.9}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-18" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-18" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="u"
        x={86.13}
        y={53.894}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-19" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-19" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="v"
        x={55.27}
        y={54.134}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-20" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-20" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="w"
        x={65.515}
        y={54.134}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-21" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-21" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="x"
        x={115.652}
        y={54.44}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-22" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-22" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="y"
        x={125.896}
        y={54.44}
        width={26.538}
        height={48.235}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-23" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-23" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="z"
        x={44.863}
        y={192.664}
        width={25.646}
        height={23.667}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-24" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-24" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="A"
        x={63.753}
        y={193.002}
        width={28.76}
        height={23.208}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-25" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-25" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="D"
        x={47.234}
        y={142.607}
        width={38.37}
        height={67.486}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-26" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-26" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="F"
        x={42.43}
        y={106.383}
        width={43.936}
        height={55.483}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-27" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-27" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="H"
        x={51.088}
        y={96.804}
        width={24.845}
        height={28.544}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-28" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-28" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="J"
        x={51.84}
        y={89.851}
        width={26.375}
        height={29.221}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-29" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-29" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="L"
        x={49.778}
        y={86.481}
        width={28.834}
        height={28.323}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-30" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-30" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="N"
        x={50.519}
        y={95.378}
        width={20.868}
        height={21.423}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-31" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-31" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="P"
        x={33.585}
        y={107.206}
        width={30.892}
        height={51.689}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-32" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-32" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="S"
        x={62.112}
        y={107.035}
        width={42.75}
        height={33.76}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-33" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-33" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="U"
        x={85.879}
        y={111.485}
        width={28.301}
        height={26.814}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-34" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-34" />
        <feComposite in="SourceGraphic" />
      </filter>
      <filter
        id="W"
        x={93.047}
        y={109.061}
        width={28.377}
        height={24.882}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} result="blur-35" />
        <feFlood floodOpacity={0.161} />
        <feComposite operator="in" in2="blur-35" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient
        id="a"
        x1={4.159}
        y1={14.88}
        x2={4.535}
        y2={5.105}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#09005d" />
        <stop offset={1} stopColor="#1a0f91" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={43.731}
        y1={-0.843}
        x2={43.225}
        y2={2.39}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#febbba" />
        <stop offset={1} stopColor="#ff928e" />
      </linearGradient>
      <linearGradient
        id="B"
        x1={12.436}
        y1={5.546}
        x2={12.703}
        y2={-5.089}
        xlinkHref="#a"
      />
      <linearGradient
        id="C"
        x1={47.379}
        y1={-0.224}
        x2={46.864}
        y2={2.539}
        xlinkHref="#b"
      />
      <linearGradient
        id="E"
        x1={1.683}
        y1={-0.87}
        x2={0.105}
        y2={1.031}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#e1473d" />
        <stop offset={1} stopColor="#e9605a" />
      </linearGradient>
      <linearGradient
        id="G"
        x1={0.701}
        y1={0.397}
        x2={-0.714}
        y2={0.875}
        xlinkHref="#a"
      />
      <linearGradient id="I" x1={0} y1={0.5} x2={1} y2={0.5} xlinkHref="#b" />
      <linearGradient
        id="K"
        x1={84.807}
        y1={-19.665}
        x2={86.238}
        y2={-19.925}
        xlinkHref="#b"
      />
      <linearGradient
        id="M"
        x1={-3.986}
        y1={-3.781}
        x2={-2.846}
        y2={-3.781}
        xlinkHref="#a"
      />
      <linearGradient
        id="O"
        x1={247.941}
        y1={-66.59}
        x2={252.121}
        y2={-67.442}
        xlinkHref="#b"
      />
      <linearGradient
        id="Q"
        x1={1.94}
        y1={0.295}
        x2={-0.907}
        y2={0.828}
        xlinkHref="#a"
      />
      <linearGradient
        id="R"
        x1={0.945}
        y1={-0.15}
        x2={-0.809}
        y2={2.992}
        xlinkHref="#b"
      />
      <linearGradient
        id="T"
        x1={-0.069}
        y1={0.877}
        x2={-1.552}
        y2={2.015}
        xlinkHref="#a"
      />
      <linearGradient
        id="V"
        x1={-0.591}
        y1={0.954}
        x2={1.94}
        y2={-0.06}
        xlinkHref="#b"
      />
      <linearGradient
        id="X"
        x1={-1.281}
        y1={1.559}
        x2={1.231}
        y2={0.261}
        xlinkHref="#b"
      />
    </defs>
    <g data-name="Group 102214">
      <path
        data-name="Path 11200"
        d="M194.991 140.68c-20.249 47.1-73.7 67.329-115.578 63.879-11.435-.942-34.442-2.837-52.406-19.742-34.5-32.465-29.185-100.327 3.84-140.28 4.244-5.142 33.687-39.614 79.561-39.157 33.586.338 57.16 19.218 60.027 21.57a92.162 92.162 0 0 1 18.806 21.238c16.913 26.32 18.4 63.062 5.746 92.495Z"
        fill="#f1f7ff"
      />
      <path
        data-name="Path 11201"
        d="M3.757 167.904c19.369 63.484 172.245 68.612 199.83 8.04 1.89-4.151-1.449-8.714-6.444-8.806l-189.666-3.5c-2.514-.047-4.385 2.09-3.721 4.268Z"
        fill="#f1f7ff"
      />
      <g data-name="Group 102210">
        <g data-name="Group 102195">
          <g transform="translate(-.003 -.001)" filter="url(#c)">
            <path
              data-name="Path 11209"
              d="M153.42 103.75h38.481v72.749a1.465 1.465 0 0 1-1.464 1.465H153.42V103.75Z"
              fill="#7d97f4"
            />
          </g>
          <g
            data-name="Group 102194"
            transform="translate(161.775 123.907)"
            opacity={0.89}
          >
            <rect
              data-name="Rectangle 53975"
              width={21.989}
              height={33.9}
              rx={1.123}
              fill="#fff"
              opacity={0.67}
            />
            <g data-name="Group 102193">
              <g filter="url(#d)" transform="translate(-161.777 -123.908)">
                <rect
                  data-name="Rectangle 53976"
                  width={8.538}
                  height={30.235}
                  rx={0.624}
                  transform="translate(173.62 125.74)"
                  fill="#7d97f4"
                />
              </g>
              <g filter="url(#e)" transform="translate(-161.777 -123.908)">
                <rect
                  data-name="Rectangle 53977"
                  width={8.538}
                  height={30.235}
                  rx={0.624}
                  transform="translate(163.38 125.74)"
                  fill="#7d97f4"
                />
              </g>
            </g>
          </g>
          <g transform="translate(-.003 -.001)" filter="url(#f)">
            <path
              data-name="Path 11210"
              d="M199.14 109.611h-47.554l-1.94-34.23 39.312-.746 12.055 32.042a2.065 2.065 0 0 1-1.873 2.934Z"
              fill="#5078e1"
            />
          </g>
          <path
            data-name="Path 11211"
            d="m189.116 76.207-37.788-.422.237-3.826 35.319.269a2.4 2.4 0 0 1 2.325 2.473Z"
            fill="#5078e1"
          />
        </g>
        <g data-name="Group 102198">
          <g transform="translate(-.001 .002)" filter="url(#g)">
            <path
              data-name="Path 11212"
              d="M56.79 177.71H19.906a1.6 1.6 0 0 1-1.6-1.6v-72.614H56.79v74.214Z"
              fill="#7d97f4"
            />
          </g>
          <g
            data-name="Group 102197"
            transform="translate(26.443 123.649)"
            opacity={0.89}
          >
            <rect
              data-name="Rectangle 53978"
              width={21.989}
              height={33.9}
              rx={1.123}
              transform="rotate(180 10.994 16.95)"
              fill="#fff"
              opacity={0.67}
            />
            <g data-name="Group 102196">
              <g filter="url(#h)" transform="translate(-26.447 -123.648)">
                <rect
                  data-name="Rectangle 53979"
                  width={8.538}
                  height={30.235}
                  rx={0.624}
                  transform="rotate(180 18.295 77.86)"
                  fill="#7d97f4"
                />
              </g>
              <g filter="url(#i)" transform="translate(-26.447 -123.648)">
                <rect
                  data-name="Rectangle 53980"
                  width={8.538}
                  height={30.235}
                  rx={0.624}
                  transform="rotate(180 23.415 77.86)"
                  fill="#7d97f4"
                />
              </g>
            </g>
          </g>
          <g transform="translate(-.001 .002)" filter="url(#j)">
            <path
              data-name="Path 11213"
              d="M11.07 109.351h47.554l1.94-34.23-39.312-.746-12.06 32.042a2.065 2.065 0 0 0 1.873 2.934Z"
              fill="#5078e1"
            />
          </g>
          <path
            data-name="Path 11214"
            d="m21.086 75.95 37.788-.422-.237-3.826-35.319.269a2.4 2.4 0 0 0-2.325 2.473l.093 1.506Z"
            fill="#5078e1"
          />
        </g>
        <g data-name="Group 102209" transform="translate(45.825 6)">
          <g transform="translate(-45.83 -6)" filter="url(#k)">
            <path
              data-name="Path 11215"
              d="M51.477 99v75.946a6.036 6.036 0 0 0 6.036 6.036h98.957a1.93 1.93 0 0 0 1.93-1.931V99Z"
              fill="#91b3fa"
            />
          </g>
          <path
            data-name="Path 11216"
            d="M47.683 127.079h21.311a.255.255 0 0 1 .255.255v42.315H47.428v-42.315a.255.255 0 0 1 .255-.255Z"
            fill="#7d97f4"
          />
          <g transform="translate(-45.83 -6)" filter="url(#l)">
            <path
              data-name="Path 11217"
              d="M94.33 134.689h19.666a.255.255 0 0 1 .255.255v39.1H94.08v-39.1a.255.255 0 0 1 .25-.255Z"
              fill="#7d97f4"
            />
          </g>
          <g transform="translate(-45.83 -6)" filter="url(#m)">
            <path
              data-name="Path 11218"
              d="M113.536 130.214H94.8a1.541 1.541 0 0 1-1.541-1.54v-1.346a1.541 1.541 0 0 1 1.434-1.537l18.74-1.3a1.541 1.541 0 0 1 1.648 1.537v2.648a1.541 1.541 0 0 1-1.545 1.538Z"
              fill="#bdd0fb"
            />
          </g>
          <path
            data-name="Path 11219"
            d="M69.102 172.285h-21.27a1.749 1.749 0 0 1-1.749-1.749v-1.527c0-.92.7-3.46 1.617-3.524l21.281.3a1.748 1.748 0 0 1 1.87 1.744v3a1.749 1.749 0 0 1-1.749 1.756Z"
            fill="#bdd0fb"
          />
          <g transform="translate(-45.83 -6)" filter="url(#n)">
            <path
              data-name="Path 11220"
              d="M114.986 181.61h-21.27a1.748 1.748 0 0 1-1.749-1.749v-1.528c0-.919.2-2.623 1.117-2.687l21.78-.535a1.749 1.749 0 0 1 1.87 1.744v3.005a1.748 1.748 0 0 1-1.748 1.75Z"
              fill="#7d97f4"
            />
          </g>
          <g data-name="Group 102199" transform="translate(75.987 115.916)">
            <rect
              data-name="Rectangle 53981"
              width={31.133}
              height={49.824}
              rx={3.142}
              fill="#fff"
            />
            <g transform="translate(-121.81 -121.92)" filter="url(#o)">
              <rect
                data-name="Rectangle 53982"
                width={27.619}
                height={45.894}
                rx={2.934}
                transform="translate(123.54 124.01)"
                fill="#7d97f4"
              />
            </g>
            <path
              data-name="Rectangle 53983"
              fill="#fff"
              d="M14.175 1.46h1.584v48.364h-1.584z"
            />
          </g>
          <g data-name="Group 102200" transform="translate(10.968 115.916)">
            <rect
              data-name="Rectangle 53984"
              width={31.133}
              height={49.824}
              rx={3.142}
              fill="#fff"
            />
            <rect
              data-name="Rectangle 53985"
              width={27.619}
              height={45.894}
              rx={2.934}
              transform="translate(1.725 2.091)"
              fill="#7d97f4"
            />
            <path
              data-name="Rectangle 53986"
              fill="#fff"
              d="M14.175 1.46h1.584v48.364h-1.584z"
            />
          </g>
          <g data-name="Group 102208">
            <g transform="translate(-45.83 -6)" filter="url(#p)">
              <path
                data-name="Path 11221"
                d="m159.649 53.146-.908-.687v-.955h-1.264l-52.537-39.71-53.121 39.71v47.5H158.74v-40.96Z"
                fill="#91b3fa"
              />
            </g>
            <g data-name="Group 102201">
              <g filter="url(#q)" transform="translate(-45.83 -6)">
                <path
                  data-name="Rectangle 53987"
                  fill="#7d97f4"
                  d="M128.39 16.41h8.844v14.4h-8.844z"
                />
              </g>
              <g filter="url(#r)" transform="translate(-45.83 -6)">
                <path
                  data-name="Path 11222"
                  d="M126.29 14.325h13.195a.664.664 0 0 1 .664.664v3.758h-14.3v-3.986a.437.437 0 0 1 .441-.436Z"
                  fill="#5078e1"
                />
              </g>
              <g filter="url(#s)" transform="translate(-45.83 -6)">
                <path
                  data-name="Path 11223"
                  d="m48.24 59.067 56.74-43.515 53.86 41.777a3.344 3.344 0 0 0 5.325-1.972l.755-3.7-59.936-45.656-58.02 44.316a2.89 2.89 0 0 0-.919 3.395l2.2 5.355Z"
                  fill="#5078e1"
                />
              </g>
            </g>
            <g data-name="Group 102203" opacity={0.89}>
              <g transform="translate(-45.83 -5.999)" filter="url(#t)">
                <rect
                  data-name="Rectangle 53988"
                  width={21.989}
                  height={33.9}
                  rx={1.123}
                  transform="rotate(180 57.76 45.98)"
                  fill="#fff"
                />
              </g>
              <g data-name="Group 102202" transform="translate(49.304 53.893)">
                <g transform="translate(-95.13 -59.89)" filter="url(#u)">
                  <rect
                    data-name="Rectangle 53989"
                    width={8.538}
                    height={30.235}
                    rx={0.624}
                    transform="translate(95.13 59.89)"
                    fill="#7d97f4"
                  />
                </g>
                <rect
                  data-name="Rectangle 53990"
                  width={8.538}
                  height={30.235}
                  rx={0.624}
                  transform="translate(10.245)"
                  fill="#7d97f4"
                />
              </g>
            </g>
            <g
              data-name="Group 102205"
              transform="translate(16.84 52.301)"
              opacity={0.89}
            >
              <rect
                data-name="Rectangle 53991"
                width={21.989}
                height={33.9}
                rx={1.123}
                transform="rotate(180 10.994 16.95)"
                fill="#fff"
              />
              <g data-name="Group 102204">
                <g filter="url(#v)" transform="translate(-62.667 -58.298)">
                  <rect
                    data-name="Rectangle 53992"
                    width={8.538}
                    height={30.235}
                    rx={0.624}
                    transform="translate(64.27 60.13)"
                    fill="#7d97f4"
                  />
                </g>
                <g filter="url(#w)" transform="translate(-62.667 -58.298)">
                  <rect
                    data-name="Rectangle 53993"
                    width={8.538}
                    height={30.235}
                    rx={0.624}
                    transform="translate(74.52 60.13)"
                    fill="#7d97f4"
                  />
                </g>
              </g>
            </g>
            <g
              data-name="Group 102207"
              transform="translate(77.221 52.608)"
              opacity={0.89}
            >
              <rect
                data-name="Rectangle 53994"
                width={21.989}
                height={33.9}
                rx={1.123}
                transform="rotate(180 10.994 16.95)"
                fill="#fff"
              />
              <g data-name="Group 102206">
                <g filter="url(#x)" transform="translate(-123.046 -58.608)">
                  <rect
                    data-name="Rectangle 53995"
                    width={8.538}
                    height={30.235}
                    rx={0.624}
                    transform="translate(124.65 60.44)"
                    fill="#7d97f4"
                  />
                </g>
                <g filter="url(#y)" transform="translate(-123.046 -58.608)">
                  <rect
                    data-name="Rectangle 53996"
                    width={8.538}
                    height={30.235}
                    rx={0.624}
                    transform="translate(134.9 60.44)"
                    fill="#7d97f4"
                  />
                </g>
              </g>
            </g>
          </g>
          <rect
            data-name="Rectangle 53997"
            width={113.009}
            height={6.124}
            rx={3.062}
            transform="translate(2.607 92.025)"
            fill="#7d97f4"
          />
        </g>
      </g>
      <g data-name="Group 102213">
        <g transform="translate(-.006 .001)" filter="url(#z)">
          <path
            data-name="Path 11224"
            d="M703.763 2705.562a.668.668 0 0 1 .018-.175c.87-3.451 4.43-5.221 4.43-5.221l1.712.134c.985 3.042 1.619 4.528 1.461 5.359 0 .023-.011.045-.018.068a40.633 40.633 0 0 1-7.603-.165Z"
            transform="translate(-649.9 -2501.5)"
            fill="url(#a)"
          />
        </g>
        <path
          data-name="Path 11225"
          d="M61.466 204.227a.743.743 0 0 1-.543.55 23.525 23.525 0 0 1-6.544-.093.624.624 0 0 1-.517-.621 40.6 40.6 0 0 0 7.604.164Z"
          fill="#7d97f4"
        />
        <path
          data-name="Path 11226"
          d="m746.209 2662.94.75 3.609a.59.59 0 0 0 .5.486 5.009 5.009 0 0 0 1.162-.019.742.742 0 0 0 .587-.872l-.521-3.2Z"
          transform="translate(-689.309 -2466.94)"
          fill="url(#b)"
        />
        <path
          data-name="Path 11227"
          d="M76.015 204.195a9.862 9.862 0 0 1-3.264-.466c0 .182 0 .367.006.559a.362.362 0 0 0 .345.368l10.041-.066a.458.458 0 0 0 .369-.667c-1.317.108-4.67.351-7.497.272Z"
          fill="#7d97f4"
        />
        <g transform="translate(-.006 .001)" filter="url(#A)">
          <path
            data-name="Path 11228"
            d="M978.411 2709.8a.482.482 0 0 0-.04-.076 12.793 12.793 0 0 0-7.608-4.777l-2.141-.068a10.278 10.278 0 0 0-.971 4.727 9.9 9.9 0 0 0 3.264.466c2.826.081 6.179-.162 7.496-.272Z"
            transform="translate(-894.9 -2505.88)"
            fill="url(#B)"
          />
        </g>
        <path
          data-name="Path 11229"
          d="M975.673 2652.825c.024.16.317 2.585.431 3.526a.466.466 0 0 0 .393.42 4.917 4.917 0 0 0 1.471-.022.633.633 0 0 0 .48-.635v-3.807Z"
          transform="translate(-902.347 -2457.068)"
          fill="url(#C)"
        />
        <g transform="translate(-.006 .001)" filter="url(#D)">
          <path
            data-name="Path 11230"
            d="M738.98 2000.869s-3.426 30.453-1.523 49.486a14.72 14.72 0 0 0 3.426-.381s3.56-29.775 4.712-38.3a.286.286 0 0 1 .565-.018c1.421 7.168 7 35.278 7.666 37.933a4.747 4.747 0 0 0 3.426 0s-3.045-40.731-5.329-47.964Z"
            transform="translate(-680.65 -1852.26)"
            fill="url(#E)"
          />
        </g>
        <g transform="translate(-.006 .001)" filter="url(#F)">
          <path
            data-name="Path 11231"
            d="M669.821 1495.646s14.465-2.284 25.885.761l-.761 11.8s-3.045 16.369-5.329 23.6c0 0-8.755 1.523-12.943-.761 0 0-3.426-20.556-5.329-23.6 0 0-1.903-7.994-1.523-11.8Z"
            transform="translate(-618.34 -1382.44)"
            fill="url(#G)"
          />
        </g>
        <g transform="translate(-.006 .001)" filter="url(#H)">
          <path
            data-name="Path 11232"
            d="m792.165 1361-1.441 9.745s4.026 1.849 6.845-.079l-.607-6.877Z"
            transform="translate(-730.64 -1258.2)"
            fill="url(#I)"
          />
        </g>
        <g data-name="Group 102211">
          <g filter="url(#J)" transform="translate(-.003 .001)">
            <path
              data-name="Path 11233"
              d="M808.121 1264.473c.045.082.914 5 1.449 8.038a2.134 2.134 0 0 1-1.977 2.5l-1.167.069a4.157 4.157 0 0 1-4.355-3.521l-.844-5.51s5.792-3.575 6.894-1.576Z"
              transform="translate(-740.39 -1168.01)"
              fill="url(#K)"
            />
          </g>
          <g filter="url(#L)" transform="translate(-.003 .001)">
            <path
              data-name="Path 11234"
              d="M773.226 1222.357a1.636 1.636 0 0 1 .115-2.4 2.306 2.306 0 0 1 2.752-.4.768.768 0 0 0 .9-.179.489.489 0 0 1 .455-.169.718.718 0 0 0 .811-.624 1.894 1.894 0 0 1 1.672-1.74c2.241-.433 3.359 1.809 3.319 3.386s-2.5 1.908-3.89 1.646a2.674 2.674 0 0 0-2.307 1.2.74.74 0 0 0-.146.635l.354 1.523a7.028 7.028 0 0 1-.831 1.361 1.5 1.5 0 0 1-.809.519c-.418-.778-1.586-.909-2.129-.927a.742.742 0 0 1-.538-.255 1.629 1.629 0 0 1 .177-2.548.739.739 0 0 0 .09-1.026Z"
              transform="translate(-713.64 -1124.31)"
              fill="url(#M)"
            />
          </g>
          <g filter="url(#N)" transform="translate(-.003 .001)">
            <path
              data-name="Path 11235"
              d="M785.644 1344.142s-.048-2.749-1.161-3.008-2.313.547-1.361 2.193c1.178 2.048 2.522.815 2.522.815Z"
              transform="translate(-723.26 -1239.71)"
              fill="url(#O)"
            />
          </g>
        </g>
        <g transform="translate(-.006 .001)" filter="url(#P)">
          <path
            data-name="Path 11236"
            d="M555.1 1506.31s-22.459 24.362 4 33.689v-1.9s-9.9-8.375-2.284-19.794c.002-.005-.95-10.853-1.716-11.995Z"
            transform="translate(-503.62 -1393.1)"
            fill="url(#Q)"
          />
        </g>
        <path
          data-name="Path 11237"
          d="M722.8 1931.246s3.426-2.284 4.949-1.523a2.354 2.354 0 0 1 1.523 1.9s-.761 1.523-1.9 1.142-3.807.381-4.949 0c-.005.004-.386-1.519.377-1.519Z"
          transform="translate(-667.132 -1786.065)"
          fill="url(#R)"
        />
        <g data-name="Group 102212">
          <g filter="url(#S)" transform="translate(.001 .005)">
            <path
              data-name="Path 11238"
              d="M968.5 1512.7c-7.251 1.088-11.325-3.863-14.846-6.638-4.65-3.664-8.92-1.506-8.92-1.506l4.575 10.679c7.405 6.982 15.381 4.215 20.167 1.953a9.234 9.234 0 0 0-.976-4.488Z"
              transform="translate(-873.62 -1390.89)"
              fill="url(#T)"
            />
          </g>
          <g filter="url(#U)" transform="translate(.001 .005)">
            <path
              data-name="Path 11239"
              d="m1286.947 1566.09-2.4 1.294a21.044 21.044 0 0 1-7.787 3.032 9.233 9.233 0 0 1 .975 4.488 22.181 22.181 0 0 0 4.957-3.169c5.444-3.248 4.255-5.645 4.255-5.645Z"
              transform="translate(-1181.88 -1448.61)"
              fill="url(#V)"
            />
          </g>
        </g>
        <g transform="translate(-.006 .001)" filter="url(#W)">
          <path
            data-name="Path 11240"
            d="M1387 1538.052a1.774 1.774 0 0 0-1.037-1.135l-4.88-1.962s-.074-.626 1.031-1.051a1.469 1.469 0 0 0 .846-1.663s-1.509.688-3.719 1.781a5.173 5.173 0 0 0-2.349 2.557l1.256 1.652a22.334 22.334 0 0 0 9.121.718Z"
            transform="translate(-1274.85 -1417.18)"
            fill="url(#X)"
          />
        </g>
      </g>
    </g>
  </svg>
)
