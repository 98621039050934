import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  CardContent,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { InvoicePayNowList } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GETAGREEMENTPDF } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps } from "../../utils";
import { DocumentViewer } from "../pdfviewer/index";
const useStyles = makeStyles((theme) => ({
  card: {},

  CardContent: {
    borderRadius: theme.palette.borderRadius,
    backgroundImage: `url(${"/images/actioncard.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.card.primary,
    backgroundPositionX: "100%",
    height: "88px",
    color: "black",
    width: "210px",
    "&.MuiCardContent-root": {
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "0px",
      paddingTop: "5px",
    },
  },
  requestID: {
    borderRadius: theme.palette.borderRadius,
    backgroundImage: `url(${"/images/actioncard.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.card.secondary,
    backgroundPositionX: "100%",
    height: "88px",
    width: "210px",
    "&.MuiCardContent-root": {
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "0px",
      paddingTop: "5px",
    },
  },
  general: {
    borderRadius: theme.palette.borderRadius,
    backgroundImage: `url(${"/images/actioncard.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#927CFA",
    backgroundPositionX: "100%",
    height: "88px",
    width: "210px",
    "&.MuiCardContent-root": {
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingBottom: "0px",
      paddingTop: "5px",
    },
  },
  title: {
    fontSize: "18px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.palette.primary.contrastText,
    textOverflow: "ellipsis",
  },
  body: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  subBody: {
    fontSize: "10px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    padding: " 1px 7px",
  },
  titles: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  totalAmount: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },

  subTitle: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      padding: "0px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
}));

const ActionCard = ({ cardData = [], type = "" }) => {
  const [invoicepdf, setInvoicepdf] = React.useState([]);
  const [showpdf, setShowpdf] = React.useState(false);
  const classes = useStyles(cardData);
  const [openinvoice, setOpeninvoice] = React.useState(false);
  const alert = React.useContext(AlertContext);

  const invoicedata = {
    description: "",
    invoice_due_amount: cardData.OutsandingAmount,
    invoice_no: cardData.invoice_no,
    id: cardData.id,
  };
  const viewinvoice = (data) => {
    const params = {
      query: GETAGREEMENTPDF,
      variables: {
        id: data,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      "post",
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setInvoicepdf(response.data.data);
        setShowpdf(true);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };
  return (
    <>
      {type === "invoice" && (
        <CardContent
          className={
            cardData.requestID ? classes.requestID : classes.CardContent
          }
          onClick={() => {
            setOpeninvoice(true);
          }}
        >
          {cardData.OutsandingAmount && (
            <Typography className={classes.title}>
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              }).format(Math.round(cardData.OutsandingAmount))}
            </Typography>
          )}

          <Typography className={classes.body}>
            {cardData.invoice_no}{" "}
            {cardData.requestID && (
              <>
                {" "}
                &nbsp;
                <span
                  style={{
                    width: "4px",
                    height: "5px",
                    backgroundColor: "#703D00",
                    borderRadius: "100%",
                    display: "inline-block",
                  }}
                ></span>{" "}
                &nbsp;
                {cardData.requestID}
              </>
            )}
          </Typography>
          {cardData.is_New && (
            <span className={classes.subBody}>{cardData.is_New}</span>
          )}
          {cardData.status && (
            <span className={classes.subBody}>{cardData.status}</span>
          )}
        </CardContent>
      )}
      {type === "maintenance" && (
        <CardContent className={classes.requestID}>
          {cardData.subject && (
            <Typography className={classes.title} noWrap>
              {cardData.subject}
            </Typography>
          )}

          <Typography className={classes.body}>
            Maintenance
            {cardData.maintenance_request_no && (
              <>
                {" "}
                &nbsp;
                <span
                  style={{
                    width: "4px",
                    height: "5px",
                    backgroundColor: "#703D00",
                    borderRadius: "100%",
                    display: "inline-block",
                  }}
                ></span>{" "}
                &nbsp;
                {cardData.maintenance_request_no}
              </>
            )}
          </Typography>

          {cardData.status && (
            <span className={classes.subBody}>{cardData.status}</span>
          )}
        </CardContent>
      )}
      {type === "general" && (
        <CardContent className={classes.general}>
          {cardData.subject && (
            <Typography className={classes.title} noWrap>
              {cardData.subject}
            </Typography>
          )}

          <Typography className={classes.body}>
            General
            {cardData.general_request_no && (
              <>
                {" "}
                &nbsp;
                <span
                  style={{
                    width: "6px",
                    height: "5px",
                    backgroundColor: "black",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>{" "}
                &nbsp;
                {cardData.general_request_no}
              </>
            )}
          </Typography>

          {cardData.status && (
            <span className={classes.subBody}>{cardData.status}</span>
          )}
        </CardContent>
      )}
      <Hidden smDown>
        <Dialog
          maxWidth="sm"
          className={classes.dialog}
          fullWidth
          open={openinvoice}
          onClose={() => {
            setOpeninvoice(!openinvoice);
          }}
        >
          <Box
            display="flex"
            p={1}
            style={{ borderBottom: "1px solid #c1c1c1" }}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.titles}>
                {type === "invoice" && "Invoices"}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setOpeninvoice(!openinvoice);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {type === "invoice" && (
            <Grid xs={12} style={{ padding: "12px" }}>
              <InvoicePayNowList
                InvoicePay={invoicedata}
                delete={true}
                type="dashboard"
                viewinvoice={viewinvoice}
              />
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography
                      className={classes.titles}
                      style={{ marginTop: "8px" }}
                    >
                      Bill Summary
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Grid item xs={6}>
                      <Typography className={classes.subTitle}>
                        Invoice Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ float: "right" }}>
                        <Typography className={classes.subTitle}>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          }).format(Math.round(cardData.OutsandingAmount))}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      borderBottom: "2px dashed #CED3DD",
                      borderTop: "2px dashed #CED3DD",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography className={classes.totalAmount}>
                        Total Amount To Pay
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ float: "right" }}>
                        <Typography className={classes.totalAmount}>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          }).format(Math.round(cardData.OutsandingAmount))}
                        </Typography>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          className={classes.btn}
                        >
                          Pay Now
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          maxWidth="sm"
          fullWidth
          anchor="bottom"
          open={openinvoice}
          onClose={() => {
            setOpeninvoice(!openinvoice);
          }}
        >
          <Box
            display="flex"
            p={1}
            style={{ borderBottom: "1px solid #c1c1c1" }}
            alignItems="center"
          >
            <Box flexGrow={1}>
              <Typography className={classes.titles}>
                {type === "invoice" && "Invoices"}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={() => {
                  setOpeninvoice(!openinvoice);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {type === "invoice" && (
            <Grid xs={12} style={{ padding: "12px" }}>
              <InvoicePayNowList
                InvoicePay={invoicedata}
                delete={true}
                type="dashboard"
                viewinvoice={viewinvoice}
              />
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography
                      className={classes.titles}
                      style={{ marginTop: "8px" }}
                    >
                      Bill Summary
                    </Typography>
                  </Grid>

                  <Grid item xs={12} style={{ display: "flex" }}>
                    <Grid item xs={6}>
                      <Typography className={classes.subTitle}>
                        Invoice Amount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ float: "right" }}>
                        <Typography className={classes.subTitle}>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          }).format(Math.round(cardData.OutsandingAmount))}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      borderBottom: "2px dashed #CED3DD",
                      borderTop: "2px dashed #CED3DD",
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography className={classes.totalAmount}>
                        Total Amount To Pay
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ float: "right" }}>
                        <Typography className={classes.totalAmount}>
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          }).format(Math.round(cardData.OutsandingAmount))}
                        </Typography>
                      </div>
                      <br />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Box>
                        <Button
                          variant="contained"
                          size="large"
                          className={classes.btn}
                        >
                          Pay Now
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Drawer>
      </Hidden>
      {/* invoice */}
      <Hidden smDown>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={showpdf}
          className={classes.dialog}
        >
          <div>
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>Invoice</Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setShowpdf(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DocumentViewer url={invoicepdf?.assets?.[0]?.url} />
          </div>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          maxWidth="sm"
          anchor="bottom"
          fullWidth
          open={showpdf}
          className={classes.drawer}
        >
          <div>
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>Invoice</Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setShowpdf(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <DocumentViewer url={invoicepdf?.assets?.[0]?.url} />
          </div>
        </Drawer>
      </Hidden>
    </>
  );
};

export default ActionCard;
