export const initialState = {
  Category: "",
  subCategory: "",
  Agreement: "",
  Unit: "",
  Title: "",
  Description: "",
  problemSince: "",
  visitorDate: "",
  name: "",
  primaryMobileNumber: "",
  alternative: "",
  error: {
    Category: "",
    subCategory: "",
    Agreement: "",
    Unit: "",
    Title: "",
    Description: "",
    problemSince: "",
    visitorDate: "",
    name: "",
    primaryMobileNumber: "",
    alternative: "",
  },
};

export const contactDetails = (tempContact) => [
  {
    type: "Contact Name",
    value: `${tempContact?.name ?? ""}`,
  },
  {
    type: "Primary Contact Number",
    value: `${tempContact?.primaryMobileNumber?.mobile_no ?? ""}  ${
      tempContact?.primaryMobileNumber?.mobile ?? ""
    }`,
  },
  {
    type: "Alternate Contact Number",
    value: `${tempContact?.alternative?.mobile_no ?? ""}  ${
      tempContact?.alternative?.mobile ?? ""
    }`,
  },
];
