import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.primary.main,
    // borderRadius: "10px",
    color: theme.palette.primary.main,
    // "&.css-14ugdsl-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected":
    //   {
    //     color: "white",
    //   },
    // margin: "0px 8px",
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    height: "62px",
    boxShadow: "0px -1px 6px #00000021",
  },
  tabStyle: {
    cursor: "pointer",
  },
  selectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.palette.primary.main,
  },
  unselectedLabelTextStyle: {
    fontSize: "11px",
    color: theme.typography.color.Tertiary,
  },
}));
export const BottomNavbar = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent={"space-around"}
      alignItems={"center"}
      className={classes.containers}
    >
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
        onClick={props.onTab1Clicked}
      >
        <center>
          <img
            src={
              window.location.pathname === "/dashboard"
                ? "/images/dashboardSelected.svg"
                : "/images/dashboardUnselected.svg"
            }
            alt="dashboard"
          />
          <Typography
            className={
              window.location.pathname === "/dashboard"
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            {"Dashboard"}
          </Typography>
        </center>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
        onClick={props.onTab2Clicked}
      >
        <center>
          <img
            src={
              window.location.pathname === "/units"
                ? "/images/yourUnitsSelected.svg"
                : "/images/yourUnitsUnselected.svg"
            }
            alt="Your Units"
          />
          <Typography
            className={
              window.location.pathname === "/units"
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            {"Your Units"}
          </Typography>
        </center>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
        onClick={props.onTab3Clicked}
      >
        <center>
          <img
            src={
              window.location.pathname === "/service"
                ? "/images/servicesSelected.svg"
                : "/images/servicesUnselected.svg"
            }
            alt="services"
          />
          <Typography
            className={
              window.location.pathname === "/service"
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            {"Services"}
          </Typography>
        </center>
      </Grid>
      <Grid
        item
        xs={2}
        className={classes.tabStyle}
        onClick={props.onTab4Clicked}
      >
        <center>
          <img
            src={
              window.location.pathname === "/requestscreen"
                ? "/images/requestSelected.svg"
                : "/images/requestUnselected.svg"
            }
            alt="request"
          />
          <Typography
            className={
              window.location.pathname === "/requestscreen"
                ? classes.selectedLabelTextStyle
                : classes.unselectedLabelTextStyle
            }
          >
            {"Request"}
          </Typography>
        </center>
      </Grid>
      {/* <Grid item xs={2}
        className={classes.tabStyle}
        onClick={props.onTab5Clicked} >
        <center>
          <img
            src={
              window.location.pathname === "/profileView"
                ? "/images/selected-profile.svg"
                : "/images/unselected-profile.svg"
            }
            alt="profile"
          />
          <Typography className={
            window.location.pathname === "/profileView"
              ? classes.selectedLabelTextStyle
              : classes.unselectedLabelTextStyle
          }>
            {"Profile"}
          </Typography>
        </center>
      </Grid> */}
    </Grid>
  );
};
