import * as React from "react"

export const CurrentAgreement = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={45} height={45} {...props}>
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 53216"
          fill="#4e5a6b"
          stroke="#707070"
          d="M0 0h22v22H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 101560" transform="translate(-2.5 -2.5)">
      <rect
        data-name="NoPath - Copy"
        width={44}
        height={44}
        rx={4}
        transform="translate(3 3)"
        fill="#f1f7ff"
        stroke="#e4edff"
      />
      <g data-name="Group 6416" transform="translate(14 14)" clipPath="url(#a)">
        <g data-name="Group 97037">
          <path
            data-name="Path 9297"
            d="M8.234 17.503V13.37L11.818 10l3.912 3.677v3.827Z"
            fill="none"
          />
          <path
            d="M5.48-.045A2.493 2.493 0 0 0 3 2.435v17.084a2.493 2.493 0 0 0 2.48 2.48h12.676a2.493 2.493 0 0 0 2.48-2.48V7.946a.827.827 0 0 0-.242-.584l-.009-.009L13.229.197a.827.827 0 0 0-.585-.242Zm0 1.653h6.338v4.684a2.493 2.493 0 0 0 2.48 2.48h4.684v10.747a.814.814 0 0 1-.827.827H5.48a.814.814 0 0 1-.827-.827V2.435a.814.814 0 0 1 .827-.827Zm7.991 1.169 4.342 4.342H14.3a.814.814 0 0 1-.827-.827Zm-1.653 6.547a2.38 2.38 0 0 0-1.47.509l-2.583 2.035a2.379 2.379 0 0 0-.906 1.868v3.08a1.873 1.873 0 0 0 1.86 1.86h6.2a1.873 1.873 0 0 0 1.86-1.86v-3.08a2.375 2.375 0 0 0-.9-1.866l-2.583-2.035a2.377 2.377 0 0 0-1.478-.511Zm0 1.653a.714.714 0 0 1 .447.155l2.583 2.035a.719.719 0 0 1 .274.567v3.08a.2.2 0 0 1-.207.207h-6.2a.2.2 0 0 1-.207-.207v-3.08a.721.721 0 0 1 .276-.568l2.582-2.035a.719.719 0 0 1 .452-.154Zm-.551 2.756a.551.551 0 0 0-.551.551v1.1a.551.551 0 0 0 .551.551h1.1a.551.551 0 0 0 .551-.551v-1.1a.551.551 0 0 0-.551-.551Z"
            fill="#4e5a6b"
          />
        </g>
      </g>
    </g>
  </svg>
)
