import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  shedule: {
    backgroundColor: "transparent",
  },
  sheduleroot: {
    padding: "6px 6px",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    backgroundColor: "white",
    backgroundImage: `url("/images/renewbg.svg")`,
    cursor: "pointer",
    backgroundRepeatX: "no-repeat",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    backgroundPositionX: "100%",
    border: "1px solid #E4E8EE",
    // marginBottom: "16px",
  },
  sheduleroot2: {
    backgroundColor: "white",
    padding: "6px 6px",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    backgroundImage: `url("/images/Mask Group violate 40.svg")`,
    // marginBottom: "16px",
    backgroundRepeatX: "no-repeat",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    backgroundPositionX: "100%",
    border: "1px solid #E4E8EE",
  },
  arrow: {
    fontSize: "20px",
    color: "black",
  },
  inprogress: {
    backgroundColor: theme.palette.success.main,
    padding: "0px 12px",
    fontSize: "12px",
    borderRadius: "3px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
}));
const RequsetCard = (props) => {
  const classes = useStyles();
  const d = new Date();
  let day = d.getDate();
  //let month = d.getMonth();
  // var year = d.getFullYear();
  let leaseday = props?.agreementDatas?.lease_end_date?.slice(8, 10);
  // let leasemonth = props?.agreementDatas?.lease_end_date?.slice(6, 7);
  // let leaseyear = props?.agreementDatas?.lease_end_date?.slice(0, 4);
  return (
    <div style={{ marginTop: "18px" }}>
      {props?.status === "Termination" && (
        <Box
          display="flex"
          alignItems="center"
          className={classes.sheduleroot2}
        >
          <Box>
            <Avatar className={classes.shedule}>
              <img
                src="/images/leavehouse.svg"
                alt="cal"
                height="20px"
              />
            </Avatar>
          </Box>
          <Box flexGrow={1}>
            <Typography
              whiteSpace="nowrap"
              className={classes.experies}
            >
              {props.t("tentative_termination_on")}&nbsp;
              <b>
                {moment(
                  props?.agreementData?.tentative_termination_date
                )
                  .tz(moment.tz.guess())
                  .format("DD MMM YY")}
              </b>
            </Typography>
          </Box>
        </Box>
      )}
      {props?.status !== "Termination" && (
        <>

          <Grid item xs={12} sm={12}>
            <>
              <Box
                display="flex"
                alignItems="center"
                className={classes.sheduleroot}
                onClick={props?.status !== "Renewal" && props?.openRenwel}
              >
                <Box>
                  <Avatar className={classes.shedule}>
                    <img
                      src="/images/icons8-schedule black.svg"
                      alt="cal"
                      height="20px"
                    />
                  </Avatar>
                </Box>
                <Box flexGrow={1}>
                  <Typography
                    whiteSpace="nowrap"
                    className={classes.experies}
                  >
                    {props?.status ===
                      "Renewal" ? (
                      `${props.t("renewal_request_in")}`
                    ) : (
                      <>
                        &nbsp;{" "}
                        {leaseday - parseInt(day) < 31 &&
                          leaseday - parseInt(day) !== 0 &&
                          leaseday - parseInt(day) > 0 ? (
                          <>
                            {props.t("expire_in")}&nbsp;
                            <b>
                              {" "}
                              {leaseday - parseInt(day)}{" "}
                              {props.t("days")}
                            </b>
                          </>
                        ) : (
                          ""
                        )}
                        {leaseday - parseInt(day) === 0 && (
                          <>
                            <b>{props.t("expire_today")}</b>
                          </>
                        )}
                        {leaseday - parseInt(day) < 0 && (
                          <>
                            &nbsp;{" "}
                            {leaseday - parseInt(day) < 31 &&
                              leaseday - parseInt(day) !== 0 &&
                              leaseday - parseInt(day) > 0 ? (
                              <>
                                <b style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {props.t("expire_in")}&nbsp;
                                  {leaseday - parseInt(day)}{" "}
                                  {props.t("day")}
                                </b>
                              </>
                            ) : (
                              ""
                            )}
                            {leaseday - parseInt(day) === 0 && (
                              <>
                                <b>{props.t("expire_today")}</b>
                              </>
                            )}
                            {leaseday - parseInt(day) < 0 && (
                              <>
                                <b>
                                  {" "}
                                  {props.t("agreement")}{" "}
                                  {props.t("expired")}
                                </b>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Typography>
                </Box>

                {props?.status === "Renewal" ? (
                  " "
                ) : (
                  <Box>
                    <Typography className={classes.experies}>
                      {props.t("renew")}
                    </Typography>
                  </Box>
                )}

                <Box>
                  {props?.status ===
                    "Renewal" ? (
                    <Typography className={classes.inprogress}>
                      {props.t("inprogress")}
                    </Typography>
                  ) : (
                    <IconButton size="small">
                      <ArrowForwardIosIcon
                        className={classes.arrow}
                      />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </>
          </Grid>

        </>
      )}


    </div>
  )
}
export default withNamespaces("agreement")(RequsetCard);