import { Box, Button, Dialog, Drawer, Hidden, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";

export const QuickLinkCard = (props) => {

    // Constants
    const authContext=props?.authContext
    const { data = {} } = props
    const classes = useStyles({ language: authContext?.auth?.auth?.language })

    // useState
    const [isNotAvailOpen, setIsNotAvailOpen] = React.useState(false)

    return <div className={classes.root}>
        <div style={{display:"flex"}}
            onClick={() => setIsNotAvailOpen(true)}>
            <div className={classes.quickCard}>
            <center>
                <img src={data?.icon} alt="" />
                <div className={classes.titleDiv}>
                    <Typography className={classes.titleText}>{data?.title[0]}</Typography>
                    <Typography className={classes.titleText}>{data?.title[1]}</Typography>
                </div>
            </center>
            </div>
        </div>
        <Hidden smDown>
            <Dialog
                fullWidth={true} maxWidth="sm"
                open={isNotAvailOpen} onClose={() => setIsNotAvailOpen(false)}
                className={classes.dialog}>
                <Box p={1.5}>
                    <center>
                        <img src="/images/noavailabel.svg" alt="" />
                    </center>
                    <Button
                        onClick={() => setIsNotAvailOpen(false)}
                        variant="contained" fullWidth
                        className={classes.submitBtn}>
                        Close
                    </Button>
                </Box>
            </Dialog>
        </Hidden>
        {/* Renewl Form Drawer*/}
        <Hidden smUp>
            <Drawer
                className={classes.drawer}
                anchor="bottom" open={isNotAvailOpen}
                onClose={() => setIsNotAvailOpen(false)}>
                <Box p={1.5}>
                    <center>
                        <img src="/images/noavailabel.svg" alt="" />
                    </center>
                    <Button
                        variant="contained" fullWidth
                        onClick={() => setIsNotAvailOpen(false)}
                        className={classes.submitBtn}>
                        Close
                    </Button>
                </Box>
            </Drawer>
        </Hidden>
    </div>
}