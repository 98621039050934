import {
  createTheme,
  responsiveFontSizes, StyledEngineProvider
} from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import React from "react";
import { AuthContext, ThemeContext } from "./contexts";
import { RTLLanguages, Themes } from "./utils";

const AppTheme = (props) => {
  const auth=React.useContext(AuthContext)
  let [theme, setTheme] = React.useState({
    name: Themes.default,
    language:auth?.auth?.auth?.language ?? ""
    
  });
  React.useEffect(() => {
    if (auth?.auth?.auth?.language) {
      setTheme({
        ...theme,
        language: auth?.auth?.auth?.language ?? ""
      })
    }
    // eslint-disable-next-line
  }, [auth])
  const giveMeTheme = () => {
    let currentThemeJson;

    //Geting the theme json for  the selected theme
    switch (theme.name) {
      case Themes.default:
        currentThemeJson = require("./themes/default.json");
        break;

      case Themes.dark:
        currentThemeJson = require("./themes/dark.json");
        break;

      default:
        currentThemeJson = require("./themes/default.json");
        break;
    }

    //Creating a Theme with json
    let currentTheme = createTheme(currentThemeJson);

    //Making theme work with responsive font size
    currentTheme = responsiveFontSizes(currentTheme);

    return currentTheme;
  };

  return (
    <ThemeContext.Provider value={{ ...theme, setTheme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={giveMeTheme()}>
          <div dir={RTLLanguages.includes(theme?.language) ? "rtl" : "ltr"}>
          {props.children}
          </div>
          
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};

export default AppTheme;
