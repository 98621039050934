import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { useStyles } from "../style";

export const Step4 = (props) => {
  const classes = useStyles();

  const { request, uploadedImages, t } = props;

  return (
    <div>
      <Grid container className={classes.screenPadding} spacing={1}>
        <Grid item xs={12}>
          <div style={{ marginTop: "10px" }}>
            <span className={classes.requsettop}>
              &nbsp;{request?.Category?.label ?? "-"}&nbsp;
            </span>{" "}
            <span className={classes.requsettop}>
              &nbsp;{request?.subCategory?.label ?? "-"}&nbsp;
            </span>
          </div>
          <Typography className={classes.requsettitle}>
            {request?.Title}
          </Typography>
          <Typography className={classes.requsetsub}>
            {request?.Description}
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="10px"
            marginBottom="14px"
          >
            <Box>
              <Typography className={classes.requsetsubs}>
                {t("Since")}
              </Typography>
              <Typography className={classes.requsetsubs2}>
                {" "}
                <img src="/images/icons8_calendar.svg" alt="img" />
                &nbsp;&nbsp;
                <span>
                  {moment(request?.problemSince)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY")}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.requsetsubs}>
                {t("unit")}
              </Typography>
              <Typography className={classes.requsetsubs2}>
                {" "}
                <img src="/images/build.svg" alt="img" />
                &nbsp;&nbsp;
                <span>{request?.Unit?.label}</span>
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.requsetsubs}>
                {t("Raised_On")}
              </Typography>
              <Typography className={classes.requsetsubs2}>
                {" "}
                <img src="/images/icons8_calendar.svg" alt="img" />
                &nbsp;&nbsp;
                <span>
                  {moment(Date()).tz(moment.tz.guess()).format("DD MMM YY")}
                </span>
              </Typography>
            </Box>
          </Box>
          <Divider />
          {uploadedImages?.length > 0 && (
            <>
              <Grid
                container
                style={{ marginBottom: "10px", marginTop: "10px" }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ marginBottom: "8px", marginTop: "8px" }}
                >
                  <Typography className={classes.requsetsubs}>
                    {t("images")}
                  </Typography>
                </Grid>
                {uploadedImages.map((val) => {
                  return (
                    <Grid item xs={3}>
                      <img
                        height={75}
                        width={75}
                        style={{ borderRadius: "10px" }}
                        src={val?.url}
                        alt="img"
                      />
                    </Grid>
                  );
                })}
              </Grid>

              <Divider />
            </>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="10px"
            marginBottom="14px"
          >
            <Box>
              <Typography className={classes.requsetsubs}>
                {t("Visit")}
              </Typography>
              <Typography className={classes.requsetsubs2}>
                {" "}
                <img src="/images/icons8_calendar.svg" alt="img" />
                &nbsp;&nbsp;
                <span>
                  {moment(request?.visitorDate)
                    .tz(moment.tz.guess())
                    .format("DD MMM YYYY hh:mm:ss A")}
                </span>
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop="10px"
            marginBottom="14px"
          >
            <Box>
              <Typography className={classes.requsetsubs}>
                {t("contactDetail")}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            marginTop="10px"
            marginBottom="14px"
          >
            <Box>
              <Avatar className={classes.avatar}>
                <img src="/images/icons8-call.svg" alt="phone" />
              </Avatar>
            </Box>
            <Box flexGrow={1} marginLeft="8px">
              <Typography className={classes.requsetsubs}>
                {request?.name}
              </Typography>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Typography
                  className={classes.requsetsubs2}
                  style={{ paddingRight: "8px" }}
                >
                  {request?.primaryMobileNumber?.mobile_no +
                    " " +
                    request?.primaryMobileNumber?.mobile}
                </Typography>
                <Typography
                  className={classes.requsetsubs2}
                  style={{ paddingLeft: "8px" }}
                >
                  {request?.alternative?.mobile_no +
                    " " +
                    request?.alternative?.mobile}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Divider />
          <br />
        </Grid>
        <br />
        <br />
        <br />
        <br />
      </Grid>
    </div>
  );
};
