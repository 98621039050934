import { useApolloClient } from "@apollo/client";
import { Button, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory } from "react-router-dom";
import { TextBox, TitleBar } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_PROPERTY_BY_UNITID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
}));
const InitialState = {
  title: "",
  category: "",
  description: "",
  error: {
    title: "",
    category: "",
    description: "",
  },
};
export const CreateDiscussion = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const size = useWindowDimensions();
  const [userId, setUserId] = React.useState("");
  const [createData, setCreateData] = React.useState({ ...InitialState });
  const [disable, setDisable] = React.useState(null);

  const [loading1, setLoading1] = React.useState(null);
  const client = useApolloClient();
  const [getunit, setgetUnit] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const updateState = (key, value) => {
    let error = createData.error;
    error[key] = "";
    setCreateData({ ...createData, [key]: value, error });
  };

  React.useState(() => {
    if (authToken !== null && authToken !== "") {
      const temp_decoded = jwt_decode(authToken);
      const userId = temp_decoded.id;
      const data = {
        tenantId: `${config.tenantid}`,
        userId: userId,
      };
      NetworkCall(
        `${config.api_url}/dashboard`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setUserId(response?.data?.data?.userProfileId);
          setgetUnit(
            response.data?.data?.units?.result.map((val) => val?.unit_name)
          );
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    } else {
    }
  }, []);

  const isIamValide = () => {
    let isValid = true;
    let error = createData.error;
    //Title name
    if (createData.title.length === 0) {
      isValid = false;
      error.title = "Title is Required";
    }
    //description name
    if (createData.description.length === 0) {
      isValid = false;
      error.description = "Description is Required";
    }
    //category name
    if (createData.category.length === 0) {
      isValid = false;
      error.category = "Category is Required";
    }

    setCreateData({ ...createData, error });

    return isValid;
  };
  const goBack = () => {
    history.goBack();
  };
  const submit = () => {
    if (isIamValide()) {
      setDisable(true);
      const data = {
        tenantId: `${config.tenantid}`,
        property_id: createData?.category?.property?.id,
        unit_id: createData?.category?.value,
        user_profile_id: userId,
        title: createData?.title,
        description: createData?.description,
      };
      NetworkCall(
        `${config.api_url}/community/create`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Discussion Created Successfully",
          });
          history.push(Routes.communityDiscussion);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
          });
          setDisable(false);
        });
    } else {
    }
  };

  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "unit":
        if (!getunit?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GET_PROPERTY_BY_UNITID(),
          variables: {
            unit_id: getunit,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.unit],
          hasMore:
            array?.length + result?.data?.unit?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };

  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        <TitleBar
          text="Create Community Discussion"

          goBack={goBack}
        />

        <div style={{ height: size.height - 135, padding: "12px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectBox
                isRequired
                value={createData.category}
                label="Select Unit"
                placeholder="Select Unit"
                onChange={(value) => updateState("category", value)}
                isError={createData?.error?.category?.length > 0}
                errorMessage={createData?.error?.category}
                isPaginate
                loadOptions={(search, array) =>
                  loadOptions(search, array, "unit")
                }
                loading={loading1 === "unit"}
                debounceTimeout={800}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBox
                color
                isRequired
                label="Title"
                value={createData.title}
                placeholder="Enter Title"
                onChange={(value) => updateState("title", value.target.value)}
                isError={createData?.error?.title?.length > 0}
                errorMessage={createData?.error?.title}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBox
                color
                isRequired
                multiline
                value={createData.description}
                label="Description"
                placeholder="Enter Description"
                onChange={(value) =>
                  updateState("description", value.target.value)
                }
                isError={createData?.error?.description?.length > 0}
                errorMessage={createData?.error?.description}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container className={classes.btncontainer} spacing={1}>
          <Grid item xs={6}>
            <Button
              variant="contain"
              fullWidth={true}
              className={classes.cancelbtn}
              onClick={() => history.push(Routes.communityDiscussion)}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              fullWidth={true}
              className={classes.applybtn}
              onClick={submit}
              disabled={disable}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
