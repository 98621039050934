import makeStyles from "@mui/styles/makeStyles";

/** -------------------------Company select styles ----------------------------------------------- */
export const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#7EA1FF",
    height: "213px",
    backgroundImage: `url("/images/bgNew.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%",
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    color: "#FFFFFF",
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  img: {
    height: "8%",
    width: "28%",
  },
  contentBottom: {
    padding: "12px",
  },

  companyName: {
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    fontSize: "14px",
    marginLeft: "12px",
  },
  companySelect: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  comapnyImage: {
    width: 50,
    height: 50,
    borderRadius: 0,
  },
  selectCompany: {
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    margin: "8px 0px",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  selectCompanyBorder: {
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    margin: "8px 0px",
    borderRadius: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
  },
  goBackStyle: {
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
    color: theme?.palette?.primary?.main,
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    marginTop: "14px",
  },
  selectMain: {
    padding: "16px",
  },
  paddingCompany: {
    padding: "16px 16px 25px 16px",
  },
}));

/** -------------------------login styles ----------------------------------------------- */
export const loginuseStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage:`url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%",
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    color: "#FFFFFF",
    backgroundColor: theme?.palette?.primary?.main,
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  img: {
    height: "8%",
    width: "28%",
  },
  forgetPassword: {
    float: "right",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
    color: theme.palette.primary.main,
    marginTop: 7,
    cursor: "pointer",
  },
  forgetPasswordTextStyle: {
    textAlign: "right",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
  },
  dialogStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  version: {
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
    color: "#091B29",
  }
}));
