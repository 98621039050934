import { Badge, Box, Grid, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 0px 9px 0px",
    backgroundColor: "white",
    borderRadius: "16px",
    marginTop: "10px",
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  posted: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
  },
  title: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,

  },
  title1: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#4E5A6B",

  },
  dis: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    cursor: "pointer",
  },
  img: {
    width: "80px",
    height: "80px",
    borderRadius: theme.palette.borderRadius,
  },
  health: {
    fontSize: "10px",
    color: "#98A0AC",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#E4E8EE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    position: "absolute",
    top: "-10px",
    right: "-19px",
    width: "30px",
    height: "30px",
  },
  announsmentnot: {
    backgroundColor: "#E6F9FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80px",
    height: "80px",
    borderRadius: theme.palette.borderRadius,
  },
}));

export const Notification = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  const redirect = (val) => {
    props?.viewNotification(val);
    if (val.content_type === "Announcement") {
      history.push(Routes.announcementDetails + "?Id=" + val?.id);
    }
  };
  return (
    <div>
      <Grid
        container
        justifyContent="center"
        spacing={1}
        className={props.data.active ? classes.roots : classes.root}
        onClick={() => redirect(props?.data)}
      >
        <Grid item xs={4} md={2} sm={2} lg={2}>
          {props?.data.description[0]?.images ? (
            <>
              <img
                src={props?.data.description[0]?.images}
                alt="imgg"
                className={classes.img}
              />
            </>
          ) : (
            <>
              <Box className={classes.announsmentnot}>
                <img src="/images/announsmentnot.svg" alt="announsment" />
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={8} md={10} sm={10} lg={10}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <Box>
              <span variant="subtitle2" noWrap className={classes.health}>
                {props?.data?.category}
              </span>
              <Typography
                className={
                  props?.data?.is_read ? classes.title1 : classes.title
                }
                noWrap
                style={{ marginBottom: "6px" }}
              >
                <Hidden smUp>
                  {props?.data?.title?.length > 15 ? (
                    <>{props?.data?.title.slice(0, 20)}...</>
                  ) : (
                    props?.data?.title
                  )}
                </Hidden>
                <Hidden smDown>
                  {props?.data?.title?.length > 40 ? (
                    <>{props?.data?.title.slice(0, 40)}...</>
                  ) : (
                    props?.data?.title
                  )}
                </Hidden>

              </Typography>
              <Typography className={classes.posted}>
                Posted at{" "}
                {moment(new Date(props?.data?.triggered_at)).fromNow()}
              </Typography>
            </Box>
            {!props?.data?.is_read && (
              <Box className={classes.dot}>
                <Badge color="primary" variant="dot" />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
