import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Profile } from "../../assets";
import { Regular } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",

    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
  },
  Cardcontent: {
    padding: "8px 12px",
    borderBottom: `1px dashed ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "12px",
    color: "#5078E1",
    fontFamily: Regular,
    textTransform: "capitalize",
  },
}));

export const VisiterCard = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.card} onClick={() => props.onClick(props.data)}>
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box>
              {
                props?.data?.visitor_image ?
                  <img
                    src={props?.data?.visitor_image}
                    alt=""
                    style={{ objectFit: "cover", marginTop: "4px", borderRadius: "50%", height: "47px", width: "47px" }}
                  />
                  : <Profile />
              }


            </Box>
            <Box flexGrow={1} marginLeft="10px">
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.name}
              </Typography>
              <Box alignItems="center" display="flex" marginTop="4px">
                {props.data.idtype ||
                  (props.data.identification_no && (
                    <Box display="flex" flexGrow={1}>
                      <Typography variant="subtitle2" className={classes.proof}>
                        {props.data.idtype && props.data.idtype}
                        {props.data.id_type && props.data.id_type}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.proof}
                        noWrap
                      >
                        {props.data.identification_no && (
                          <>
                            &nbsp;
                            {props?.data?.identification_no?.length > 10 ? (
                              <>
                                ({props.data.identification_no.slice(0, 10)}...)
                              </>
                            ) : (
                              props.data.identification_no
                            )}
                          </>
                        )}
                      </Typography>
                    </Box>
                  ))}
                <Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.view}
                    noWrap
                  >
                    View Details
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
