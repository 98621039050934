import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url("/images/payMain.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#FFFFFF",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
    borderRadius: theme.palette.borderRadius,
    // padding: "12px",
    marginTop: "8px",
  },
  cardsub: {
    color: theme.typography.color.primary,
    fontSize: "14px",
    marginTop: "4px",
    fontFamily: props => FontFamilySwitch(props?.language).semiBold,
  },
  cardsub2: {
    color: theme.typography.color.secondary,
    fontSize: "12px",
    marginTop: "2px",
  },
  cardtitle: {
    color: theme.typography.color.primary,
    fontSize: "20px",
    fontFamily: props => FontFamilySwitch(props?.language).extraBold,
  },
  paynow: {
    padding: "4px 8px",
    backgroundColor: "#F15A29",
    color: "white",
    borderRadius: "11px",
    fontSize: "10px",
    fontFamily: props => FontFamilySwitch(props?.language).semiBold,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    // marginTop: "40%",
  },
  invoiceHeader:{
    backgroundColor:"#F6F9FF",
    padding:"12px",
    borderRadius:"4px 4px 0px 0px",
    borderBottom:`1px solid ${theme.palette.border.secondary}`
  },
  detailingSection:{
    padding:"12px"
  }
}));

export const HomepagePayCard = (props) => {
  const authContext=props?.authContext
  const classes = useStyles({ language: authContext?.auth?.auth?.language });
  return (
    <div className={classes.root}>
      <div className={classes.invoiceHeader}>
        <Typography>{props?.t("invoice")}</Typography>
      </div>
    <div className={classes.detailingSection}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography className={classes.cardtitle}>
            {props?.currency_symbol ? (
              <>
                {props?.currency_symbol + " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.Outstanding_amount))}
              </>
            ) : (
              props?.Outstanding_amount
            )}
          </Typography>
          <Typography className={classes.cardsub}>
            {props?.t("outstanding_amount")}
          </Typography>
          <Typography className={classes.cardsub2}>
            {props?.t("outstanding_amount_des")}
          </Typography>
          <Box height="12px" />
          <Typography className={classes.cardtitle}>
            {props?.currency_symbol ? (
              <>
                {props?.currency_symbol + " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.amount))}
              </>
            ) : (
              0
            )}
          </Typography>
          <Typography className={classes.cardsub}>
            {" "}
            {props?.t("overdue_amount")}
          </Typography>
          <Typography className={classes.cardsub2}>
            {props?.t("overdue_amount_des")}
          </Typography>
        </Box>
        <Box>
          <Typography
            className={classes.paynow}
            onClick={() => props?.onClick()}
          >
            &nbsp; {props?.t("pay_now")} &nbsp;
            <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
          </Typography>
        </Box>
      </Box>
    </div>
    </div>
  );
};
export default withNamespaces("invoice")(HomepagePayCard);
