import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            cursor: "pointer",
            boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.1)",
            background: "white",
            borderRadius: "4px",
            textAlign: "center",
            height: "104px"
        },
        countText: {
            color: (props) => props.color,
            fontSize: "24px",
            fontFamily: props => FontFamilySwitch(props?.language).bold,
        },
        titleText: {
            color: theme?.typography?.color?.primary,
            fontFamily: props => FontFamilySwitch(props?.language).semiBold,
            fontSize: "14px",
            textAlign: "center"
        },
        titleDiv: {
            padding: "0px 10px 10px 10px",
        },
        linkBorder: {
            margin: "3px 0px",
            borderRadius: "0px 10px 10px 0px"
        },
        buttonStyle: {
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
            // padding: "12px 16px",
            boxShadow: "0px 4px 16px #5C86CB2E",
            height: "99px",
            cursor: "pointer",
            display: "flex"
        },
        lableStyle: {
            fontSize: "12px",
            fontFamily: props => FontFamilySwitch(props?.language).bold,
            color: "#071741",
        },
        essentialCard:{
            flex:"auto",
            padding:"0px 16px"
        },
        quickCard:{
            flex:"auto",
            padding:"12px 16px"
        },
        submitBtn: {
            borderRadius: "10px",
            padding: "12px 8px",
        },
        adjustimgSize:{
            marginBottom:"-45px"
        },
        drawer: {
            "& .MuiDrawer-paper": {
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
            },
        },
        dialog: {
            "& .MuiDialog-paper": {
                borderRadius: "16px",
                padding: "0px",
            },
        },
    }

});