import React from "react";
import  GeneralRequest  from './generalRequest';
import { withRouter } from 'react-router-dom';

class GeneralRequestParent extends React.Component {

    render() {
        return <GeneralRequest />;
    }
}

export default withRouter(GeneralRequestParent);