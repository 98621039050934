import { Box, Container } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DialogDrawer, DocumentViewer, PayCard, Quotation, QuotationCard, TopNavBars } from "../../components";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./styles";
export const QuotationPaymentScreen = () => {
    const classes = useStyles()
    const history = useHistory()
    const size = useWindowDimensions()
    const [openAsset, setAsset] = React.useState(false)
    const { state } = useLocation()
    const submitPay = () => {
        history.push({
            pathname: Routes.success,
            state: {
                msg: "Payment Successful",
            },
        });
    }
    return (
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
            <TopNavBars note={false} home={false} />
            <Box height={size?.height - 130} className={classes.quotationRoot} overflow="auto">
                <QuotationCard
                    onClick={() => history.goBack()}
                    type="pay"
                    quotationNo={state?.quott?.quotation_no}
                    expiresOn={state?.quott?.quotation_expiry_date}
                />
                <Box height="8px" />
                <Quotation
                    onClickDownload={() => setAsset(true)}
                    data={
                        {
                            symbol: state?.quott?.currency_master?.symbol,
                            title: "Invoice",
                            body: "",
                            body1: "",
                            subBody: "Total amount :",
                            subBody2: Math.round(state?.quott?.total_amount),
                        }
                    }
                />
            </Box>
            <Box className={classes.paycard}>
                <PayCard data={{
                    type: "quotation",
                    currency_symbol: state?.quott?.currency_master?.symbol,
                    amount: Math.round(state?.quott?.total_amount),
                }}
                    handleOnClick={submitPay} />
            </Box>
            {/*asset dialog */}
            <DialogDrawer
                height={"400px"}
                open={openAsset}
                header={"Quotation #" + state?.quott?.quotation_no}
                component={state?.quott?.asset?.length > 0 ? <DocumentViewer url={state?.quott?.asset[0]?.url} /> : "No Date Found"}
                onClose={() => setAsset(false)} />
        </Container>
    )
}