import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory } from "react-router-dom";
import { LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { LocalStorageKeys, NetWorkCallMethods, AlertProps, Regular } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertContext } from "../../contexts";
import { PropertyListCard } from "../../components/propertyListCard";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  roots: {
    margin: "0px 12px",
    padding: "0px 4px",
  },
  image: {
    objectFit: "cover",
    width: "101px",
    height: "113px",
  },
  card: {
    backgroundColor: "white",
    marginTop: "12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    marginLeft: "12px",
  },
  total: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
    marginTop: "12px",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "10px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: Regular,

  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    margin: "10px 0px 0px 12px",
    alignItems: "center"
  },
  noData: {
    color: "#999",
    textAlign: "center",
    marginTop: "20vh",
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    marginLeft: "12px"
  },
}));

export const MyNeighbours = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const size = useWindowDimensions();
  const [datas, setDatas] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const getproperty = (id) => {
    const data = JSON.stringify({
      agreementId: id,
      tenantId: `${config.tenantid}`,
    });
    NetworkCall(
      `${config.api_url}/agreement-property-info/get`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDatas(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
      });
  };
  const getUnitids = () => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded.id,
    };
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        getproperty(
          response.data?.data?.agreements?.result.map((val) => val.id)
        );
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };

  React.useEffect(() => {
    getUnitids();
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text="My Neighbours"

        goBack={() => history.goBack()}
      />
      <div
        style={{
          height: size?.height - 60,
          overflow: "scroll",
        }}
        className={classes.roots}
      >
        <Typography className={classes.total}>
          Properties&nbsp;({datas?.propertyCount > 0 ? datas?.propertyCount : 0}
          )
        </Typography>

        {loading ? (
          <LoderSimmer card count={10} size={80} />
        ) : (
          <>
            <Grid container spacing={1}>
              {datas?.propertyInfo?.length > 0 ? datas?.propertyInfo?.map((val) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <PropertyListCard
                        neighbours
                        data={val}
                        onClick={() =>
                          history.push(
                            Routes.myNeighboursDetails +
                            "?propertyId=" +
                            val?.property_id +
                            "&name=" +
                            val?.property_name
                          )
                        }
                      />
                    </Grid>

                  </>
                );
              }) :
                <Typography className={classes.noData}>
                  {"Data not found"}
                </Typography>}
            </Grid>

          </>
        )}
      </div>
    </Container>
  );
};
