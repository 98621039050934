import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import {
  Badge,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Chart,
  DocumentViewer,
  Filter,
  Paymenthistory
} from "../../components";
import { InvoiceSimmer } from "../../components/simmer/invoiceSimmer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  GET_PAYMENT_RECEIPT_BY_RECEIPT_ID as queryOne,
  PAYMENTFILTER as queryTwo
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F5F7FA",
    overflow: "hidden",
  },
  screen: {
    borderRadius: "16px",
    // border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary,
  },
  topNavBarStyle: {
    // padding: "0px 16px 0px 16px",
  },
  tabs: {
    padding: "0px 16px 0px 16px",
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  arrrow: {
    padding: "12px",
    backgroundColor: "white",
  },
  viewChart: {
    padding: "4px 8px",

    color: "#5078E1",

    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
      width: "-webkit-fill-available",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "-webkit-fill-available",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    // padding: "16px",
  },
  drawerBodyChildStyle: {
    padding: "16px",
  },
  pdfDrawerBodyStyle: {
    background: theme.palette.background.tertiary,
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    height: "400px",
    width: "inherit",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  pdfImageStyle: {
    width: "inherit",
  },
  drawerFooterStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  paymentHistoryContainerStyle: {
    padding: "16px",
    background: "white",
    borderRadius: "16px 16px 0px 0px",
    // border: "1px solid #E4E8EE",
    // boxShadow: "0px -7px 20px #0717410D",
    width: "-webkit-fill-available",
  },
  yearFilterbuttonStyle: {
    backgroundColor: "#ffffff",
    cursor: "pointer",
    marginTop: "16px",
    marginRight: "16px",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    border: "1px solid #E4E8EE",
    borderRadius: "18px",
    boxShadow: "none",
  },
  yearFilterTextStyle: {
    color: "#071741",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
  },
  paymentHistoryCard: {
    padding: "16px",
    width: "100%",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid #FFFFFF",
    backgroundColor: "#E4EDFF",
    // backgroundRepeatX: "no-repeat",
    // backgroundSize: "100% 100%",
    // backgroundImage: `url("/images/Mask_Group_18.png")`,
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  viewCharticonButtonStyle: {
    background: "white",
    height: "24px",
    width: "24px",
  },
  profileCardStyle: {
    padding: "24px 16px 16px 16px",
    backgroundImage: `url(${"/images/Illustration.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    background: theme.palette.background.tertiary1,
  },
  detailsCardStyle: {
    borderRadius: "8px",
    padding: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
  bottomNavigationBarStyle: {
    borderRadius: "20px 20px 0px 0px",
    position: "fixed",
    width: "-webkit-fill-available",
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    width: "100%",
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  unSelectedFilterDurationTabStyle: {
    padding: "8px 0px",
    borderBottom: "2px solid #F5F5F5",
    cursor: "pointer",
  },
  unSelectedFilterDurationTabTextStyle: {
    fontSize: "13px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.palette.secondary.main,
  },
  selectedFilterDurationTabStyle: {
    padding: "8px 0px",
    borderBottom: "2px solid #071741",
    cursor: "pointer",
  },
  selectedFilterDurationTabTextStyle: {
    fontSize: "13px",
    fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
    color: theme.palette.secondary.main,
  },
  arrow: {
    padding: "8px",
    backgroundColor: "#55BC7E",
    borderRadius: "8px",
  },
  bold: {
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  status: {
    borderRadius: "8px",
    backgroundColor: "#EEF9EE",
    padding: "8px 12px 8px 12px",
    color: "#5AC782",
  },
  status1: {
    borderRadius: "8px",
    backgroundColor: "#FFF4EB",
    padding: "8px 12px 8px 12px",
    color: "#FF9340",
  },

}));

const PaymentHistory = (props) => {
  const classes = useStyles();

  // Top Tabs Funnctions
  const yearFilter = ["Max", "5Y", "3Y", "1Y", "6M", "1M"];
  const [selectedFilterDuration, setSelectedFilterDuration] =
    React.useState("Max");
  const [paymentDate, setPaymentData] = React.useState("");
  const [paymentHistoryData, setPaymentHistoryData] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [paymentReceipt, setPaymentReceipt] = React.useState("");
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [total, setTotal] = React.useState(0);
  const history = useHistory();
  const [chartData, setChartData] = React.useState({
    label: [],
    data: [],
  });
  const [selectedFilter, setSelectedFilter] = React.useState({
    status: [],
  });

  const [isFilterOpen, setIsFilterOpen] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [isTransactionDetailsOpen, setIsTransactionDetailsOpen] =
    React.useState(false);
  const handleOnClickFilterDuration = (filterDuration) => {
    var currentDate = new Date();
    var tempFilterFromDate = new Date(currentDate);
    var filterFromDate = "";
    switch (filterDuration) {
      case "Max":
        tempFilterFromDate = "";
        setPaymentData(tempFilterFromDate);
        break;

      case "5Y":
        tempFilterFromDate.setFullYear(tempFilterFromDate.getFullYear() - 5);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
        setPaymentData(filterFromDate);
        break;

      case "3Y":
        tempFilterFromDate.setFullYear(tempFilterFromDate.getFullYear() - 3);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
        setPaymentData(filterFromDate);
        break;

      case "1Y":
        tempFilterFromDate.setFullYear(tempFilterFromDate.getFullYear() - 1);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
        setPaymentData(filterFromDate);
        break;

      case "6M":
        tempFilterFromDate.setMonth(tempFilterFromDate.getMonth() - 6);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
        setPaymentData(filterFromDate);
        break;

      case "1M":
        tempFilterFromDate.setMonth(tempFilterFromDate.getMonth() - 1);
        filterFromDate = moment(tempFilterFromDate)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD");
        setPaymentData(filterFromDate);
        break;

      default:
        tempFilterFromDate = "";
        setPaymentData(tempFilterFromDate);
        break;
    }
    const params = {
      query: queryTwo(props.id, filterFromDate, selectedFilter.status).loc
        .source.body,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setPaymentHistoryData(response.data.data.payment_history);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
    setSelectedFilterDuration(filterDuration);
  };
  const closeTransactionDetails = () => {
    setIsTransactionDetailsOpen(false);
  };

  const [paymentDetails, setPaymentDetails] = React.useState();
  const openTransactionDetails = (data) => {
    setPaymentDetails(data);
    setIsTransactionDetailsOpen(true);
  };
  const [isPdfOpen, setIsPdfOpen] = React.useState(false);
  const drawerclose = () => {
    setIsFilterOpen(false);
  };
  const draweropen = () => {
    setIsFilterOpen(true);
  };
  const [isChartOpen, setIsChartOpen] = React.useState(false);
  const closePdf = () => {
    setIsPdfOpen(false);
  };
  const openPdf = () => {
    const payload = {
      query: queryOne,
      variables: {
        referenceID: paymentDetails.reciepts[0].id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setPaymentReceipt(response.data.data.assets[0].url);
        setIsPdfOpen(true);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Unable to fetch the receipt for the payment.",
        });
      });
  };

  const filterData = [
    {
      id: "1",
      title: props?.t("status"),
      key: "status",
      filterType: "CHECKBOX",
      values: [
        {
          label: props?.t("success"),
          value: "SUCCESS",
        },
        {
          label: props?.t("failure"),
          value: "FAILURE",
        },
      ],
    },
  ];
  const applyFilter = (data) => {
    if (data.status > 0) {
      setSelectedFilter(data);
      const params = {
        query: queryTwo(props.id, paymentDate, data.status).loc.source.body,
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        params,
        null,
        true,
        false
      )
        .then((response) => {
          setPaymentHistoryData(response.data.data.payment_history);
          setIsFilterOpen(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong.",
          });
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Select Atleast one status",
      });
    }
  };
  const closeChart = () => {
    setIsChartOpen(false);
  };
  const openChart = () => {
    setIsChartOpen(true);
  };

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const params = {
        query: queryTwo(props.id, paymentDate, []).loc.source.body,
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        params,
        null,
        true,
        false
      )
        .then((response) => {
          setPaymentHistoryData(response.data.data.payment_history);

          setSelectedFilter({ status: ["SUCCESS", "FAILURE"] });
          setLoading(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong.",
          });
        });
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [props.id]);
  React.useEffect(() => {
    let text2 = 0;
    let tempLable = [];
    let tempData = [];
    let previousDate = "";
    chartData.label = tempLable;
    chartData.data = tempData;
    setChartData({
      ...chartData,
    });
    for (let i = 0; i < paymentHistoryData.length; i++) {
      text2 = text2 + paymentHistoryData[i].payment_amount;
    }
    for (let i = 0; i < paymentHistoryData.length; i++) {
      let tempDate = moment(paymentHistoryData[i].payment_date)
        .tz(moment.tz.guess())
        .format("DD MMM")
        .toString();
      if (i === 0) {
        tempLable = chartData.label.concat(
          moment(paymentHistoryData[i].payment_date)
            .tz(moment.tz.guess())
            .format("DD MMM")
            .toString()
        );
        tempData = chartData.data.concat(paymentHistoryData[i].payment_amount);
        chartData.label = tempLable;
        chartData.data = tempData;
      } else if (tempDate === previousDate) {
        chartData.data[i - 1] =
          chartData.data[i - 1] + paymentHistoryData[i].payment_amount;
      } else {
        tempLable = chartData.label.concat(
          moment(paymentHistoryData[i].payment_date)
            .tz(moment.tz.guess())
            .format("DD MMM")
            .toString()
        );
        tempData = chartData.data.concat(paymentHistoryData[i].payment_amount);
        chartData.label = tempLable;
        chartData.data = tempData;
        previousDate = tempDate;
      }
      setChartData({
        ...chartData,
      });
    }

    setTotal(text2);
    // eslint-disable-next-line
  }, [paymentHistoryData]);

  const downloadURI = (url, name) => {
    saveAs(url, `Receipt # ${name}`);
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <InvoiceSimmer />
      ) : (
        <Container maxWidth="sm" style={{ padding: "0px" }}>
          <Grid className={classes.screen} item xs={12}>
            <Grid className={classes.screenScrollEffect}>
              <Grid
                container
                direction={"row"}
                style={{ backgroundColor: "white" }}
              >
                {yearFilter.map((item, index) => {
                  return (
                    <>
                      <Grid
                        onClick={() => handleOnClickFilterDuration(item)}
                        className={
                          selectedFilterDuration === item
                            ? classes.selectedFilterDurationTabStyle
                            : classes.unSelectedFilterDurationTabStyle
                        }
                        container
                        xs={2}
                        justifyContent={"center"}
                        alignItems={"center"}
                        alignSelf={"center"}
                        direction={"row"}
                      >
                        <Typography
                          className={
                            selectedFilterDuration === item
                              ? classes.selectedFilterDurationTabTextStyle
                              : classes.unSelectedFilterDurationTabTextStyle
                          }
                        >
                          {item}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              <Grid style={{ padding: "0px 16px" }}>
                <Box height="12px" />
                <Box className={classes.paymentHistoryCard}>
                  <Grid container direction="column">
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Grid item xs={12}>
                        <Typography
                          style={{ fontSize: "12px", color: "#091B29" }}
                          className={classes.bold}
                        >
                          {props?.t("history_des")}
                        </Typography>
                        <Box height="4px" />
                        <Typography
                          style={{
                            fontSize: "22px",
                            color: "#091B29",
                          }}
                          className={classes.bold}
                        >
                          {(paymentHistoryData !== null &&
                            paymentHistoryData.length > 0
                            ? paymentHistoryData?.[0]?.currency_master?.symbol
                            : "") +
                            Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 0,
                            }).format(Math.round(total))}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1}>
                            {!isChartOpen && (
                              <Typography
                                onClick={openChart}
                                className={classes.viewChart}
                              >
                                {props?.t("view_chart")} &nbsp;
                              </Typography>
                            )}
                          </Box>
                          <Box>
                            <IconButton className={classes.arrrow} onClick={openChart}>
                              <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Box height={"16px"} />
              <Grid className={classes.paymentHistoryContainerStyle}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  direction={"row"}
                  style={{
                    backgroundColor: "#FFFFFF",
                    position: "sticky",
                    top: "0",
                    width: "100%",
                    paddingTop: "8px",
                  }}
                >
                  <Grid>
                    <Typography
                      style={{
                        fontSize: "16px",
                        color: "#071741",
                        fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                      }}
                    >
                      {isChartOpen
                        ? props?.t("spending_trends")
                        : props?.t("payment_history") +
                        "(" +
                        (paymentHistoryData.length < 10
                          ? "0" + paymentHistoryData.length
                          : paymentHistoryData.length) +
                        ")"}
                    </Typography>
                  </Grid>
                  {!isChartOpen && (
                    <Grid>
                      <Box
                        onClick={draweropen}
                        style={{
                          height: "30px",
                          width: "30px",
                          borderRadius: "4px",
                          border: "1px solid #E4E8EE",
                          textAlign: "center",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        {selectedFilter.status.length > 0 ? (
                          <Badge variant="dot" color="primary">
                            <img src="/images/icons8-funnel.svg" alt="filter" />
                          </Badge>
                        ) : (
                          <img src="/images/icons8-funnel.svg" alt="filter" />
                        )}
                      </Box>
                    </Grid>
                  )}
                </Grid>
                {isChartOpen ? (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Chart chartData={chartData} />
                    <img
                      onClick={closeChart}
                      src="/images/Group_4886.svg"
                      alt="close_chart"
                    />
                  </Grid>
                ) : (
                  paymentHistoryData?.map((val) => {
                    return (
                      <Paymenthistory
                        onClick={openTransactionDetails}
                        data={val}
                      />
                    );
                  })
                )}
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Dialog
              fullWidth={true}
              maxWidth="sm"
              open={isFilterOpen}
              onClose={drawerclose}
              className={classes.dialog}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Filter
                    filterData={filterData}
                    onGoBack={drawerclose}
                    selectedList={filterData[0]}
                    filters={{
                      status: selectedFilter.status,
                    }}
                    onApply={applyFilter}
                    onClose={drawerclose}
                  />
                </Grid>
              </Grid>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.filterDrawer}
              style={{ width: "100px" }}
              open={isFilterOpen}
              onClose={drawerclose}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Filter
                    filterData={filterData}
                    onGoBack={drawerclose}
                    selectedList={filterData[0]}
                    filters={{
                      status: selectedFilter.status,
                    }}
                    onApply={applyFilter}
                    onClose={drawerclose}
                  />
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              fullWidth={true}
              maxWidth="sm"
              open={isTransactionDetailsOpen}
              onClose={closeTransactionDetails}
            >
              <Grid
                container
                direction="column"
                className={classes.dialogStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      {props?.t("transaction_details")}
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeTransactionDetails}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.drawerBodyStyle}>
                  <Grid container direction="column">
                    <Grid
                      className={classes.drawerBodyChildStyle}
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Grid item direction={"column"}>
                        <Typography
                          style={{ fontSize: "14px", color: "#98A0AC" }}
                        >
                          {props?.t("transaction_amount")}
                        </Typography>
                        <Box height="4px" />
                        <Typography
                          style={{
                            fontSize: "20px",
                            color: "#091B29",
                            fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
                          }}
                        >
                          {paymentDetails?.payment_amount &&
                            paymentHistoryData?.[0]?.currency_master?.symbol +
                            Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 0,
                            }).format(
                              Math.round(paymentDetails?.payment_amount)
                            )}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Box
                          className={paymentDetails?.payment_response === "SUCCESS" ? classes.status : classes.status1}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
                            }}
                          >
                            {paymentDetails?.payment_response}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      style={{
                        height: "4px",
                        width: "100%",
                        backgroundColor: "#F2F4F7",
                      }}
                    />
                    <Grid
                      className={classes.drawerBodyChildStyle}
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Grid container direction={"column"}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            color: "#091B29",
                            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                          }}
                        >
                          {props?.t("transaction_details")}
                        </Typography>
                        <Box height="8px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("ref_no")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {paymentDetails?.transaction_reference_no}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="16px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("pay_mode")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {paymentDetails?.payment_mode}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="16px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("payment_through")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {paymentDetails?.origin}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="16px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("pay_against")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              1 {props?.t("invoice")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {paymentDetails?.reciepts.length > 0 ? (
                      <>
                        <Box
                          style={{
                            height: "4px",
                            width: "100%",
                            backgroundColor: "#F2F4F7",
                          }}
                        />
                        <Grid
                          className={classes.drawerBodyChildStyle}
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid item direction={"column"}>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("payment_recipt")}
                            </Typography>
                            <Box height="4px" />
                            {paymentDetails?.reciepts.map((item) => {
                              return (
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    color: "#091B29",
                                    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                                  }}
                                >
                                  {item.reciepts_no}
                                </Typography>
                              );
                            })}
                          </Grid>
                          <Grid onClick={openPdf}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                color: "#5078E1",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {props?.t("view_download")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={isTransactionDetailsOpen}
              onClose={closeTransactionDetails}
            >
              <Grid
                container
                direction="column"
                className={classes.dialogStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      {props?.t("transaction_details")}
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeTransactionDetails}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.drawerBodyStyle}>
                  <Grid container direction="column">
                    <Grid
                      className={classes.drawerBodyChildStyle}
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Grid item direction={"column"}>
                        <Typography
                          style={{ fontSize: "14px", color: "#98A0AC" }}
                        >
                          {props?.t("transaction_amount")}
                        </Typography>
                        <Box height="4px" />
                        <Typography
                          style={{
                            fontSize: "20px",
                            color: "#091B29",
                            fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
                          }}
                        >
                          {paymentDetails?.payment_amount &&
                            paymentHistoryData?.[0]?.currency_master?.symbol +
                            Intl.NumberFormat("en-IN", {
                              minimumFractionDigits: 0,
                            }).format(
                              Math.round(paymentDetails?.payment_amount)
                            )}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Box
                          className={paymentDetails?.payment_response === "SUCCESS" ? classes.status : classes.status1}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
                            }}

                          >
                            {paymentDetails?.payment_response}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box
                      style={{
                        height: "4px",
                        width: "100%",
                        backgroundColor: "#F2F4F7",
                      }}
                    />
                    <Grid
                      className={classes.drawerBodyChildStyle}
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Grid container direction={"column"}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            color: "#091B29",
                            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                          }}
                        >
                          {props?.t("transaction_details")}
                        </Typography>
                        <Box height="8px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("ref_no")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {paymentDetails?.transaction_reference_no}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="16px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("pay_mode")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {paymentDetails?.payment_mode}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="16px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("payment_through")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {paymentDetails?.origin}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box height="16px" />
                        <Grid
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("pay_against")}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                fontSize: "12px",
                                color: "#4E5A6B",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              1 {props?.t("invoice")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {paymentDetails?.reciepts.length > 0 ? (
                      <>
                        <Box
                          style={{
                            height: "4px",
                            width: "100%",
                            backgroundColor: "#F2F4F7",
                          }}
                        />
                        <Grid
                          className={classes.drawerBodyChildStyle}
                          container
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          direction={"row"}
                        >
                          <Grid item direction={"column"}>
                            <Typography
                              style={{ fontSize: "12px", color: "#4E5A6B" }}
                            >
                              {props?.t("payment_recipt")}
                            </Typography>
                            <Box height="4px" />
                            {paymentDetails?.reciepts.map((item) => {
                              return (
                                <Typography
                                  style={{
                                    fontSize: "12px",
                                    color: "#091B29",
                                    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                                  }}
                                >
                                  {item.reciepts_no}
                                </Typography>
                              );
                            })}
                          </Grid>
                          <Grid onClick={openPdf}>
                            <Typography
                              style={{
                                fontSize: "14px",
                                color: "#5078E1",
                                fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                              }}
                            >
                              {props?.t("view_download")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              maxWidth="sm"
              fullWidth={true}
              open={isPdfOpen}
              onClose={() => setIsPdfOpen(!isPdfOpen)}
            >
              <Grid
                container
                direction="column"
                className={classes.dialogStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      {props?.t("receipt")}
                    </Typography>
                    <Box height="3px" />
                    <Grid container alignItems="center">
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                        }}
                      >
                        {paymentDetails?.transaction_reference_no}
                      </Typography>
                      <Box className={classes.periodStyle} />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        {props?.t("receiptId") +
                          paymentDetails?.reciepts?.[0]?.reciepts_no}
                      </Typography>
                      <Box className={classes.periodStyle} />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        {moment(paymentDetails?.payment_date)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY")}
                      </Typography>
                      <Box className={classes.periodStyle} />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        {paymentDetails?.payment_mode}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closePdf}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.pdfDrawerBodyStyle}>
                  <DocumentViewer url={paymentReceipt} />
                </Grid>
                <Grid className={classes.drawerFooterStyle}>
                  <Box height={"6px"} />
                  <Button
                    fullWidth
                    className={classes.drawerButtonStyle}
                    variant="contained"
                    onClick={() => {
                      downloadURI(
                        paymentReceipt,
                        paymentDetails?.reciepts?.[0]?.reciepts_no
                      );
                    }}
                  >
                    {props?.t("download")}
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={isPdfOpen}
              onClose={() => setIsPdfOpen(!isPdfOpen)}
            >
              <Grid
                container
                direction="column"
                className={classes.bottomSheetStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      {props?.t("receipt")}
                    </Typography>
                    <Box height="3px" />
                    <Grid container alignItems="center">
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                        }}
                      >
                        {paymentDetails?.transaction_reference_no}
                      </Typography>
                      <Box className={classes.periodStyle} />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        {props?.t("receiptId") +
                          paymentDetails?.reciepts?.[0]?.reciepts_no}
                      </Typography>
                      <Box className={classes.periodStyle} />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        {moment(paymentDetails?.payment_date)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY")}
                      </Typography>
                      <Box className={classes.periodStyle} />
                      <Typography
                        style={{
                          fontSize: "12px",
                          color: "#4E5A6B",
                          textAlign: "left",
                          textTransform: "capitalize",
                        }}
                      >
                        {paymentDetails?.payment_mode}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closePdf}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid className={classes.pdfDrawerBodyStyle}>
                  <DocumentViewer url={paymentReceipt} />
                </Grid>
                <Grid className={classes.drawerFooterStyle}>
                  <Box height={"6px"} />
                  <Button
                    fullWidth
                    className={classes.drawerButtonStyle}
                    variant="contained"
                    onClick={() => {
                      downloadURI(
                        paymentReceipt,
                        paymentDetails?.reciepts?.[0]?.reciepts_no
                      );
                    }}
                  >
                    {props?.t("download")}
                  </Button>
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>
          <Hidden smDown>
            <Dialog
              className={classes.dialog}
              fullWidth={true}
              maxWidth="sm"
              open={false}
              onClose={closeChart}
            >
              <Grid
                container
                direction="column"
                className={classes.dialogStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Spending Trends
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeChart}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid width="100%" className={classes.drawerBodyStyle}>
                  <Chart />
                </Grid>
              </Grid>
            </Dialog>
          </Hidden>
          <Hidden smUp>
            <Drawer
              className={classes.drawer}
              anchor="bottom"
              open={false}
              onClose={closeChart}
            >
              <Grid
                container
                direction="column"
                className={classes.bottomSheetStyle}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.drawerHeaderStyle}
                >
                  <Grid>
                    <Typography className={classes.drawerHeaderTextStyle}>
                      Spending Trends
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      style={{
                        padding: "0px",
                      }}
                      onClick={closeChart}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <Grid width="100%" className={classes.drawerBodyStyle}>
                  <Chart />
                </Grid>
              </Grid>
            </Drawer>
          </Hidden>
        </Container>
      )}
    </div>
  );
};
export default withNamespaces("invoice")(PaymentHistory);
