import React from "react";
import { useLocation } from "react-router-dom";
import { withBottombar } from "../../HOCs";
import RequestHomeScreen from "./requestHome";
import { RequestFromAgreement } from "./requsetFromAgreement";
const RequestHomeScreenParent = () => {
  const search = useLocation().search;
  const requestId = new URLSearchParams(search).get("Id");
  return requestId ? <RequestFromAgreement /> : <RequestHomeScreen />;
};

export default withBottombar(RequestHomeScreenParent);
