import { useApolloClient } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import {
  Backdrop,
  CircularProgress,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Tab,
  Tabs
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ContactList, Filter, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  // GET_PROPERTY_BY_UNITID_VIEW,
  GET_AGREEMENT_UNITS, GET_CONTACT_DROPDOWN_VIEW
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  tabroot: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "0px",
    minHeight: "auto",
    borderRadius: "20px",
    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "20px",
    },
    minWidth: "auto",
    border: "1px solid #E4E8EE",
    marginRight: "8px",
    backgroundColor: "white",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "20px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },

  title: {
    border: "1px solid /serviceE4E8EE",
    padding: "8px 14px",
    borderRadius: "20px",
    background: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "20px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  divider: {
    border: "2px solid #F5F7FA",
    marginTop: "18px",
  },
  Label: {
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "14px",
  },
  field: {
    marginTop: "10px",
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
    marginTop: "16px",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "10px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "40px",
      left: "65%",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    width: "100%",
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
}));

export const ContactDetails = (props) => {
  const client = useApolloClient();
  const backdrop = React.useContext(BackdropContext);
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  const [myContacts, setMyContacts] = React.useState();
  const [publicContacts, setPublicContacts] = React.useState();
  const [propertyType, setpropertyType] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState([]);
  const { state } = useLocation();
  const action = "create_contact";
  const [value, setValue] = React.useState("1");
  const [drawer, setDrawer] = React.useState(null);
  const [professionId, setProfessionId] = React.useState([]);
  const [professionMaster, setProfessionMaster] = React.useState([]);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);

  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const handleChange = (event, newValue) => {
    setSelectedFilter([]);
    setSelectedFilter(professionMaster.map((val) => val?.value));
    setValue(newValue);
  };

  const goBack = () => {
    history.push(state ? Routes.dashboard : Routes.service);
  };

  const filterData = [
    {
      id: "1",
      title: "Profession Type",
      key: "professionType",
      showSearch: true,
      filterType: "CHECKBOX",
      values: professionMaster,
    },
  ];
  const action2 = "view_contact";
  //view details
  const viewDetails = (data) => {
    // setView(true)
    history.push(Routes.contacts + "?action=" + action2 + "&id=" + data.id);
  };

  //dashboardapi
  const dashboardapi = () => {
    if (authToken !== null && authToken !== "") {
      const tempDecoded = jwt_decode(authToken);
      const data = {
        tenantId: `${config.tenantid}`,
        userId: tempDecoded.id,
      };
      NetworkCall(
        `${config.api_url}/dashboard`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          let agreement = response?.data?.data?.agreements?.result?.map((x) => x?.id)
          let data = response?.data.data.units.result;
          const request_units = data.map(({ id }) => id);
          getagreementUnitid(agreement, request_units)
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
  };

  const getagreementUnitid = (v, x) => {
    client.query({
      query: GET_AGREEMENT_UNITS(),
      fetchPolicy: 'network-only',
      variables: {
        agreementId: v,
        unitId: x,
      }
    }).then((res) => {

      const allunits = res?.data?.agreement_units?.map((val, index) => {
        let _d;
        try {
          _d = {
            label: val?.unit?.name,
            value: val?.id,
            unit_id: val?.unit?.id,
            propertyId: val?.unit?.property?.id,

          };
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong.",
          });
        }

        return _d;
      });
      setpropertyType(allunits?.flat());
    }).catch((err) => {

    })
  }


  // get professionid
  const getProfessionid = () => {
    const payload = {
      query: GET_CONTACT_DROPDOWN_VIEW,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        let prof_id = response?.data?.data?.profession_master.map(
          (val) => val.value
        );
        setProfessionId(prof_id);
        setProfessionMaster(response?.data?.data?.profession_master);
        setSelectedFilter(response?.data?.data?.profession_master.map((val) => val?.value));
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  // my contacts
  const getMyContacts = () => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "Loading",
    // });
    const data = {
      tenantId: `${config.tenantid}`,
      agreement_unit_id: propertyType.map((val) => val.value),
      profession_id: professionId,
      offset: 0,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/community/contact/private`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setMyContacts(response?.data?.data);
        setLoading(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  // Public contacts
  const getPublicContacts = () => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "Loading",
    // });
    const data = {
      tenantId: `${config.tenantid}`,
      property_id: propertyType.map((val) => val.propertyId),
      profession_id: professionId,
      offset: 0,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/community/contact/public`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setPublicContacts(response?.data?.data);
        setLoading(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      });
  };

  React.useEffect(() => {
    getProfessionid();
    dashboardapi();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (value === "1" && professionId.length > 0 && propertyType.length > 0) {
      getMyContacts();
    }
    if (value === "2") {
      getPublicContacts();
    }
    // eslint-disable-next-line
  }, [value, professionId, propertyType]);

  //apply filter
  const applyFilter = (val) => {
    if (val?.professionType?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast select one Profession Type",
      });
      return false;
    }
    setSelectedFilter(val?.professionType);
    if (value === "1") {
      const data = {
        tenantId: `${config.tenantid}`,
        unit_id: propertyType.map((val) => val.value),
        profession_id: val?.professionType,
        offset: 0,
        limit: 10,
      };
      NetworkCall(
        `${config.api_url}/community/contact/private`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          drawerclose();
          setMyContacts(response?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    }
    if (value === "2") {
      const data = {
        tenantId: `${config.tenantid}`,
        property_id: propertyType.map((val) => val.propertyId),
        profession_id: val?.professionType,
        offset: 0,
        limit: 10,
      };
      NetworkCall(
        `${config.api_url}/community/contact/public`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          drawerclose();
          setPublicContacts(response?.data?.data);
          setLoading(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    }
  };
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TitleBar text="Contact" goBack={goBack} />
            <div style={{ height: size.height - 60, overflow: 'auto' }}>
              <Grid container>
                <Grid item xs={10.5}>
                  {/* Tabs section */}
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="none"
                    textColor="secondary"
                    className={classes.tabroot}
                    variant="scrollable"
                    visibleScrollbar={false}
                    scrollButtons={false}
                  >
                    <Tab
                      className={
                        value === "1" ? classes.tabItemSelect : classes.tabItem
                      }
                      label={
                        <span
                          className={
                            value === "1" ? classes.titleselect : classes.title
                          }
                        >
                          {value === "1" && (
                            <img src="/images/contactdetails.svg" alt=" " />
                          )}
                          &nbsp;My Contacts
                        </span>
                      }
                      value="1"
                    />
                    <Tab
                      className={
                        value === "2" ? classes.tabItemSelect : classes.tabItem
                      }
                      label={
                        <span
                          className={
                            value === "2" ? classes.titleselect : classes.title
                          }
                        >
                          {value === "2" && (
                            <img
                              src="/images/contactdetails.svg"
                              alt=" "
                              style={{ marginTop: "-4px" }}
                            />
                          )}
                          &nbsp;&nbsp;Public Contacts
                        </span>
                      }
                      value="2"
                    />
                  </Tabs>
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton className={classes.filter} onClick={draweropen}>
                    <img src="/images/icons8-funnel.svg" alt="filter" />
                  </IconButton>
                </Grid>

                {value === "1" && (
                  <>
                    {loading ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : (
                      <Grid item xs={12}>
                        {myContacts?.map((val) => {
                          return (
                            <ContactList
                              data={val}
                              switch={true}
                              onClick={viewDetails}
                              MyContacts={getMyContacts}
                              callonly
                            />
                          );
                        })}
                      </Grid>
                    )}
                  </>
                )}
                {value === "2" && (
                  <>
                    {loading ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : (
                      <Grid item xs={12}>
                        {publicContacts?.map((val) => {
                          return (
                            <ContactList
                              data={val}
                              public
                              onClick={false}
                              call
                            />
                          );
                        })}
                      </Grid>
                    )}
                  </>
                )}{" "}
                <Fab
                  color="primary"
                  className={classes.fab}
                  onClick={() =>
                    history.push(Routes.contacts + "?action=" + action)
                  }
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </div>

            {/* filter drawer */}
            <Hidden smDown>
              <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={drawer}
                onClose={drawerclose}
                className={classes.dialog}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Filter
                      filterData={filterData}
                      onGoBack={drawerclose}
                      selectedList={filterData[0]}
                      filters={{
                        professionType: selectedFilter,
                      }}
                      onApply={applyFilter}
                      onClose={drawerclose}
                    />
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.filterDrawer}
                open={drawer}
                fullWidth={true}
                onClose={drawerclose}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Filter
                      filterData={filterData}
                      onGoBack={drawerclose}
                      selectedList={filterData[0]}
                      filters={{
                        professionType: selectedFilter,
                      }}
                      onApply={applyFilter}
                      onClose={drawerclose}
                    />
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>
          </>
        )}
      </Container>
    </>
  );
};
