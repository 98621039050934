import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
  Label: {
    color: theme.typography.color.Tertiary,
    fontSize: "12px",
    paddingBottom:"8px"
  },
  text: {},

  textbox: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: `1px solid ${theme?.palette?.border?.primary}`,
      "& :hover": {
        border: `1px solid ${theme?.palette?.primary?.main}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "0px" : "11px 14px"),
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
}));

export const TextBox = (props) => {
  const classes = useStyles(props);

  const getLabel = (props) => {
    return (
      <div style={{ display: "flex" }}>
        {
          <Typography
            variant="body1"
            color="textsecondary"
            className={classes.Label}
            align="left"
          >
            {props?.label}
          </Typography>
        }
        {props?.isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <>
        {getLabel(props)}
        <div className={classes.text}>
          <TextField
            onKeyDown={props?.handleKeypress}
            className={classes.textbox}
            type={props.type}
            id={props.id}
            placeholder={props.placeholder}
            variant={props.variant ?? "outlined"}
            fullWidth
            InputLabelProps={{
              shrink: false,
            }}
            style={{
              backgroundColor: props.color ? "white" : "auto",
              borderRadius: "10px",
            }}
            inputProps={{
              readOnly: props?.isReadonly ?? false,
              autoCapitalize: "none",
            }}
            onKeyPress={props?.onKeyPress}
            disabled={props?.disabled ?? false}
            size="small"
            multiline={props.multiline}
            rows={5}
            rowsMax={10}
            onChange={props.onChange}
            value={props.value}
          />
        </div>
      </>
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  type: PropTypes.string,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
};
TextBox.defaultProps = {
  label: "Textbox",
  multiline: false,
  type: "text",
  placeholder: "Type Here...",
};
