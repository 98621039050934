import gql from "graphql-tag";

export const QUOTATIONSTATUS = `
mutation($id: String, $update: quotationInput) {
    quotation(
    update: $update
    where: { id: { eq: $id } }
    ) {
        id
    }
}
`;

// export const yourMutation = gql``;
export const UPDATE = `mutation($userId: String, $update: user_profilesInput) {
    user_profiles(
    update: $update
    where: { id: { eq: $userId } }

    ) {
        id
        user_id
        alternative_mobile_no
        email_id
        first_name
        last_name
        mobile_no
    }
}
`;
export const AGREEMENTREQUSET = `
mutation($insert:[agreement_requestOutput!]) {
 agreement_request(
    insert: $insert
  ) {
    agreement_id
    tentative_termination_date
    remarks
    contact_id
    is_active
    request_purpose
  }
} `;

export const UPDATEADDRESS = `
mutation($contactID: ID, $updatedAddress: contactInput) {
  contact(
    where: { id: { eq: $contactID } }
    update: $updatedAddress

  ) {
    id
    street_1
    street_2
    street_3
    district
    city
    state
    zipcode
    country
    latitude
    longitude
  }
}
`;

export const INSERT_PROFILE_IMAGE_BY_CONTACT_ID = `
mutation($insertPayload: [assetsInput!]) {
  assets(insert: $insertPayload) {
    id
  }
}
`;

export const UPDATE_PROFILE_IMAGE_BY_CONTACT_ID = `
mutation($assetsID: ID, $updatePayload: assetsInput) {
  assets(
    where: {
      reference_id: { eq: $assetsID }
      assets_master_type: { id: { eq: 2 } }
      is_active: { eq: true }
    }
    update: $updatePayload

  ) {
    id
  }
}
`;
export const ADD_COMMENT = `
mutation($demo: [community_discussion_commentsInput!]) {
  community_discussion_comments(insert: $demo) {
    id
  }
}
`;
export const ADD_LIKE = `
mutation($like: [community_discussion_reactions!]) {
  community_discussion_reactions(insert: $like) {
    id
  }
}
`;
export const DELETE_LIKE = `
mutation($likeid:String){
  community_discussion_reactions(where:{id:$likeid}){
    id
  }
}
`;
export const CANCEL_DOMESTIC = `
mutation($Id: ID, $data: domestic_helper_requestOutput) {
  domestic_helper_request(
    where: { id: { eq: $Id } }
    update: $data

  ) {
    id
  }
}`;
export const CLOSE_GENERAL_REQUEST = `
mutation($general_id: String, $updateData: general_statusInput) {
  general_status(
    where: { general_id: { eq: $general_id } }
    update: $updateData

  ) {
    id
    general_status_id
  }
}`;

export let INSERT_GENERAL_MAINTENANCE_REQUEST_STATUS = (requestType) => gql`
mutation INSERT_GENERAL_MAINTENANCE_REQUEST_STATUS(
  ${requestType === "General"
    ? `$insertRequestStatusPayload: [general_statusInput!]`
    : ``
  }
  ${requestType === "Maintenance"
    ? `$insertRequestStatusPayload: [maintenance_statusInput!]`
    : ``
  }
) {
  ${requestType === "General"
    ? `general_status(insert: $insertRequestStatusPayload) {
    id
  }`
    : ``
  }
  ${requestType === "Maintenance"
    ? `maintenance_status(insert: $insertRequestStatusPayload) {
    id
  }`
    : ``
  }
}`;

export const GET_AGREEMENTACESS = `
mutation($data:[agreement_accessInput!]){
  agreement_access(insert:$data){
    id
  }
}
`;
export const updateDeviceToken = `
mutation($insert:[user_device_tokenOutput!]) {
 user_device_token(
    insert: $insert

  ) {
   id
  }
}`;

export const LOG_OUT_TOKEN = `
mutation($id:String,$token:String,$data:user_device_tokenInput) {
user_device_token(
    where: { user_profile_id: { eq: $id },token:{eq:$token} }
    update: $data

  ) {
    id
  }
}

`;
export const UNREADNOTIFICATION = `
mutation($id: String, $updatedData: notification_logInput) {
  notification_log(
    where: { notification_id: { eq: $id } }
    update: $updatedData

  ) {
    id
    is_read
  }
}`;
export const UPDATELIKE = `
mutation($id: String, $updatedData: community_discussion_reactionsInput) {
  community_discussion_reactions(
    where: { id: { eq: $id } }
    update: $updatedData

  ) {
    id
    is_active
  }
}
`;

export const ADD_VEHICLE = () => {
  return gql`mutation ADD_VEHICLE {
    parking_slot(insert: $payload) {
      id
    }
  }`
};

export const EDIT_PARKING_MASTER = () => {
  return gql`mutation EDIT_PARKING_MASTER {
    parking_master(
      where: { id: { eq: $parkingMasterID } }
      update: $payload
    ) {
      id
    }
  }`
};
export const CLOSE_REQUEST = (payload) => gql`
mutation UPDATE_REQUEST_STATUS(
    $updatePayload: ${payload?.inputType}
  ) {
    ${payload?.typename}(
      where: { ${payload?.request_id}: { eq:"${payload?.request}" }  }
      update: $updatePayload
    ) {
      id
    }
  }
`

export const CLOSE_REQUEST_MAINTANCE = (payload) => gql`
mutation {
    ${payload?.typename}(
         insert: $payload
          ) {
      id
    }
  }
`

export const INSERT_UNIT_ASSETS = gql`
mutation INSERT_UNIT_ASSETS($payload: unit_assetsInput) {
  unit_assets(insert: $payload) {
    id
  }
}`;