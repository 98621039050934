import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Badge,
  Hidden,
  Dialog,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import { useStyle } from "./style";
import {
  TopNavBars,
  YourUnitCard,
  Filter,
  UseDebounce,
  LoderSimmer,
} from "../../components";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import {
  AlertProps,
  NetWorkCallMethods,
  enumSelect,
  enumCatagory,
} from "../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../utils/useWindowDimensions";

export const YourUnits = () => {
  const size = useWindowDimensions();
  // classes
  const classes = useStyle();
  const debounce = UseDebounce();
  //   states
  const [search, setSearch] = useState("");
  const [details, setDetails] = useState([]);
  const [offSet, setOffSet] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [enumValue, setEnumValue] = useState({
    unit_catagory: [],
    unit_status_type: [],
  });
  const [loading, setLoading] = useState(true);

  //   history
  const history = useHistory();
  // context
  const alert = useContext(AlertContext);
  // ----------------------------function-----------------------------------------------------------
  const saveGeneralUnits = async (
    offset,
    unitCatagory,
    status,
    filter,
    searchText
  ) => {
    const saveGeneralRequestPayload = {
      offset: offset,
      limit: 10,
      status: status ?? undefined,
      unit_category: unitCatagory ?? undefined,
      search: searchText ?? "",
    };
    await NetworkCall(
      `${config.api_url}/owner/get_all_owner_units`,
      NetWorkCallMethods.post,
      saveGeneralRequestPayload,
      null,
      true,
      false
    )
      .then((res) => {
        if (filter) {
          setDetails(details.concat(res?.data?.data));
        } else {
          setDetails(res?.data?.data ?? []);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };
  // infinity scroll
  const fetchMoreData = () => {
    setOffSet(offSet + 10);
    saveGeneralUnits(
      offSet + 10,
      selectedFilter?.unit_catagory,
      selectedFilter?.unit_status_type,
      true,
      search
    );
  };
  // getEnum
  const getEnum = async (data) => {
    const stayType = await enumSelect([
      enumCatagory?.unit_category,
      enumCatagory?.unit_status_type,
    ]);
    setEnumValue({
      unit_catagory: stayType?.unit_category,
      unit_status_type: stayType?.unit_status_type,
    });
    setSelectedFilter({
      unit_catagory: stayType?.unit_category?.map((x) => x?.value),
      unit_status_type: stayType?.unit_status_type?.map((x) => x?.value),
    });
    saveGeneralUnits(
      0,
      stayType?.unit_category?.map((x) => x?.value),
      stayType?.unit_status_type?.map((x) => x?.value),
      false,
      ""
    );
  };
  // useEffect
  useEffect(() => {
    getEnum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const draweropen = () => {
    setDrawer(!drawer);
  };
  // filter JSON
  const filterData = [
    {
      id: "1",
      title: "Unit Catagoey",
      key: "unit_catagory",
      filterType: "CHECKBOX",
      values: enumValue?.unit_catagory,
    },

    {
      id: "2",
      title: "Status",
      key: "unit_status_type",
      filterType: "CHECKBOX",
      values: enumValue?.unit_status_type,
    },
  ];
  // apply filter
  const applyFilter = (data) => {
    if (data?.unit_catagory?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast select one unit catagory",
      });
      return false;
    }
    saveGeneralUnits(
      0,
      data?.unit_catagory,
      data?.unit_status_type,
      false,
      search
    );
    setOffSet(0);
    setDrawer(false);
  };
  const handleSearch = (value) => {
    setSearch(value);
    debounce(
      () =>
        saveGeneralUnits(
          0,
          selectedFilter?.unit_catagory,
          selectedFilter?.unit_status_type,
          false,
          value
        ),
      800
    );
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      {/* top nav bar */}
      <TopNavBars title="Units" isBack />
      <Box p={2}>
        <Box display="flex" alignItems="center">
          {/* search box */}
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search Unit Number"
            style={{ backgroundColor: "white", borderRadius: "10px" }}
            fullWidth
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            className={classes.search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small">
                    <img src="/images/icons8-search (2).svg" alt="" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <IconButton className={classes.filter} onClick={draweropen}>
            <Badge variant="dot" className={classes.dot} color="warning">
              <img src="/images/icons8-funnel.svg" alt="filter" />
            </Badge>
          </IconButton>
        </Box>

        {loading ? (
          <LoderSimmer count={10} card size={"210px"} />
        ) : (
          <>
            {details?.length > 0 ? (
              <InfiniteScroll
                dataLength={details?.length ?? null}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 190}
                className={classes.scrollBar}
              >
                <Box p={1}>
                  {/* YourUnitCard */}
                  {details?.map((x) => {
                    return (
                      <>
                        <Box height={"12px"} />
                        <YourUnitCard
                          color={
                            x?.status === "Occupied" ? "#F15A29" : "#5AC782"
                          }
                          onClick={(val) =>
                            history.push({
                              pathname: Routes.yourvacantunit,
                              state: val,
                            })
                          }
                          data={{
                            name: x?.name,
                            logo: x?.logo,
                            no: x?.unit_no,
                            property_name: x?.property_name,
                            location: `${x?.area} , ${x?.city}`,
                            status: x?.status,
                            id:x?.id
                          }}
                        />
                      </>
                    );
                  })}
                </Box>
              </InfiniteScroll>
            ) : (
              <Typography className={classes.menuText} textAlign="center">
                No data Found
              </Typography>
            )}
          </>
        )}
      </Box>
      {/* ------------------------------------filter----------------------------------------------- */}
      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={draweropen}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={draweropen}
                selectedList={filterData[0]}
                filters={{
                  unit_catagory: selectedFilter?.unit_catagory,
                  unit_status_type: selectedFilter?.unit_status_type,
                }}
                onApply={applyFilter}
                onClose={draweropen}
                defaultData={selectedFilter}
                request
                setSelectedFilter={setSelectedFilter}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.filterDrawer}
          open={drawer}
          onClose={draweropen}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={draweropen}
                selectedList={filterData[0]}
                filters={{
                  unit_catagory: selectedFilter?.unit_catagory,
                  unit_status_type: selectedFilter?.unit_status_type,
                }}
                onApply={applyFilter}
                onClose={draweropen}
                defaultData={selectedFilter}
                request
                setSelectedFilter={setSelectedFilter}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Container>
  );
};
