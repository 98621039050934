import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { Upload } from "../../components/upload/fileupload";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { multiImageUpload } from "../../utils/common";
import { useStyles } from "./style";

export const ImageUpload = (props) => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const { setState, state, t } = props;

  //   uploading image
  const updateState = async (data) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    try {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      let propsData = {
        companyId: props?.companyId,
        type: props?.id,
        file_meta:true
      };
      const image = await multiImageUpload(data, propsData);
      state?.push(image);
      props?.setState(state?.map((val) => val));
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
    } catch (err) {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some Thing Went Wrong",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  //   multi image Upload
  const handleUpload = async (e) => {
    if (e.target.files) {
      let files = e.target.files;

      const filesArray = Array.from(e.target.files).map((file) => {
        return URL.createObjectURL(file);
      });
      Array?.from(files)?.map((val) => {
        return updateState(val);
      });
      setState((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
  };
  // delete function
  const handleDelete = (data) => {
    setState(state.filter((val) => val !== data));
  };
  //   remove all function
  const onRemoveAll = () => {
    setState([]);
  };
  return (
    <div className={classes.root} noValidate>
      <Grid>
        <Grid item>
          <Upload
            onChange={handleUpload}
            single={props?.single}
            state={state}
          />
        </Grid>

        {state?.length > 0 && (
          <div>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.Container}
              spacing={2}
            >
              <Grid item xs={12}>
                <Box display="flex">
                  <Box flexGrow={1}>
                    <Typography
                      style={{
                        color: "#404E61",
                        fontSize: "16px",
                        fontFamily:
                          "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                      }}
                    >
                      {t("Uploaded")}
                      {state?.length} {t("images")}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      onClick={() => onRemoveAll()}
                      style={{
                        cursor: "pointer",
                        color: "#F15A29",
                        fontSize: "14px",
                        fontFamily:
                          "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                      }}
                    >
                      {t("remove")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {state?.map((item, index) => {
                return (
                  <Grid item xs={3} style={{ position: "relative" }}>
                    <img
                      src={item?.url}
                      alt="upload"
                      style={{
                        width: "100%",
                        height: "75.6px",
                        borderRadius: 10,
                      }}
                    ></img>
                    {!props?.isReadonly && (
                      <IconButton
                        style={{
                          position: "absolute",
                          top: "8px",
                          right: "-8px",
                        }}
                        onClick={() => handleDelete(item)}
                        aria-label="settings"
                        disabled={props?.isReadonly}
                      >
                        <img src="/images/Group 96059.svg" alt="gruop" />
                      </IconButton>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        )}
      </Grid>
    </div>
  );
};
