import { Box, Typography } from "@mui/material";
// import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E8EE",
    padding: '12px',
    cursor: "pointer"
  },
  list2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E8EE",
    cursor: "pointer",
    padding: '12px',

  },
  Tittle: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#071741",
  },
  contant: {
    fontSize: "12px",
    fontFamily: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
    color: "#4E5A6B",
  },
  expires: {
    fontSize: "10px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#4E5A6B",
    backgroundColor: "#F2F4F7",
    padding: "5px",
    marginLeft: "10px",
  },
  lease: {
    fontSize: "12px",
    color: "#4E5A6B",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  Appartment: {
    fontSize: "10px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "#4E5A6B",
  },
  root: {
    marginTop: (props) => props?.agreementList && "12px",
  },
  box: {
    display: "flex", marginTop: "5px"
  }
}));
export const TranslatorModal = (props) => {
  moment.defaultFormat = "YYYY-MM";
  const classes = useStyles(props);
  // const history = useHistory();
  return (
    <div className={classes.root}>
    
        {props?.languageOptions?.map((item) => {
          return (
            <>
              <Box
                className={classes.list2}
                onClick={() => props?.updateLanguage(item)}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* language name */}
                  <Box>
                    <div>
                      <Typography className={classes.Tittle}>
                        {item.label}
                      </Typography>
                    </div>
                  </Box>
                </Box>
                <Box>
                  {item.value ===
                    props?.selectedLanguage?.value && (
                      <img src="/images/ticknew.svg" alt="tick_img" />
                    )}
                </Box>
              </Box>
            </>
          )
        })


      }

    </div>
  );
};
