import { NetWorkCallMethods } from "../utils";
import { NetworkCall } from "../networkcall";
import { config } from "../config";
export const mapResult = (map) => {
      let updateData = {
            latitude: map?.lat,
            longitude: map?.lng
      };
      if (map) {
            let keys = ['doorNo', 'addressLineOne', 'addressLineTwo', 'landmark', 'area', 'district', 'city', 'state', 'country', 'pincode', 'zipcode'];
            let findAddress = map?.result?.address_components;
            keys?.map(_ => {
                  switch (_) {
                        case 'doorNo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["premise"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineOne':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["establishment", "street_number"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineTwo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["neighborhood", "route",]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'landmark':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('landmark'))?.long_name ?? ''
                              break;
                        case 'area':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes("political", "locality"))?.long_name ?? ''
                              break;
                        case 'district':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'city':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'state':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_1'))?.long_name ?? ''
                              break;
                        case 'country':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('country'))?.long_name ?? ''
                              break;
                        case 'pincode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        case 'zipcode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        default:
                              return null
                  }
                  return _
            })
      }

      return updateData
}

// sigle images
export const singleImageUpload = async (data) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("tenantId", `${config.tenantid}`);

      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )

      return image.data.fileUrls[0].url

}

// multiple images
export const multiImageUpload = async (data, { companyId ,file_meta}) => {
      const formData = new FormData();
      formData.append("files", data);
      formData.append("companyId", `${companyId}`);

      let image = await NetworkCall(
            `${config.api_url}/file-upload`,
            NetWorkCallMethods.post,
            formData,
            null,
            true,
            false
      )
      if (!file_meta) {
            return {
              url: image.data.fileUrls[0].url,
            }
          }
          else {
            return {
              url: image.data.fileUrls[0].url,
              name: data?.name?.split(".")?.[0],
              size: formatSizeUnits(data?.size ?? 0),
              type:data?.name?.split(".")?.[1],
            };
          }

};
// access companies
export const companyDetails = JSON.parse(localStorage.getItem('accessType'))


export const enumSelect = async (enumTypes) => {
      const payload = {
            enumName: enumTypes
      }
      let emnum = await NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
      )
      let result = {}
      for (let i = 0; i < enumTypes.length; i++) {
            let state = enumTypes[i];
            let value = emnum?.data?.data?.[state]?.map((x) => {
                  return {
                        value: x,
                        label: x
                  }
            })
            result[state] = value;
      }
      return result
}


export const enumCatagory = {
      notifications_content_type: 'notifications_content_type',
      security_status_enum_type: 'security_status_enum_type',
      security_request_type: 'security_request_type',
      delivery_status_type: 'delivery_status_type',
      general_maintenance_type: 'general_maintenance_type',
      swift_type: 'swift_type',
      unit_category: "unit_category",
      unit_status_type: 'unit_status_type'
}

// get types
export const keyMapSource = ({ enumType }) => {
      return enumType.flatMap((item, index) => {
            return {
                  [`type${index + 1}`]: item?.value
            }
      }).reduce((old, item) => (
            { ...old, ...item }
      ), {})

}
export const FontFamilySwitch = (language) => {
      switch (language) {
            case "en":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "zh":
                  return {
                        regular: "'NotoSansSC-Regular'",
                        bold: "'NotoSansSC-Bold'",
                        semiBold: "'NotoSansSC-SemiBold'",
                        extraBold: "'NotoSansSC-ExtraBold'"
                  }
            case "hi":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "es":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "fr":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "ar":
                  return {
                        regular: "'NotoSansArabic-Regular'",
                        bold: "'NotoSansArabic-Bold'",
                        semiBold: "'NotoSansArabic-SemiBold'",
                        extraBold: "'NotoSansArabic-ExtraBold'"
                  }
            case "ru":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "pt":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "it":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "fil":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "id":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "ta":
                  return {
                        regular: "'NotoSansTamil-Regular'",
                        bold: "'NotoSansTamil-Bold'",
                        semiBold: "'NotoSansTamil-SemiBold'",
                        extraBold: "'NotoSansTamil-ExtraBold'"
                  }
            case "te":
                  return {
                        regular: "'NotoSansTelugu-Regular'",
                        bold: "'NotoSansTelugu-Bold'",
                        semiBold: "'NotoSansTelugu-SemiBold'",
                        extraBold: "'NotoSansTelugu-ExtraBold'"
                  }
            case "kn":
                  return {
                        regular: "'NotoSansKannada-Regular'",
                        bold: "'NotoSansKannada-Bold'",
                        semiBold: "'NotoSansKannada-SemiBold'",
                        extraBold: "'NotoSansKannada-ExtraBold'"
                  }
            case "ml":
                  return {
                        regular: "'NotoSansMalayalam-Regular'",
                        bold: "'NotoSansMalayalam-Bold'",
                        semiBold: "'NotoSansMalayalam-SemiBold'",
                        extraBold: "'NotoSansMalayalam-ExtraBold'"
                  }
            case "de":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            default:
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
      }
}

// get size of image
export function formatSizeUnits(bytes){
      if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
      else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
      else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
      else if (bytes > 1)           { bytes = bytes + " bytes"; }
      else if (bytes === 1)          { bytes = bytes + " byte"; }
      else                          { bytes = "0 bytes"; }
      return bytes;
    }

// const images type
export const assestType = {
      Facility_Images: 1,
      General_Images: 2,
      Videos: 3,
      Documents: 4,
      Three_sixty_Images: 5,
      Thumbnails: 6,
      Floor_Plan: 7,
    };