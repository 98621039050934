import makeStyles from "@mui/styles/makeStyles";
import { Bold} from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme.palette.dashboard.primary,
    height: "100%",
    position:'relative'
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  bottombar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    left: "0px",
    right: "0px",
  },
  fab: {
    textTransform: "capitalize",
    backgroundColor: `${theme?.palette?.primary?.main} !important`,
    color: theme.palette.background.paper,
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "82px",
      right: "6%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "82px",
      right: "17%",
    },
    [theme.breakpoints.up("md")]: {
      position: "fixed",
      bottom: "82px",
      right: "25%",
    },
    [theme.breakpoints.up("lg")]: {
      position: "fixed",
      bottom: "82px",
      right: "32%",
    },
  },
  menuContainer: {},
  menuText: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  menuItem: {
    position: "absolute",
    background: theme.palette.common.white,
    borderRadius: "8px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(5),
    },
    [theme.breakpoints.up("sm")]: {
      left: "60%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  containers: {
    [theme.breakpoints.down("sm")]: {
      "&.MuiContainer-root": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },

      overflow: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      "&.MuiContainer-root": {
        paddingLeft: "12px",
        paddingRight: "12px",
      },

      overflow: "hidden",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: Bold,
  },

  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: Bold,
    cursor: "pointer",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: Bold,
  },

  filterleft: {
    borderRight: `1px solid ${theme?.palette?.border?.tertiary1}`,
    [theme.breakpoints.up("sm")]: {
      minHeight: "68vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "75vh",
    },
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: theme?.palette?.border?.third,
    backgroundColor: theme?.palette?.border?.secondary,
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: theme?.palette?.background?.secondary,
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    backgroundColor: "white",
    borderRadius: "10px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: Bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
  },
  drawer: {
    "& .MuiPaper-root-MuiDrawer-paper": {
      borderTopRightRadius: "12px",
      borderTopLeftRadius: "12px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  filter: {
    backgroundColor: "white",
    padding: "12px",
    borderRadius: "8px",
  },
}));
