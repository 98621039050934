import gql from "graphql-tag";
export const GET_AGREEMENT_PARKING = gql`
query {
  unit(
    where: { id: { in: $id }, 
    is_active: true, 
    unit_category: $parking_unit,
    client:$client
     or: {
        parking_master: { parking_no: { iregex: $searchText } }
        parking_master: { description: { iregex: $searchText } }
      }
  }
    offset:$offset
    limit:$limit
  ) {
    id
    name
      logo
    parking_master {
      id
      parking_no
    }
    property_id
    property{
      id
      name
    }


  }
}
`
export const GET_PARKING_DETAILS = gql`
query {
  unit(
    where: { id: { eq: $id },unit_category: $type }
  ) {
    id
    name
     unit_assets{
      id
      url
    }
    address_id
    address{
      street_1
      street_2
      street_3
      city
      district
      latitude
      longitude
      country
    }
    parking_master {
      id
      parking_no
      description
    }
    property_id
    property{
      id
      name
    }
    block_id
    block{
      id
      name
    }
    floor_id
    floor{
      id
      name
    }

  }
}
`