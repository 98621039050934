import { Avatar, Typography } from "@mui/material";
import styled from '@mui/material/styles/styled';
import makeStyles from "@mui/styles/makeStyles";
import { Bold, FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: theme.palette.dashboard.primary,
            overflow: "hidden",
        },
        username: {
            fontSize: "18px",
            // fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
            fontFamily: props => FontFamilySwitch(props?.language).extraBold
        },
        msg: {
            fontSize: "14px",
            fontFamily: props => FontFamilySwitch(props?.language).regular,
            color: theme.typography.color.primary,
        },
        invoice: {
            backgroundColor: "white",
            padding: "14px",
            borderTopLeftRadius: theme.palette.borderRadius,
            borderTopRightRadius: theme.palette.borderRadius,
            marginTop: "14px",
            boxShadow: "0px 3px 30px #5C86CB2E",
        },
        invoice2: {
            backgroundColor: theme.palette.background.secondary,
            padding: "14px",
            boxShadow: "0px 3px 30px #5C86CB2E",
            borderBottomLeftRadius: theme.palette.borderRadius,
            borderBottomRightRadius: theme.palette.borderRadius,

        },
        invoice3: {
            margin: "0px 16px",
            backgroundColor: "#D0D9E7",
            // boxShadow: "0px 3px 30px #5C86CB2E",
            borderBottomLeftRadius: theme.palette.borderRadius,
            borderBottomRightRadius: theme.palette.borderRadius,
        },
        graph: {
            backgroundColor: theme.palette.background.secondary,
            borderRadius: theme.palette.borderRadius,
            marginTop: "14px",
            // boxShadow: "0px 3px 30px #5C86CB2E",
            padding: "0px 16px"
        },
        profileImg: {
            height: "55px",
            width: "55px",
            borderRadius: "50%",
            border: `2px solid ${theme.palette.primary.main}`,
        },
        relative: {
            position: "relative",
            cursor: "pointer",
        },
        absolute: {
            position: "absolute",
            bottom: "4px",
            backgroundColor: theme.palette.primary.main,
            borderRadius: "7px",
            color: "white",
            left: "0px",
            right: "0px",
            fontSize: "8px",
            textAlign: "center",
            fontFamily: props => FontFamilySwitch(props?.language).bold,
            display: "inline",
            justifyContent: "center",
            padding: "0px 2px",
        },
        closeIcon: {
            position: 'absolute',
            top: '24px',
            right: '24px',
            cursor: 'pointer'
        },
        backgroundImage: {
            objectFit: 'cover',
            // height: '100%',
            width: '100%',
        },
        referCard: {
            backgroundColor: "white",
            padding: "14px 16px",
            marginTop:"20px",
            marginBottom:"15px"
          },
          btn: {
            padding: "8px 12px",
            textTransform: "none",
            marginTop: "8px",
            backgroundColor:"#F15A29",
            '&:hover':{
            backgroundColor:"#F15A29",
            }
          },
          name2: {
            fontSize: "20px",
            fontFamily: props => FontFamilySwitch(props?.language).bold,
            color: theme.typography.color.primary
          },
          subName: {
            fontSize: "12px",
            fontFamily: props => FontFamilySwitch(props?.language).semiBold,
            color: theme.typography.color.primary
          },
        rootmain: {
            '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
                margin: '10px',
            },
            width: "100%",
            maxWidth: "auto",
            padding: "0 !important",
            "& .MuiPaper-root": {
                width: "991px !important",
                borderRadius: theme.palette.borderRadius,
            },
            "& .MuiDialogContent-root": {
                padding: "0px !important",
                position: 'relative',
                display: 'flex'
            },
            '& .MuiDialog-container': {
                padding: (props) => props?.padding ?? '',
            },
          
        },
        welcome: {
            color: '#091B29',
            fontSize: '24px',
            fontFamily: props => FontFamilySwitch(props?.language).extraBold,
            position: 'absolute',
            top: '62px',
            left: '23px'
        },
        name: {
            color: '#5078E1 !important',
        }
    }
});

export const ProfileImageOuterDiv = styled('div')({
    alignSelf: "center"
})

export const ProfileImageInnerDiv = styled('div')({
    position: "relative",
    display: "flex",
    justifyContent: "center",
    margin: "8px 8px 19px"
})

export const Image = styled(Avatar)((props) => ({
    cursor: "pointer",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    border: `1.5px solid #F15A29`
}))

export const UserID = styled(Typography)((props) => ({
    padding: "1px 4px",
    borderRadius: "7px",
    backgroundColor: "#F15A29",
    color: "white",
    fontSize: "8px",
    fontFamily: Bold,
    position: "absolute",
    bottom: "0px",
    textAlign: "center",
    display: "inline",
}))