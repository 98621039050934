import React, { useState, useContext } from "react";
import { Grid, Container, Button } from "@mui/material";
import { TopNavBars, FormGenerator } from "../../components";
import { useStyles } from "./style";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { loadOptions } from "../../utils";
import {
  GET_GENERAL_CATEGORY_MASTERS_NEW as GET_MAINTENANCE_CATEGORY,
  GET_GENERAL_SUB_CATEGORY_NEW as GET_MAINTENANCE_SUB_CATEGORY,
} from "../../graphql/queries";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { NetworkCall } from "../../networkcall";
import { config } from "../../config";
import { initialState } from "./common";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { withNamespaces } from "react-i18next";

const GeneralRequest = ({ t }) => {
  // ---------------------------classes-------------------------------------------
  const classes = useStyles();
  // --------------------------------use Diemension-------------------------------
  const size = useWindowDimensions();
  const history = useHistory();
  const alert = useContext(AlertContext);
  // state
  const [loading1, setLoading1] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [disable, setDisable] = useState(false);

  //   ----------------------------functions-------------------------------------
  const loadOption = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "unit":
        result = await saveGeneralUnits(offset, 10, search);
        console.log(result);
        setLoading1(null);
        const unitList = result?.data?.data?.map((x) => {
          return {
            ...x,
            value: x?.id,
            label: x?.name,
          };
        });
        return {
          options: [...unitList],
          hasMore:
            array?.length + result?.data?.data?.length < result?.data?.count,
        };

      default:
        return { options: [] };
    }
  };

  //   state updation
  const updateState = (key, value) => {
    let error = state?.error;
    error[key] = "";
    if (key === "Category") {
      setState({ ...state, subCategory: "", [key]: value, error });
    } else if (key === "Agreement") {
      setState({ ...state, Unit: "", [key]: value, error });
    } else {
      setState({ ...state, [key]: value, error });
    }
  };
  const isRequestValid = () => {
    let isValid = true;
    let error = state.error;
    if (state?.Category?.length === 0) {
      isValid = false;
      error.Category = "Category is Required";
    }
    if (state?.Unit?.length === 0) {
      isValid = false;
      error.Unit = "Unit is Required";
    }
    if (state?.subCategory?.length === 0) {
      isValid = false;
      error.subCategory = "subCategory is Required";
    }
    if (state?.Title?.length === 0) {
      isValid = false;
      error.Title = "Problem Title is Required";
    }
    if (state?.problemSince?.length === 0) {
      isValid = false;
      error.problemSince = "Problem Since is Required";
    }

    // if (state?.Agreement?.length === 0) {
    //   isValid = false;
    //   error.Agreement = "Agreement is Required";
    // }

    setState({ ...state, error });

    return isValid;
  };
  //   create request
  const saveGeneralRequest = () => {
    if (isRequestValid()) {
      setDisable(true);
      const saveGeneralRequestPayload = {
        type: 1,
        subject: state?.Title,
        problem_since: moment(state?.problemSince)
          .tz(moment.tz.guess())
          .format("YYYY-MM-DD"),
        category: state?.Category?.value,
        sub_category: state?.subCategory?.value,
        units: [state?.Unit?.value],
        userProfileId: localStorage.getItem(LocalStorageKeys?.userProfileId),
        propertyId: state?.Unit?.property_id,
        companyId: state?.Unit?.company_id,
        description: state?.Description,
        is_active:true
      };
      NetworkCall(
        `${config.api_url}/request/create`,
        NetWorkCallMethods.post,
        saveGeneralRequestPayload,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Request submitted successfully.",
          });
          goBack();
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
          });
          setDisable(false);
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill all mandatory field",
      });
    }
  };
  // get units
  const saveGeneralUnits = async (offset, limit, search) => {
    const saveGeneralRequestPayload = {
      offset: offset,
      limit: limit,
      search: search ?? "",
    };
    const result = await NetworkCall(
      `${config.api_url}/owner/get_all_owner_units`,
      NetWorkCallMethods.post,
      saveGeneralRequestPayload,
      null,
      true,
      false
    );
    return result;
  };

  const goBack = () => {
    history.push(Routes.requestScreen);
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      <Grid item xs={12} sx={{ position: "sticky", top: 0 }}>
        <TopNavBars title={t("generalTitle")} isBack />
      </Grid>

      <div
        style={{
          height: size?.height - 135,
          overflow: "auto",
          padding: "16px",
        }}
      >
        <FormGenerator
          components={[
            {
              isActive: true,
              component: "select",
              label: t("Category"),
              value: state?.Category,
              placeholder: t("Category"),
              onChange: (value) => updateState("Category", value),
              error: state?.error?.Category,
              isRequired: true,
              size: {
                xs: 12,
              },
              isPaginate: true,
              loadOptions: (search, array, handleLoading) =>
                loadOptions(
                  search,
                  array,
                  handleLoading,
                  GET_MAINTENANCE_CATEGORY,
                  "general_category_master",
                  { isActive: true },
                  { label: "label", value: "value" },
                  false,
                  false
                ),
            },
            {
              isActive: true,
              component: "select",
              label: t("Sub-Category"),
              value: state?.subCategory,
              placeholder: t("Sub-Category"),
              onChange: (value) => updateState("subCategory", value),
              error: state?.error?.subCategory,
              isRequired: true,
              size: {
                xs: 12,
              },
              isPaginate: true,
              loadOptions: (search, array, handleLoading) =>
                loadOptions(
                  search,
                  array,
                  handleLoading,
                  GET_MAINTENANCE_SUB_CATEGORY,
                  "general_sub_category_master",
                  { isActive: true },
                  { label: "label", value: "value" },
                  false,
                  false,
                  state?.Category?.value ?? ""
                ),
              key: JSON.stringify(state?.Category),
            },
            {
              isActive: true,
              component: "select",
              label: t("Unit"),
              value: state?.Unit,
              placeholder: t("Unit"),
              onChange: (value) => updateState("Unit", value),
              error: state?.error?.Unit,
              isRequired: true,
              size: {
                xs: 12,
              },
              isPaginate: true,
              loadOptions: (search, array) => loadOption(search, array, "unit"),
              key: JSON.stringify(state?.Agreement),
              loading: loading1 === "unit" ? true : false,
            },
            {
              isActive: true,
              component: "text",
              label: t("Title"),
              value: state?.Title,
              placeholder: t("Title"),
              onChange: (e) => updateState("Title", e?.target?.value),
              error: state?.error?.Title,
              isRequired: true,
              size: {
                xs: 12,
              },
              isPaginate: true,
            },
            {
              isActive: true,
              component: "text",
              label: t("Description"),
              value: state?.Description,
              placeholder: t("Description"),
              onChange: (e) => updateState("Description", e?.target?.value),
              error: state?.error?.Description,
              multiline: true,
              size: {
                xs: 12,
              },
              isPaginate: true,
            },
            {
              isActive: true,
              component: "date",
              label: t("Since"),
              value: state?.problemSince,
              placeholder: t("Since"),
              onChange: (value) => updateState("problemSince", value),
              error: state?.error?.problemSince,
              maxDate: new Date(),
              isRequired: true,
              size: {
                xs: 12,
              },
            },
          ]}
        />
      </div>

      {/* button  */}
      <Container maxWidth="sm" className={classes.btnRoot}>
        <Grid
          container
          spacing={2}
          className={classes.saveNUpdateButtonContainerStyle}
        >
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              disable={disable}
              onClick={saveGeneralRequest}
            >
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};
export default withNamespaces("request")(GeneralRequest);
