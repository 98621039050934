import { useApolloClient } from "@apollo/client";
import { Box, Container, Hidden } from "@mui/material";
import jwt_decode from "jwt-decode";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { AlertDialog, DrawerComponent, LoderSimmer, TitleBar, UseDebounce } from "../../components";
import { config } from "../../config";
import { GET_AGREEMENT_PARKING } from "../../graphql/query/agreementParking";
import { NetworkCall } from "../../networkcall/index";
import { LocalStorageKeys, NetWorkCallMethods, parkingEnum } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DrawerDetails, ParkingCard, Search } from "./component";
import { ParkinguseStyles } from "./style";
export const AgreementParking = (props) => {
      const classes = ParkinguseStyles();
      const history = useHistory();
      const debounce = UseDebounce()
      const client = useApolloClient();
      const authToken = localStorage.getItem(LocalStorageKeys.authToken);
      const clientID = JSON.parse(localStorage.getItem(LocalStorageKeys.client))
      const size = useWindowDimensions();
      const [offset, setOffset] = React.useState(0);
      const [datas, setDatas] = React.useState([]);
      const [loading, setLoading] = React.useState(true);
      const [open, setOpen] = React.useState(false);
      const [unit, setUnit] = React.useState([])
      const [selectedUnit, setSelectedUnit] = React.useState("");
      const [search, setSearch] = React.useState("")
      const getparking = (id, offset, limit, searchText, filter) => {
            client.query({
                  query: GET_AGREEMENT_PARKING,
                  fetchPolicy: 'network-only',
                  variables: {
                        id: id,
                        offset: offset,
                        limit: limit,
                        searchText: searchText,
                        client: clientID?.id,
                        parking_unit: parkingEnum?.parking_unit
                  }
            }).then((res) => {
                  if (filter) {
                        setDatas(res.data?.unit)
                  } else {
                        setDatas(datas?.concat(res.data?.unit))
                  }
                  setLoading(false)

            }).catch((err) => {
                  console.log(err)
                  setLoading(false)
            })
      };
      const getUnitids = () => {
            const tempDecoded = jwt_decode(authToken);
            const data = {
                  tenantId: `${config.tenantid}`,
                  userId: tempDecoded.id,
            };
            NetworkCall(
                  `${config.api_url}/dashboard`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        getparking(response.data?.data?.units?.result.map((val) => val.id), 0, 10, "", false);
                        setUnit(response.data?.data?.units?.result.map((val) => val.id))
                  })
                  .catch((err) => {
                        console.log(err)
                  });
      };
      React.useEffect(() => {
            getUnitids();
            // eslint-disable-next-line
      }, []);
      const closeDrawer = () => {
            setOpen(!open)
      }
      const fetchMoreData = () => {
            setOffset(offset + 10)
            getparking(unit, offset + 10, 10, search, false);
      }
      const handleDetails = (data) => {
            setSelectedUnit(data)
            setOpen(true)
      }
      const handleSearch = (value) => {
            setSearch(value)
            debounce(() => getparking(unit, 0, 10, value, true), 800)

      }
      return (
            <Container className={classes.root} maxWidth="sm">
                  <TitleBar text="Parking" goBack={() => history.goBack()} />
                  <div className={classes.roots}>
                        <Box display="flex" alignItems="center" p={1}>
                              <Box flexGrow={1}><Search onChange={handleSearch} value={search} /></Box>
                        </Box>

                        {loading ? (
                              <LoderSimmer card count={10} size={80} />
                        ) : (
                              <InfiniteScroll
                                    dataLength={datas?.length ?? 10}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 116}
                              >
                                    <Box p={1}>
                                          {
                                                datas?.map((item) => {
                                                      return (<ParkingCard val={item} handleDetails={handleDetails} />);
                                                })
                                          }
                                    </Box>
                              </InfiniteScroll>

                        )}
                  </div>

                  {/* drawer */}
                  <Hidden smUp>
                        <DrawerComponent onClose={closeDrawer} open={open} component={<DrawerDetails selectedUnit={selectedUnit} open={open} closeDrawer={closeDrawer} />} />
                  </Hidden>

                  {/* dialog */}
                  <Hidden smDown>
                        <AlertDialog toggleDrawer={closeDrawer} open={open} component={<DrawerDetails open={open} selectedUnit={selectedUnit} closeDrawer={closeDrawer} isMobile />} />
                  </Hidden>
            </Container>
      );
};
