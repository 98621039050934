import { Grid, Typography, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "12px",
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    marginTop: "10px",
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  posted: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
  },
  title: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  dis: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    cursor: "pointer",
  },
  img: {
    width: "101px",
    height: "120px",
    borderRadius: theme.palette.borderRadius,
  },
  health: {
    fontSize: "10px",
    color: "#98A0AC",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    background: "#E4E8EE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  announsmentnot: {
    backgroundColor: "#E6F9FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.palette.borderRadius,
    width: "80px",
    height: "80px",
  },
  rightgrid: {
    // margin: "10px 0px 0px 0px",
  }
}));

export const AnnouncementCard = ({ data = [] }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div
      className={classes.root}
      onClick={() =>
        history.push(Routes.announcementDetails + "?Id=" + data?.id)
      }
    >
      <Grid container>
        <Grid item xs={12} md={12} sm={12} lg={12} height={"120px"}>
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              {data.description[0]?.images ? (
                <>
                  <img
                    src={data.description[0]?.images}
                    alt="imgg"
                    className={classes.img}
                  />
                </>
              ) : (
                <>
                  <Box className={classes.announsmentnot}>
                    <img src="/images/announsmentnot.svg" alt="announsment" />
                  </Box>
                </>
              )}
            </Box>
            <Box marginLeft={"12px"}>
              <Box marginBottom={"10px"}>
                <span variant="subtitle2" noWrap className={classes.health}>
                  {data?.category}
                </span>
              </Box>

              <Typography
                className={classes.title}
                noWrap
                style={{ marginBottom: "6px" }}
              >
                {data?.title}
              </Typography>

              <Typography className={classes.posted}>
                Posted at {moment(new Date(data?.triggered_at)).fromNow()}
              </Typography>
            </Box>
          </Box>

        </Grid>
      </Grid>
    </div>
  );
};
