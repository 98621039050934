/* eslint-disable array-callback-return */
import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from "@mui/material";
import {
  GET_AGREEMENT_UNITS
} from "../../graphql/queries";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { Filter, LoderSimmer, PassCard, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, enumSelect, enumCatagory, keyMapSource } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useApolloClient } from "@apollo/client";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.main,
    padding: "0px",
    overflow: "hidden",
  },
  menuText: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    marginTop: '30%'
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "120px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "80px",
      left: "65%",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
export const WorkerGatePass = () => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const client = useApolloClient();
  const { state } = useLocation();
  const history = useHistory();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [filter, setFilter] = React.useState(false);
  const [gatelist, setGateList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const alert = React.useContext(AlertContext);
  const [unitsfil, setUnitsfil] = React.useState([]);
  const [statusType, setStatusType] = React.useState([]);
  const [securityType, setSecurityType] = React.useState({
    type: {}
  });

  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const filterData = [
    {
      id: "1",
      title: "Type",
      key: "type",
      showSearch: true,
      filterType: "CHECKBOX",
      values: [
        {
          label: "Worker Gate Pass",
          value: securityType?.type?.type2,
        },
        {
          label: "Visitor Gate Pass",
          value: securityType?.type?.type1,
        },
      ],
    },
    {
      id: "2",
      title: "Status",
      key: "status",

      filterType: "CHECKBOX",
      values: statusType ?? []
    },
    {
      id: "3",
      title: "Unit",
      key: "unit",
      filterType: "CHECKBOX",
      values: unitsfil,
    },
    {
      id: "4",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];
  const getPassList = (unit, type, status, date, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: type,
      status: status,
      offset: offset,
      limit: 20,
      agreement_unit_id: unit,
      start_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.startDate).toISOString()
          : undefined,
      end_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.endDate).toISOString()
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setGateList(response?.data?.data);
        } else {
          setGateList(gatelist?.concat(response?.data?.data));
        }
        setFilter(false);
        setLoading(false);
        setTotal(response?.data?.totalRecords);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };
  const getUnitids = () => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded?.id,
    };
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let agreement = response?.data?.data?.agreements?.result?.map((x) => x?.id)
        let data = response?.data?.data?.units?.result;
        const request_units = data.map(({ id }) => id);
        getagreementUnitid(agreement, request_units)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };
  const getagreementUnitid = (v, x) => {
    client.query({
      query: GET_AGREEMENT_UNITS(),
      fetchPolicy: 'network-only',
      variables: {
        agreementId: v,
        unitId: x,
      }
    }).then((res) => {
      getEnum(res?.data?.agreement_units)
      const allunits = res?.data?.agreement_units?.map((val, index) => {
        let _d;
        try {
          _d = {
            label: val?.unit?.name,
            value: String(val?.id),
          };
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong.",
          });
        }

        return _d;
      });
      setUnitsfil(allunits.flat());
    }).catch((err) => {

    })
  }
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.security_status_enum_type, enumCatagory?.security_request_type])

    const statusType = keyMapSource({ enumType: stayType?.security_status_enum_type })

    let requestSourceMap = stayType?.security_request_type.flatMap((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap).map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})

    setStatusType(stayType?.security_status_enum_type?.filter(x => x?.value !== statusType?.type5))

    setSecurityType({ ...securityType, type: requestType })

    getPassList(
      data?.map((x) => x?.id),
      [requestType?.type2, requestType?.type1],
      stayType?.security_status_enum_type?.filter(x => x?.value !== statusType?.type5)?.map((val) => val?.value)
    );

    setSelectedFilter({
      unit: data?.map((x) => x?.id),
      type: [requestType?.type2, requestType?.type1],
      status: stayType?.security_status_enum_type?.filter(x => x?.value !== statusType?.type5)?.map((val) => val?.value),
    });
  }
  React.useEffect(() => {
    getUnitids();
    // eslint-disable-next-line
  }, []);
  const applyFilter = (data) => {
    if (data?.type?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast select one type",
      });
      return false;
    }
    if (data?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast select one status",
      });
      return false;
    }
    if (data?.unit?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast select one unit",
      });
      return false;
    }
    if (dateFilter?.startDate > dateFilter?.endDate) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "End date must be greater than start date",
      });
      return false;
    }
    setOffset(0);
    setSelectedFilter(data);
    getPassList(data?.unit, data?.type, data?.status, dateFilter, filter, 0);
  };
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(
      selectedFilter?.unit,
      selectedFilter?.type,
      selectedFilter?.status,
      "",
      false,
      offset + 20
    );
  };
  const goBack = () => {
    history.push(state ? Routes.dashboard : Routes.service);
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text="Worker / Visitor Gate Pass"

        goBack={goBack}
      />

      <Grid container height={size?.height - 126}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" p={1}>
            <Box flexGrow={1}>
              <Typography className={classes.total}>
                Worker / Visitor Gate Pass ({total})
              </Typography>
            </Box>
            <Box marginLeft="10px">
              <IconButton
                className={classes.filter}
                onClick={() => setFilter(true)}
              >
                <Badge variant="dot" color="primary">
                  <img src="/images/icons8-funnel.svg" alt="filter" />
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <div style={{ marginRight: "-6px", paddingBottom: '15%' }}>
            {loading ? (
              <LoderSimmer count={10} />
            ) : (
              <>
                {
                  gatelist?.length > 0 ?
                    <InfiniteScroll
                      dataLength={gatelist?.length}
                      next={fetchMoreData}
                      hasMore={true}
                      height={size?.height - 110}
                    >
                      <Box padding={"16px"} >
                        {gatelist?.map((item) => {
                          return <>
                            <Box marginBottom={"12px"}>
                              <PassCard data={item} />

                            </Box>
                          </>
                        })}
                      </Box>
                    </InfiniteScroll>
                    :
                    <Typography className={classes.menuText} textAlign="center">
                      No records found
                    </Typography>

                }
              </>

            )}
          </div>

          <Fab
            color="primary"
            className={classes.fab}
            onClick={() => history.push(Routes.createPass)}
          >
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter?.type,
              status: selectedFilter?.status,
              unit: selectedFilter?.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            disable={true}
            minDate={new Date()}
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              type: selectedFilter?.type,
              status: selectedFilter?.status,
              unit: selectedFilter?.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};
