import { Box, Button, Dialog, Drawer, Grid, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    minHeight: "100px",
    display: "flex"
  },
  Cardcontent: {
    padding: "16px",
    flex: "auto"
  },
  title: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
  count: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },

  image: {
    width: 46,
    height: 46,
    backgroundColor: (props) => props.data.color,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "16px",
      borderTopRightRadius: "16px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  submitBtn: {
    borderRadius: "10px",
    padding: "12px 8px",
  },
  sideBorder: {
    backgroundColor: (props) => props?.data?.borderColor,
    margin: " 3px 0px",
    borderRadius: " 0px 10px 10px 0px",
    border: (props) => `3px solid ${props?.data?.borderColor}`
  }
}));

export const QuickLink = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [open, setopen] = React.useState(false);
  return (
    <>
      <div
        className={classes.card}
        onClick={() => {
          if (props?.data?.noavailable) {
            setopen(true);
          } else {
            history.push({
              pathname: props.data.link,
              state: {
                dashboard: true,
              },
            });
          }

        }}
      >
        <Box className={classes.sideBorder} />
        <div className={classes.Cardcontent}>
          <Grid container alignContent="center">
            <Grid xs={12}>
              <center>
                <img src={props.data.image} alt={props.data.name} />
                <Typography variant="body1" className={classes.count}>
                  {props.data.name}
                </Typography>
                <Typography variant="body1" className={classes.count}>
                  {props.data.count}
                </Typography>
              </center>
            </Grid>
          </Grid>
        </div>
      </div>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={open}
          onClose={() => setopen(false)}
          className={classes.dialog}
        >
          <Box p={1.5}>
            <center>
              <img src="/images/noavailabel.svg" alt="" />
            </center>
            <Button
              onClick={() => setopen(false)}
              variant="contained"
              fullWidth
              className={classes.submitBtn}
            >
              Close
            </Button>
          </Box>
        </Dialog>
      </Hidden>
      {/* Renewl Form Drawer*/}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={open}
          onClose={() => setopen(false)}
        >
          <Box p={1.5}>
            <center>
              <img src="/images/noavailabel.svg" alt="" />
            </center>
            <Button
              variant="contained"
              onClick={() => setopen(false)}
              fullWidth
              className={classes.submitBtn}
            >
              Close
            </Button>
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};
