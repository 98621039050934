import React, { useState } from "react";
import { AvatarGroup, Box, Typography, Avatar } from "@mui/material";
import { useStyle } from "../style";
import { CurrentAgreement } from "../../../assets";
import { ProfileCard } from "../components";
export const CurrentAgreementCard = ({ date = {}, details = {} }) => {
  const classes = useStyle();
  const [hidden, setHidden] = useState(false);
  return (
    <Box textAlign={"left"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        className={classes.agreementBox}
      >
        <CurrentAgreement />
        <Box ml={1}>
          <Typography className={classes.agreementTitle}>
            Current Agreement
          </Typography>
          <Box display={"flex"} alignItems={"center"} mt={1}>
            <Box className={classes.lease}>
              {details?.agreement?.agreement_type}
            </Box>
            <Box className={classes.agreementNo}>
              {details?.agreement?.agreement_no}
            </Box>
          </Box>
        </Box>
      </Box>
      {/*  */}
      <Box display={"flex"} alignItems={"center"}>
        {date?.map((x) => {
          return (
            <Box marginLeft="10px">
              <Typography className={classes.dateTitle}>{x?.title}</Typography>
              <Box display="flex" alignItems="center">
                <img src="/images/icons8-calendar (5).svg" alt="calander" />
                <Typography className={classes.date}> {x?.date}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      {hidden ? (
        <Box mt={2} onClick={() => setHidden(false)}>
          <Typography className={classes.dateTitle}>Residents</Typography>
          {details?.residents?.map((x) => {
            return (
              <ProfileCard
                data={{
                  url: x?.image_url,
                  name: x?.first_name,
                  contactNumber: `${x?.mobile_no_country_code} ${x?.mobile_no}`,
                  email: x?.email_id,
                }}
              />
            );
          })}
        </Box>
      ) : (
        <Box className={classes.avatarGroup} onClick={() => setHidden(true)}>
          <AvatarGroup max={3} className={classes.avatarGroups}>
            {details?.residents?.map((x) => {
              return x?.image_url?.length > 0 ? (
                <Avatar
                  alt={x?.first_name}
                  src={x?.image_url}
                  sx={{ width: 34, height: 34 }}
                />
              ) : (
                <Avatar sx={{ bgcolor: "#F15A29" }}>
                  {x?.first_name?.[0]}
                </Avatar>
              );
            })}
          </AvatarGroup>
        </Box>
      )}
    </Box>
  );
};
