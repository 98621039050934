import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MobileNumberInputComponent,
  TextBox,
  TitleBar,
} from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  GET_CONTACT_DROPDOWN,
  GET_DETAILS_OF_CONTACT,
  GET_PROPERTY_BY_UNITID,
  GET_PROPERTY_UNITID
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useApolloClient } from "@apollo/client";
import { BackdropContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: "0px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  UnitStatusactive: {
    padding: "10px 16px",

    textAlign: "center",
    borderRadius: "4px",
    color: "white",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
  UnitStatus: {
    padding: "10px 16px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "14px",
    cursor: "pointer",

    backgroundColor: "#F2F4F7",
  },
  divider: {
    border: "2px solid #F5F7FA",
    marginTop: "18px",
  },
  Label: {
    color: theme.typography.color.Tertiary,
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    fontSize: "12px",
  },
  field: {
    marginTop: "10px",
  },
}));
const InitialState = {
  contactName: "",
  selectProfession: "",
  unitReference: "",
  contactNumber: "",
  // countryCode: "",
  makeItPublic: "",
  gender: "",
  error: {
    contactName: "",
    selectProfession: "",
    unitReference: "",
    contactNumber: " ",
    // countryCode: "",
    makeItPublic: "",
    gender: "",
  },
};
export const Contacts = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  const [createData, setCreateData] = React.useState({ ...InitialState });
  const search = useLocation().search;
  const alert = React.useContext(AlertContext);
  const [userId, setUserId] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [disable, setDisable] = React.useState(null);
  const backdrop = React.useContext(BackdropContext);
  const [loading1, setLoading1] = React.useState(null);
  const client = useApolloClient();
  const [getunit, setgetUnit] = React.useState(null);
  const [property, setProperty] = React.useState({});
  const [agreementList, setAgreementLists] = React.useState([]);
  const action = new URLSearchParams(search).get("action");
  const action_id = new URLSearchParams(search).get("id");
  const updateState = (key, value) => {
    if (key === "unitReference") {
      getpropertyUnitid(value)
    }
    let error = createData.error;
    error[key] = "";
    setCreateData({ ...createData, [key]: value, error });
  };

  const isIamValide = () => {
    let isValid = true;
    let error = createData.error;
    //contactName name
    if (createData.contactName.length === 0) {
      isValid = false;
      error.contactName = "contact Name is Required";
    }
    // contactNumber
    // if (createData.contactNumber.length === 0) {
    //     isValid = true;
    //     error.contactNumber = "Contact Number is Required";
    // }
    //unitReference name
    if (createData.unitReference.length === 0) {
      isValid = false;
      error.unitReference = "Unit Reference is Required";
    }
    //selectProfession name
    if (createData.selectProfession.length === 0) {
      isValid = false;
      error.selectProfession = "Select Profession is Required";
    }

    setCreateData({ ...createData, error });

    return isValid;
  };
  const goBack = () => {
    history.goBack();
  };

  const submit = () => {
    if (action === "create_contact") {
      if (isIamValide()) {
        const data = {
          tenantId: `${config.tenantid}`,
          property_id: property?.id,
          unit_id: createData?.unitReference?.unit_id,
          agreement_unit_id: createData?.unitReference?.value,
          user_profile_id: userId,
          name: createData?.contactName,
          gender: createData?.gender,
          contact_number: createData?.contactNumber,
          country_code: "+91",
          profession_id: createData?.selectProfession?.value,
          is_share: createData?.makeItPublic,
        };
        NetworkCall(
          `${config.api_url}/community/contact/create`,
          NetWorkCallMethods.post,
          data,
          null,
          true,
          false
        )
          .then((response) => {
            if (response.status === 200) {
              setDisable(true);
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.success,
                msg: "Contact Created Succesfully !",
              });
              history.push(Routes.contactsDetails);
            }
          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Some thing Went wrong.",
            });
          });
      } else {
      }
    }
    const action_3 = "edit_contact";
    if (action === "view_contact") {
      history.push(
        Routes.contacts + "?action=" + action_3 + "&id=" + action_id
      );
    }
    if (action === "edit_contact") {
      if (isIamValide()) {
        editContactUpdate();
      }
    }
  };

  //api
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);


  const getUnitIDS = () => {
    const temp_decoded = jwt_decode(authToken);
    const userId = temp_decoded.id;
    const data = {
      tenantId: `${config.tenantid}`,
      userId: userId,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.id ?? "",
              unit_id: _uitem?.unit_id ?? "",
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  }
  const getpropertyUnitid = (v) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    client.query({
      query: GET_PROPERTY_UNITID(),
      fetchPolicy: 'network-only',
      variables: {
        unit_id: [v?.unit_id],
        offset: 0,
        limit: 10,
        search: "",
      }
    }).then((res) => {
      setProperty(res?.data?.unit?.[0]?.property)
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
    }).catch((err) => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Some thing Went wrong.",
      });
    })
  }
  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const tempDecoded = jwt_decode(authToken);
      const data = {
        tenantId: `${config.tenantid}`,
        userId: tempDecoded.id,
      };
      NetworkCall(
        `${config.api_url}/dashboard`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          setUserId(response?.data?.data?.userProfileId);
          setgetUnit(
            response.data?.data?.units?.result.map((val) => val?.unit_name)
          );
          setLoading(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
      getUnitIDS()
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);

  // options for unitreferenece
  //get details of contact
  const detailsOfContact = () => {
    const data = {
      query: GET_DETAILS_OF_CONTACT,
      variables: {
        ID: action_id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.community_contacts[0];
        setLoading(false);
        const InitialState = {
          contactName: data?.name,
          selectProfession: {
            label: data?.profession_master?.name,
            value: data?.profession_master?.id,
          },
          unitReference: {
            label: data?.unit?.name,
            value: data?.agreement_units?.id,
            unit_id: data?.unit?.id,
            propertyId: data?.unit?.property?.id,
          },
          contactNumber: data?.contact_number,
          // countryCode: data?.countryCode,
          makeItPublic: data?.is_share,
          gender: data?.gender,
          error: {
            contactName: "",
            selectProfession: "",
            unitReference: "",
            contactNumber: " ",
            // countryCode: "",
            makeItPublic: "",
            gender: "",
          },
        };
        setCreateData(InitialState);
        setProperty({
          id: data?.unit?.property?.id,
          name: data?.unit?.property?.name
        })
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  const editContactUpdate = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      property_id: property?.id,
      unit_id: createData?.unitReference?.unit_id,
      agreement_unit_id: createData?.unitReference?.value,
      contact_id: action_id,
      user_profile_id: userId,
      name: createData?.contactName,
      gender: createData?.gender,
      contact_number: createData?.contactNumber,
      // country_code: createData?.countryCode,
      country_code: "+91",
      profession_id: createData?.selectProfession?.value,
      is_share: createData?.makeItPublic,
    };
    NetworkCall(
      `${config.api_url}/community/contact/update`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (response.status === 200) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Contact Edited Succesfully !",
          });
          history.push(Routes.contactsDetails);
        }
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };

  React.useEffect(() => {
    if (action === "view_contact") {
      detailsOfContact();
    }
    // eslint-disable-next-line
  }, [action]);

  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "unit":
        if (!getunit?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GET_PROPERTY_BY_UNITID(),
          variables: {
            unit_id: getunit,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.unit],
          hasMore:
            array?.length + result?.data?.unit?.length <
            result?.data?.count[0]?.count,
        };

      case "Profession":
        result = await client.query({
          query: GET_CONTACT_DROPDOWN(),
          variables: {
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.profession_master],
          hasMore:
            array?.length + result?.data?.profession_master?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TitleBar
              text={
                <>
                  {action === "create_contact" && "Create Contact"}
                  {action === "edit_contact" && "Edit Contact"}
                  {action === "view_contact" && "View Contact"}
                </>
              }

              goBack={goBack}
            />
            <div
              style={{
                height: size.height - 135,
                padding: "12px",
                overflow: "auto",
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextBox
                    isRequired
                    isReadonly={action === "view_contact" ? true : false}
                    label="Contact Name"
                    value={createData?.contactName}
                    placeholder="Enter Contact Name"
                    onChange={(value) =>
                      updateState("contactName", value.target.value)
                    }
                    isError={createData?.error?.contactName?.length > 0}
                    errorMessage={createData?.error?.contactName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MobileNumberInputComponent
                    isRequired
                    isReadonly={action === "view_contact" ? true : false}
                    label="Contact Number"
                    value={{
                      mobile: createData?.contactNumber,
                      mobile_code: "+91",
                    }}
                    placeholder="Enter Contact Number"
                    handleChange={(value) => {
                      createData.contactNumber = value.mobile;
                      // createData.countryCode = value.mobile_code;
                      setCreateData({ ...createData });
                    }}
                    // onChange={(value) => updateState("contactNumber", value.target.value)}
                    isError={createData?.error?.contactNumber?.length > 0}
                    errorMessage={createData?.error?.contactNumber}
                  />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                  <Typography
                    variant="body1"
                    className={classes.Label}
                    gutterBottom
                  >
                    Gender<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("gender", "Male")
                    }
                    className={
                      createData.gender === "Male"
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    Male
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("gender", "Female")
                    }
                    className={
                      createData.gender === "Female"
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    Female
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("gender", "Others")
                    }
                    className={
                      createData.gender === "Others"
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    Others
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SelectBox
                    isRequired
                    isDisabled={action === "view_contact" ? true : false}
                    value={createData?.selectProfession}
                    label="Select Profession"
                    placeholder="Select Profession"
                    isPaginate
                    loadOptions={(search, array) =>
                      loadOptions(search, array, "Profession")
                    }
                    loading={loading1 === "Profession"}
                    debounceTimeout={800}
                    onChange={(value) => updateState("selectProfession", value)}
                    isError={createData?.error?.selectProfession?.length > 0}
                    errorMessage={createData?.error?.selectProfession}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectBox
                    isRequired
                    isDisabled={action === "view_contact" ? true : false}
                    value={createData?.unitReference}
                    options={agreementList}
                    label="Unit Reference"
                    placeholder="Enter unitReference"
                    onChange={(value) => updateState("unitReference", value)}
                    isError={createData?.error?.unitReference?.length > 0}
                    errorMessage={createData?.error?.unitReference}
                  />
                </Grid>
                <Grid item xs={12} className={classes.field}>
                  <Typography
                    variant="body1"
                    className={classes.Label}
                    gutterBottom
                  >
                    Make It Public<sup style={{ color: "red" }}>*</sup>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("makeItPublic", true)
                    }
                    className={
                      createData.makeItPublic === true
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    Yes
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    onClick={() =>
                      action === "view_contact"
                        ? false
                        : updateState("makeItPublic", false)
                    }
                    className={
                      createData.makeItPublic === false
                        ? classes.UnitStatusactive
                        : classes.UnitStatus
                    }
                    isReadonly={action === "view_contact" ? true : false}
                  >
                    No
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Grid container className={classes.btncontainer} spacing={1}>
              <Grid item xs={6}>
                <Button
                  variant="contain"
                  fullWidth={true}
                  className={classes.cancelbtn}
                  onClick={() => history.push(Routes.contactsDetails)}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth={true}
                  disable={disable}
                  className={classes.applybtn}
                  onClick={submit}
                >
                  {action === "view_contact" && "Edit"}
                  {action === "edit_contact" && "Save"}
                  {action === "create_contact" && "Save"}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </>
  );
};
