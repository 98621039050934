import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { Filter, Statement, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.secondary,
  },

  statementtotal: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  iconbtn: {
    border: "1px solid #E4E8EE",
    borderRadius: "3px",
    backgroundColor: "#FFFFFF",
  },
  statement: {
    background: theme.palette.background.paper,
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    width: "100%",
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
    marginLeft: "4px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 4px",
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
}));
export const Statementofaccounts = (props) => {
  const classes = useStyles();
  const defaultFilter = {};
  const size = useWindowDimensions();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [drawer, setDrawer] = React.useState(null);
  const [state, setState] = React.useState([]);
  const [Statementdata, setStatementdata] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [selectedFilter, setSelectedFilter] = React.useState(defaultFilter);
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const goBack = () => {
    window.history.go(-1);
  };

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const tempDecoded = jwt_decode(authToken);
      const userId = tempDecoded.id;
      const payload = {
        tenantId: "8e9c54d2-45ef-4fac-bb37-6bc7d35bb199",
        userId: userId,
      };
      NetworkCall(
        `${config.api_url}/user-accounts`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          const data = response?.data?.data;
          let accNo = data.map((item) => {
            return item.account_no;
          });
          const fetch = response?.data?.data.map((val, index) => {
            let _d;
            try {
              _d = {
                value: val.account_no,
                label: val.account_no,
                key: "account_no",
              };
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong.",
              });
            }
            return _d;
          });
          setState(fetch);
          const defaultFilters = response?.data?.data.map((val, index) => {
            let _d = [];
            try {
              _d.push(val.account_no);
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong.",
              });
            }
            return _d;
          });
          defaultFilter.account_no = defaultFilters.flat();
          const payload = {
            tenantId: "8e9c54d2-45ef-4fac-bb37-6bc7d35bb199",
            account_no: accNo,
            offset: 0,
            limit: 10,
          };
          NetworkCall(
            `${config.api_url}/account-statement`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
          )
            .then((response) => {
              setStatementdata(response?.data?.data);
            })
            .catch((err) => {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some Thing Went Wrong.",
              });
            });
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong.",
          });
        });
    }
    // eslint-disable-next-line
  }, []);

  const applyFilter = (data) => {
    setSelectedFilter(data);
    const payload = {
      tenantId: "8e9c54d2-45ef-4fac-bb37-6bc7d35bb199",
      account_no: data.account_no,
      offset: 0,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/account-statement`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setStatementdata(response?.data?.data);

        setDrawer(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };
  const filterData = [
    {
      id: "1",
      title: "Account No",
      key: "account_no",
      filterType: "CHECKBOX",

      values: state,
    },
  ];

  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        <div className={classes.root}>
          <Grid
            className={classes.screen}
            style={{ height: size?.height }}
            item
            xs={12}
          >
            <Grid className={classes.topNavBarStyle}>
              <TitleBar text="Statement Of Account" goBack={goBack} />
              <Grid xs={12}>
                <Box display="flex" p={1}>
                  <Box flexGrow={1}>
                    <Typography className={classes.statementtotal}>
                      {Statementdata?.length} Statements
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton className={classes.iconbtn}>
                      <img src="/images/downlodestatement.svg" alt="downlode" />
                    </IconButton>
                  </Box>
                  <Box marginLeft={1}>
                    <IconButton
                      className={classes.iconbtn}
                      onClick={draweropen}
                    >
                      <Badge variant="dot" color="primary">
                        <img src="/images/icons8-funnel.svg" alt="filter" />
                      </Badge>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              className={classes.screenPadding}
              style={{ height: size?.height - 115 }}
            >
              <Grid Container className={classes.statement}>
                {Statementdata.length > 0 && (
                  <>
                    {Statementdata?.map((item) => {
                      return (
                        <Grid xs={12}>
                          <Statement Statementdata={item} />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>

      {/* filter drawer */}
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={drawer}
          onClose={drawerclose}
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  account_no: selectedFilter.account_no,
                }}
                allFilter={true}
                onApply={applyFilter}
                onClose={drawerclose}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={drawer} onClose={drawerclose}>
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  account_no: selectedFilter.account_no,
                }}
                allFilter={true}
                onApply={applyFilter}
                onClose={drawerclose}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </div>
  );
};
