/* eslint-disable array-callback-return */
import { useApolloClient } from "@apollo/client";
import { Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DateWithTime, TextBox, TitleBar } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { GET_GATES, GET_PROPERTY_UNITID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, enumSelect } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },
  input: {
    marginTop: "10px",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "10px",
  },
  rootForm: {
    overflow: "scroll",
    marginRight: "-6px"
  }
}));
const InitialState = {
  unit: "",
  vehicleType: "",
  number: "",
  startDate: new Date(),
  endDate: new Date(),
  gate: "",
  error: {
    unit: "",
    vehicleType: "",
    startDate: "",
    endDate: "",
    gate: "",
  },
};
export const CreateParking = () => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const searchURL = useLocation().search;
  const [data, setdata] = React.useState({ ...InitialState });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [disable, setDisable] = React.useState(null);
  const [loading1, setLoading1] = React.useState(null);
  const client = useApolloClient();
  // const [getunit, setgetUnit] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const passId = new URLSearchParams(searchURL).get("passId");
  const [property, setProperty] = React.useState({});
  const [agreementList, setAgreementLists] = React.useState([]);
  const backdrop = React.useContext(BackdropContext);
  const [veicalType, setVeicalType] = React.useState([]);

  const getEnum = async () => {
    const stayType = await enumSelect(['vechicle_type'])
    setVeicalType(stayType?.vechicle_type)
  }
  // const getUnitids = () => {
  //   const tempDecoded = jwt_decode(authToken);
  //   const data = {
  //     tenantId: `${config.tenantid}`,
  //     userId: tempDecoded.id,
  //   };
  //   NetworkCall(
  //     `${config.api_url}/dashboard`,
  //     NetWorkCallMethods.post,
  //     data,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       // setgetUnit(
  //       //   response.data?.data?.units?.result.map((val) => val?.unit_name)
  //       // );
  //     })
  //     .catch((err) => {
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: "Some Thing Went Wrong.",
  //       });
  //     });
  // };

  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }


    switch (type) {
      case "gate":
        if (!data?.unit?.value?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GET_GATES(),
          variables: {
            property_id: property?.id,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.access_gates_master],
          hasMore:
            array?.length + result?.data?.access_gates_master?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };
  const getUnitIDS = () => {
    const temp_decoded = jwt_decode(authToken);
    const userId = temp_decoded.id;
    const data = {
      tenantId: `${config.tenantid}`,
      userId: userId,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.id ?? "",
              unit_id: _uitem?.unit_id ?? "",
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  }
  const getpropertyUnitid = (v) => {
    client.query({
      query: GET_PROPERTY_UNITID(),
      fetchPolicy: 'network-only',
      variables: {
        unit_id: [v?.unit_id],
        offset: 0,
        limit: 10,
        search: "",
      }
    }).then((res) => {
      setProperty(res?.data?.unit?.[0]?.property)
      // setdata({...data , gate: ""})
    }).catch((err) => {

    })
  }

  React.useEffect(() => {
    if (passId) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
      const data = {
        tenantId: `${config.tenantid}`,
        request_id: passId,
      };
      NetworkCall(
        `${config.api_url}/security/request`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          const temp = response?.data?.data[0];
          const InitialState = {
            unit: {
              value: temp?.agreement_unit_id ?? "",
              label: temp?.unit?.[0]?.name ?? "",
              unit_id: temp?.unit?.[0]?.id ?? "",
            },
            vehicleType: {
              value: temp?.vechicle_type,
              label: temp?.vechicle_type,
            },
            number: temp?.vechicle_number,
            startDate: new Date(temp?.request_from),
            endDate: new Date(temp?.request_to),
            gate: {
              value: temp?.gate_id,
              label: temp?.gate,
            },
            error: {
              unit: "",
              vehicleType: "",
              startDate: "",
              endDate: "",
              gate: "",
            },
          };
          setdata(InitialState);
          setProperty({
            id: temp?.property_id,
            name: temp?.property_name
          })
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch((err) => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong.",
          });
        });
    }
    // getUnitids();
    getUnitIDS()
    getEnum()
    // eslint-disable-next-line
  }, []);
  const updateState = (key, value) => {
    if (key === 'unit') {
      getpropertyUnitid(value)
    }
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const isIamValideTostep1 = () => {
    let isValid = true;
    let error = data.error;
    //Checking unit
    if (data?.unit?.length === 0) {
      isValid = false;
      error.unit = "Unit is Required";
    }
    //Checking vehicleType
    if (data?.vehicleType?.length === 0) {
      isValid = false;
      error.vehicleType = "Vehicle Type is Required";
    }
    //Checking startDate
    if (data?.startDate?.length === 0) {
      isValid = false;
      error.startDate = "Start Date is Required";
    }
    //Checking endDate
    if (data?.endDate?.length === 0) {
      isValid = false;
      error.endDate = "End Date is Required";
    }
    //Checking gate
    if (data?.gate?.length === 0) {
      isValid = false;
      error.gate = "Gate is Required";
    }
    let initialDate = moment(data.startDate);
    let endDate = moment(data.endDate);

    if (endDate.diff(initialDate) >= 0) {
    } else {
      isValid = false;
      error.endDate = "End Date is More then Start Date";
    }
    setdata({ ...data, error });
    return isValid;
  };
  const add = () => {
    const payload2 = {
      tenantId: `${config.tenantid}`,
      pass_type: "PSR",
      start_date: data?.startDate,
      end_date: data?.endDate,
      gate_no: data?.gate?.value === "Any" ? undefined : data?.gate?.value,
      vechicle_number: data?.number,
      vechicle_type: data?.vehicleType?.value,
      user_profile_id: localStorage.getItem("userProfileId"),
      property_id: property?.id,
      unit_id: data?.unit?.unit_id,
      agreement_unit_id: data?.unit?.value,
      currency_id: data?.gate?.company
        ? data?.gate?.company[0]?.currency_id
        : 1,
    };
    NetworkCall(
      `${config.api_url}/security/create`,
      NetWorkCallMethods.post,
      payload2,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Parking Slot Successfully Created",
        });
        history.push(Routes.parkingPass);
        setDisable(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
        setDisable(false);
      });
  };
  const updatePass = () => {
    const payload2 = {
      tenantId: `${config.tenantid}`,
      pass_type: "PSR",
      start_date: data?.startDate,
      end_date: data?.endDate,
      gate_no: data?.gate?.value === "Any" ? undefined : data?.gate?.value,
      vechicle_number: data?.number,
      vechicle_type: data?.vehicleType?.value,
      user_profile_id: localStorage.getItem("userProfileId"),
      property_id: property?.id,
      unit_id: data?.unit?.unit_id,
      agreement_unit_id: data?.unit?.value,
      request_id: passId,
      // value: temp?.agreement_unit_id ?? "",
      // label: temp?.unit?.[0]?.name ?? "",
      // unit_id: temp?.unit?.[0]?.id ?? "",
      profiles: [],
      deleteProfiles: [],
      currency_id: data?.gate?.company?.[0]?.currency_id,
    };
    NetworkCall(
      `${config.api_url}/security/update`,
      NetWorkCallMethods.post,
      payload2,
      null,
      true,
      false
    )
      .then((response) => {
        setDisable(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Parking Slot Successfully Updated",
        });
        history.push(Routes.parkingPass);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something Went Wrong",
        });
        setDisable(false);
      });
  };
  const createPass = () => {
    if (isIamValideTostep1()) {
      if (passId?.length > 0) {
        updatePass();
      } else {
        add();
      }
      setDisable(true);
    } else {
      return false;
    }
  };


  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text="Create Parking Slot Requset"

        goBack={() => history.push(Routes.parkingPass)}
      />
      <Grid container>
        <Grid item className={classes.rootForm} xs={12} style={{ height: size?.height - 135 }}>
          <div style={{ padding: "12px" }}>
            <div className={classes.input}>
              <SelectBox
                isRequired
                label="Choose Unit"
                placeholder="Select Unit"
                value={data.unit}
                onChange={(value) => {
                  updateState("unit", value);
                }}
                // isPaginate
                // loadOptions={(search, array) => loadOptions(search, array, "unit")}
                // loading={loading1 === "unit"}
                // debounceTimeout={800}
                options={agreementList}
                isError={data?.error?.unit?.length > 0}
                errorMessage={data?.error?.unit}
              />
            </div>
            <div className={classes.input}>
              <SelectBox
                isRequired
                label="Choose Vehicle Type"
                placeholder="Vehicle Type"
                value={data.vehicleType}
                options={veicalType}
                onChange={(value) => updateState("vehicleType", value)}
                isError={data?.error?.vehicleType?.length > 0}
                errorMessage={data?.error?.vehicleType}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                isRequired
                color
                label="Enter Vehicle Number (Optional)"
                placeholder="Enter Vehicle Number"
                value={data.number}
                onChange={(e) => updateState("number", e.target.value)}
              />
            </div>

            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item xs={6}>
                <DateWithTime
                  isRequired
                  color
                  title="Tentative Start Date & Time"
                  value={data.startDate}
                  onChange={(value) => updateState("startDate", value)}
                  disablePast
                />
                {data?.error?.startDate?.length > 0 && (
                  <Typography
                    style={{ marginTop: "6px" }}
                    color="error"
                    variant="caption"
                  >
                    {data?.error?.startDate}
                  </Typography>
                )}
                {/* <DateWithTime
                  isRequired
                  disableFuture={true}
                  disablePast={false}
                  label="Tentative Start Date & Time"
                  placeholder="Start Date & Time"
                  value={data.startDate}
                  onChange={(value) => updateState("startDate", value)}
                  isError={data?.error?.startDate?.length > 0}
                  errorMessage={data?.error?.startDate}
                /> */}
              </Grid>
              <Grid item xs={6}>
                <DateWithTime
                  isRequired
                  color
                  title="Tentative End Date & Time"
                  value={data.endDate}
                  onChange={(value) => updateState("endDate", value)}
                />
                {data?.error?.endDate?.length > 0 && (
                  <Typography
                    style={{ marginTop: "6px" }}
                    color="error"
                    variant="caption"
                  >
                    {data?.error?.endDate}
                  </Typography>
                )}
                {/* <DateWithTime
                  isRequired
                  disableFuture={true}
                  disablePast={false}
                  label="Tentative End Date & Time"
                  placeholder="End Date & Time"
                  value={data.endDate}
                  onChange={(value) => updateState("endDate", value)}
                  isError={data?.error?.endDate?.length > 0}
                  errorMessage={data?.error?.endDate}
                /> */}
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  label="Choose Gate"
                  placeholder="Choose Gate "
                  value={data.gate}
                  onChange={(value) => updateState("gate", value)}
                  isError={data?.error?.gate?.length > 0}
                  errorMessage={data?.error?.gate}
                  isPaginate
                  key={JSON.stringify(data.unit)}
                  loadOptions={(search, array) => loadOptions(search, array, "gate")}
                  loading={loading1 === "gate"}
                  debounceTimeout={800}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              padding: "12px",
              backgroundColor: "white",
              boxShadow: "0px -1px 6px #00000021",
            }}
          >
            <Button
              fullWidth
              disabled={disable}
              className={classes.applybtn}
              variant="contained"
              onClick={createPass}
            >
              Submit
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
