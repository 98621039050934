import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import moment from "moment";
import * as React from "react";
import { Regular } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    margin: "0px",
    padding: "4px",
    border: "1px solid #E4E8EE",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 3px 30px #5C86CB2E",
    textAlign: "center",
    cursor: "pointer",
  },
  detailsActionCard: {
    margin: "0px",
    padding: "12px",
    color: "#FFFFFF",
    //backgroundImage: `url(${"/images/Group_4563.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#5078E1",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
    borderRadius: "10px",
    border: "1px solid #E4E8EE",
    justifyContent: "space-between",
    alignItems: "center",
  },
  payActionCard: {
    margin: "0px",
    padding: "12px",
    color: "#FFFFFF",
    backgroundImage: `url(${"/images/icons8-cash-receipt.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "#5078E1",
    backgroundPositionX: "85%",
    backgroundPositionY: "50%",
    borderRadius: "16px",
    border: "1px solid #E4E8EE",
    justifyContent: "space-between",
    alignItems: "center",
  },
  extraDetailsStyle: {
    padding: "16px",
  },
  title: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: "white",
    textAlign: "left",
  },
  subTitle: {
    fontSize: "10px",
    color: "white",
    textAlign: "left",
    fontFamily: Regular,
  },
  lable: {
    fontSize: "12px",
    color: "#98A0AC",
    fontFamily: Regular,
    textAlign: "left",
  },
  value: {
    fontSize: "12px",
    color: "#071741",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    textAlign: "left",
  },
  dividerStyle: {
    backgroundColor: "#E4E8EE",
    width: "1px",
    height: "24px",
  },
  iconButtonStyle: {
    background: "white",
    height: "24px",
    width: "24px",
  },
}));
export const QuotationCard = (props) => {
  const classes = useStyles();

  return (
    <Grid
      onClick={props.onClick}
      container
      direction="column"
      className={classes.root}
    >
      <Grid
        container
        direction="row"
        className={
          props?.type === "details"
            ? classes.detailsActionCard
            : classes.payActionCard
        }
      >
        <Grid container sm={11} xs={11} direction="column">
          <Typography className={classes.title}>
            {props?.type === "details"
              ? "Quotation"
              : "Pay against quotation #" + props?.quotationNo}
          </Typography>
          <Typography className={classes.subTitle}>
            {props?.type === "details"
              ? "Generated On " + props?.generatedOn ? moment(props?.generatedOn).tz(moment.tz.guess()).format("DD MMM YY") : ""
              : "Expires On " + props?.expiresOn ? moment(props?.expiresOn).tz(moment.tz.guess()).format("DD MMM YY") : ""}
          </Typography>
        </Grid>
        <Grid
          container
          sm={1}
          xs={1}
          justifyContent="right"
          alignItems="center"
        >
          <IconButton
            onClick={props.onClick}
            className={classes.iconButtonStyle}
          >
            <ChevronRightIcon />
          </IconButton>
        </Grid>
      </Grid>
      {props?.type === "details" && (
        <Grid
          container
          className={classes.extraDetailsStyle}
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item direction="column">
            <Typography className={classes.lable}>Quotation No</Typography>
            <Box height="4px" />
            <Typography className={classes.value}>
              {"#" + props?.quotationNo}
            </Typography>
          </Grid>
          <Box className={classes.dividerStyle} />
          <Grid item direction="column">
            <Typography className={classes.lable}>Expires On</Typography>
            <Box height="4px" />
            <Typography className={classes.value}>
              {props?.expiresOn ? moment(props?.expiresOn).tz(moment.tz.guess()).format("DD MMM YY") : ""}
            </Typography>
          </Grid>
          <Box className={classes.dividerStyle} />
          <Grid item direction="column">
            <Typography className={classes.lable}>Lease Period</Typography>
            <Box height="4px" />
            <Typography className={classes.value}>
              {props?.leasePeriod}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
