/* eslint-disable array-callback-return */
import { useApolloClient } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { DomesticCard, Filter, LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  GET_AGREEMENT_UNITS
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods, enumSelect } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
    overflow: "hidden",
    height: '100%'
  },
  menuText: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    marginTop: '30%'
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "10px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  filter: {
    borderRadius: "6px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "120px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "80px",
      left: "64%",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
}));

export const DomesticHelpList = () => {
  const size = useWindowDimensions();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const classes = useStyles();
  const history = useHistory();
  const client = useApolloClient();
  const [list, setList] = React.useState([]);
  // const [units, setUnits] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [drawer, setDrawer] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const alert = React.useContext(AlertContext);
  // const [unitsfil, setUnitsfil] = React.useState([]);
  const [unitID, setUnitID] = React.useState([]);
  const [statusType, setStatusType] = React.useState([]);

  const getPassList = (unit, search, offset, filter, status) => {
    const data = {
      tenantId: `${config.tenantid}`,
      offset: offset,
      limit: 20,
      agreement_unit_id: unit,
      query: search,
      status: status,
    };

    NetworkCall(
      `${config.api_url}/services/domestic/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter) {
          setList(response?.data?.data?.response);
        } else {
          setList(list.concat(response.data.data?.response));
        }
        setDrawer(false);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };
  const getUnitids = () => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded.id,
    };
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let agreement = response?.data?.data?.agreements?.result?.map((x) => x?.id)
        let data = response?.data.data.units.result;
        const request_units = data.map(({ id }) => id);


        getagreementUnitid(agreement, request_units)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing went wrong",
        });
      });
  };

  const getagreementUnitid = (v, x) => {
    client.query({
      query: GET_AGREEMENT_UNITS(),
      fetchPolicy: 'network-only',
      variables: {
        agreementId: v,
        unitId: x,
      }
    }).then((res) => {
      setUnitID(res?.data?.agreement_units?.map((x) => x?.id))
      getEnum(res?.data?.agreement_units?.map((x) => x?.id))
    }).catch((err) => {

    })
  }
  const getEnum = async (data) => {
    const stayType = await enumSelect(['domestic_value_type'])
    setStatusType(stayType?.domestic_value_type)
    getPassList(
      data,
      "",
      0,
      false,
      stayType?.domestic_value_type?.map((val) => val?.value)
    );
    setSelectedFilter(stayType?.domestic_value_type?.map((val) => val?.value));

  }
  React.useState(() => {
    getUnitids();
  }, []);
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(unitID, "", offset + 20, false, selectedFilter);
  };
  const filterData = [
    {
      id: "1",
      title: "Status",
      key: "status",
      showSearch: true,
      filterType: "CHECKBOX",
      values: statusType
    },
  ];
  const applyFilter = (val) => {
    if (val?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Atleast select one status",
      });
      return false;
    }
    setOffset(0);
    setSelectedFilter(val?.status);
    getPassList(unitID, "", 0, true, val?.status);
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text="Domestic Help Request"

        goBack={() => history.push(Routes.service)}
      />
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                  <TextField
                    size="small"
                    variant="outlined"

                    placeholder="Search"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                    fullWidth
                    className={classes.search}
                    onChange={(e) =>
                      getPassList(unitID, e.target.value, 0, true)
                    }
                  />
                </Box>
                <Box marginLeft="8px">
                  <IconButton
                    className={classes.filter}
                    onClick={() => setDrawer(true)}
                  >
                    <Badge variant="dot" color="primary">
                      <img src="/images/icons8-funnel.svg" alt="filter" />
                    </Badge>
                  </IconButton>
                </Box>
              </Box>
              <div style={{ marginRight: "-6px" }}>
                {loading ? (
                  <LoderSimmer count={10} />
                ) : list.length > 0 ? (
                  <InfiniteScroll
                    dataLength={list.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 150}
                  >
                    <Box padding={"16px"}>
                      <div>
                        {list?.map((item) => {
                          return <Box marginBottom={"12px"}>
                            <DomesticCard data={item} />
                          </Box>
                        })}
                      </div>
                    </Box>
                  </InfiniteScroll>
                ) : (
                  <Typography className={classes.menuText} textAlign="center">
                    No records found
                  </Typography>
                )}
              </div>

              <Fab
                color="primary"
                className={classes.fab}
                onClick={() => history.push(Routes.domesticCreate)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={() => setDrawer(false)}
          fullWidth
          className={classes.dialog}
          maxWidth="sm"
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter,
            }}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
            onApply={applyFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={drawer}
          onClose={() => setDrawer(false)}
          className={classes.dialog}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter,
            }}
            onApply={applyFilter}
            onGoBack={() => setDrawer(false)}
            onClose={() => setDrawer(false)}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};
