import makeStyles from "@mui/styles/makeStyles";
import { Bold , SemiBold , ExtraBold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: "16px",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: ExtraBold,
    color: theme.typography.color.primary,
  },
  title: {
    fontSize: "16px",
    fontFamily: Bold,
    color: theme.typography.color.secondary,
  },
  titleroot: {
    borderBottom: `1px solid ${theme.palette.border.tertiary1}`,
    padding: "4px 8px 8px 8px",
  },
  requsettop: {
    backgroundColor: theme?.palette?.background?.tertiary2,
    padding: "4px",
    borderRadius: "5px",
    fontSize: "12px",
    fontFamily: SemiBold,
  },
  requsettitle: {
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: Bold,
  },
  requsetsub: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "8px",
  },
  requsetsubs: {
    fontSize: "14px",
    fontFamily: SemiBold,
  },
  requsetsubs2: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    display:"flex",
    alignItems:"center"
  },
  requsetsubs3: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    alignSelf: "end",
  },
  avatar: {
    backgroundColor: theme?.palette?.background?.avatar,
  },
  pa: {
    color: theme.typography.color.primary,
  },
  cancelbtn: {
    padding: "12px",
    border: `1px solid ${theme?.palette?.primary?.main}`,
    backgroundColor: "white",
    borderRadius: "10px",
    color:theme?.palette?.primary?.main
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "16px",
      borderTopLeftRadius: "16px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "16px",
      padding: "0px",
    },
  },
  submitbtn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 6px 10px ${theme?.palette?.boxShadow?.primary}`,
    padding: "8px",
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      color: theme?.palette?.background?.tertiary1,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  status:{
    color:`${theme?.typography?.color?.Tertiary} !important`,
    fontSize:"14px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif", 
  },
  subStatus:{
    color:theme?.palette?.border?.status,
    fontSize:"14px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif", 
  },
  statusBox:{
    borderRadius:"4px",
    backgroundColor:theme?.palette?.info?.light,
    padding:"16px"
  },
  notesTitle:{
    color:theme?.typography?.color?.primary,
    fontSize:"14px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
  },
  createnewsectionlog1: {
    padding: "14px 20px 30px 26px",
    height:`calc(100vh - 394px)`,
    overflow:"auto",
    backgroundColor:"white",
  },
  dateTime:{
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: "#7C8594",
  },
  innerImage:{
    height: "100%",
    width: "100%",
    backgroundColor: theme?.typography?.status?.primary,
  },
  notesTimeLine:{
    color:theme?.palette?.background?.primary,
    fontSize:"12px",
    fontFamily:"'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif"
  },
  noteTimeLine:{
    color:theme?.typography?.color?.secondary,
    fontSize:"14px",
    fontFamily:"'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    maxWidth:"624px",
    width:'100%',
    textAlign:"left"
  },
  border: {
    borderLeft: `2px dashed ${theme?.palette?.primary?.main}`,
    padding: "0px 40px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  avatars1: {
    position: "absolute",
    top: 0,
    left: '-22px',
    height: "45px",
    width: "45px",
    backgroundColor: "#F15A2914",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    padding:"10px"
  },
  image:{
    backgroundColor:theme?.palette?.primary?.main,
    borderRadius:"50%",
    padding:"10px 9px",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
  },
  imageStyle:{
    height: "75px",
    width: "75px",
    borderRadius: "10px",
    margin: "0px 16px 0px 0px",
    objectFit:"cover"
  },
  notes:{
    fontSize: "12px",
    fontFamily: SemiBold,
    color: theme?.typography?.color?.secondary,
  },
  recentNote:{
    fontSize: "15px",
    fontFamily: Bold,
    color: theme?.typography?.color?.primary,
  },
  imageStyleView:{
    height: "100%",
    width: "100%",
    margin:'0 auto',
    objectFit:"cover"
  },
  divider:{
    backgroundColor:theme?.palette?.background?.background,
    height:"6px"
  }
}));
