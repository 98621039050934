import { CheckBox } from "@mui/icons-material";
import { Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  InvoiceListGrid: {
    padding: "8px 0px",
  },
  checkBox: {
    color: theme.palette.primary.main,
    borderRadius: "5px",
  },
  title: {
    fontSize: "14px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    color: theme.typography.color.primary,
  },
  subTitle: {
    fontSize: "12px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,
  },
  roundShape: {
    width: "6px",
    height: "6px",
    backgroundColor: theme.palette.border.primary,
    borderRadius: "100%",
    display: "inline-block",
    marginBottom: "2px",
  },
  amount: {
    fontSize: "16px",
    fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    color: theme.typography.color.secondary,
    textAlign: "end",
  },
  status: {
    fontSize: "10px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    backgroundColor: theme.palette.warning.light,
    borderRadius: "4px",
    color: theme.palette.warning.main,
    padding: " 1px 7px",
    float: "right",
  },
  statusPaid: {
    fontSize: "10px",
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
    backgroundColor: theme.palette.success.light,
    borderRadius: "4px",
    color: theme.palette.success.main,
    padding: " 1px 7px",
    float: "right",
  },
}));

const InvoiceList = ({
  invoiceData = {},
  t,
  onCheck = () => false,
  viewinvoice = () => false,
}) => {
  const classes = useStyles();

  moment.defaultFormat = "DD MMM YY";

  const handlefun = (invoiceData) => {
    if (invoiceData.payment_status !== "no-due") {
      onCheck(invoiceData.active ? false : true, invoiceData);
    } else {
    }
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        onClick={() => handlefun(invoiceData)}
        alignItems="center"
        className={classes.InvoiceListGrid}
      >
        <Grid item xs={8} display="flex" alignItems="center">
          {invoiceData.active && (
            <Grid item xs={1} marginRight={2}>
              <CheckBox className={classes.checkBox} />
            </Grid>
          )}

          <Grid item xs={11}>
            <Typography className={classes.title}>
              {invoiceData.description ? invoiceData.description : ""}
            </Typography>
            <Typography className={classes.subTitle}>
              {invoiceData.invoice_no}
            </Typography>

            <Typography className={classes.subTitle}>
              {invoiceData?.invoice_type && invoiceData?.invoice_type}

              {invoiceData?.invoice_type && (
                <>
                  &nbsp;
                  <span className={classes.roundShape} />
                </>
              )}
              {invoiceData.payment_due_date && (
                <>
                  &nbsp;&nbsp;
                  {moment(invoiceData.payment_due_date)
                    .tz(moment.tz.guess())
                    .format()}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <div style={{ float: "right" }}>
            <Typography className={classes.amount}>
              {invoiceData.invoice_due_amount &&
                invoiceData.currency_master.symbol + " " +
                Intl.NumberFormat("en-IN", {
                  minimumFractionDigits: 0,
                }).format(Math.round(invoiceData.invoice_due_amount))}
            </Typography>

            {invoiceData.payment_status != null && (
              <span>
                {invoiceData.payment_status === "due" && (
                  <span className={classes.status}>{t("pending")}</span>
                )}
                {invoiceData.payment_status === "no-due" && (
                  <span className={classes.statusPaid}>{t("paid")}</span>
                )}
              </span>
            )}
          </div>
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
};
export default withNamespaces("invoice")(InvoiceList);
