import * as React from "react"

const Bicycle2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Group 96504"
    width={16.509}
    height={24}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 52995"
          fill="#5078e1"
          d="M0 0h16.509v24H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 96503" clipPath="url(#a)" fill="#5078e1">
      <path
        data-name="Path 8998"
        d="M15.943 0c.459.251.635.548.543.919-.1.4-.394.581-.943.581h-2.086a.864.864 0 0 0-.669.277c-.194.2-.394.4-.6.6A2.2 2.2 0 0 1 10.646 3H9.019v6.78a8.3 8.3 0 0 1 1.146.008 2.968 2.968 0 0 1 2.588 2.829c0 .037.007.074.013.133.509 0 1.007-.007 1.5 0a.748.748 0 0 1 0 1.495c-.289.009-.578 0-.867 0h-.651V19.438a.749.749 0 0 1-1.03.749.759.759 0 0 1-.471-.766V12.811A1.5 1.5 0 0 0 9.7 11.25H6.817a1.5 1.5 0 0 0-1.558 1.568v6.561a1.2 1.2 0 0 1-.063.412.741.741 0 0 1-.794.451.759.759 0 0 1-.639-.707c-.007-.125 0-.25 0-.375v-.411h-.356c-.391 0-.781.007-1.172 0a.747.747 0 0 1 0-1.494c.5-.01 1 0 1.515 0V12.833a2.994 2.994 0 0 1 3.084-3.084h.651V3h-.709c-.429-.009-.86 0-1.285-.043a1.956 1.956 0 0 1-1.155-.57q-.314-.3-.615-.611a.872.872 0 0 0-.67-.276c-.7.007-1.39 0-2.086 0-.546 0-.844-.184-.942-.582C-.068.545.106.251.567 0h2.86A2.886 2.886 0 0 1 5.1 1.036c.052.058.113.108.165.166A.9.9 0 0 0 6 1.5c1.428-.007 2.857-.018 4.285.006a1.32 1.32 0 0 0 1.132-.47A2.784 2.784 0 0 1 13.083 0Z"
      />
      <path
        data-name="Path 8999"
        d="M6.007 17.986v-4.38a1.5 1.5 0 0 1 1.612-1.607h1.359a1.49 1.49 0 0 1 1.528 1.526q.006 4.473 0 8.947a1.491 1.491 0 0 1-1.529 1.525q-.726.006-1.452 0a1.492 1.492 0 0 1-1.516-1.514c-.006-1.5 0-3 0-4.5m1.516 4.493h1.472v-8.969H7.522Z"
      />
    </g>
  </svg>
)

export default Bicycle2
