import makeStyles from "@mui/styles/makeStyles";
import { Bold } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  btnRoot: {
    backgroundColor: theme?.palette?.background?.secondary,
    padding: "16px",
    border: `1px solid ${theme?.palette?.border?.tertiary}`,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: `0px 6px 10px ${theme?.palette?.boxShadow?.primary}`,
    padding: "8px",
    color: theme?.palette?.background?.tertiary1,
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      color: theme?.palette?.background?.tertiary1,
      backgroundColor: theme?.palette?.primary?.main,
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    fontFamily: Bold,
    color:  theme?.palette?.background?.tertiary1,
  },
}));
