import { Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import {
  AlertProps,
  Message,
  NetWorkCallMethods,
  ValidateEmail,
} from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px",
  },
  btn: {
    padding: "7px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "opx 6px 10px #00000014",
    marginTop: 30,
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
}));

const InitialState = {
  email: "",
  error: {
    email: "",
  },
};

export const ForgetPassword = (props) => {
  const classes = useStyles();
  const [forgetMail, setForgetMail] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);
  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = forgetMail.error;
    error[key] = "";
    setForgetMail({ ...forgetMail, [key]: value, error });
  };
  const isIamValideToforgetMail = () => {
    let isValid = true;
    let error = forgetMail.error;
    // Checking Email
    if (forgetMail.email.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage("Email");
    }
    setForgetMail({ ...forgetMail, error });
    return isValid;
  };
  const onSendforgetMailBtnClicked = () => {
    if (isIamValideToforgetMail()) {
      const data = {
        email_id: forgetMail.email,
      };

      NetworkCall(
        `${config.auth_api_url}/auth/forgotpassword`,
        NetWorkCallMethods.post,
        data,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 200) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Password reset email sent successfully",
            });
            props.onDrawerClose();
          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Invalid Email ID",
          });
        });
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <br />
          <TextBox
            label="Registered Email ID"
            placeholder="Enter Registered Email ID"
            value={forgetMail.email}
            isError={forgetMail.error.email.length > 0}
            errorMessage={forgetMail.error.email}
            onChange={(e) => updateState("email", e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <br />

          <Button
            fullWidth
            variant="contained"
            className={classes.btn}
            onClick={onSendforgetMailBtnClicked}
          >
            Submit
          </Button>
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};
