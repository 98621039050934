import React from "react";
import { Box, Typography } from "@mui/material";
import { useStyle } from "../style";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Card = ({ data = {}, onClick = () => false }) => {
  const classes = useStyle();
  return (
    <div className={classes.parentBox}>
      <Box
        className={classes.assetsBox}
        onClick={onClick}
        style={{ backgroundImage: `url(${data?.image})` }}
      >
        <Typography className={classes.catagoryTitle}>{data?.title}</Typography>
        <ArrowForwardIosIcon className={classes.arrowIcon} />
      </Box>
    </div>
  );
};
