import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
    status: {
        backgroundColor: "white",
        padding: "auto 12px",
        border: "1px solid #E4E8EE",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 3px 18px #0000000F",
        cursor: "pointer",
    },
    statusdes: {
        fontSize: "16px",
        fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
    },
}));