import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    minHeight: "94px",
    display: "flex"
  },
  Cardcontent: {
    padding: "12px 16px",
    flex: "auto",
    [theme.breakpoints.down(321)]: {
      padding: "8px",

    },
  },
  title: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: props => FontFamilySwitch(props?.language).bold,
  },
  count: {
    fontSize: "20px",
    color: theme.typography.color.primary,
    fontFamily: props => FontFamilySwitch(props?.language).bold,
  },
}));

export const StatCard = (props) => {
  const authContext=props?.authContext
  const classes = useStyles({ language: authContext?.auth?.auth?.language });
  const history = useHistory();

  return (
    <>
      <div
        className={classes.card}
        onClick={() => {
          history.push({
            pathname: props.data.link,
            state: props.data.main,
          });
        }}
      >

        <div className={classes.Cardcontent}>
          <Grid container>
            <Grid
              item
              xs={7}
            >
              <Typography variant="body1" className={classes.count}>
                {props.data.count}
              </Typography>
            </Grid>
            <Grid item xs={5} textAlign="end">
              <Box mt={"-12px"} />
              <img src={props.data.icon} alt="icon" />
            </Grid>
          </Grid>
          <Box mt={"10px"}>
            <Typography variant="subtitle2" className={classes.title}>
              {props.data.title[0].concat(" ", props.data.title[1])}
            </Typography>
          </Box>
        </div>
      </div>
    </>
  );
};
