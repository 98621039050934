import makeStyles from "@mui/styles/makeStyles";

export const AmenitiesBookingStyles = makeStyles((theme) => (
    {

        root: {
            padding: "0px",
            backgroundColor: "#F2F5FA",
        },
        roots: {
            margin: "0px 12px",
            padding: "12px 0px 0px 5px",
            overflow: "scroll",
        },
        image: {
            objectFit: "cover",
            width: "64px",
            borderRadius: theme.palette.borderRadius,
            height: "60px",
            border: "1px solid #5C86CB2E",
        },
        card: {
            backgroundColor: "white",
            // padding: "16px 16px 9px 16px",
            padding: "16px 0px 0px",
            marginTop: "12px",
            borderRadius: "8px",
        },
        name: {
            fontSize: "12px",
            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
            color: theme.typography.color.Tertiary,
            marginBottom: "8px"

        },
        total: {
            fontSize: "16px",
            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
            color: "#071741",
            marginTop: "12px",
        },
        arrow: {
            border: "1px solid #E4E8EE",
            backgroundColor: "#F2F4F7",
            fontSize: "10px",
        },
        noData: {
            color: "#999",
            textAlign: "center",
            marginTop: "20vh",
            fontSize: "14px",
            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
            marginLeft: "12px"
        },
        slots: {
            backgroundColor: "white",
            padding: "14px",
            borderRadius: "16px",
            marginTop: "14px",
            boxShadow: "0px 3px 30px #5C86CB2E",
        },
        titles: {
            fontSize: "16px",
            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
            color: theme.typography.color.primary,
        },
        modalTitle: {
            fontSize: "16px",
            fontFamily: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif",
            color: theme.typography.color.primary,
        },
        title2: {
            fontSize: "12px",
            fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
            color: theme.typography.color.primary,
        },
        totalAmount: {
            fontSize: "14px",
            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
            color: theme.typography.color.primary,
        },

        subTitle: {
            fontSize: "12px",
            fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
            color: theme.typography.color.secondary,
        },
        drawer: {
            "& .MuiDrawer-paper": {
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                padding: "0px",
            },
        },
        dialog: {
            "& .MuiDialog-paper": {
                borderRadius: "16px",
                padding: "0px",
            },
        },
        Cardcontent: {
            [theme.breakpoints.up("sm")]: {
                padding: "12px",
                backgroundColor: "#F5F7FA"

            },

            [theme.breakpoints.down("sm")]: {
                padding: "12px",
                backgroundColor: "#F5F7FA"
            },
        },
        btn: {
            borderRadius: theme.palette.borderRadius,
            padding: "10px"
        },
        flex: {
            display: "flex",
            alignItems: "center"
        },
        justifyContent: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "10px"
        },
        flexWithPadding: {
            display: "flex",
            padding: "8px",
            borderBottom: "1px solid #c1c1c1",
            alignItems: "center"
        },
        divider: {
            backgroundColor: "#F2F4F7",
            height: "4px",
            margin: "8px 0px"
        },
        dot: {
            height: "6px",
            width: "6px",
            borderRadius: "50%",
            backgroundColor: "red",
            margin: "6px 6px 6px 8px",
        },
        tabroot: {
            display: "flex",
            alignItems: "center",
            "& .MuiTabs-indicator": {
                display: "none"
            }
        },
        tabItemroot: {

            "& .MuiButtonBase-root-MuiTab-root": {
            },

        },
        tabItem: {
            borderRadius: "4px",
            padding: "8px",
            minHeight: "auto",
            minWidth: "auto",
            maxHeight: "68px",
            margin: "0px 14px",
            "& .MuiButtonBase-root-MuiTab-root": {
                borderRadius: "4px",
            },

        },
        tabItemSelect: {

            backgroundColor: "#F2F5FA",
            borderRadius: "4px",
            padding: "8px",
            minHeight: "auto",
            minWidth: "auto",
            maxHeight: "68px",
            margin: "0px 14px",
            "& .MuiButtonBase-root-MuiTab-root": {
                minHeight: 0,
                borderRadius: "4px",

            },
        },
        textSelect1: {
            color: theme.palette.primary.main,
            fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
            fontSize: "12px"
        },
        textSelect2: {
            color: theme.palette.primary.main,
            fontSize: "18px"
        },
        textNotSelect1: {
            color: theme.typography.color.Tertiary,
            fontFamily: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
            fontSize: "12px"
        },
        textNotSelect2: {
            color: theme.typography.color.secondary,
            fontSize: "18px"
        },
        panel: {
            padding: "12px !important"
        },
        box: {
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            padding: "8px",
            borderRadius: "8px",
            marginBottom: "11px",
            cursor: "pointer"
        },
        tagStyle: {
            backgroundColor: "#F1E6FE",
            borderRadius: "4px",
            padding: "4px 6px 4px 6px",
            fontSize: "8px",
            fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
            color: "#5D427F",
            marginRight: "16px",

        },
        borderGrid: {
            display: "flex",
            borderBottom: "2px dashed #CED3DD",
            borderTop: "2px dashed #CED3DD",
            padding: "12px 0px",
            marginLeft: "10px"
        }
    }));
