import { Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F5F7FA",
  },
  check: {
    fontSize: "75px",
    color: theme.palette.success.main,
  },
  msg: {
    fontWeight: "bold",
    fontSize: "20px",
    marginTop: "8px",
  },
}));

export const Success = ({ failure = false }) => {
  const classes = useStyles();
  const size = useWindowDimensions();

  return (
    <Container
      maxWidth="sm"
      className={classes.root}
      style={{ height: size?.height }}
    >
      <div>
        <img
          src={!failure ? "https://i.pinimg.com/originals/a1/05/7d/a1057dcb644d8729f4f76b2032f21743.gif" : "/images/failed.gif"}
          alt=""
          style={{ height: "50%", width: "100px", borderRadius: "60%" }}
        />
        <Typography variant="body1" textAlign="center" className={classes.msg}>
          {failure ? "Payment Failed" : "Payment Success"}
        </Typography>
      </div>
    </Container>
  );
};
