import makeStyles from "@mui/styles/makeStyles";
export const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.background.tertiary1,
    padding: "12px 20px",
    minHeight: "54px",
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    // display: "flex",
    // justifyContent: "center",
    position: "relative",
    // textAlign:(props)=>props?.is_center ? "left" : "center"
  },
  navbarimg: {
    height: "36px",
    marginTop: "4px",
  },
  backArrow: {
    width: "22px",
    height: "19px",
    margin:'0 auto'
  },
  iconPosition: {
    position: "absolute",
    left: "2px",
    top:'-3px',
    bottom:'0px'
  },
  title: {
    color: theme?.typography?.color?.primary,
    fontSize:'16px',
    fontFamily: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
  },
}));
