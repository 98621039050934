import React from "react";
import  MaintanceRequest  from './maintanceRequest';
import { withRouter } from 'react-router-dom';

class MaintanceRequestParent extends React.Component {

    render() {
        return <MaintanceRequest />;
    }
}

export default withRouter(MaintanceRequestParent);
