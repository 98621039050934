import React from "react";
import { ExperiedAgreement } from './experiedagreement';

class ExperiedAgreementParent extends React.Component {
    render() {
        return <ExperiedAgreement />;
    }
}

export default ExperiedAgreementParent;
